import React from "react";

import styles from "./styles.module.scss";

import { TrendIcon } from "../../../shared";

import { charInsteadOfEmpty } from "../../../../utils/general-helper";

import { KPIContentType } from "../../../../types";

const KPIContent = ({ question, value, trendIcon, answerType, previousPeriod, ranks = [] }) => {
  let { periodText, periodValue } = previousPeriod;

  return (
    <div className={styles["kpi-wrapper"]}>
      <div className={styles["question-title"]}>{charInsteadOfEmpty(question, "...")}</div>
      <div className={styles["stats-area"]}>
        <div className={styles["value-wrapper"]}>{getValueAndIndicator(value, trendIcon)}</div>
        <div className={styles["answer"]}>{answerType}</div>
        <div className={styles["previous-period"]}>
          {periodText}: {charInsteadOfEmpty(periodValue)}
        </div>
      </div>
      <div className={styles["details"]}>
        {ranks &&
          ranks.map(({ title, rank }) => {
            return (
              <div key={title} className={styles["detail-row"]}>
                <div className={styles["title"]}>{title}</div>
                <div className={styles["rank"]}>{charInsteadOfEmpty(rank)}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const getValueAndIndicator = (value, trendIcon) => {
  if (value) {
    return (
      <React.Fragment>
        <div className={styles["value"]}>{dashAsEmpty(value)}</div>
        <div className={styles["icon"]}>
          <TrendIcon icon={trendIcon} size={22} />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className={styles["value-small"]}>No Data</div>
      </React.Fragment>
    );
  }
};

const dashAsEmpty = (val, char = "-") => (val ? val : char);

KPIContent.propTypes = KPIContentType;

export default KPIContent;
