import React from "react";
import { ConversationsStatus } from "./SurveyIngestionTypes";

import styles from "./ProcessConversationsStatus.module.scss";

type ProcessConversationsStatusProps = {
  status: ConversationsStatus;
  isPreview: boolean;
};
const ProcessConversationsStatus: React.FC<ProcessConversationsStatusProps> = ({
  status,
  isPreview,
}: ProcessConversationsStatusProps) => {
  const { state, nbInvalidRows, nbProcessedRows, nbSkippedRows, nbTotalRows, nbValidRows, nbRepliesToCategorize } = status;

  return (
    <div className={styles.processConversationsStatus}>
      <div className={styles.title}>{isPreview ? "Preview Results" : "Ingest Results"}</div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>State:</div>
        <div className={styles.resultValue}>{state}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Processed rows:</div>
        <div className={styles.resultValue}>{nbProcessedRows}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Total rows:</div>
        <div className={styles.resultValue}>{nbTotalRows}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Progression:</div>
        <div className={styles.resultValue}>{nbProcessedRows && nbTotalRows ? Math.round(nbProcessedRows / nbTotalRows * 100) : 0}%</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Conversations {isPreview ? "to be created" : "created"}:</div>
        <div className={styles.resultValue}>{nbValidRows}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Invalid Conversations:</div>
        <div className={styles.resultValue}>{nbInvalidRows}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Skipped Conversations:</div>
        <div className={styles.resultValue}>{nbSkippedRows}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Total Conversations in File:</div>
        <div className={styles.resultValue}>{nbTotalRows}</div>
      </div>
      {!isPreview && state === "completed" && (
        <div className={styles.completion}>
          You have {nbRepliesToCategorize ?? 0} answer(s) to categorize; they have been queued and will be processed in the background.
        </div>
      )}
    </div>
  );
};
export default ProcessConversationsStatus;
