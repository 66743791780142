import React from "react";
import CSVReader from "react-csv-reader";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";

const parseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

const DataEntryGrid = ({
  title,
  initialValues,
  name = "data_grid",
  rows = 10,
  columns,
  emptyRow,
  dataParser,
  rowSetter,
  templatePath,
}) => {
  const [grid, setGrid] = React.useState(initialGrid(rows, columns, emptyRow));

  const [values, setValues] = React.useState(initialValues);
  //update the table when the values are edited (values are either initial values or csv import)
  React.useEffect(() => {
    if (values) {
      setGrid(setGridValues(values, columns, emptyRow, rows, rowSetter) || grid);
    }
  }, [values]);

  return (
    <>
      <input
        type="text"
        name={name}
        style={{ display: "none" }}
        value={JSON.stringify(dataParser(grid))}
      />
      <div className="data-entry-header">
        <h2>{title}</h2>
        {rowSetter && (
          <div className="data-entry-header-actions">
            {templatePath && (
              <a className="btn deployment-button" href={templatePath} download>
                Download Template
              </a>
            )}
            <CSVReader
              label="Import from CSV"
              inputId={`csv-${name}`}
              cssLabelClass="deployment-button"
              name="csv-upload"
              onFileLoaded={(data) => setValues(data)}
              parserOptions={parseOptions}
            />
          </div>
        )}
      </div>
      <ReactDataSheet
        overflow="wrap"
        data={grid}
        valueRenderer={(cell) => cell.value}
        cellRenderer={({ onDoubleClick, children, className, cell, onMouseDown }) => {
          return (
            <td
              onClick={
                cell.editOnClick
                  ? (e) => {
                      onMouseDown(e);
                      onDoubleClick(e);
                    }
                  : onMouseDown
              }
              onDoubleClick={onDoubleClick}
              className={className}
            >
              {children}
            </td>
          );
        }}
        onCellsChanged={(changes) => {
          const newGrid = grid.map((row) => [...row]);
          changes.forEach(({ row, col, value }) => {
            newGrid[row][col] = { ...newGrid[row][col], value };
            grid[row].forEach((cell, index) => {
              if (cell.dependsOn && cell.dependsOn == newGrid[row][col].id) {
                newGrid[row][index] = {
                  ...newGrid[row][index],
                  [cell.dependanceChange.object]: cell.dependanceChange.change[value].field,
                  value: cell.dependanceChange.change[value].value,
                };
              }
            });
          });
          setGrid(newGrid);
        }}
      />
      <div className="new-row-container">
        <a className="deployment-button row-action" onClick={() => deleteLastRow(grid, setGrid)}>
          -
        </a>
        <a
          className="deployment-button row-action"
          onClick={() => addNewRow(grid, setGrid, emptyRow)}
        >
          +
        </a>
      </div>
    </>
  );
};

const deleteLastRow = (grid, setGrid) => {
  const newGrid = [...grid];
  newGrid.pop();
  setGrid(newGrid);
};

const addNewRow = (grid, setGrid, emptyRow) => {
  setGrid([...grid, emptyRow]);
};

const setGridValues = (values, columns, emptyRow, rows, rowSetter) => {
  if (!values || !columns || !emptyRow || !rows || !rowSetter) {
    return null;
  }
  const grid = [];
  grid[0] = columns.map((c) => ({ value: c, readOnly: true }));
  for (let i = 0; i < values.length; i++) {
    const newRow = rowSetter(values[i]);
    if (newRow) {
      grid.push(rowSetter(values[i]));
    }
  }
  // keep a minumum of the default amount of rows
  const filledRows = grid.length - 1;
  if (filledRows === 0) {
    alert("the csv's format did not match the expected input, please correct your file");
    return null;
  }
  if (filledRows < rows) {
    for (let i = filledRows; i <= rows - 1; i++) {
      grid.push(emptyRow);
    }
  }

  return grid;
};

const initialGrid = (rows, columns, emptyRow) => {
  const grid = [];
  grid[0] = columns.map((c) => ({ value: c, readOnly: true }));
  for (let i = 1; i <= rows; i++) {
    grid[i] = emptyRow;
  }

  return grid;
};

export default DataEntryGrid;
