import {modelSeriesArray} from "../components/utils/chart-helper"

jQuery ->

  # short circuit if not on right page
  return unless window.location.pathname.includes("/alerts")

  require("chosen-js")
  require('chosen-js/chosen.css')
  require("url-search-params-polyfill")

  $(".alertmanager-filters-poc-dropdown").chosen()
  $(".alertmanager-filters-survey-dropdown").chosen()
  $(".alertmanager-filters-metadata-dropdown").chosen()

  # document ready
  $ ->
    # short circuit unless we're on the alert manager page
    return unless $('.alertmanager-container').length

    autosize($('.resolution-container .resolution-text'))

    # select unresolved section first
    $('div.alertmanager-alert-list-title-unresolved').trigger "click"

  filterAlerts = () ->
    survey = $('.alertmanager-filters-survey-dropdown').val()
    poc = $('.alertmanager-filters-poc-dropdown').val()
    selectedMetadata = {}
    $('.alertmanager-filters-metadata-dropdown').each (_,el) ->
      selectedMetadata[$(el).data('metadataKey')] = $(el).val()

    $(".alertmanager-alert-entry").each ->
      alertEntry =  $(this)
      alertMetadata = alertEntry.data('metadata')

      alertEntry.removeClass( "hidden" )
      if survey
        if alertEntry.data("survey-id").toString() != survey.toString()
          alertEntry.addClass( "hidden" )
      if poc
        if alertEntry.data("poc-name") != poc
          alertEntry.addClass( "hidden" )

      Object.keys(selectedMetadata).forEach (metadataKey) ->
        if selectedMetadata[metadataKey]
          unless alertMetadata
            alertEntry.addClass( "hidden" )
          else unless alertMetadata[metadataKey]
            alertEntry.addClass( "hidden" )
          else if alertMetadata[metadataKey].toString() != selectedMetadata[metadataKey]
            alertEntry.addClass( "hidden" )

  initiateFilters = () ->
    params = new URLSearchParams(window.location.search.substring(1))
    survey = params.get("alert_survey")
    poc = params.get("alert_poc")
    $('.alertmanager-filters-poc-dropdown').val(poc)
    $('.alertmanager-filters-poc-dropdown').trigger("chosen:updated")
    $('.alertmanager-filters-survey-dropdown').val(survey)
    $('.alertmanager-filters-survey-dropdown').trigger("chosen:updated")
    filterAlerts()

  initiateFilters()

  #make sure you reset the right state when navigatinf back / forward on the browser
  if window.history && window.history.pushState
    $(window).on "popstate", ->
      initiateFilters()

  # filters
  $('.alertmanager-filters-poc-dropdown').change ->
    url = new URL(window.location)
    if $(this).val()
      url.searchParams.set('alert_poc', $(this).val())
    else
      url.searchParams.delete('alert_poc')
    window.history.pushState({}, '', url)
    filterAlerts()

  $('.alertmanager-filters-survey-dropdown').change ->
    url = new URL(window.location)
    if $(this).val()
      url.searchParams.set('alert_survey', $(this).val())
    else
      url.searchParams.delete('alert_survey')
    window.history.pushState({}, '', url)
    filterAlerts()

  $('.alertmanager-filters-metadata-dropdown').change ->
    url = new URL(window.location)
    key = $(this).data('metadataKey')
    if $(this).val()
      url.searchParams.set(key, $(this).val())
    else
      url.searchParams.delete(key)
    window.history.pushState({}, '',url)
    filterAlerts()

  # handle click on unresolved header
  $('div.alertmanager-alert-list-titles div.alertmanager-alert-list-title-unresolved').on 'click', ->
    $('div.alertmanager-alert-list-titles div.alertmanager-alert-list-title-resolved').removeClass "active"
    $('div.alertmanager-alerts-resolved').removeClass "active"
    $('div.alertmanager-alert-list-titles div.alertmanager-alert-list-title-unresolved').addClass "active"
    $('div.alertmanager-alerts-unresolved').addClass "active"

    # find the currently active entry
    # if no entry is active choose the first
    current_alert = $('.alertmanager-alerts-unresolved').find('.alertmanager-alert-entry.active')
    current_alert = $('.alertmanager-alerts-unresolved').find('.alertmanager-alert-entry').first() unless current_alert.length
    clearDetailsPanel() unless current_alert.length
    current_alert.trigger "click" if current_alert.length
    
  # handle click on resolved header
  $('div.alertmanager-alert-list-titles div.alertmanager-alert-list-title-resolved').on 'click', ->
    $('div.alertmanager-alert-list-titles div.alertmanager-alert-list-title-unresolved').removeClass "active"
    $('div.alertmanager-alerts-unresolved').removeClass "active"
    $('div.alertmanager-alert-list-titles div.alertmanager-alert-list-title-resolved').addClass "active"
    $('div.alertmanager-alerts-resolved').addClass "active"

    # find the currently active entry
    # if no entry is active choose the first
    current_alert = $('.alertmanager-alerts-resolved').find('.alertmanager-alert-entry.active')
    current_alert = $('.alertmanager-alerts-resolved').find('.alertmanager-alert-entry').first() unless current_alert.length
    clearDetailsPanel() unless current_alert.length
    current_alert.trigger "click" if current_alert.length
    
  # handle click on specific alert
  $('div.alertmanager-alert-entry').on 'click', ->
    $(this).parent().find('div.alertmanager-alert-entry.active').removeClass('active')
    $(this).addClass('active')

    clearDetailsPanel()
    $('.alertmanager-conversation-messages').addClass('loading')
    $('.alertmanager-alert-sidebar').addClass('loading')

    $.ajax
      type: "GET"
      url: $(this).data('reply-data-url')
      data: reply_id: $(this).data("reply-id")
      success: (response) ->
        $('div.alertmanager-conversation-messages').removeClass('loading')
        $('.alertmanager-alert-sidebar').removeClass('loading')
        updateAlertDetails(response)

  # handle click on add comment button
  $('.alertmanager-alert-sidebar-section #add-comment-button').on 'click', ->
    alertId = $('div.alertmanager-alert-entry.active').first().data('alert-id')
    comment = $(@).closest('.add-comment-container').find('.add-comment-textarea').val()
    addCommentToAlert(alertId, comment)
  
  # handle click on resolve button
  $('.alertmanager-alert-sidebar-section #resolve_button').on 'click', ->
    alertId = $('div.alertmanager-alert-entry.active').first().data('alert-id')
    resolution = $(@).closest('.resolution-container').find('.resolution-text').val()
    resolveAlert(alertId, resolution) if confirm?(I18n.t("confirmation_text"))

  addCommentToAlert = (alertId, comment) ->
    $.ajax
      type: "POST"
      url: $('div.alertmanager-alert-entry.active').first().data('comment-url')
      data:
        alert_id: alertId,
        comment: comment
      success: (response) ->
        $('.alertmanager-alert-sidebar .add-comment-container .add-comment-textarea').val('')
        template = $('div.alertmanager-alert-sidebar-templates > div.alertmanager-alert-comment')
        newComment = template.clone()
        newComment.data('comment-id', response.id)
        newComment.find('.alertmanager-alert-comment-email').text(response.email)
        newComment.find('.alertmanager-alert-comment-text').text(response.comment_text)
        newComment.find('.alertmanager-alert-comment-timestamp').text(response.created_at)
        $('.alertmanager-alert-sidebar .comments-container').append(newComment)
      error: (response) ->
        alert("Sorry, there was an error. Please reload the page and try again.")

  resolveAlert = (alertId, resolution_comment) ->
    $.ajax
      type: "POST"
      url: $('div.alertmanager-alert-entry.active').first().data('resolve-url')
      data:
        alert_id: alertId,
        resolution_comment: resolution_comment
      success: (response) ->
        next_alert = $('div.alertmanager-alert-entry.active').first().next()
        $('div.alertmanager-alert-entry.active').first().remove()
        $('div.alertmanager-alert-list-title-unresolved.active > span').text("Unresolved (#{$('.alertmanager-alert-list-unresolved .alertmanager-alert-entry').length})")
        $(next_alert).trigger 'click'
      error: (response) ->
        alert("Sorry, there was an error. Please reload the page and try again.")

  updateAlertDetails = (responseData) ->
    $('div.alertmanager-alert-conversation').attr('data-reply-id', responseData.reply.id)

    # fill in the conversation page
    conversationTemplates = $('div.alertmanager-alert-conversation div.alertmanager-conversation-templates')
    conversationMessages = $('div.alertmanager-alert-conversation div.alertmanager-conversation-messages')
    conversationMessages.empty()
    for reply in responseData.conversation.replies
      if reply.answer_type == "Alert"
        template = conversationTemplates.find('div.alertmanager-conversation-message-alert')
        newAlert = template.clone()
        newAlert.attr('data-reply-id', reply.id)
        newAlert.find('div.alertmanager-conversation-message-text').text(reply.answer)
        newAlert.find('div.alertmanager-conversation-message-timestamp').text(reply.created_at)
        conversationMessages.append(newAlert)
      else
        template = conversationTemplates.find('div.alertmanager-conversation-message-sent')
        newSentMessage = template.clone()
        newSentMessage.attr('data-reply-id', reply.id)
        newSentMessage.find('div.alertmanager-conversation-message-text').text(reply.sent_text)
        newSentMessage.find('div.alertmanager-conversation-message-timestamp').text(reply.created_at)
        if reply.answer_type == "Image URL"
          newSentMessage.find('div.alertmanager-conversation-message-text').addClass('hide')
          newSentMessage.find('div.alertmanager-conversation-message-image').removeClass('hide')
          newSentMessage.find('div.alertmanager-conversation-message-image img').attr('src', reply.sent_text)
        conversationMessages.append( newSentMessage )

        if reply.answer != null && reply.answer.trim() != ''
          template = conversationTemplates.find('div.alertmanager-conversation-message-received')
          newReceivedMessage = template.clone()
          newReceivedMessage.attr('data-reply-id', reply.id)
          newReceivedMessage.find('div.alertmanager-conversation-message-text').text(reply.answer)
          newReceivedMessage.find('div.alertmanager-conversation-message-timestamp').text(reply.answered_at)
          newReceivedMessage.addClass('alert') if reply.id == responseData.alert.reply_id
          if reply.answer_type == "Ingest Image"
            newReceivedMessage.find('div.alertmanager-conversation-message-text').addClass('hide')
            newReceivedMessage.find('div.alertmanager-conversation-message-image').removeClass('hide')
            newReceivedMessage.find('div.alertmanager-conversation-message-image img').attr('src', reply.answer)
          conversationMessages.append(newReceivedMessage)
    
    # fill alert information
    fromIcon = if responseData.reply.them.startsWith("+1") then "fa-sms" else "fa-laptop"

    $('div.js-alert-information-content').append("<div><i class=\"fas #{fromIcon}\"></i><strong>#{responseData.reply.them}</strong></div>")
    if responseData.reply.point_of_contact_name
      $('div.js-alert-information-content').append("<div><i class=\"fas fa-map-marker-alt\"></i><strong>#{responseData.reply.point_of_contact_name}</strong></div>")
    $('div.js-alert-information-content').append("<div><i class=\"fas fa-calendar\"></i><strong>#{responseData.alert.opened_at}</strong></div>")
    $('div.js-alert-information-content').append("<br>")
    
    alert_content = responseData.alert.content.replace(/(?:<br>)/g, '')
    alert_content = alert_content.replace(/(?:\r\n|\r|\n|\|)/g, '<br>')
    alert_content = alert_content.replace(/(?:<br><br>)/g, '<br>')
    $('div.js-alert-information-content').append(alert_content)
    
    # fill conversation metadata
    conversation_metadata = Object.keys(responseData.reply.metadata).map (k) -> "#{k}: <strong>#{responseData.reply.metadata[k]}</strong>" if responseData.reply.metadata[k]
    conversation_metadata = conversation_metadata.filter (s) -> !(s == undefined)
    conversation_metadata = conversation_metadata.sort().join("<br>")
    $('div.js-alert-metadata-content').html(conversation_metadata)

    # fill assigned users
    if responseData.alert.assignments.length
      alert_assignments = responseData.alert.assignments.map (assignment) ->
        str = "<div>"
        str += "<i class=\"fas fa-user\" alt=\"user icon\"></i>"
        str += "<span>#{assignment.email}</span>"
        str += "<i class=\"fas fa-eye\" alt=\"eye icon\" title=\"This user has viewed this alert\"></i>" if assignment.status == "seen"
        str += "<i class=\"fas fa-check\" alt=\"check icon\" title=\"This user closed this alert\"></i>" if assignment.email == responseData.alert.closed_by_email
        str += "</div>"
        str
    $('div.js-alert-assignment-content').html(alert_assignments)

    # fill comments
    $('.alertmanager-alert-sidebar .comments-container').empty()
    if responseData.alert.comments.length
      for comment in responseData.alert.comments
        template = $('div.alertmanager-alert-sidebar-templates > div.alertmanager-alert-comment')
        newComment = template.clone()
        newComment.data('comment-id', comment.id)
        newComment.find('.alertmanager-alert-comment-email').text(comment.email)
        newComment.find('.alertmanager-alert-comment-text').text(comment.comment_text)
        newComment.find('.alertmanager-alert-comment-timestamp').text(comment.created_at)
        $('.alertmanager-alert-sidebar .comments-container').append(newComment)

    # fill alert resolution
    # set up the response pane based on the alert status
    switch responseData.alert.status
      when "opened"
        $('.resolution-container .resolve-button-container').removeClass('hide')
        $('.resolution-container .resolution-text').val('').prop('readonly', false)
        autosize.update($('.resolution-container .resolution-text'))
      when "closed"
        $('.resolution-container .resolve-button-container').addClass('hide')
        unless resolutionComment = responseData.alert.resolution_comment
          resolutionComment = "System:\nThis alert was automatically resolved and closed by the system."
        $('.resolution-container .resolution-text').val(resolutionComment).prop('readonly', true)
        autosize.update($('.resolution-container .resolution-text'))

  clearDetailsPanel = ->
    $('.alertmanager-conversation-messages').empty()
    $('.alertmanager-alert-sidebar .section-content').empty()
    $('.alertmanager-alert-sidebar .comments-container').empty()
    return true
