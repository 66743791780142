import React from "react";
import SelectInput, { SelectOption } from "../shared/Select";

import styles from "./SurveyIngestion.module.scss";
import { useFormContext } from "react-hook-form";

const PointOfContactMatchingFields: React.FC<{ excelColumns: SelectOption[] }> = ({ excelColumns }) => {
  const watch = useFormContext().watch;

  const pointOfContactKeys: SelectOption[] = [
    { label: "Name", value: "name" },
    { label: "Trigger", value: "trigger" },
    { label: "Code", value: "code" },
    { label: "Do not match on point of contact", value: "none" },
  ];

  const pointOfContactKey = watch("pointOfContactKey");

  return (
    <>
      <SelectInput options={pointOfContactKeys} name="pointOfContactKey">
        <div className={styles.question}>Which field will be used to match Point of Contacts?</div>
      </SelectInput>

      {pointOfContactKey && pointOfContactKey.value !== "none" &&
        <SelectInput options={excelColumns} name="pointOfContactKeyColumn">
          <div className={styles.question}>Select the excel column for {pointOfContactKey.label}</div>
        </SelectInput>}
    </>
  );
};

export default PointOfContactMatchingFields;
