import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import TrendIcon from "../../shared/trend-icon/trend-icon";
import TooltipHelper from "../../../utils/tooltip-helper";

import NPSWrapper from "../response-rate-wrapper/response-rate-wrapper";
import NPSChart from "../response-rate-chart/response-rate-chart";

import { NPSContentType } from "../../../types";

import { GotoLink } from "../../shared";

const ResponseRateContent = ({
  value,
  text = "Response Rate",
  icon,
  tooltipMessage,
  detailsPath,
}) => {
  let hasData = value != null;

  return (
    <NPSWrapper>
      <NPSChart value={value} />
      <div className={cx("wrapper")}>
        <div className={cx("percentage")}>
          <div className={cx("content")}>{hasData ? `${value}%` : "No Data"}</div>
          {hasData && (
            <TooltipHelper tooltipMessage={tooltipMessage}>
              <div className={cx("icon")}>
                <TrendIcon icon={icon} />
              </div>
            </TooltipHelper>
          )}
        </div>
        <div className={cx("text")}>{text}</div>
      </div>
      {detailsPath && <GotoLink url={detailsPath} openInNewTab={true} />}
    </NPSWrapper>
  );
};

ResponseRateContent.propTypes = NPSContentType;

export default ResponseRateContent;
