import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import { PrettySquareBoxType } from "../../../types";

const PrettySquareBox = ({ height = "small", children, style, link }) => {
  return (
    !link ? (
      <div className={style ? cx("box") : cx("box", height)} style={style}>
        <div className={cx("inner-content")}>{children}</div>
      </div>
    ) : (
      <a target="_blank" className={cx("link-container")} href={link}>
        <div className={style ? cx("box") : cx("box", height)} style={style}>
          <div className={cx("inner-content")}>{children}</div>
        </div>
      </a>
     
    )
  );
};

PrettySquareBox.propTypes = PrettySquareBoxType;

export default PrettySquareBox;
