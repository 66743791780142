jQuery ->

  disableButtons = () ->
    $('.import-survey-csv-build-survey-button').addClass "disabled"
    $('.import-survey-csv-preview-button').addClass "disabled"
    $('.import-survey-csv-sample-button').addClass "disabled"

  enableButtons = () ->
    $('.import-survey-csv-build-survey-button').removeClass "disabled"
    $('.import-survey-csv-preview-button').removeClass "disabled"
    $('.import-survey-csv-sample-button').removeClass "disabled"

  performRequest = (dataUrl, successCallback, failCallback, showConfirmation = true ) ->
    if showConfirmation
      if !confirm("You are about to attempt the creation of a new company and survey. Are you sure?")
        return

    disableButtons()

    url = $('.import-survey-csv-descriptor-section').data(dataUrl)
    liberal_parsing = $('.import-survey-csv-liberal-parsing')[0].checked
    data = new FormData(document.querySelector('#form_import_csv'))
    data.append('liberal_parsing',liberal_parsing)

    $.ajax
      type: "POST"
      url: url
      data: data
      success: (response) ->
        $('.import-survey-csv-errors-block').empty()
        $('.import-survey-csv-result-block')[0].innerHTML = response
        $('.import-survey-csv-errors-block').addClass "hidden"
        $('.import-survey-csv-result-block').removeClass "hidden"
        enableButtons()
        successCallback()
      error: (xhr, status, error) ->
        $('.import-survey-csv-errors-block')[0].innerHTML = xhr.responseText
        $('.import-survey-csv-errors-block').removeClass "hidden"
        $('.import-survey-csv-result-block').addClass "hidden"
        enableButtons()
        failCallback()
      processData: false
      contentType: false

  handleCreate = () ->
    successCallback = () ->
      $('.import-survey-csv-build-survey-button').addClass "hidden"
      $('.import-survey-csv-preview-button').addClass "hidden"
      $('.import-survey-csv-sample-button').addClass "hidden"
      $('#form_import_csv').addClass "hidden"

    failCallback = () ->
      $('.import-survey-csv-build-survey-button').addClass "hidden"
      $('.import-survey-csv-preview-button').removeClass "hidden"
      $('.import-survey-csv-sample-button').removeClass "hidden"

    performRequest('create-url', successCallback, failCallback)

  handlePreview = () ->
    successCallback = () ->
      $('.import-survey-csv-build-survey-button').removeClass "hidden"
      $('.import-survey-csv-preview-button').removeClass "hidden"
      $('.import-survey-csv-sample-button').removeClass "hidden"

    failCallback = () ->
      $('.import-survey-csv-build-survey-button').addClass "hidden"
      $('.import-survey-csv-preview-button').removeClass "hidden"
      $('.import-survey-csv-sample-button').removeClass "hidden"

    performRequest('preview-url', successCallback, failCallback, false)

  $('.import-survey-csv-build-survey-button:not(.disabled)').on 'click', ->
     handleCreate()

  $('.import-survey-csv-preview-button:not(.disabled)').on 'click', ->
     handlePreview()

  $('.import-survey-csv-tool').on 'click', '.button.poc-metadata-show-details', ->
    $(this).parents('td').children('.poc-metadata-details').toggleClass('hidden')
    $(this).parents('td').children('.button.poc-metadata-show-details').toggleClass('hidden')
