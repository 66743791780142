jQuery ->

  require("chosen-js")
  require('chosen-js/chosen.css')
  { loadJSONComponentAndSchema } = require('./jsoneditor.coffee')

  $('.deployment-manager-dashboard .dashboard-design-assets-container select.company-dashboard-survey-chooser').chosen({allow_single_deselect: true})
  $('.deployment-manager-dashboard .dashboard-design-assets-container select.company-dashboard-poc-chooser').chosen({allow_single_deselect: true})
  $('.deployment-manager-dashboard .dashboard-design-assets-container select.company-dashboard-template-chooser').chosen({disable_search: true})
  $('.deployment-manager-dashboard .dashboard-design-assets-container select.company-dashboard-language-chooser').chosen({disable_search: true})

  checkIfHexColor = (inputString) ->
    return /^#[0-9A-F]{6}$/i.test(inputString)

  processInfoSelectChange = ->
    $surveySelect = $('.deployment-manager-dashboard .dashboard-information-container select.company-dashboard-survey-chooser')
    $pocSelect = $('.deployment-manager-dashboard .dashboard-information-container select.company-dashboard-poc-chooser')
    $info = $('.deployment-manager-dashboard .dashboard-information-container .dashboard-information')

    return if $surveySelect.find(":selected").length == 0

    survey_triggers = $surveySelect.find(":selected").data("triggers")
    survey_phones = $surveySelect.find(":selected").data("phones")
    survey_phone_info = $surveySelect.find(":selected").data("phoneinfo")
    simulator_path = $surveySelect.find(":selected").data("simulator")
    poc_trigger = $pocSelect.find(":selected").data("poctrigger")

    simulator_path = simulator_path + "&poc_trigger_code=" + poc_trigger if poc_trigger != ""

    if survey_phones.length > 0
      $info.find('span.no-phones').addClass( 'hide' )
    else
      $info.find('span.no-phones').removeClass( 'hide' )

    # substitute the triggers information
    $info.find('span.triggers').text( survey_triggers.join(', ') )

    # substitute the phone numbers information
    if survey_phones.length > 0
      phones_content = "<table><tbody>"
      for idx in [0..survey_phones.length-1]
        phones_content += "<tr>"
        phones_content += "<td>"+survey_phones[idx]+"</td>"
        phones_content += "<td>"+survey_phone_info[survey_phones[idx]]["Description"]+"</td>"
        phones_content += "</tr>"
      phones_content += "</tbody></table>"
      $info.find('span.phone-numbers').html( "" ).html( phones_content )
      $info.find('span.phone-numbers').removeClass( 'hide' )
    else
      $info.find('span.phone-numbers').addClass( 'hide' )

    # substitute the simulator path information
    $info.find('a.simulator-link').attr( "href", simulator_path )
    $info.find('a.simulator-link span').text( simulator_path )

    # add the SMS initiation information
    $sms_initiation_list = $info.find('ul.initiate-sms')
    if survey_phones.length > 0
      $info.find('p.initiate-sms').removeClass( 'hide' )
      $li_template = $sms_initiation_list.find('li.template')
      $sms_initiation_list.find('li:not(.template)').remove()
      for trigger in survey_triggers
        for phone in survey_phones
          $new_li = $li_template.clone()
          $new_li.removeClass('template')
          $new_li.find('span.sms-trigger').text(" "+trigger+poc_trigger)
          $new_li.find('span.sms-phone').text(" "+phone)
          $sms_initiation_list.append( $new_li )
    else
      $sms_initiation_list.find('li:not(.template)').remove()
      $info.find('p.initiate-sms').addClass( 'hide' )

    # add the online initiation information
    $online_initiation_list = $info.find('ul.initiate-online')
    $li_online = $online_initiation_list.find('li:first')
    online_url = "https://chatter.one/"+$surveySelect.val()
    online_url += "/"+encodeURIComponent(poc_trigger) if poc_trigger != ""
    $li_online.find('a').attr("href", online_url)
    $li_online.find('span.online-url').text(online_url)

    # add the online initiation + contact info information
    $online_initiation_list = $info.find('ul.initiate-online-email')
    $li_online = $online_initiation_list.find('li:first')
    online_url += "?email=" + encodeURIComponent("someone@example.com")
    $li_online.find('a').attr("href", online_url)
    $li_online.find('span.online-url').text(online_url)

    # add the smart link information
    $online_smart_link_list = $info.find('ul.smart-link')
    $li_template = $online_smart_link_list.find('li.template')
    $online_smart_link_list.find('li:not(.template)').remove()
    online_url = "https://chatter.one/"+$surveySelect.val()+"?trigger="
    for trigger in survey_triggers
      $new_li = $li_template.clone()
      $new_li.removeClass('template')
      url = online_url+encodeURIComponent(trigger)
      url += encodeURIComponent(poc_trigger) if poc_trigger != ""
      $new_li.find('a').attr("href", url)
      $new_li.find('span.smart-link').text(url)
      $online_smart_link_list.append( $new_li )

  $('.deployment-manager-dashboard .dashboard-information-container select.company-dashboard-survey-chooser').on "change", ->
    processInfoSelectChange()

  $('.deployment-manager-dashboard .dashboard-information-container select.company-dashboard-poc-chooser').on "change", ->
    processInfoSelectChange()

  $('.deployment-manager-dashboard .js-data-table-dashboard-info').each ->
    $(@).DataTable

  if $('.deployment-manager-dashboard').length > 0
    setTimeout (->
      processInfoSelectChange()
    ), 250

  $('.deployment-manager-dashboard .dashboard-information-container select.company-dashboard-survey-chooser').chosen({allow_single_deselect: true})
  $('.deployment-manager-dashboard .dashboard-information-container select.company-dashboard-poc-chooser').chosen({allow_single_deselect: true})

  generateLocaleChoices = (locales) ->
    if $('.company-dashboard-language-chooser').length > 0 && locales
      $('.company-dashboard-language-chooser').empty()
      locales.forEach((locale) ->
        $('.company-dashboard-language-chooser').append("<option value='#{locale}'>#{locale.toUpperCase()}</option>")
      )
      $('.company-dashboard-language-chooser').trigger("chosen:updated")

  generateLocaleChoices($('select.company-dashboard-template-chooser').find(':selected').data('locales'))

  processAssetLoad = (data) ->
    # find the asset list and template
    $asset_list = $('.deployment-manager-dashboard .dashboard-design-assets-container .dashboard-asset-list')
    $card_template = $asset_list.find('.dashboard-asset-card.template')
    $asset_list.find('.dashboard-asset-card:not(.template)').remove()

    for image in data
      $new_card = $card_template.clone()
      $new_card.removeClass('template')
      $new_card.find('.dashboard-asset-name').text(image.asset_name)
      $new_card.find('.dashboard-asset a').attr("download", image.asset_filename)
      $new_card.find('.dashboard-asset a').attr("href", "data:" + image.content_type + ";base64," + image.asset_data)
      $new_card.find('.dashboard-asset a').html('<img class="the-image" src="data:'+image.content_type+';base64,' + image.asset_data + '" />')
      $asset_list.append( $new_card )

  processAssetsSelectChange = ->
    $container = $('.deployment-manager-dashboard .dashboard-design-assets-container')
    url = $container.data("url")
    $surveySelect = $container.find('select.company-dashboard-survey-chooser')
    $pocSelect = $container.find('select.company-dashboard-poc-chooser')
    $templateSelect = $container.find('select.company-dashboard-template-chooser')
    $languageSelect = $container.find('select.company-dashboard-language-chooser')
    $asset_overlay = $container.find('.dashboard-design-assets-overlay')
    $asset_container = $container.find('.dashboard-asset-list-container')
    $asset_download = $container.find('.dashboard-download-all-link')
    survey_id = $surveySelect.val()
    selectedTemplate = $templateSelect.val()
    selectedLanguage = $languageSelect.val()

    return if (survey_id == null || survey_id.trim() =="")

    $asset_overlay.removeClass('hide')
    $asset_container.addClass('hide')
    $asset_download.addClass('hide')
    $('.dashboard-asset-generate-warning').addClass('hide')

    survey_triggers = $surveySelect.find(":selected").data("triggers")
    survey_phones = $surveySelect.find(":selected").data("phones")
    poc_trigger = $pocSelect.find(":selected").data("poctrigger")

    download_url = $container.data("downloadurl") + "?survey_id=" + survey_id
    download_url += "&poc_trigger=" + poc_trigger if poc_trigger != ""
    download_url += "&template=" + selectedTemplate
    download_url += "&language=" + selectedLanguage


    data =
      survey_id: survey_id
      template: selectedTemplate
      language: selectedLanguage
    data.poc_trigger = poc_trigger if poc_trigger != ""

    $.ajax
      type: "GET"
      url: url
      data: data
      success: (data) ->
        $asset_overlay.addClass('hide')
        $asset_container.removeClass('hide')
        $asset_download.removeClass('hide')
        if data.error
          alert( "Signage Error: "+data.error )
        else
          $container.find('a.dashboard-download-all-link').attr("href", download_url)
          processAssetLoad(data)
      error: (data) ->
        $asset_overlay.addClass('hide')
        alert( data.statusText+": "+data.responseText )

  enableTemplateEditMode = ->
    $editorContainer = $('.js-modal-signage-template-editor').find('.jsoneditor-container')
    $editorContainer.removeClass('hide')
    $signageTemplate = $editorContainer.find('.json-editor')
    $container = $('.deployment-manager-dashboard .dashboard-design-assets-container')
    $templateSelect = $container.find('select.company-dashboard-template-chooser')
    selectedTemplate = $templateSelect.val()
    url = $editorContainer.data("json-path")

    data =
      template: selectedTemplate

    $.ajax
      type: "GET"
      url: url
      data: data
      success: (data) ->
        $editorContainer.find('.jsoneditor-value-field').val JSON.stringify(data)
        if $signageTemplate[0] && $signageTemplate[0].jsoneditor
          loadJSONComponentAndSchema($signageTemplate, ".js-modal-signage-template-editor .jsoneditor-value-field", "signage_templates")
          $signageTemplate.on 'json-editor-change', -> handleEditSignageValueChange()
        else
          $signageTemplate.on 'json-editor-load', ->
            loadJSONComponentAndSchema($signageTemplate, ".jsoneditor-value-field", "signage_templates")
            $signageTemplate.on 'json-editor-change', -> handleEditSignageValueChange()

  enableTemplateCreateMode = ->
    $editorContainer = $('.js-modal-signage-template-new').find('.jsoneditor-container')
    $editorContainer.find('.jsoneditor-value-field').val(JSON.stringify(
      { languages: { en: { formats: { "4x4": { signage_elements: [], base_image_url: "https://yourcompany.com/yourimage.png" } } } } }
    ))
    $signageTemplate = $editorContainer.find('.json-editor')
    if $signageTemplate[0] && $signageTemplate[0].jsoneditor
      loadJSONComponentAndSchema($signageTemplate, ".js-modal-signage-template-new .jsoneditor-value-field", "signage_templates")
      $signageTemplate.on 'json-editor-change', -> handleNewSignageValueChange()
    else
      $signageTemplate.on 'json-editor-load', ->
        loadJSONComponentAndSchema($signageTemplate, ".jsoneditor-value-field", "signage_templates")
        $signageTemplate.on 'json-editor-change', -> handleNewSignageValueChange()
  generateReloadAssetsWarning = ->
    if !$('.dashboard-download-all-link').hasClass('hide') || !$('.dashboard-design-assets-overlay').hasClass('hide')
      $('.dashboard-asset-generate-warning').removeClass('hide')

  handleNewSignageValueChange = ->
    $container = $('.js-modal-signage-template-new')
    templateName = $container.find('.signage-template-name').val()
    if $container.find('.input-error').length == 0 && templateName
      $container.find('.js-modal-signage-template-new-save').prop('disabled', false)
    else
      $container.find('.js-modal-signage-template-new-save').prop('disabled', true)
  $('.js-modal-signage-template-new').on 'keyup', -> handleNewSignageValueChange()

  handleEditSignageValueChange = ->
    $container = $('.js-modal-signage-template-editor')
    if $container.find('.input-error').length == 0
      $container.find('.js-modal-signage-template-editor-save').prop('disabled', false)
    else
      $container.find('.js-modal-signage-template-editor-save').prop('disabled', true)

  $('.js-modal-signage-template-remove').find('.js-modal-signage-template-remove-save').on "click", ->
    $(@).prop('disabled', true)
    templateToDelete = $('.deployment-manager-dashboard .dashboard-design-assets-container select.company-dashboard-template-chooser').val()
    deleteURL = $(@).data("delete-path")
    if templateToDelete && deleteURL
      $.ajax
        type: "DELETE"
        url: deleteURL
        data:
          signage_name: templateToDelete
        success: (data) ->
          $('.js-modal-signage-template-remove').modal "hide"
          $('.js-modal-signage-template-remove-save').prop('disabled', false)
          $("select.company-dashboard-template-chooser option[value='#{templateToDelete}']").remove()
          $("select.company-dashboard-template-chooser").trigger("chosen:updated")
          $('.dashboard-design-assets-overlay').addClass('hide')
          $('.dashboard-asset-list-container').addClass('hide')
          $('.dashboard-download-all-link').addClass('hide')
          generateLocaleChoices($('select.company-dashboard-template-chooser').find(':selected').data('locales'))
        error: (error) ->
          $('.js-modal-signage-template-remove-save').prop('disabled', false)

  $('.js-modal-signage-template-new').find('.js-modal-signage-template-new-save').on "click", ->
    $(@).prop('disabled', true)
    newSignageName = $('.js-modal-signage-template-new').find('.signage-template-name').val()
    if $("select.company-dashboard-template-chooser option[value='#{newSignageName}']").length > 0
      alert("This signage template already exists!")
      $('.js-modal-signage-template-new-save').prop('disabled', false)
      return
    newSignageValue = $('.js-modal-signage-template-new').find('.json-editor')[0].jsoneditor.get()
    locales = Object.keys(newSignageValue.languages)
    newSignageJSON = JSON.stringify(newSignageValue)
    saveURL = $(@).data("save-path")
    if newSignageName && newSignageJSON && saveURL
      $.ajax
        type: "POST"
        url: saveURL
        data:
          signage_name: newSignageName
          signage_template: newSignageJSON
        success: (data) ->
          $('.js-modal-signage-template-new').modal "hide"
          $('.js-modal-signage-template-new-save').prop('disabled', false)
          $("select.company-dashboard-template-chooser").append("<option value='#{newSignageName}' data-locales=#{JSON.stringify(locales)}>#{newSignageName}</option>")
          $("select.company-dashboard-template-chooser").val(newSignageName)
          $("select.company-dashboard-template-chooser").trigger("chosen:updated")
          $('.dashboard-design-assets-overlay').addClass('hide')
          $('.dashboard-asset-list-container').addClass('hide')
          $('.dashboard-download-all-link').addClass('hide')
          generateLocaleChoices(locales)
        error: (error) ->
          $('.js-modal-signage-template-new-save').prop('disabled', false)

  $('.js-modal-signage-template-editor').find('.js-modal-signage-template-editor-save').on "click", ->
    $(@).prop('disabled', true)
    signageName = $("select.company-dashboard-template-chooser").val()
    newSignageValue = $('.js-modal-signage-template-editor').find('.json-editor')[0].jsoneditor.get()
    newSignageJSON = JSON.stringify(newSignageValue)
    saveURL = $(@).data("save-path")
    if signageName && newSignageJSON && saveURL
      $.ajax
        type: "POST"
        url: saveURL
        data:
          signage_name: signageName
          signage_template: newSignageJSON
        success: (data) ->
          $('.js-modal-signage-template-editor').modal "hide"
          $('.js-modal-signage-template-editor-save').prop('disabled', false)
          $('.dashboard-design-assets-overlay').addClass('hide')
          $('.dashboard-asset-list-container').addClass('hide')
          $('.dashboard-download-all-link').addClass('hide')
        error: (error) ->
          $('.js-modal-signage-template-editor-save').prop('disabled', false)

  $('.deployment-manager-dashboard .dashboard-design-assets-container .generate-assets').on "click", ->
    processAssetsSelectChange()

  $('.deployment-manager-dashboard select.company-dashboard-survey-chooser').on "change", ->
    generateReloadAssetsWarning()

  $('.deployment-manager-dashboard select.company-dashboard-poc-chooser').on "change", ->
    generateReloadAssetsWarning()

  $('.deployment-manager-dashboard .generate-assets-container .new-assets').on "click", ->
    enableTemplateCreateMode()
    $('.js-modal-signage-template-new').find('.signage-template-name').val ""

  $('select.company-dashboard-template-chooser').on "change", ->
    generateLocaleChoices($(@).find(":selected").data("locales"))
    generateReloadAssetsWarning()

  $('select.company-dashboard-language-chooser').on "change", ->
    generateReloadAssetsWarning()

  $('.deployment-manager-dashboard .dashboard-design-assets-container .edit-assets').on "click", ->
    enableTemplateEditMode()

  $('.toggle-keywords-hint').on "click", ->
    $('.keywords-content').toggleClass("hide")

  $('.deployment-manager-dashboard .js-data-table-dashboard-user-list').each ->
    language = window.jQueryDataTableLanguagePacks[I18n.locale] || {}
    language.info = $(@).data('langinfo') if $(@).data('langinfo')
    language.infoEmpty = $(@).data('langinfoempty') if $(@).data('langinfoempty')
    language.emptyTable = $(@).data('langempty') if $(@).data('langempty')
    language.lengthMenu = $(@).data('langlengthmenu') if $(@).data('langlengthmenu')

    options =
      language: language
      paging: true
      searching: true
      info: true
      ordering: true
      lengthMenu: [
        [ 10, 25, 50, 100, -1 ]
        [ 10, 25, 50, 100, "All" ]
      ]
      pageLength: 10

    $(@).DataTable options

  $('.deployment-manager-dashboard .js-data-table-dashboard-poc-list').each ->
    language = window.jQueryDataTableLanguagePacks[I18n.locale] || {}
    language.info = $(@).data('langinfo') if $(@).data('langinfo')
    language.infoEmpty = $(@).data('langinfoempty') if $(@).data('langinfoempty')
    language.emptyTable = $(@).data('langempty') if $(@).data('langempty')
    language.lengthMenu = $(@).data('langlengthmenu') if $(@).data('langlengthmenu')

    options =
      language: language
      paging: true
      searching: true
      info: true
      ordering: true
      lengthMenu: [
        [ 10, 25, 50, 100, -1 ]
        [ 10, 25, 50, 100, "All" ]
      ]
      pageLength: 10
      order: [[1, 'asc']]

    $(@).DataTable options

  $('.deployment-logo-file-input').on 'change', ->
    fileName = $(@).val().split('\\').pop();
    $('.deployment-fake-logo-file-input').html(fileName);

