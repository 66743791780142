import I18n from "i18n-js";
import React from "react";

import Tile from "./tile";

const AlertsTile = ({ alertsData, filters, link }) => {
  const overall = alertsData && alertsData.opened;
  const total = alertsData && alertsData.total;
  const counts = alertsData && [
    {
      category: I18n.t("dashboard_lite.resolved_alerts"),
      number: alertsData.closed,
      percentage: `${total ? Math.round((alertsData.closed / total) * 100) : 0}%`,
      state: "positive",
    },
    {
      category: I18n.t("dashboard_lite.active_alerts"),
      number: alertsData.opened,
      percentage: `${total ? Math.round((alertsData.opened / total) * 100) : 0}%`,
      state: "negative",
    },
    {
      category: I18n.t("dashboard_lite.total_alerts"),
      number: total,
      percentage: "-",
      state: "neutral",
    },
  ];

  return (
    <Tile
      counts={counts}
      filters={filters}
      hideTrend={true}
      inversePositiveness={true}
      link={link}
      loading={!alertsData}
      overall={overall}
      overallHistorical={overall}
      title={I18n.t("dashboard_lite.alerts_tile_title")}
      tooltip={I18n.t("dashboard_lite.alerts_tile_tooltip")}
    />
  );
};

export default AlertsTile;
