import classNames from "classnames/bind";
import React, { useState } from "react";

import SearchBox from "../search-box";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const PointOfContactSelections = ({
  currentPointOfContactRestrictions,
  allPointOfContacts,
  allPointOfContactGroups,
  allPointOfContactClauses,
  includeLabels = false,
  inheritsPointOfContacts = false,
  height = "150px"
}) => {

  const [selectedPointOfContactRestrictions, setSelectedPointOfContactRestrictions] = useState(currentPointOfContactRestrictions);
  const addPointOfContactRestriction = (restriction) => {
    setSelectedPointOfContactRestrictions([restriction, ...selectedPointOfContactRestrictions]);
  }

  const removePointOfContactRestriction = (restriction) => {
    setSelectedPointOfContactRestrictions(selectedPointOfContactRestrictions.filter((selectedPointOfContactRestriction) => selectedPointOfContactRestriction.value !== restriction.value))
  }

  const noSelectionLabel = inheritsPointOfContacts ? "Using points of contact from data access" : "No points of contact has been selected"

  const getSelectOptions = () => {
    let pointOfContactGroups = allPointOfContactGroups.filter((pointOfContactGroup) => !selectedPointOfContactRestrictions.some((selectedPointOfContactRestriction) => selectedPointOfContactRestriction.value === pointOfContactGroup.value ) )
    let singlePointOfContacts = allPointOfContacts.filter((pointOfContact) => !selectedPointOfContactRestrictions.some((selectedPointOfContactRestriction) => selectedPointOfContactRestriction.value === pointOfContact.value ) )
    let pointOfContactClauses = allPointOfContactClauses.filter((pointOfContactClause) => !selectedPointOfContactRestrictions.some((selectedPointOfContactRestriction) => selectedPointOfContactRestriction.value === pointOfContactClause.value ) )
    return [...pointOfContactGroups, ...pointOfContactClauses, ...singlePointOfContacts]
  }
  return (
    <div
      data-selected-values={"[" + selectedPointOfContactRestrictions.map((selectedPointOfContactRestriction) => selectedPointOfContactRestriction.value) + "]"}
      data-selected-names={selectedPointOfContactRestrictions.map((selectedPointOfContactRestriction) => selectedPointOfContactRestriction.label)}
      className={cx("point-of-contact-selections")}>
      <div className={cx("point-of-contact-to-add")}>
        {includeLabels &&
          <>
            <span>Point of contact:</span>
            <br />
          </>
        }
        <SearchBox
          onSelect={(restriction) => addPointOfContactRestriction(restriction)}
          options={getSelectOptions()}
          optionIcon={<i className={cx("fa-solid fa-plus")}></i>}
          placeholder="Search"
        />
      </div>
      {includeLabels &&
        <div>Current restrictions:</div>
      }
      <div className={cx("selected-point-of-contacts")} style={{ minHeight: height, maxHeight: height }}>
        { !selectedPointOfContactRestrictions.length && <div className={cx("no-point-of-contacts-selected")}>{noSelectionLabel}</div>}
        { selectedPointOfContactRestrictions.map((selectedPointOfContactRestriction) =>
          <div key={selectedPointOfContactRestriction.value} className={cx("selected-point-of-contact")}>
            <div className={cx("selected-point-of-contact-label")}>{selectedPointOfContactRestriction.label}</div>
            <div className={cx("selected-point-of-contact-remove-button")} onClick={() => removePointOfContactRestriction(selectedPointOfContactRestriction)}>
              <i className={cx("remove-button fa-solid fa-xmark")} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default PointOfContactSelections;
