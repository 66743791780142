jQuery ->
  enableButtons = () ->
    $('.search-location-button').prop 'disabled', false
    $('.search-navigation #select-all-locations').prop 'disabled', false
    $('.search-and-select #remove-all-locations').prop 'disabled', false
    hideSelectedLocations()
    $('.search-results').removeClass 'hidden'
    $('.searching-placeholder').addClass 'hidden'
    $('.search-navigation').prop 'disabled', false

  disableButtons = () ->
    $('.search-location-button').prop 'disabled', true
    $('.search-results').addClass 'hidden'
    $('.searching-placeholder').removeClass 'hidden'
    $('.search-navigation').prop 'disabled', true

  hideSelectedLocations = () ->
    selectedGoogleIds = $('.location', '.selected-locations').map (i,e) ->
      $(e).data('googleId')
    selectedGoogleIds = selectedGoogleIds.get()
    $('.location', '.search-results').each (i,e) ->
      googleId = $(e).data('googleId')
      if selectedGoogleIds.includes(googleId)
        $(e).toggle(0, 'highlight')


  performRequest = (successCallback, page = 0) ->
    url = $('#location-search').attr('action')
    data = new FormData(document.querySelector('#location-search'))
    data.append('page',page)

    disableButtons()

    $.ajax
      type: "POST"
      url: url
      data: data
      success: (response) ->
        $('.search-results').empty()
        $('.search-results')[0].innerHTML = response
        successCallback()
        enableButtons()
      error: (xhr, status, error) ->
        enableButtons()
      processData: false
      contentType: false

  $('.search-location-button').on 'click', (event) ->
    event.preventDefault()
    onSuccess = () ->
      $('.search-navigation').data('current-page',0)

    performRequest(onSuccess)

  selectLocation = (locationElement) ->
    $('.selected-locations').append(locationElement.prop('outerHTML'))
    clonedLocation = $('.location:last', '.selected-locations')
    clonedLocation.toggle(0,'highlight', () -> clonedLocation.toggle('highlight'))
    $(locationElement).toggle('highlight')
    $('.remove-location, .select-location', clonedLocation).toggleClass('hidden')

  $('.search-location-section').on 'click', '.select-location', (event) ->
    event.preventDefault()
    location = $(this).parents('.location')
    selectLocation(location)

  removeLocation = (locationElement) ->
    google_id = $(locationElement).data('googleId')
    $(".location[data-google-id='#{google_id}']", '.search-results').toggle('highlight')
    locationElement.toggle(complete: () -> locationElement.remove())

  $('.search-location-section').on 'click', '.remove-location', (event) ->
    event.preventDefault()
    location = $(this).parents('.location')
    removeLocation(location)

  $('#select-all-locations').on 'click', () ->
    $('.search-results .location:visible').each (i,e) ->
      selectLocation($(e))

  $('#remove-all-locations').on 'click', () ->
    $('.selected-locations .location').each (i,e) ->
      removeLocation($(e))
