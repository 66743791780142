import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import styles from "./FilterChip.module.scss";

const FilterChip = ({label, onRemoveCallback}) => {
  const onFilterClicked = (e) => {
    onRemoveCallback();
    e.stopPropagation();
  };

  return (
    <div className={styles.filterChip}>
      {label}
      {onRemoveCallback && <FontAwesomeIcon className={styles.removeIcon} icon={faRemove} onClick={(e) => onFilterClicked(e)} />}
    </div>
  );
}

export default FilterChip;
