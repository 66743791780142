import React from "react";

import AlertsContent from "../../views/tile-alert";

import { PrettyLoader } from "../../views/shared";
import { getTrendIconFromDelta } from "../../utils/general-helper";

import hasLoader from "../hoc/has-loader";

import { AlertContainerType } from "../../types";

class AlertContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;
    const { newAlertsCount, totalAlertsCount, totalAlertsDelta, alertDetailsPath } = componentData;

    const totalTrendIcon = getTrendIconFromDelta(totalAlertsDelta);

    return (
      <PrettyLoader status={status}>
        <AlertsContent
          newAlertsCount={newAlertsCount}
          totalAlertsCount={totalAlertsCount}
          totalIcon={totalTrendIcon}
          alertDetailsPath={alertDetailsPath}
        />
      </PrettyLoader>
    );
  }
}

AlertContainer.propTypes = AlertContainerType;

export default hasLoader(AlertContainer);
