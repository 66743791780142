import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import TrendIcon from "../../shared/trend-icon/trend-icon";
import TooltipHelper from "../../../utils/tooltip-helper";
import { initiationSeriesHasEnoughDataPoints } from "../../../utils/general-helper";

import { InitiationContentType } from "../../../types";

import InitiationWrapper from "../initiation-wrapper/initiation-wrapper";
import InitiationChart from "../initiation-chart/initiation-chart";

import { GotoLink } from "../../shared";

const InitiationContent = ({
  value = 0,
  description,
  icon = "none",
  tooltipMessage,
  initiationSeries = [],
  detailsPath,
}) => {
  let hasData = value != null;
  let seriesHasEnoughDataPoints = initiationSeriesHasEnoughDataPoints(initiationSeries);

  return (
    <InitiationWrapper>
      <InitiationChart
        seriesHasEnoughDataPoints={seriesHasEnoughDataPoints}
        initiationSeries={initiationSeries}
      />
      <div className={cx("wrapper")}>
        <div className={cx("percentage")}>
          <div className={cx("content")}>{value.toLocaleString("en")}</div>
          {hasData && (
            <TooltipHelper tooltipMessage={tooltipMessage}>
              <div className={cx("icon")}>
                <TrendIcon icon={icon} />
              </div>
            </TooltipHelper>
          )}
        </div>
        {description && <div className={cx("type")}>{description}</div>}
      </div>
      {detailsPath && <GotoLink url={detailsPath} openInNewTab={true} />}
    </InitiationWrapper>
  );
};
InitiationContent.propTypes = InitiationContentType;
export default InitiationContent;
