import React, { HTMLProps, forwardRef } from "react";
import { Input, Typography } from "stingray-components";

import styles from "./TextInput.module.scss";

const TextInput = forwardRef<HTMLInputElement, HTMLProps<HTMLInputElement>>(({children, ...props}, ref) => {
  return (
    <div className={styles.textInputContainer}>
      <Typography variant="h7">{children}</Typography>
      <Input ref={ref as any} className={styles.textInput} {...props} />
    </div>
  );
});

export default TextInput;
