/* eslint-disable camelcase */
import React from "react";
import i18n from "i18n-js";

import { PrettyLoader } from "../../views/shared";

import NPSSegmentationGraph from "../../views/tile-nps-segmentation-graph/index";

import hasLoader from "../hoc/has-loader";

// mixpanel event vars
const graphRef = React.createRef();
let inViewTriggered = false;

const handleScroll = () => {
  if (
    graphRef.current.getBoundingClientRect().bottom < window.innerHeight ||
    graphRef.current.getBoundingClientRect().top < 0
  ) {
    if (!inViewTriggered) {
      inViewTriggered = true;
      // mixpanel is defined in the global scale and only on prod, eslint does not like this, but we are catching it with the if
      try {
        // eslint-disable-next-line no-undef
        mixpanel.track("Nps Segment viewed");
      } catch (e) {
        if (e instanceof ReferenceError) {
          // ignore it just means were not in prod
        } else {
          console.error(e);
        }
      }
    }
  }
};

const NpsSegmentationGraphContainer = ({ status, componentData }) => {
  React.useEffect(() => {
    if (graphRef.current) {
      window.addEventListener("scroll", handleScroll, true);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [graphRef.current]);

  if (componentData && componentData.error) {
    console.warn(componentData.error || "could not load the nps segmentation data");
  }
  return (
    <div className="report-segmentation-graph-container" ref={graphRef}>
      {componentData && componentData.error ? null : (
        <PrettyLoader
          status={status}
          size="medium"
          style={{ margin: "10px 0px" }}
          title={i18n.t("segmentation_analysis")}
          titleClass="report-title"
        >
          <NPSSegmentationGraph
            segmentInfo={componentData.segmentation_info}
            npsInfo={componentData.nps_info}
            answerType={componentData.answer_type}
            useOrderForGraphs={componentData.use_order_for_graphs}
          />
        </PrettyLoader>
      )}
    </div>
  );
};

export default hasLoader(NpsSegmentationGraphContainer);
