import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import TrendIcon from "../../shared/trend-icon/trend-icon";
import TooltipHelper from "../../../utils/tooltip-helper";

import { CompletionContentType } from "../../../types";

import CompletionWrapper from "../completion-wrapper/completion-wrapper";
import CompletionChart from "../completion-chart/completion-chart";

const CompletionContent = ({
  percentage = 0,
  icon = "none",
  tooltipMessage,
  preferResponded = false,
  initiated = 0,
  completed = 0,
}) => {
  let hasData = percentage != null;

  return (
    <CompletionWrapper>
      <CompletionChart percentage={percentage} />
      <div className={cx("wrapper")}>
        <div className={cx("percentage")}>
          <div className={cx("content")}>{hasData ? `${percentage}%` : "No Data"}</div>
          {hasData && (
            <TooltipHelper tooltipMessage={tooltipMessage}>
              <div className={cx("icon")}>
                <TrendIcon icon={icon} />
              </div>
            </TooltipHelper>
          )}
        </div>
        <div className={cx("type")}>{preferResponded ? "Engagement rate" : "Completion rate"}</div>
        {hasData && (
          <div className={cx("text")}>{getText(completed, initiated, preferResponded)}</div>
        )}
      </div>
    </CompletionWrapper>
  );
};

const getText = (completed, initiated, preferResponded) => {
  let completedFormatted = completed ? completed.toLocaleString() : 0;
  let initiatedFormatted = initiated ? initiated.toLocaleString() : 0;

  return `${completedFormatted} of ${initiatedFormatted} ${
    preferResponded ? "Responses" : "Completed"
  }`;
};

CompletionContent.propTypes = CompletionContentType;

export default CompletionContent;
