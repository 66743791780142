import I18n from "i18n-js";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { QueryFunctionContext, useQuery } from "react-query";
import { Button, Card, Icon, Input, Typography } from "stingray-components";

import { useModal } from "../../src/hooks/useModal";
import Modal from "../live_conversations/Modal";
import { POCRestrictionType, PointOfContact, samePOCRestriction } from "./userManagementTypes";

import styles from "./PointOfContactRestrictionsModal.module.scss";

export type POCRestrictionsModalInitialData = {
  companyId: number;
  pocRestrictions: PointOfContact[];
}

export type POCRestrictionsModalReturnData = PointOfContact[];

const getPOCRestrictionOptions = async ({ queryKey }: QueryFunctionContext) => {
  const [_, companyId] = queryKey

  const response = await fetch(`/api/companies/${companyId}/point_of_contact_restriction_options`);

  if (!response.ok) throw new Error("Could not fetch Point of Contact restriction options.");

  return await response.json()
}

export const PointOfContactSelectorModal = () => {
  const { setOpen, onSubmit, initialData } = useModal()
  const { pocRestrictions: previousPOCRestrictions, companyId } = initialData as POCRestrictionsModalInitialData

  // POC Restrictions available for this company that can be applied to the user
  const { data: pocRestrictionOptions } = useQuery({ queryFn: getPOCRestrictionOptions, queryKey: ["pocRestrictionOptions", companyId] })

  const [searchValue, setSearchValue] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);

  const [selectedPOCRestrictions, setSelectedPOCRestrictions] = useState<PointOfContact[]>(previousPOCRestrictions);

  const referenceElement = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(referenceElement.current, popperElement, { placement: "bottom-start" });

  // Make the search results popper the same width as the reference element (the search input).
  useEffect(() => {
    if (referenceElement && popperElement) {
      const refWidth = referenceElement.current?.getBoundingClientRect().width;
      popperElement.style.width = `${refWidth}px`;
    }
  }, [referenceElement, popperElement]);

  const sortPointOfContacts = (pocs: PointOfContact[]) => {
    return pocs.sort((a, b) => {
      if (a.type < b.type) return -1
      if (a.type > b.type) return 1

      if (a.label < b.label) return -1
      if (a.label > b.label) return 1

      return 0
    })
  }

  const searchResults = useMemo(() => {
    if (!pocRestrictionOptions) return []

    const filteredPOCs: PointOfContact[] = pocRestrictionOptions.filter(
      (pointOfContact: PointOfContact) =>
        pointOfContact.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()) && // Filter out results not matching the search string
        !selectedPOCRestrictions.find((poc) => samePOCRestriction(poc, pointOfContact))
    );

    return sortPointOfContacts(filteredPOCs)
  }, [pocRestrictionOptions, searchValue, selectedPOCRestrictions])

  return (
    <>
      {/* @ts-ignore // This is due to the Modal component not being in TypeScript */}
      <Modal
        isOpen={true}
        setIsOpen={setOpen}
        actionButtonText={I18n.t("save")}
        onActionButtonClicked={() => onSubmit(selectedPOCRestrictions)}
        cancelButtonText={I18n.t("cancel")}
        body={
          <div className={styles.pointOfContactRestrictionsModal}>
            <Typography variant="h6">{I18n.t("user_management.point_of_contact_restrictions")}</Typography>
            <div ref={referenceElement}>
              <Input
                placeholder={I18n.t("user_management.search")}
                type="search"
                value={searchValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                onFocus={() => setShowSearchResults(true)}
                rightIcon={
                  searchValue ? (
                    <Button type="icon" onClick={() => setSearchValue("")}>
                      <span className={styles.inputTextColor}>
                        <Icon icon="clear" size="25px" />
                      </span>
                    </Button>
                  ) : (
                    <Icon icon="magnifyingGlass" size="25px" />
                  )} />
            </div>
            <Card css={{ width: "100%" }}>
              {selectedPOCRestrictions.length > 0 ? (
                <div className={styles.pointOfContactRestrictionSelection}>
                  {sortPointOfContacts(selectedPOCRestrictions).map((pointOfContact: PointOfContact, index) => (
                    <div key={index} className={styles.pointOfContactRestrictionSelectionItem}>
                      <Typography variant="h8" title={pointOfContact.label}>{pointOfContact.label}</Typography>
                      <Button
                        type="icon"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedPOCRestrictions(selectedPOCRestrictions.filter((poc) => !samePOCRestriction(poc, pointOfContact)));
                        }}>
                        <span className={styles.inputTextColor}>
                          <Icon icon="close" size="25px" />
                        </span>
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <Typography variant="h8">{I18n.t("user_management.no_point_of_contact_restrictions")}</Typography>
              )}
            </Card>
          </div>
        } />
      {showSearchResults && ReactDOM.createPortal(
        <div className={styles.searchResultsPortal} onClick={() => setShowSearchResults(false)}>
          <div
            ref={setPopperElement}
            style={popperStyles.popper}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            {...popperAttributes.popper}>
            <div className={styles.searchResults}>
              {searchResults.length > 0 ? (
                <div className={styles.searchResultsContainer}>
                  {searchResults.map((pointOfContact: PointOfContact) => (
                    <div
                      key={pointOfContact.label}
                      className={styles.searchResultItem}
                      title={pointOfContact.label}
                      onClick={() => {
                        setSelectedPOCRestrictions([...selectedPOCRestrictions, pointOfContact]);
                      }}>
                      <Typography variant="h8">{pointOfContact.label}</Typography>
                    </div>
                  ))}
                </div>
              ) : (
                <Typography variant="h8">{I18n.t("user_management.no_results_found")}</Typography>
              )}
            </div>
          </div>
        </div>, document.body)}
    </>
  )
}
