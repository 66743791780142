import classNames from "classnames/bind";
import I18n from "i18n-js";
import React, { useState } from "react";
import styles from "../styles.module.scss";
import config from "../config.json";

const cx = classNames.bind(styles);

const processInputConditions = (conditions) => {
  const processedConditions = [];
  if (!conditions) {
    return processedConditions;
  }
  conditions.forEach(condition => {
    switch (condition.type) {
      case "dom_element":
        processedConditions.push({
          type: condition.type,
          value: condition.value.map(domElement => ({ 
            selectorType: domElement.selector_type,
            selectorValue: domElement.selector_value,
            contentType: domElement.content_type,
            contentName: domElement.content_name,
            comparisonType: domElement.comparison_type,
            comparisonValue: domElement.comparison_value
           }))
        })
        break;
    }
  });
  return processedConditions;
}

const processAvailableConditionsInput = (conditions) => {
  return config.types.conditions.filter(type => !conditions.find(condition => condition.type === type));
}

const TriggerConditionsConfiguration = ({ conditions }) => {
  const inputCurrentConditions = processInputConditions(conditions);
  const inputAvailableConditions = processAvailableConditionsInput(inputCurrentConditions);
  const [currentConditions, setCurrentConditions] = useState(inputCurrentConditions);
  const [availableConditions, setAvailableConditions] = useState(inputAvailableConditions);
  const [newConditionOverlayEnabled, setNewConditionOverlayEnabled] = useState(false);

  const handleAddCondition = (e) => {
    e.preventDefault();
    setNewConditionOverlayEnabled(true);
  }

  const handleAddConditionOption = (e, newConditionType) => {
    e.preventDefault();
    setNewConditionOverlayEnabled(false);
    setAvailableConditions(availableConditions.filter(condition => condition !== newConditionType));
    const newCondition = {
      type: newConditionType,
      value: null
    };
    switch (newConditionType) {
      case "dom_element":
        newCondition.value = [
          {
            selectorType: "id",
            selectorValue: "",
            contentType: "attribute",
            contentName: "",
            comparisonType: "equal",
            comparisonValue: ""
          }
        ]
        break;
    }
    setCurrentConditions([...currentConditions, newCondition]);
  }

  const handleRemoveTriggerType = (e, type) => {
    e.preventDefault();
    const newCurrentConditions = [...currentConditions].filter((condition) => condition.type !== type);
    setAvailableConditions([...availableConditions, type]);
    setCurrentConditions(newCurrentConditions);
  }

  const handleRemoveDomElementCondition = (e, index) => {
    e.preventDefault();
    const domElementCondition = currentConditions.find((condition) => condition.type === "dom_element");
    domElementCondition.value.splice(index, 1);
    setCurrentConditions([...currentConditions]);
  }

  const handleChangeDomElementSelectorType = (index, newType) => {
    const domElementCondition = currentConditions.find((source) => source.type === "dom_element");
    const domElement = domElementCondition.value[index];
    domElement.selectorType = newType;
    setCurrentConditions([...currentConditions]);
  }

  const handleChangeDomElementSelectorValue = (index, newValue) => {
    const domElementCondition = currentConditions.find((source) => source.type === "dom_element");
    const domElement = domElementCondition.value[index];
    domElement.selectorValue = newValue;
    setCurrentConditions([...currentConditions]);
  }

  const handleChangeDomElementContentType = (index, newType) => {
    const domElementCondition = currentConditions.find((source) => source.type === "dom_element");
    const domElement = domElementCondition.value[index];
    domElement.contentType = newType;
    setCurrentConditions([...currentConditions]);
  }

  const handleChangeDomElementContentName = (index, newValue) => {
    const domElementCondition = currentConditions.find((source) => source.type === "dom_element");
    const domElement = domElementCondition.value[index];
    domElement.contentName = newValue;
    setCurrentConditions([...currentConditions]);
  }

  const handleChangeDomElementComparisonType = (index, newType) => {
    const domElementCondition = currentConditions.find((source) => source.type === "dom_element");
    const domElement = domElementCondition.value[index];
    domElement.comparisonType = newType;
    setCurrentConditions([...currentConditions]);
  }

  const handleChangeDomElementComparisonValue = (index, newValue) => {
    const domElementCondition = currentConditions.find((source) => source.type === "dom_element");
    const domElement = domElementCondition.value[index];
    domElement.comparisonValue = newValue;
    setCurrentConditions([...currentConditions]);
  }

  const handleAddConditionDomElement = (e) => {
    e.preventDefault();
    const domElementCondition = currentConditions.find((source) => source.type === "dom_element");
    domElementCondition.value.push({
      selectorType: "id",
      selectorValue: "",
      contentType: "attribute",
      contentName: "",
      comparisonType: "equal",
      comparisonValue: ""
    });
    setCurrentConditions([...currentConditions]);
  }

  return (
    <div className={cx("trigger-conditions")}>
      <div className={cx("connector")} />
      <h2>{I18n.t("web_tag_configuration.trigger_conditions")}</h2>
      <div className={cx("trigger-configuration-container")}>
        {currentConditions.length === 0 && (
          <div id="no_trigger_conditions" dangerouslySetInnerHTML={{ __html: I18n.t("web_tag_configuration.no_condition_selected") }}></div>
        )}
        {currentConditions.map((condition) => {
          switch (condition.type) {
            case "dom_element":
              return (
                <div id={"condition-type-configuration-" + condition.type} className={cx(["trigger-type", "condition-dom-element"])} key={"condition-type-configuration-" + condition.type}>
                  <h4 className={cx("trigger-type-header")}>{I18n.t("web_tag_configuration.condition_trigger_type_dom_element")}</h4>
                  <button onClick={(e) => handleRemoveTriggerType(e, condition.type)} className={cx("trigger-type-remove")}>&#10006;</button>
                  {condition.value.map((domElement, index) => {
                    return (
                      <div key={"condition-dom-element-list-element-" + index} className={cx("condition-dom-element-tile")}>
                        {index > 0 && (
                          <button onClick={(e) => handleRemoveDomElementCondition(e, index)} className={cx("condition-dom-element-tile-delete")}>
                            <i className="fas fa-trash"></i>
                          </button>
                        )}
                        <div className={cx("condition-dom-element-tile-line")}>
                          <div className={cx(["condition-dom-element-selector-inputs", "col-xs-8"])}>
                            <div className={cx("row")}>
                              <div className={cx("col-xs-6")}>
                                <label className="control-label select" htmlFor={`trigger_condition[dom_element][${index}][selector_type]`}>{I18n.t("web_tag_configuration.selector_type")}</label>
                                <select onChange={(e) => handleChangeDomElementSelectorType(index, e.target.value)} value={domElement.selectorType} className="form-control select" required id={`trigger_condition[dom_element][${index}][selector_type]`} name={`trigger_condition[dom_element][${index}][selector_type]`}>
                                  {config.dom_element_query_types.map((queryType) =>
                                    <option key={"dom_element_option_" + queryType} value={queryType}>{I18n.t("web_tag_configuration.identifier_type." + queryType)}</option>
                                  )}
                                </select>
                              </div>
                              <div className={cx("col-xs-6")}>
                                <label className="control-label string" htmlFor={`trigger_condition[dom_element][${index}][selector_value]`}>{I18n.t("web_tag_configuration.selector_value")}</label>
                                <input onChange={(e) => handleChangeDomElementSelectorValue(index, e.target.value)} value={domElement.selectorValue} required className="form-control string" id={`trigger_condition[dom_element][${index}][selector_value]`} name={`trigger_condition[dom_element][${index}][selector_value]`} />
                              </div>
                            </div>
                            <br></br>
                            <div className={cx("row")}>
                              <div className={cx("col-xs-6")}>
                                <label className="control-label select" htmlFor={`trigger_condition[dom_element][${index}][content_type]`}>{I18n.t("web_tag_configuration.content_type")}</label>
                                <select onChange={(e) => handleChangeDomElementContentType(index, e.target.value)} value={domElement.contentType} className="form-control select" required id={`trigger_condition[dom_element][${index}][content_type]`} name={`trigger_condition[dom_element][${index}][content_type]`}>
                                  {config.dom_element_content_types.map((contentType) =>
                                    <option key={"dom_element_content_option_" + contentType} value={contentType}>{I18n.t("web_tag_configuration.content_types." + contentType)}</option>
                                  )}
                                </select>
                              </div>
                              <div className="col-xs-6">
                                {domElement.contentType === "attribute" && (
                                  <React.Fragment>
                                    <label className="control-label string" htmlFor={`trigger_condition[dom_element][${index}][content_name]`}>{I18n.t("web_tag_configuration.content_name")}</label>
                                    <input onChange={(e) => handleChangeDomElementContentName(index, e.target.value)} value={domElement.contentName} required className="form-control string" id={`trigger_condition[dom_element][${index}][content_name]`} name={`trigger_condition[dom_element][${index}][content_name]`} />
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className={cx(["condition-dom-element-preview", "col-xs-4"])}>
                            <div className={cx("preview-dom-element")}>
                              <span className={cx("preview-selector")}>
                                {(() => {
                                  switch (domElement.selectorType) {
                                    case "id":
                                      if (domElement.selectorValue.charAt(0) !== "#") {
                                        return "#" + domElement.selectorValue.replace(" ", "");
                                      } else {
                                        return domElement.selectorValue.replace(" ", "");
                                      }
                                    case "classname":
                                      if (domElement.selectorValue.charAt(0) !== ".") {
                                        return "." + domElement.selectorValue.replace(" ", "");
                                      } else {
                                        return domElement.selectorValue.replace(" ", "");
                                      }
                                    default:
                                      return domElement.selectorValue.replace(" ", "");
                                  }
                                })()}
                              </span>
                              <span className={cx("preview-content")}>
                                <i className="fas fa-search"></i>
                                <span>{(() => {
                                  switch (domElement.contentType) {
                                    case "attribute":
                                      return `"${domElement.contentName}"`;
                                    case "inner":
                                      return I18n.t("web_tag_configuration.content_types.inner");
                                  }
                                })()}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={cx("condition-dom-element-tile-line")}>
                          <div className={cx("value-comparator")}>
                            <h3>{I18n.t("web_tag_configuration.should_be")}</h3>
                            <select value={domElement.comparisonType} onChange={(e) => handleChangeDomElementComparisonType(index, e.target.value)} className="form-control select" required id={`trigger_condition[dom_element][${index}][comparison_type]`} name={`trigger_condition[dom_element][${index}][comparison_type]`}>
                              {config.value_comparators.map(comparator => (
                                <option key={"dom_element_comparison_option_" + comparator} value={comparator}>{I18n.t("web_tag_configuration.value_comparators." + comparator)}</option>
                              ))}
                            </select>
                            {["greater_than", "lesser_than", "equal"].includes(domElement.comparisonType) && (
                              <input className="form-control string" value={domElement.comparisonValue} onChange={(e) => handleChangeDomElementComparisonValue(index, e.target.value)} id={`trigger_condition[dom_element][${index}][comparison_value]`} name={`trigger_condition[dom_element][${index}][comparison_value]`} required />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <div className={cx("condition-dom-element-add-button-container")}>
                    <button onClick={(e) => handleAddConditionDomElement(e)} className={cx("condition-dom-element-add-button")}>{I18n.t("web_tag_configuration.add_new_element")}<span>+</span></button>
                  </div>
                </div>
              )
          }
        })}
        {availableConditions.length > 0 && (
          <div className={cx("add-trigger-type")}>
            <button onClick={handleAddCondition} className={cx("add-trigger-type-button")}>+</button>
            <div className={cx(["add-trigger-type-options", `${newConditionOverlayEnabled ? "enabled" : ""}`])}>
              {availableConditions.map(availableCondition => (
                <button onClick={(e) => handleAddConditionOption(e, availableCondition)} key={"condition-option-" + availableCondition} className={cx("add-trigger-type-option")}>
                  {I18n.t("web_tag_configuration.condition_trigger_type_" + availableCondition)}
                </button>
              ))}
            </div>
            <div onClick={() => setNewConditionOverlayEnabled(false)} className={cx(["add-trigger-type-overlay", `${newConditionOverlayEnabled ? "enabled" : ""}`])} />
          </div>
        )}

      </div>
    </div>
  );
}

export default TriggerConditionsConfiguration;