import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";

import { faChevronUp, faRotateLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabList, TabRoot, TabTrigger } from "stingray-components";

import { screenSize } from "../utils/constants";
import IconButton from "./IconButton";
import FilterChip from "./FilterChip";

import I18n from "i18n-js";
import classNames from "classnames";
import styles from "./FilterConversations.module.scss";

const FilterConversations = ({
  locations,
  filters,
  setFiltersCallback,
  filtersAreExpanded,
  setFiltersAreExpandedCallback,
  showClosedConversations,
  setShowClosedConversations,
  userScreenSize,
}) => {
  const [conversationName, setConversationName] = useState("");
  const [location, setLocation] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const hasMultipleLocations = locations.length > 1;

  const allLocations = {
    label: I18n.t("live_conversations.all_locations"),
    value: ""
  }

  const onApplyFilters = () => {
    setFiltersCallback({conversationName, location, phoneNumber, message})
    setFiltersAreExpandedCallback(false);
  };

  const onResetFilters = () => {
    setConversationName("");
    setLocation();
    setPhoneNumber("");
    setMessage("");

    setFiltersCallback();
  };

  const onRemoveConversationName = () => {
    setConversationName("");
    setFiltersCallback({ ...filters, conversationName: "" });
  };

  const onRemovePhoneNumber = () => {
    setPhoneNumber("");
    setFiltersCallback({ ...filters, phoneNumber: "" });
  };

  const onRemoveMessage = () => {
    setMessage("");
    setFiltersCallback({ ...filters, message: "" });
  };

  useEffect(() => {
    if (userScreenSize === screenSize.SMALL) {
      document.getElementById("live-conversations").style.overflowY = filtersAreExpanded ? "hidden" : "auto";
    } else {
      document.getElementById("scrolling-left-container").style.overflowY = filtersAreExpanded ? "hidden" : "auto";
    }
  }, [filtersAreExpanded, userScreenSize]);

  return (
    <div className={styles.filterConversations}>
      <div
        className={classNames(styles.expandedFilters, {
          [styles.filtersAreExpanded]: filtersAreExpanded,
          [styles.withoutMultipleLocations]: !hasMultipleLocations,
        })}>
        <div className={styles.filterTitle}>
          {I18n.t("live_conversations.filter_title")}
        </div>
        <div className={styles.filterLabel}>
          {I18n.t("live_conversations.conversation_name")}
        </div>
        <input className={styles.filterValue} type="text" value={conversationName} onChange={e => setConversationName(e.target.value)} />

        { hasMultipleLocations && (
          <>
            <div className={styles.filterLabel}>
              {I18n.t("live_conversations.location")}
            </div>
            <ReactSelect
              styles={{
                control: (base, state) => ({
                  ...base,
                  marginBottom: "10px",
                  height: "50px",
                  background: "rgba(0, 0, 0, 0.05)",
                  border: "0",
                  borderColor: "none",
                  borderRadius: "10px",
                  boxShadow: state.isFocused ? "0 0 0 2px #5175FA" : null,
                }),
              }}
              value={location || allLocations}
              onChange={location => setLocation(location)}
              options={[allLocations, ...locations]} />
          </>
        ) }

        <div className={styles.filterLabel}>
          {I18n.t("live_conversations.phone_number")}
        </div>
        <input className={styles.filterValue} type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />

        <div className={styles.filterLabel}>
          {I18n.t("live_conversations.message")}
          </div>
        <input className={styles.filterValue} type="text" value={message} onChange={e => setMessage(e.target.value)} />

        <div className={styles.buttonsContainer}>
          <button className={styles.applyFilters} onClick={() => onApplyFilters()}>
            <FontAwesomeIcon icon={faSearch} />
            {I18n.t("live_conversations.apply_filters")}
          </button>
          <button className={styles.resetFilters} onClick={() => onResetFilters()}>
            <FontAwesomeIcon icon={faRotateLeft} />
            {I18n.t("live_conversations.reset_filters")}
          </button>
        </div>
        <IconButton className={styles.collapseIcon} onClick={() => setFiltersAreExpandedCallback(false)}>
          <FontAwesomeIcon icon={faChevronUp} />
        </IconButton>
      </div>
      <div className={classNames(styles.filterBar, {
          [styles.cursorPointer]: !filtersAreExpanded
        })} onClick={() => setFiltersAreExpandedCallback(true)}>
        <FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />
        <div className={styles.filterChipContainer}>
          <FilterChip label={hasMultipleLocations ? filters?.location?.label || allLocations.label : locations[0]?.label} />
          {filters?.conversationName && <FilterChip label={filters?.conversationName} onRemoveCallback={() => onRemoveConversationName()} />}
          {filters?.phoneNumber && <FilterChip label={filters?.phoneNumber} onRemoveCallback={() => onRemovePhoneNumber()} />}
          {filters?.message && <FilterChip label={filters?.message} onRemoveCallback={() => onRemoveMessage()} />}
        </div>
      </div>
      <div className={styles.openCloseWrapper}>
        <TabRoot
          defaultValue="open"
          css={{ width: "fit-content" }}
          value={showClosedConversations ? "closed" : "open"}
          onValueChange={(v) => setShowClosedConversations(v === "closed")}>
          <TabList variant="roundedDiscreet" css={{ height: "fit-content", padding: "5px" }}>
            <TabTrigger value="open">{ I18n.t("live_conversations.open") }</TabTrigger>
            <TabTrigger value="closed">{ I18n.t("live_conversations.closed") }</TabTrigger>
          </TabList>
        </TabRoot>
      </div>
      <div className={classNames(styles.dimmer, { [styles.filtersAreExpanded]: filtersAreExpanded })} onClick={() => setFiltersAreExpandedCallback(false)}></div>
    </div>
  );
}

export default FilterConversations;
