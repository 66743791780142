import React from "react";

import styles from "./styles.module.scss";

import { HeaderText, ChartLegend } from "../../../shared";

import ComparisonChart from "../comparison-chart/comparison-chart";

//TODO: add new type
import { NPSTrendingContentType } from "../../../../types";

import { isArrayEmpty } from "../../../../utils/general-helper";

const ComparisonContent = ({ headerText = "NPS Comparison", description, chartData }) => {
  const { categories, series, xAxisTitle, yAxisTitle } = chartData;

  return (
    <div>
      <HeaderText value={headerText}>
        {description && <div className={styles["description"]}>{description}</div>}
        <div className={styles["legend-wrapper"]}>
          {series &&
            series.map((s) => {
              if (!isArrayEmpty(s.data))
                return <ChartLegend key={s.name} value={s.name} color={s.color} />;
            })}
        </div>
      </HeaderText>
      <ComparisonChart
        categories={categories}
        series={series}
        xAxisTitle={xAxisTitle}
        yAxisTitle={yAxisTitle}
      />
    </div>
  );
};

ComparisonContent.propTypes = NPSTrendingContentType;

export default ComparisonContent;
