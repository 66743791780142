import React from "react";
import styles from "./styles.module.scss";
import { GotoLinkType } from "components/types";

const GotoLink = ({ url, openInNewTab = false }) => {
  return (
    <a className={styles.wrapper} href={url} target={openInNewTab ? "_blank" : "_self"}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <g strokeMiterlimit="10">
          <circle cx="10" cy="10" r="10" stroke="none" />
          <circle cx="10" cy="10" r="9.5" />
        </g>
        <path d="M8.666 13.361a.522.522 0 00.737 0l2.927-2.984a.531.531 0 000-.747L9.406 6.643a.519.519 0 00-.734-.01l-.009.01a.532.532 0 000 .747l2.4 2.613-2.4 2.611a.534.534 0 000 .748z" />
      </svg>
    </a>
  );
};

GotoLink.propTypes = GotoLinkType;

export default GotoLink;
