import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const SummaryEngagement = ({
  initiated = 0,
  completed = 0,
  partial = 0,
  rate = 0,
  responded = 0,
  preferResponded = false,
}) => {
  return (
    <div className={cx("summary")}>
      <div className={cx("line")}>
        <div className={cx("amt")}>{initiated}</div>
        <div className={cx("txt")}>Initiated contact</div>
      </div>
      <div className={cx("line")}>
        <div className={cx("amt")}>{preferResponded ? responded : completed}</div>
        <div className={cx("txt")}>{preferResponded ? "Responded" : "Completions"}</div>
      </div>
      <div className={cx("line")}>
        <div className={cx("amt")}>{preferResponded ? completed : partial}</div>
        <div className={cx("txt")}>{preferResponded ? "Completions" : "Partial completions"}</div>
      </div>
      <div className={cx("line")}>
        <div className={cx("amt")}>{rate}%</div>
        <div className={cx("txt")}>{preferResponded ? "Engagement rate" : "Completion rate"}</div>
      </div>
    </div>
  );
};

SummaryEngagement.propTypes = {
  completed: PropTypes.number,
  initiated: PropTypes.number,
  partial: PropTypes.number,
  preferResponded: PropTypes.bool,
  rate: PropTypes.number,
  responded: PropTypes.number,
};

export default SummaryEngagement;
