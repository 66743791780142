import React from "react";
import PropTypes from "prop-types";
import I18n from "i18n-js";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";

import defaultOptions from "./default-options.json";

import { extendChartConfig } from "../../utils/chart-helper";

import $ from "jquery";
import DataTable from "datatables.net";
$.DataTable = DataTable;

const cx = classNames.bind(styles);

const NPSSegmentationGraph = ({ segmentInfo, npsInfo, answerType, useOrderForGraphs }) => {
  React.useEffect(() => {
    if (segmentInfo) {
      if (!$.fn.dataTable.isDataTable(".nps-segmentation-table")) {
        $(".nps-segmentation-table").DataTable({
          paging: false,
          searching: false,
          info: false,
          ordering: !useOrderForGraphs,
          order: answerType === "Scale" ? [[0, "desc"]] : [[1, "desc"]],
        });
      }
    }
  }, [segmentInfo]);

  if (!segmentInfo) {
    return <div className={cx("no-data")}>{I18n.t("no_data")}</div>;
  }

  const extendedOptions = parseData(segmentInfo, npsInfo.nps);

  return (
    <div className={cx("segmentation-graph")}>
      <div className={cx("chart-content")}>
        <HighchartsReact highcharts={Highcharts} options={extendedOptions} />
      </div>
      <SegmentationTable
        npsInfo={npsInfo}
        useOrderForGraphs={useOrderForGraphs}
        segmentInfo={segmentInfo}
      />
    </div>
  );
};

NPSSegmentationGraph.propTypes = {
  segmentInfo: PropTypes.array,
  npsInfo: PropTypes.object,
  answerType: PropTypes.string,
  useOrderForGraphs: PropTypes.bool,
};

export default NPSSegmentationGraph;

const SegmentationTable = ({ npsInfo, useOrderForGraphs, segmentInfo }) => {
  const buckets = Object.keys(npsInfo.segmented_buckets);

  if (useOrderForGraphs) {
    buckets.sort((a, b) => {
      const aIndex = segmentInfo.findIndex((segment) => segment.cohort === (a === "" ? null : a));
      const bIndex = segmentInfo.findIndex((segment) => segment.cohort === (b === "" ? null : b));
      return aIndex - bIndex;
    });
  }

  return (
    <div>
      <table className="nps-segmentation-table table">
        <thead>
          <tr>
            <th data-sortable="true">{I18n.t("category")}</th>
            <th className={cx("align-center")} data-sortable="true">
              {I18n.t("sample_count")}
            </th>
            <th className={cx("align-center")} data-sortable="true">
              {I18n.t("sample_count")}
            </th>
            <th className={cx("align-center")} data-sortable="true">
              {I18n.t("nps")}
            </th>
            <th className={cx("align-center")} data-sortable="true">
              {I18n.t("overall_diff")}
            </th>
          </tr>
        </thead>
        <tbody>
          {buckets.map((key) => {
            const bucket = npsInfo.segmented_buckets[key];
            const bucketPercentage =
              npsInfo.nps_total > 0 ? (
                `${((bucket.nps_total * 100.0) / npsInfo.nps_total).toFixed(2)}%`
              ) : (
                <i>n/a</i>
              );

            const bucketNpsDelta =
              bucket.nps != null ? (bucket.nps - npsInfo.nps).toFixed(1) : <i>n/a</i>;

            return key === "" ? null : (
              <tr key={key} style={{ fontWeight: 600 }}>
                <td>{I18n.t(key, { defaultValue: key.charAt(0).toUpperCase() + key.slice(1) })}</td>
                <td className={cx("align-center")}>{bucket.nps_total}</td>
                <td className={cx("align-center")} data-order={bucketPercentage}>
                  {bucketPercentage}
                </td>
                <td
                  className={cx("align-center")}
                  data-order={bucket.nps === "" ? -200.0 : bucket.nps}
                >
                  {bucket.nps == null ? <i>n/a</i> : bucket.nps.toFixed(1)}
                </td>
                <td className={cx("align-center")}>{bucketNpsDelta}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const parseData = (segmentInfo, nps) => {
  const xAxisCategories = [];
  const yAxisSampleSizes = [];
  const yAxisNPS = [];
  let minNPS = 100;
  let maxNPS = -100;

  segmentInfo.slice(0, 15).forEach((seg) => {
    if (seg.cohort) {
      const cohort = I18n.t(seg.cohort, {
        defaultValue: seg.cohort.charAt(0).toUpperCase() + seg.cohort.slice(1),
      });

      xAxisCategories.push(cohort);
      yAxisSampleSizes.push(seg.sample_size);
      yAxisNPS.push(parseFloat(seg.nps.toFixed(1)));
      minNPS = minNPS > seg.nps ? seg.nps : minNPS;
      maxNPS = maxNPS < seg.nps ? seg.nps : maxNPS;
    }
  });

  minNPS = Math.max(minNPS - (minNPS % 10), -100);
  maxNPS = Math.min(maxNPS + 10 - (maxNPS % 10), 100);

  let showLastNPSLabel = true;
  if (maxNPS == 100) {
    maxNPS = 105;
    showLastNPSLabel = false;
  }
  if (minNPS == -100) {
    minNPS = -105;
  }

  const valueOptions = {
    xAxis: [
      {
        categories: xAxisCategories,
      },
    ],
    yAxis: [
      {
        min: minNPS - 10,
        max: maxNPS + 10,
        showLastLabel: showLastNPSLabel,
        plotLines: [
          {
            value: nps,
            label: {
              text: I18n.t("average_nps", { value: nps.toFixed(1) }),
            },
          },
        ],
      },
    ],
    series: [
      {
        name: I18n.t("sample_size"),
        data: yAxisSampleSizes,
        dataLabels: {
          formatter: function () {
            return this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
      },
      {
        data: yAxisNPS,
        dataLabels: {
          formatter: function () {
            return "NPS: " + this.y.toFixed(1);
          },
        },
      },
    ],
  };

  //merge it with defaultOptions
  return extendChartConfig(defaultOptions, valueOptions);
};
