import React, { Fragment } from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

//TODO: this will need aler-section-wrapper -> moving rendering condition to container
const AlertSection = ({
  totalAlertsCount = 0,
  newAlertsCount = 0,
  alertDetailsPath,
  contentMessage,
}) => {
  const content = contentMessage ? (
    <div className={cx("none")}>{contentMessage}</div>
  ) : (
    <Fragment>
      <div className={cx("total")}>
        <span className={cx("count")}>{totalAlertsCount}</span> Total Alerts
      </div>
      <div className={cx("new")}>
        <span className={cx("count")}>{newAlertsCount}</span> New Alerts
      </div>
    </Fragment>
  );

  return (
    <div className={cx("section")}>
      <div className={cx("bar")}>
        <div className={cx("counts")}>{content}</div>
        {alertDetailsPath && (
          <a href={alertDetailsPath}>
            <div className={cx("button", { new: newAlertsCount > 0 })} title="View Alerts">
              <i aria-hidden="true" className="fa fa-chevron-right" />
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

AlertSection.propTypes = {
  alertDetailsPath: PropTypes.string,
  contentMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  newAlertsCount: PropTypes.number,
  totalAlertsCount: PropTypes.number,
};

export default AlertSection;
