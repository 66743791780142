import React from "react";

import { PrettyLoader } from "../../../views/shared";

import { getTrendIconFromDelta } from "../../../utils/general-helper";

import KPI from "../../../views/report-page/kpi";

import hasLoader from "../../hoc/has-loader";

//todo: fix value if percentage
const KPIContainer = ({ status, componentData }) => {
  const { question, value, previousPeriod, answerType, ranks } = componentData;

  let trendingIcon;

  if (previousPeriod) {
    const { periodValue } = previousPeriod;

    if (value != null && periodValue != null) {
      trendingIcon = getTrendIconFromDelta(value - periodValue);
    }
  }

  return (
    <PrettyLoader status={status} size="flex">
      <KPI
        question={question}
        value={value}
        trendIcon={trendingIcon}
        answerType={answerType}
        previousPeriod={previousPeriod}
        ranks={ranks}
      />
    </PrettyLoader>
  );
};

export default hasLoader(KPIContainer);
