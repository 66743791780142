import React from "react";
import I18n from "i18n-js";

import { HeaderText, SimpleTable, PrettyButton } from "../../../shared";

import CustomProgressBar from "../top-ten-progress-bar/top-ten-progress-bar";

import styles from "./styles.module.scss";

const TopTenContent = ({ header, columns, rows, globalImpactColor, maxRows = 25, detailsPath }) => {
  if (maxRows > 0 && rows.length > 1) {
    rows = rows.slice(0, maxRows);
  }

  let newRows = prepareData(rows, globalImpactColor);

  return (
    <React.Fragment>
      <HeaderText value={header} />
      <SimpleTable columns={columns} rows={newRows} />
      {detailsPath && (
        <div className={styles.divider}>
          <PrettyButton className="inverted" path={detailsPath} openInNewTab={true}>
            {I18n.t("see_more")}
          </PrettyButton>
        </div>
      )}
    </React.Fragment>
  );
};

let prepareData = (data = [], globalImpactColor) => {
  let maximum;
  if (data[0] && data[0].impact) {
    maximum = data[0].impact;
  }

  let result = data.map((row) => {
    let { impact, impactColor } = row;

    let newImpact = impact && (
      <CustomProgressBar
        value={impact}
        progressValue={getProgressValue(impact, maximum)}
        color={impactColor ? impactColor : globalImpactColor}
      />
    );

    return changeEmptyToDash({
      ...row,
      impact: newImpact,
    });
  });

  return result;
};

//null or undefined values changed to dash
let changeEmptyToDash = (obj) => {
  let keys = Object.keys(obj);

  keys.forEach((key) => {
    if (obj[key] === null || obj[key] === undefined) {
      obj[key] = "-";
    }
  });
  return obj;
};

let getProgressValue = (value, maximum) => {
  return Math.round((value / maximum) * 100);
};

export default TopTenContent;
