Highcharts = require('highcharts')

jQuery ->

  # short circuit if not on right page
  return unless window.location.pathname.includes("/surveys")

  Url = require('url-parse')
  parse = require('url-parse')

  $('.values').css({fontSize: "12px", fontWeight: "300"})

  $('.survey-nps-report select.segmentation-question-selector').on 'change', ->
    url = $(this).find(":selected").data('newtarget')
    window.location.href = url + "#segmentation_anchor"

  $('#report-trending-poc-cohort-name-dropdown').on 'change', ->
    url = $(this).find(":selected").data('newtarget')
    window.location.href = url + "#trending_anchor"

  $('#report-trending-interval-dropdown').on 'change', ->
    url = $(this).find(":selected").data('newtarget')
    window.location.href = url + "#trending_anchor"

  renderInitiationReportGraph = ($element) ->
    url = new Url(window.location.href)
    url = parse(url, true)
    existing_params = url.query
    if existing_params["fltr_interval"] != undefined
      interval = I18n.t("datetime.prompts.#{existing_params["fltr_interval"]}", default: existing_params["fltr_interval"])
    else
      interval = I18n.t("datetime.prompts.week")
    graphTitle = interval.charAt(0).toUpperCase() + interval.slice(1)
    customPlotLines = []

    initiationDataValues = $element.data("initiation-series")['values']
    completionDataValues = $element.data("completed-series")['values']
    initiationDates = $element.data("initiation-dates")
    categories = []

    date_list = Object.keys(initiationDates)

    for date in date_list
      if interval == "Month"
        categories.push(moment(date, "YYYY-M-D H:m").format("MMM YYYY"))
      else
        categories.push(moment(date, "YYYY-M-D H:m").format("DD MMM"))

    for i in [0..initiationDataValues.length-1]
      initiationDataValues[i] -= completionDataValues[i]

    # Layout workaround for vertical label positioning when we have don't have enough data to enable the scrollbar
    getAxisLabelOffset = (dataLength) ->
      switch dataLength
        when 0 then 0
        when 1 then 162
        when 2 then 83
        when 3 then 57
        when 4 then 44
        when 5 then 36
        when 6 then 30
        when 7 then 27
        when 8 then 23
        when 9 then 22
        when 10 then 20
        when 11 then 18
        when 12 then 17
        else 19

    xAxisLabelOffset = getAxisLabelOffset(date_list.length)

    Highcharts.chart($element[0], {
      chart: {
        ignoreHiddenSeries: true,
        type: 'bar',
        marginRight: 35,
        scrollablePlotArea: {
          opacity: 1
          minHeight: (date_list.length * 400) / 12
        },
      },
      title: {
        text: ''
      },
      xAxis: {
        labels: {
          y: xAxisLabelOffset,
        },
        tickmarkPlacement: 'between'
        title: {
          text: "<span style='margin-top: 15px;'>" + graphTitle + "</span>",
          margin: 20,
          style: {
            fontSize: 15
          },
          y: 10
          align: 'high'
        },
        tickColor: '',
        categories: categories,
        min: 0,
      },
      yAxis: {
        lineColor: '',
        margin: 13,
        lineWidth: 1,
        min: 0,
        title: {
          text: I18n.t("engagements_axis"),
          margin: 20,
          style: {
            fontSize: 15
          },
          align: 'middle'
        },
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: 'bold',
            color: "#2962FF"
          }
        }
      },
      tooltip: {
        formatter: () ->
          if this.series.name == I18n.t("initiations")
            result = (this.total)
            return '<b>' + this.series.name +
              '</b>: <b>' + result+ '</b>';
          else
            return '<b>' + this.series.name +
              '</b>: <b>' + this.y + '</b>';
      },
      credits: {
        enabled: false
      },
      colors: [ '#E4E4EE', '#2962FF'],
      plotOptions: {
        series: {
          pointStart: 0,
          pointPlacement: 'between',
          pointWidth: 15,
          stacking: 'normal',
        },
      },
      series: [{
        width: 20,
        name: I18n.t("initiations"),
        showInLegend: false,
        data: initiationDataValues
      }, {
        width: 20,
        name: I18n.t("completions"),
        showInLegend: false,
        data: completionDataValues
      }]
    });

  renderInitiationReportGraph $('#initiation-graph-container') if $('#initiation-graph-container').length

  renderNPSReportGraph = ($element) ->

    ordered_roles = $element.data('colors')
    role_order = $element.data('role-order')
    nps = parseInt($element.data('nps'))

    colors = []
    tooltip = []
    color_dict = {
      "1": "green",
      "2": "red",
      "3": "lightgray"
    }

    tooltip_dict = {
      "1": I18n.t("promoters"),
      "2": I18n.t("detractors"),
      "3": I18n.t("passives")
    }

    ordered_roles.forEach (color) ->
      colors.push color_dict[color]
      tooltip.push tooltip_dict[color]
      return

    if ( nps > 0)
      nps_semi_circle_positive = nps
      nps_semi_circle_negative = 100 - nps
      text = "<span style='color:green; font-size: 40px; font-family: \"Open Sans\", sans-serif; font-weight: 600;'>#{nps}</span><br><span style='font-size:13px;'>#{I18n.t("net_promoter_score")}</span>"
      nps_color = ['green', 'lightgray']
    else
      nps_semi_circle_positive = Math.abs(nps)
      nps_semi_circle_negative = 100 + nps
      text = "<span style='color:red; font-size: 40px; font-family: \"Open Sans\", sans-serif; font-weight: 600;'>#{nps}</span><br><span style='font-size:13px;'>#{I18n.t("net_promoter_score")}</span>"
      nps_color = ['red', 'lightgray']

    Highcharts.chart $element[0],
      chart:
        # marginTop: -80
        marginBottom: -120
      credits:
        enabled: false
      title:
        text: text
        align: 'center'
        verticalAlign: 'middle'
        y: 60
      plotOptions:
        pie:
          dataLabels:
            enabled: false
          startAngle: -90
          endAngle: 90
      series: [
        {
          type: 'pie'
          enableMouseTracking: false
          borderWidth: 3
          colors: nps_color
          innerSize: '65%'
          data: [
            ['', nps_semi_circle_positive]
            ['', nps_semi_circle_negative]
          ]
        },
        {
          type: 'pie'
          name: I18n.t("fullwidth_percentage")
          tooltip:
            valueDecimals: 1
            valueSuffix: "%"
          borderWidth: 6
          colors: colors
          innerSize: '75%'
          data: [
            [ tooltip[0], role_order[0] ]
            [ tooltip[1], role_order[1] ]
            [ tooltip[2], role_order[2] ]
          ]
        }
      ]

    $('.report-graph-container .highcharts-label').remove()

  renderNPSReportGraph $('.survey-nps-report #semi-circle-container') if $('.survey-nps-report #semi-circle-container').length

  renderSatisfactionReportGraph = ($element) ->

    display_sat = $element.data('display-sat')
    exact_sat = parseFloat($element.data('exact-sat'))
    color = if parseInt(exact_sat) > 50.0 then "green" else "red"
    text = "<span style='color: #{color}; font-size: 40px; font-family: \"Open Sans\", sans-serif; font-weight: bold;'>#{display_sat}</span><br><span style='font-size:13px;'>#{I18n.t("overall_satisfaction")}</span>"
    nps_color = ['green', 'lightgray']

    Highcharts.chart $element[0],
      chart:
        marginBottom: -120
      credits:
        enabled: false
      title:
        text: text
        align: 'center'
        verticalAlign: 'middle'
        y: 60
      plotOptions:
        pie:
          dataLabels:
            enabled: false
          startAngle: -90
          endAngle: 90
      series: [
        {
          type: 'pie'
          name: I18n.t("fullwidth_percentage")
          tooltip:
            valueDecimals: 0
            valueSuffix: "%"
          borderWidth: 6
          colors: [ "green", "red" ]
          innerSize: '65%'
          data: [
            [ I18n.t("fullwidth_satisfied"), exact_sat ],
            [ I18n.t("fullwidth_unsatisfied"), 100.0 - exact_sat ]
          ]
        }
      ]

    $('.report-graph-container .highcharts-label').remove()

  renderSatisfactionReportGraph $('.survey-sat-report #semi-circle-container') if $('.survey-sat-report #semi-circle-container').length

  renderMultipleChoiceReportGraph = ($element) ->

    mc_info = $element.data('mc')
    colours = [
      "#FA5C37",
      "#0AD1E5",
      "#800BD6",
      "#2962FF",
      "#1C00B3",
      "#C085EB",
      "#94B1FF",
      "#C2F3F9",
      "#2F9CB2",
      "#130077"
    ]
    everything_else_colour = "#E4E4EE"

    data = []
    idx = 0
    max_to_graph = mc_info.max_to_graph
    mc_info.buckets.slice(0, max_to_graph).forEach (bucket) ->
      if bucket.count > 0
        bucket_name = bucket.interpreted
        if bucket_name == "other"
          bucket_name = "uncategorized"
        data.push
          name: bucket_name
          count: bucket.count
          y: bucket.percentage
          color: colours[idx]
        idx = idx + 1

    if mc_info.everything_else
      data.push
        name: "everything else"
        count: mc_info.everything_else.count
        y: mc_info.everything_else.percentage
        color: everything_else_colour

    # Create the chart
    Highcharts.chart $element[0],
      chart:
        type: 'pie'
        spacing: [0, 0, 0, 0]
        margin: [0, 0, 0, 0]
      plotOptions: pie:
        shadow: false
        size: "100%"
        borderWidth: 3
      title: text: null
      credits: enabled: false
      tooltip: valueSuffix: '%'
      series: [
        {
          name: I18n.t("fullwidth_percentage")
          data: data
          size: '100%'
          innerSize: '60%'
          dataLabels:
            enabled: false
        }
      ]

  renderMultipleChoiceReportGraph $('#mc-report-container') if $('#mc-report-container').length

  renderScaleReportGraph = ($element) ->

    scale_info = $element.data( 'scaleinfo' )
    colours = [
      "#FF7864",
      "#FF5983",
      "#E300A3",
      "#E153ED",
      "#800BD6",
      "#1C00B3",
      "#2962FF",
      "#1A9EE8",
      "#0AD1E5",
      "#0AD1E5",
      "#1DC7C0"
    ]
    everything_else_colour = "#E4E4EE"

    data = []
    idx = 0
    max_to_graph = scale_info.max_to_graph
    scale_info.buckets.slice(0, max_to_graph).forEach (bucket) ->
      bucket_name = bucket.interpreted
      if bucket_name == "other"
        bucket_name = "uncategorized"
      data.push
        name: bucket_name
        count: bucket.count
        y: bucket.percentage
        color: colours[idx]
      idx = idx + 1

    if scale_info.everything_else
      data.push
        name: "everything else"
        count: scale_info.everything_else.count
        y: scale_info.everything_else.percentage
        color: everything_else_colour

    # Create the chart
    Highcharts.chart $element[0],
      chart:
        type: 'column'
        spacing: [10, 0, 15, 0]
      xAxis:
        tickWidth: 0
        type: 'category'
      yAxis: title: text: I18n.t("sample_count")
      legend: enabled: false
      title: text: null
      credits: enabled: false
      tooltip: valueSuffix: '%'
      series: [
        {
          name: I18n.t("fullwidth_percentage")
          data: data
          #size: '100%'
          #innerSize: '60%'
          dataLabels:
            enabled: false
        }
      ]

  renderScaleReportGraph $('#scale-report-container') if $('#scale-report-container').length

  $('.sia-header').on 'click', '.sia-header-explain-me', ->
    $('.row.js-sia-explanation').removeClass('hide')

  $('.sia-explanation .sia-explanation-close').on 'click', ->
    $('.row.js-sia-explanation').addClass('hide')

  $('.js-data-table-initiation').each ->
    options =
      paging: false
      searching: false
      info: false
      ordering: true
      pageLength: 10
      columnDefs: [ {
        orderable: true,
        targets: 0
      }, {
        orderable: false
        targets: '_all'
      }]
      bLengthChange: false
      scrollY: '410px',
      scrollCollapse: true

    $(@).DataTable options

  $('#initiation-checkbox').on 'change', ->
    foundChart = Highcharts.charts.find((chart) -> chart.renderTo.id == 'initiation-graph-container')
    if !foundChart
      return
    foundChart.series[0].setVisible(!foundChart.series[0].visible)
    # If no series is selected, toggle the other one since the layout would break if no data is present
    if !foundChart.series[0].visible && !foundChart.series[1].visible
      $('.highcharts-scrolling').css('overflow', 'hidden')
    else
      $('.highcharts-scrolling').css('overflow', 'hidden auto')
    # Force an update on the scroll event which will reposition the xAxis label - the graph will sometimes reposition it
    $('.highcharts-scrolling').trigger("scroll")

  $('#completion-checkbox').on 'change', ->
    foundChart = Highcharts.charts.find((chart) -> chart.renderTo.id == 'initiation-graph-container')
    if !foundChart
      return
    foundChart.series[1].setVisible(!foundChart.series[1].visible)
    # If no series is selected, toggle the other one since the layout would break if no data is present
    if !foundChart.series[0].visible && !foundChart.series[1].visible
      $('.highcharts-scrolling').css('overflow', 'hidden')
    else
      $('.highcharts-scrolling').css('overflow', 'hidden auto')
    # Force an update on the scroll event which will reposition the xAxis label - the graph will sometimes reposition it
    $('.highcharts-scrolling').trigger("scroll")


  $('.highcharts-scrolling').on 'scroll', ->
    $('.highcharts-xaxis').find(">:first-child").css('transform', "translate(5px, #{$(@).scrollTop() + 180}px) rotate(270deg)")

  $('.highcharts-xaxis').find(">:first-child").css('transform', "translate(5px, 180px) rotate(270deg)")
