import React from "react";
import PropTypes from "prop-types";

import { AlertSection } from "../../views/engagement";
import { LoadingStatus } from "../../views/shared";

import hasLoader from "../hoc/has-loader";

import { fetchStatusType } from "../../types";

class AlertSectionContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;
    const { totalAlertsCount = 0 } = this.props.componentData;
    let stateMessage = null;

    if (status === "error") {
      stateMessage = "Error";
    } else if (totalAlertsCount <= 0) {
      stateMessage = "No Alerts";
    } else if (status === "loading") {
      stateMessage = <LoadingStatus />;
    }

    return <AlertSection {...componentData} contentMessage={stateMessage} />;
  }
}

AlertSectionContainer.propTypes = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

export default hasLoader(AlertSectionContainer);
