interface FetchOptions extends RequestInit {
  throwOnError?: boolean;
}

const fetchData = async (url: string, options: FetchOptions | null = null) => {
  try {
    const response = options ? await fetch(url, options) : await fetch(url);
    const responseData = await safeResponseJson(response);

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          status: response.status,
          message: "There has been an error while fetching data. Please try again",
          url,
          response: responseData,
        })
      );
    }
    
    return responseData;
  } catch (e) {
    console.error("cannot fetch data", e);

    if (options?.throwOnError) {
      throw e;
    } else {
      return null;
    }
  }
};

const safeResponseJson = async (response: Response) => {
  try {
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error parsing JSON from response", error);
    return {};
  }
};

export { fetchData, safeResponseJson };
