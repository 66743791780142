import React from "react";
import { SimpleProgressBar } from "../../../shared";

import styles from "./styles.module.scss";

const CustomProgressBar = ({ value, progressValue = 0, color }) => {
  return (
    <div className={styles.progressbar}>
      <div className={styles.value}>{value}%</div>
      <SimpleProgressBar value={progressValue} color={color} />
    </div>
  );
};

export default CustomProgressBar;
