import classNames from "classnames/bind";
import I18n from "i18n-js";
import React from "react";
import QuestionIcon from "images/question-icon.svg";
import TooltipHelper from "../../utils/tooltip-helper";
import styles from "./styles.module.scss";
import Topic from "./topic";

const cx = classNames.bind(styles);

const Insight = ({
  capitalizeTopicName = false,
  filters,
  iconFontAwesomeClass,
  insightData,
  isTopic,
  state,
  survey,
  title,
  tooltip,
}) => {
  const dateRangeFilterValue = filters["fltr_daterange"];
  const dateRange = I18n.t(`${dateRangeFilterValue || "last_week"}`);

  return (
    <div className={cx("insight", capitalizeTopicName ? "capitalize-topic-name" : "")}>
      <div className={cx("header-container")}>
        <div className={cx("title-container")}>
          <i className={iconFontAwesomeClass} />
          <div className={cx("title")}>{title}</div>
          <TooltipHelper tooltipMessage={tooltip}>
            <img src={QuestionIcon} className={cx("question-icon")} />
          </TooltipHelper>
        </div>
      </div>
      <div className={cx("footer-container")}>
        {Array.from({ ...insightData, length: 3 }).map((topic, index) => (
          <Topic
            key={index}
            count={topic && topic[state].mentions}
            direction={
              !topic || topic[state].diff === 0
                ? ""
                : !topic[state].diff || topic[state].diff > 0
                ? "increased"
                : "decreased"
            }
            filters={filters}
            isTopic={isTopic}
            loading={!insightData}
            name={topic && topic.name}
            position={I18n.t(`dashboard_lite.topic_position_${index + 1}`)}
            state={state}
            survey={survey}
            value={
              topic &&
              I18n.t(
                topic[state].diff === 0
                  ? "dashboard_lite.topic_no_change"
                  : !topic[state].diff
                  ? "dashboard_lite.topic_no_diff"
                  : topic[state].diff > 0
                  ? "dashboard_lite.topic_increased"
                  : "dashboard_lite.topic_decreased",
                {
                  count: Math.abs(
                    topic[state].diff === 0 ? 0 : topic[state].diff || topic[state].mentions
                  ),
                  date_range: dateRange,
                }
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Insight;
