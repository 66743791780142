import classnames from "classnames";
import I18n from "i18n-js";
import React, { MouseEvent, useState } from "react";
import { QueryFunctionContext, useMutation, useQuery } from "react-query";
import { Themes } from "stingray-components";
import { useRoute } from "wouter";

import withReactQuery from "../../src/hocs/withReactQuery";
import LoadingPage from "../shared/LoadingPage";
import { SelectOption } from "../shared/Select";
import { screenSize } from "../utils/constants";
import { hasValues } from "../utils/form-utils";
import ScreenBreakpoints from "../utils/screen_breakpoints/ScreenBreakpoints";
import UserManagementForm from "./UserManagementForm";
import { Company, User, UserManagementFormFields } from "./userManagementTypes";
import { Config, ConfigProvider, MutationFetcherProps } from "../../src/hooks/useConfig";

const getAccessibleFeaturesByRoleType = async () => {
  const response = await fetch("/api/accessible_features_by_role_type");

  if (!response.ok) throw new Error("Could not fetch accessible features by role type.");

  return await response.json()
};

const getLanguages = async (): Promise<SelectOption[]> => {
  const response = await fetch("/language");

  if (!response.ok) throw new Error("Could not fetch languages.");

  return (await response.json()).map((value: string) => ({ value, label: I18n.t(`user_management.languages.${value}`) }));
};

const getEmailDigestFrequencies = async (): Promise<SelectOption[]> => {
  const response = await fetch("/email_digest_period");

  if (!response.ok) throw new Error("Could not fetch email digest frequencies.");

  return (await response.json()).map((value: string) => ({ value, label: I18n.t(`user_management.email_digest_frequencies.${value}`) }));
};

const getCompany = async (): Promise<Company> => {
  const response = await fetch("/api/companies/current_company");

  if (!response.ok) throw new Error("Could not fetch company.");

  return response.json();
};

type CreateUserMutationProps = MutationFetcherProps & {
  data: UserManagementFormFields;
}

const createUser = async ({ csrfToken, data }: CreateUserMutationProps) => {
  const user = {
    userId: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    phoneNumber: data.phoneNumber?.length === 0 ? null : data.phoneNumber,
    email: data.email,
    language: data.language.value,
    roleTypes: data.roleTypes.map((roleTypeOption) => roleTypeOption.value),
    emailDigestFrequency: data.emailDigestFrequency.value,
    messageCenterEnabled: data.messageCenterEnabled || false,
    liveConversationsEnabled: data.liveConversationsEnabled || false,
    liveConversationsConfiguration: hasValues(data.liveConversationsConfiguration) ? { contactMethod: data?.liveConversationsConfiguration?.contactMethod?.value } : null,
    surveyConfigs: data.surveyConfigs.map((config) => ({
      ...config,
      readAccess: config.readAccess || false,
      hasReadAccessByDefault: config.hasReadAccessByDefault || false,
      surveyAlertConfiguration: hasValues(config.surveyAlertConfiguration) ? {
        ...config.surveyAlertConfiguration,
        contactMethod: config.surveyAlertConfiguration.contactMethod ? config.surveyAlertConfiguration.contactMethod.value : undefined
      } : null
    })),
    hidePII: data.hidePII,
    grantAccessToAllPoc: data.grantAccessToAllPoc,
    pocRestrictions: data.pocRestrictions,
    liveConversationsPocRestrictions: data.liveConversationsPocRestrictions,
    syncToJourneys: data.syncToJourneys,
    apiToken: data.apiToken,
  }

  const response = await fetch("/api/user_management/", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      "X-CSRF-Token": csrfToken
    },
    body: JSON.stringify(user)
  })

  const responseData = await response.json()

  if (!response.ok) throw new Error(responseData.error ? I18n.t(`user_management.errors.${responseData.error}`) : "Could not create the user.")

  return responseData
}



const getTargetUser = async ({ queryKey }: QueryFunctionContext): Promise<User> => {
  const id = queryKey[1]
  const response = await fetch(`/api/user_management/${id ?? ""}`)

  if (!response.ok) throw new Error("Could not fetch the target user.")

  return await response.json()
}

const getCurrentUser = async (): Promise<User | null> => {
  const response = await fetch(`/api/companies_users/current_company_user`)

  if (!response.ok) throw new Error("Could not fetch the current user.")

  return await response.json()
}

const getCurrentUserAccessibleFeatures = async ({ queryKey }: QueryFunctionContext): Promise<string[]> => {
  const currentUserId = queryKey[1];

  const response = await fetch(`/api/companies_users/${currentUserId}/accessible_features`)

  if (!response.ok) throw new Error("Could not fetch the current user accessible features.")

  return await response.json()
}


const UsersManagement = (config: Config) => {
  const [_, params] = useRoute("/user_management/:id?");

  const { data: targetUser } = useQuery({ queryFn: getTargetUser, queryKey: ["targetUser", params?.id] });

  // If the currentUser is null, we assume that the user is an admin and not a companies user
  const { data: currentUser } = useQuery("currentUser", getCurrentUser);
  const { data: currentUserAccessibleFeatures } = useQuery({ queryKey: ["currentUserAccessibleFeatures", currentUser?.id], queryFn: getCurrentUserAccessibleFeatures, enabled: !!currentUser });

  const { data: accessibleFeaturesByRoleType } = useQuery("accessibleFeaturesByRoleType", getAccessibleFeaturesByRoleType);
  const { data: languages } = useQuery("languages", getLanguages);
  const { data: company } = useQuery("company", getCompany);
  const { data: emailDigestFrequencies } = useQuery("emailDigestFrequencies", getEmailDigestFrequencies);

  const [userScreenSize, setUserScreenSize] = useState(screenSize.SMALL);

  const returnToUsersPage = (e: MouseEvent) => location.assign(document.referrer);

  const { mutate, isLoading, isSuccess, error: createUserError } = useMutation({ mutationFn: createUser, onError: console.error, onSuccess: returnToUsersPage });

  const isDataReady = currentUser !== undefined && targetUser && languages && accessibleFeaturesByRoleType && emailDigestFrequencies && company;

  if (!isDataReady) return <LoadingPage />;

  return (
    <ConfigProvider config={config}>
      <ScreenBreakpoints userScreenSize={userScreenSize} setUserScreenSizeCallback={setUserScreenSize} />
      <div className={classnames(Themes.CHATTER.theme)}>
        <UserManagementForm
          currentUser={currentUser}
          currentUserAccessibleFeatures={currentUser === null ? accessibleFeaturesByRoleType["account_owner"] : currentUserAccessibleFeatures}
          targetUser={targetUser}
          languages={languages}
          accessibleFeaturesByRoleType={accessibleFeaturesByRoleType}
          emailDigestFrequencies={emailDigestFrequencies}
          company={company}
          submitError={createUserError?.message}
          onSubmit={(data) => mutate({csrfToken: config.api.csrfToken, data})}
          isSubmitting={isLoading || isSuccess}
          onCancel={returnToUsersPage}
        />
      </div>
    </ConfigProvider>
  );
};

export default withReactQuery(UsersManagement);
