import "regenerator-runtime/runtime";

// Highcharts. Do not change the order of these files
let Highcharts = require("highcharts");
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/map")(Highcharts);
require("highcharts/modules/solid-gauge")(Highcharts);

// React UJS setup
let componentRequireContext = require.context("components", true);
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.useContext(componentRequireContext);

import {} from "jquery-ujs";

import "webpack-jquery-ui/draggable";
import "webpack-jquery-ui/sortable";
import "webpack-jquery-ui/datepicker";

import moment from "moment";
import "moment/locale/es";
import "moment/locale/fr";
window.moment = moment;

// Bootstrap all JS
import "bootstrap-sass/assets/javascripts/bootstrap";

// Chart requires that moment.js be loaded before it.
import "chart.js";
import "jsplumb";

import "src/i18n.js.erb";

import autosize from "autosize";
window.autosize = autosize;

import JSZip from "jszip";
window.JSZip = JSZip;

import "datatables.net";
import "datatables.net-bs";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons-bs";
import "datatables.net-responsive-bs";

import "src/utils/translation_helper.coffee";
import "src/utils/datatable-languages.coffee";

import jQueryBridget from "jquery-bridget";

// Vendored JS
import Isotope from "src/vendor/isotope.pkgd.js";
jQueryBridget("isotope", Isotope, $);

import Flickity from "src/vendor/flickity.pkgd.js";
jQueryBridget("flickity", Flickity, $);

import "src/vendor/jquery.color-2.1.2.js";
import "src/vendor/jquery.easy-ticker.js";
import "src/vendor/jquery.ui.touch-punch.js";
import "src/vendor/jquery.visible.min.js";
import "src/vendor/slick.js";

import "src/admin/import-survey.coffee";
import "src/admin/google_review_outscraper.coffee";
import "src/admin/json_dashboards.coffee";
import "src/admin/ml_processors.coffee";
import "src/admin/topics.coffee";
import "src/admin/admin_panel.coffee";
import "src/admin/companies.coffee";
import "src/admin/profiles.coffee";
import "src/admin/point_of_contacts.coffee";
import "src/admin/survey_categories.coffee";
import "src/admin/topic_functions.coffee";
import "src/application.coffee";
import "src/company-filter.coffee";
import "src/conversation.coffee";
import "src/conversation.js";
import "src/data_exports.coffee";
import "src/deliveries.coffee";
import "src/deployment-manager.coffee";
import "src/jsoneditor.coffee";
import "src/entities.coffee";
import "src/filters.coffee";
import "src/forms.coffee";
import "src/lists.coffee";
import "src/message_center_campaigns.js";
import "src/navbar-2020.coffee";
import "src/nps-reports.coffee";
import "src/point_of_contact_groups.coffee";
import "src/solicitation_configurations.coffee";
import "src/solicitations_report.js";
import "src/survey-alerts.coffee";
import "src/survey-analyze.coffee";
import "src/survey-benchmarks.coffee";
import "src/survey-blacklist.coffee";
import "src/survey-builder.coffee";
import "src/survey-dump.coffee";
import "src/survey-graphs.coffee";
import "src/survey-graphs-fullwidth.coffee";
import "src/survey-insights.coffee";
import "src/survey-logs.coffee";
import "src/survey-rankings.coffee";
import "src/survey-reports.coffee";
import "src/survey-search.coffee";
import "src/survey-timelines.coffee";
import "src/survey-topics-report.coffee";
import "src/survey-tree.coffee";
import "src/surveys.coffee";
import "src/tooltips.coffee";
import "src/users.coffee";
import "src/words_and_phrases.coffee";
import "src/actions.coffee";

// google fonts
import "stylesheets/google_fonts.css";

// Sentry
import * as Sentry from "@sentry/browser";
import "@sentry/tracing";
window.Sentry = Sentry;

// import fontawesome
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

// import bootstrap tables
import "bootstrap-table/dist/bootstrap-table.js";
import "bootstrap-table/dist/bootstrap-table.css";

// import flatpickr
import "flatpickr";
import "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/l10n/es.js";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/plugins/monthSelect/style.css";

// These scripts must be loaded after third party libraries
import "src/web_tag_configuration.coffee";

// import images
require.context("../images", true);
