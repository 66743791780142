import uuid from "react-uuid";

const screenSize = {
  SMALL: 0,
  MEDIUM: 1,
  LARGE: 2,
};

// this is to have a unique id that does not change for the life of a page
const uniqueId = uuid();

export { screenSize, uniqueId };
