import I18n from "i18n-js";
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js";
import React, { MouseEvent, useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Button, Card, Loading, Typography, styled } from "stingray-components";

import SelectInput, { SelectOption } from "../shared/Select";
import TextInput from "../shared/TextInput";
import APIKeyCard from "./APIKeyCard";
import DataAccessCard from "./DataAccessCard";
import FeaturesCard from "./FeaturesCard";
import ResetPasswordCard from "./ResetPasswordCard";
import SurveyConfigTable from "./SurveyConfigTable";
import { Company, User, UserManagementFormFields, contactMethodOptions } from "./userManagementTypes";

import styles from "./UserManagementForm.module.scss";
import Checkbox from "../shared/Checkbox";

const ErrorTypography = styled(Typography, {
    color: "$red400",
});

type UserManagementFormProps = {
    currentUser: User | null;
    currentUserAccessibleFeatures: string[];
    targetUser: User;
    languages: SelectOption[];
    emailDigestFrequencies: SelectOption[];
    accessibleFeaturesByRoleType: { [roleType: string]: string[] };
    company: Company;
    submitError?: string;
    onSubmit: (data: UserManagementFormFields) => void;
    isSubmitting: boolean;
    onCancel: (e: MouseEvent) => void;
}

const validatePhoneNumberAvailableCountries = (phoneNumber: string) => {
    const countries = ["US", "CA", "FR"] as CountryCode[]

    for (const country of countries) {
        if (isValidPhoneNumber(phoneNumber, country)) {
            return true;
        }
    }

    return false
}

const UserManagementForm = ({
    isSubmitting,
    submitError,
    currentUser,
    currentUserAccessibleFeatures,
    targetUser,
    accessibleFeaturesByRoleType,
    emailDigestFrequencies,
    languages,
    company,
    onCancel,
    onSubmit,
}: UserManagementFormProps) => {
    const roleTypes = useMemo(() => {
        if (!accessibleFeaturesByRoleType) return [];

        return Object.keys(accessibleFeaturesByRoleType).map((roleType) => ({ value: roleType, label: I18n.t(`user_management.role_types.${roleType}`) }));
    }, [accessibleFeaturesByRoleType]);


    const methods = useForm<UserManagementFormFields>({
        defaultValues: {
            id: targetUser.id,
            firstName: targetUser.firstName,
            lastName: targetUser.lastName,
            phoneNumber: targetUser.phoneNumber,
            email: targetUser.email,
            language: languages.find((language) => language.value === targetUser.language),
            roleTypes: roleTypes.filter((roleType) => targetUser.roleTypes.includes(roleType.value)),
            emailDigestFrequency: emailDigestFrequencies.find((frequency) => frequency.value === targetUser.emailDigestFrequency),
            messageCenterEnabled: targetUser.messageCenterEnabled,
            liveConversationsEnabled: targetUser.liveConversationsEnabled,
            liveConversationsConfiguration: targetUser.liveConversationsConfiguration ? {
                contactMethod: contactMethodOptions.find((option) => option.value === targetUser.liveConversationsConfiguration.contactMethod as unknown)
            } : { contactMethod: contactMethodOptions.find((option) => option.value === "none") },
            surveyConfigs: targetUser.surveyConfigs.map((config) => ({
                ...config,
                surveyAlertConfiguration: config.surveyAlertConfiguration ? {
                    ...config.surveyAlertConfiguration,
                    contactMethod: contactMethodOptions.find((option) => option.value === config.surveyAlertConfiguration.contactMethod as unknown),
                    pocRestrictions: config.surveyAlertConfiguration.pocRestrictions
                } : { pocRestrictions: [], contactMethod: contactMethodOptions.find((option) => option.value === "none") }
            })),
            hidePII: targetUser.hidePII,
            grantAccessToAllPoc: targetUser.grantAccessToAllPoc,
            pocRestrictions: targetUser.pocRestrictions,
            liveConversationsPocRestrictions: targetUser.liveConversationsPocRestrictions,
            syncToJourneys: targetUser.syncToJourneys,
            apiToken: targetUser.apiToken,
            currentUserAccessibleFeatures: currentUserAccessibleFeatures,
        },
    });

    const { register, handleSubmit, formState: { errors }, control, setValue } = methods;

    const { fields: surveyConfigFields } = useFieldArray({
        control,
        name: "surveyConfigs",
    });

    const currentRoleTypes = useWatch({ control, name: "roleTypes" });

    useEffect(() => {
        if (!accessibleFeaturesByRoleType) {
            setValue("targetUserAccessibleFeatures", []);
            return;
        }

        let features = [];

        for (let roleType of currentRoleTypes.map((currentRoleType) => currentRoleType.value)) {
            features.push(...accessibleFeaturesByRoleType[roleType]);
        }

        setValue("targetUserAccessibleFeatures", Array.from(new Set(features)));
    }, [accessibleFeaturesByRoleType, currentRoleTypes]);

    const isAdmin = currentUser === null;

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formContainer}>
                    <div className={styles.sectionContainer}>
                        <Typography variant="h3" css={{ fontWeight: 500 }}>{I18n.t('user_management.general_settings')}</Typography>
                        <Card className={styles.verticalCard}>
                            <div>
                                <TextInput {...register("firstName", { required: I18n.t("user_management.required") })}>{I18n.t('first_name')}</TextInput>
                                {errors.firstName && <ErrorTypography variant="h9">{errors.firstName.message}</ErrorTypography>}
                            </div>
                            <div>
                                <TextInput {...register("lastName", { required: I18n.t('user_management.required') })}>{I18n.t('last_name')}</TextInput>
                                {errors.lastName && <ErrorTypography variant="h9">{errors.lastName.message}</ErrorTypography>}
                            </div>
                            <div>
                                <TextInput {...register("phoneNumber", { validate: (phoneNumber) => phoneNumber ? (validatePhoneNumberAvailableCountries(phoneNumber) || "The phone number is not valid.") : true })}>{I18n.t('phone_number')}</TextInput>
                                {errors.phoneNumber && <ErrorTypography variant="h9">{errors.phoneNumber.message}</ErrorTypography>}
                            </div>
                            <div>
                                <TextInput {...register("email", {
                                    required: I18n.t("user_management.required"),
                                    validate: (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) || I18n.t("user_management.invalid_email")
                                })}>{I18n.t('email_address')}</TextInput>
                                {errors.email && <ErrorTypography variant="h9">{errors.email.message}</ErrorTypography>}
                            </div>
                            {languages && <SelectInput options={languages} name="language" >{I18n.t('language')}</SelectInput>}
                        </Card>
                        {!!targetUser.id && <ResetPasswordCard targetUser={targetUser} />}
                    </div>

                    {currentUserAccessibleFeatures?.includes("user_management") && (
                        <div className={styles.sectionContainer}>
                            <Typography variant="h3" css={{ fontWeight: 500 }}> {I18n.t("user_management.advanced_settings")}</Typography>
                            <Card className={styles.verticalCard}>
                                {roleTypes && <SelectInput required isMulti options={roleTypes} name="roleTypes">{I18n.t("user_management.roles")}</SelectInput>}
                                {errors.roleTypes && <ErrorTypography variant="h9">{I18n.t("user_management.required")}</ErrorTypography>}
                                {emailDigestFrequencies && <SelectInput options={emailDigestFrequencies} name="emailDigestFrequency">{I18n.t("email_digest_frequency")}</SelectInput>}
                            </Card>
                            <DataAccessCard companyId={company.id} />
                            <FeaturesCard companyId={company.id} />
                            { isAdmin && (
                                <Card className={styles.cardContainer}>
                                    <Typography variant="h6">{I18n.t("user_management.sync_to_journeys")}</Typography>
                                    <Checkbox {...register("syncToJourneys")}>{I18n.t("user_management.sync_to_journeys_description")}</Checkbox>
                                </Card>
                            )}
                            {!!targetUser.id && <APIKeyCard targetUser={targetUser} />}
                        </div>
                    )}
                </div>

                {(currentUserAccessibleFeatures?.includes("user_management")) && (
                    <div className={styles.sectionContainer}>
                        <Typography variant="h3"> {I18n.t("user_management.survey_configuration")}</Typography>
                        <Card className={styles.surveyConfigCard}>
                            <SurveyConfigTable configs={surveyConfigFields} companyId={company.id} />
                        </Card>
                    </div>
                )}

                <div className={styles.submitErrorContainer}>
                    <ErrorTypography>{submitError}</ErrorTypography>
                </div>

                <div className={styles.submitSectionContainer}>
                    <Button size='large' width="125px" onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        onCancel(e);
                    }}>{I18n.t('cancel')}</Button>
                    <Button disabled={isSubmitting} size='large' width="125px" onClick={handleSubmit(onSubmit)}>{isSubmitting ? <Loading size="25px" /> : I18n.t('save')}</Button>
                </div>
            </form>
        </FormProvider>
    );
}

export default UserManagementForm;
