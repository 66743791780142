jQuery ->

  return unless $(".survey-builder").length > 0

  # *** IMPORTS

  require("chosen-js")
  require('chosen-js/chosen.css')

  { loadJSONComponentAndSchema, initJSONEditor } = require('./jsoneditor.coffee')

  # variables used by multiple functions
  $nextQuestionPicker = null
  $nextQuestionPickerTriggeredBy = null
  $newBranchTriggeredBy = null

  # IMAGE PICKER OPENED
  $imageIdField = null
  $imageUrlField = null

  # *** FUNCTIONS

  handleDefaultLanguageChange = ($component) ->
    defaultLanguageValue = $('.survey-builder #survey_default_language').val()
    try currentJSON = $component[0].jsoneditor.get()
    catch e
    
    if currentJSON
      $component.children().removeClass("input-error")
      $component.siblings('.json-editor-error').remove()
      if defaultLanguageValue == "" && currentJSON["default"] == undefined
        $component.children().addClass("input-error")
        $component.after("<p class='json-editor-error'><i class='fas fa-exclamation-circle'></i> Error: No default language selected, please write a default value or select a default language!</p>")
      else if defaultLanguageValue != "" && currentJSON[defaultLanguageValue] == undefined
        $component.children().addClass("input-error")
        $component.after("<p class='json-editor-error'><i class='fas fa-exclamation-circle'></i> Error: Language '#{defaultLanguageValue}' must be present!</p>")

  hideDefaultLanguageIfRequired = ($element, fieldClassName) ->
    defaultLanguageValue = $('.survey-builder #survey_default_language').val()
    if defaultLanguageValue != "" && $(fieldClassName).val()
      foundJSON = JSON.parse($(fieldClassName).val())
      delete foundJSON["default"]
      $(fieldClassName).val(JSON.stringify(foundJSON))
      if $element && $element.length && $element[0].jsoneditor
        $element[0].jsoneditor.set(foundJSON)

  addDefaultLanguageIfRequired = ($element, fieldClassName) ->
    defaultLanguageValue = $('.survey-builder #survey_default_language').val()
    if $(fieldClassName).val()
      foundJSON = JSON.parse($(fieldClassName).val())
      if defaultLanguageValue != ""
        foundJSON["default"] = foundJSON[defaultLanguageValue] || ""
        $(fieldClassName).val(JSON.stringify(foundJSON))
      else
        foundJSON["default"] = foundJSON["default"] || ""
        $(fieldClassName).val(JSON.stringify(foundJSON))
        if $element && $element.length && $element[0].jsoneditor
          $element[0].jsoneditor.set(foundJSON)

  makePseudoID = (length) ->
    result = []
    characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    charactersLength = characters.length
    i = 0
    while i < length
      result.push characters.charAt(Math.floor(Math.random() * charactersLength))
      i++
    result.join ''

  handleSupportedLanguagesEdit = ($element, hiddenField) ->
    $supportedLanguages.on "change", () ->
      if $element.length
        currentJSON = $element[0].jsoneditor.get()
        Object.keys(currentJSON).forEach((key) ->
          if currentJSON[key] == ''
            delete currentJSON[key]
        )
        newJSONValue = `{ ...defaultJSONValue, ...currentJSON }`
        $element[0].jsoneditor.set(newJSONValue)
        if $(hiddenField).length
          $(hiddenField).val(JSON.stringify(newJSONValue))

  refreshCarouselHeight = ($element) ->
    $carousel.flickity 'resize'
    # outerHeight = $element.closest('.carousel-cell').outerHeight()
    # outerHeight = outerHeight.toString() if outerHeight && outerHeight != undefined
    # $('.flickity-viewport').css 'height', outerHeight

  alertToggle = ($checkbox) ->
    $toggles = $checkbox.closest('.js-send-alert-fields').children '.js-send-alert-toggles'
    if $checkbox.is ':checked'
      $toggles.removeClass 'hide'
    else
      $toggles.addClass 'hide'
      $toggles.find 'input'
      .val ''
    return

  updateQuestionPositions = ->
    position = 0
    $branches = $('.js-branches .js-branch')
    changes = []
    $branches.each ->
      branchNumber = $(@).data 'branch'
      $button = $(".js-branch-nav[data-branch=#{branchNumber}]")
      .removeData 'min'
      .removeData 'max'
      .removeAttr 'data-min'
      .removeAttr 'data-max'
      $branchQuestions = $(@).find('.js-question:visible')
      totalBranchQuestions = $branchQuestions.length
      buttonText = ''
      $branchQuestions.each (index) ->
        position += 1
        $button.data 'max', position
        $button.attr 'data-max', position
        if index == 0
          $button.data 'min', position
          $button.attr 'data-min', position

        # update question position data
        $positionField = $(@).find '.js-question-position-hidden-field'
        originalPosition = parseInt $positionField.val()
        if originalPosition != position
          changes.push
            from: originalPosition
            to: position

          # console.log "#{originalPosition} is now #{position}"
          $positionField.val position
          $(@).find '.js-question-position'
          .html position
          # note: have to set data-number as attr not jquery data 'number' for some weird jquery bug
          $(@).attr 'data-number', position

        # update branch labels
        $button = $(".js-branch-nav[data-branch=#{branchNumber}]")
        buttonText = "Q#{$button.data 'min'}"
        if $button.data('min') != $button.data('max')
          buttonText += "-#{$button.data 'max'}"
        $button.html buttonText
        $(@).find('.js-question-branch-hidden-field').val branchNumber

      return

    # now find any questions or answers pointing to this
    # build up a full map of all DOM changes to be made (must be cached or changing any number will propagate throughout the rest of the dom)
    domChanges = []

    for change in changes
      from = change['from']
      to = change['to']
      domChanges.push
        from: change['from']
        to: change['to']
        questions: $(".js-question-next-question-hidden-field[value=#{from}]")
        answers: $(".js-answer-next-question-hidden-field[value=#{from}]")

    for domChange in domChanges
      domChange['questions'].each ->
        $(@).val domChange['to']
        $(@).closest '.js-question'
        .find '.js-question-next-question'
        .removeClass 'survey-icon'
        .removeClass 'survey-icon-next-question'
        .addClass 'next-question'
        .html domChange['to']
        return

      domChange['answers'].each ->
        $(@).val domChange['to']
        $(@).closest '.js-answer'
        .find '.js-answer-next-question'
        .removeClass 'survey-icon'
        .removeClass 'survey-icon-next-answer'
        .addClass 'next-question'
        .html domChange['to']
        return

    # auto hide/reveal branch navigation based on number of branches
    $branchesNav = $('.js-branches-nav')
    if $branches.length == 1
      $branchesNav.hide()
    else
      $branchesNav.show()

    autosize( $('.js-textarea-autosize') )
    autosize.update( $('.js-textarea-autosize') )

    return

  # *** EVENTS

  $('#js-flow-tab').on "shown.bs.tab", (e) ->
    autosize.update( $('.js-textarea-autosize') )
    refreshCarouselHeight $('.js-question:first')

  $('.survey-builder').on 'click', '.js-skip-survey-name', ->
    $('.js-survey-name').val 'Untitled'
    return

  $('.survey-builder #survey_supported_languages').on "change", () ->
    defaultLanguageValue = $('.survey-builder #survey_default_language').val()
    if $(@).val().length > 0
      supportedLanguages = $(@).val().split(",")
    else
      supportedLanguages = []
    defaultJSONValue = if defaultLanguageValue == "" then { default: "" } else {}
    supportedLanguages.forEach((language) ->
      defaultJSONValue[language.trim()] = "" if language.trim().length == 2
    )
    if defaultJSONValue[$defaultLanguage.val()] == undefined
      $defaultLanguage.val("")

    currentDefaultLanguage = $defaultLanguage.val()
    $defaultLanguage.empty()
    $defaultLanguage.append("<option value=''></option>")

    supportedLanguages.forEach (language) ->
      $defaultLanguage.append("<option value='#{language}'>#{language}</option>")

    $defaultLanguage.val(currentDefaultLanguage)

    if currentDefaultLanguage == ""
      defaultJSONValue["default"] = ""

  $('.survey-builder').on 'click', '.js-branch-nav', ->
    switch $(@).data 'action'
      when 'previous'
        $carousel.flickity 'previous'
      when 'slide'
        branch = $(@).data 'branch'
        $carousel.flickity 'selectCell', branch - 1, false, false
      when 'next'
        $carousel.flickity 'next'
    $carousel.flickity 'resize'
    @

  $('.survey-builder').on 'change', '.js-send-alert', ->
    alertToggle $(@)

  $('.survey-builder').on 'keyup', ->
    if $(this).find('.input-error').length > 0
      $('#bottom-section').prop('disabled', true)
    else
      $('#bottom-section').prop('disabled', false)
      
  $('.survey-builder').on 'click', '.js-question-goto', ->
    nextQuestionNumber = $(@).data('next-question-id')
    return if !nextQuestionNumber

    nextQuestion = $(".js-question[data-number=#{nextQuestionNumber}]")
    return if !nextQuestion.length

    nextQuestionBranch = nextQuestion.closest '.js-branch'
    branchNumber = nextQuestionBranch.data 'branch'

    $carousel.flickity 'select', branchNumber - 1, false, false
    $carousel.flickity 'resize'
    $(".wrapper").scrollTop($(".wrapper").scrollTop() + nextQuestion.offset().top)
    false

  $('.survey-builder').find('.js-ask-again-if-unclear-content-field').on 'keyup', -> refreshCarouselHeight $(@)

  $('.survey-builder').on 'change', '.js-ask-again-if-unclear-toggle', ->
    $(@).closest('.js-question').find('.js-ask-again-content').toggleClass 'hidden'
    $(@).closest('.js-question').find('.js-ask-again-if-unclear-content-field').on 'keyup', ->
      refreshCarouselHeight $(@)
    refreshCarouselHeight $(@)
    @

  $('.survey-builder').find('.js-question-content-field').on "keyup", ->
    refreshCarouselHeight $(@)

  $('.survey-builder').on 'click', '.js-question-add', ->
    $blueprint = $('.js-question-new-blueprint[data-answer-type=pending]')

    $surveyQuestion = $(@).closest '.js-question'
    $surveyQuestion.after $blueprint.html().replace(/\[questions_attributes\]\[[^\]]+\]/g, "[questions_attributes][#{Date.now()}]")

    $newQuestion = $($surveyQuestion[0].nextSibling)
    $newQuestion.find('.js-question-content-field').on "keyup", ->
      refreshCarouselHeight $(@)
    $shortDescription = $newQuestion.find('.json-editor')
    i = $surveyQuestion.data("number")
    $shortDescription.on 'mousedown', (e) ->
      e.stopPropagation()
    
    pseudoID = makePseudoID(6)
    $newQuestion.find(".js-question-short-description-field").attr('pseudoid', pseudoID)
    shortDescFieldClassname = ".js-question-short-description-field[pseudoid='#{pseudoID}']"
    $(shortDescFieldClassname).val(JSON.stringify(defaultJSONValue))

    pseudoLCWebChatConfigID = makePseudoID(6)
    $newQuestion.find(".js-question-live-conversation-web-chat-field").attr('pseudoid', pseudoLCWebChatConfigID)
    liveConversationWebChatConfigFieldClassname = ".js-question-live-conversation-web-chat-field[pseudoid='#{pseudoLCWebChatConfigID}']"
    $(liveConversationWebChatConfigFieldClassname).val(JSON.stringify("{}"))

    $shortDescription.on 'json-editor-load', ->
      hideDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)
      loadJSONComponentAndSchema($shortDescription, shortDescFieldClassname, "smart_strings")
      $shortDescription[0].jsoneditor.set(defaultJSONValue) if $.isEmptyObject($shortDescription[0].jsoneditor.get())

    $shortDescription.on "json-editor-change", ->
      handleDefaultLanguageChange($shortDescription)

    $('form.simple_form.survey-builder').on 'submit', ->
      addDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)

    initJSONEditor($shortDescription[0])

    handleSupportedLanguagesEdit($shortDescription, shortDescFieldClassname)

    $('.survey-builder #survey_default_language').on "change", ->
      if $(@).val() == ""
        addDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)
      else
        hideDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)

    updateQuestionPositions()

    autosize( $('.js-textarea-autosize') )
    autosize.update( $('.js-textarea-autosize') )
    refreshCarouselHeight $(@)

    $("html, body").animate
      scrollTop: $surveyQuestion.position().top + $surveyQuestion.outerHeight()
    , 400

    return

  $('.survey-builder').on 'change', '.js-question-answer-type', ->
    type = $(@).val()

    # Just change the answer type if swapping between NPS and Scale.
    initialType = $(@).data("initial-answer-type")
    excludedTypes = ["NPS", "Scale"]
    if $.inArray(initialType, excludedTypes) >= 0 && $.inArray(type, excludedTypes) >= 0
      return

    $question = $(@).closest '.js-question'

    # mark the current question for deletion
    $question.find '.js-question-delete-hidden-field'
    .val 1

    # remember the question content and answer name for later
    questionPersistedID = null
    questionPersistedIDInput = $question.find '.js-question-persisted-question-id'
    questionPersistedID = questionPersistedIDInput.val() if questionPersistedIDInput.length

    questionContent = $question.find '.js-question-content-field'
    .val()

    answerName = $question.find '.js-question-answer-name-field'
    .val()

    shortDesc = $question.find '.js-question-short-description-field'
    .val() || "{}"

    liveConversationWebChatConfig = $question.find '.js-question-live-conversation-web-chat-field'
    .val() || "{}"

    dashPosition = $question.find '.js-question-dash-position-field'
    .val() || -1

    dashAskAgainToggle = $question.find '.js-ask-again-if-unclear-toggle:checked'
    .val()

    dashAskAgainContent = $question.find '.js-ask-again-if-unclear-content-field'
    .val()

    blueprint = $(".js-question-new-blueprint[data-answer-type='#{type}']")
    $blueprint = $(blueprint.html().replace(/\[questions_attributes\]\[[^\]]+\]/g, "[questions_attributes][#{Date.now()}]"))

    $blueprint.find '.js-question-persisted-question-id'
    .val questionPersistedID

    $blueprint.find '.js-question-content-field'
    .html questionContent

    $blueprint.find '.js-question-answer-name-field'
    .val answerName

    currentShortDesc = `{...defaultJSONValue,  ...JSON.parse(shortDesc)}`
    $blueprint.find('.js-question-short-description-field').val JSON.stringify(currentShortDesc)

    currentLiveConversationWebChatConfig = JSON.parse(liveConversationWebChatConfig)
    $blueprint.find('.js-question-live-conversation-web-chat-field').val JSON.stringify(currentLiveConversationWebChatConfig)

    $blueprint.find '.js-question-dash-position-field'
    .val dashPosition

    $blueprint.find '.js-ask-again-if-unclear-toggle[value='+dashAskAgainToggle+']'
    .prop( 'checked', true )

    $blueprint.find '.js-ask-again-if-unclear-content-field'
    .html dashAskAgainContent
    if dashAskAgainContent
      $blueprint.find '.js-ask-again-content'
      .removeClass( 'hidden' )
    else
      $blueprint.find '.js-ask-again-content'
      .addClass( 'hidden' )

    $question
    .replaceWith $blueprint

    $blueprint.find('.js-answer-display-value-field').each(() ->
      pseudoID = $(@).attr('pseudoid')
      displayValueFieldClassname = ".js-answer-display-value-field[pseudoid='#{pseudoID}']"
      currentDisplayValue = JSON.parse($(displayValueFieldClassname).val())
      newJSONValue = `{ ...defaultJSONValue, ...currentDisplayValue }`
      $(displayValueFieldClassname).val(JSON.stringify(newJSONValue))

      $('.survey-builder #survey_default_language').on "change", ->
        if $(@).val() == ""
          addDefaultLanguageIfRequired(null, displayValueFieldClassname)
        else
          hideDefaultLanguageIfRequired(null, displayValueFieldClassname)
    )

    $blueprint.find('.js-ask-again-if-unclear-content-field').on 'keyup', -> refreshCarouselHeight($(@)) if dashAskAgainContent

    $blueprint.find('.js-question-content-field').on "keyup", ->
      refreshCarouselHeight $(@)

    i = $question.data("number") - 1
    $shortDescription = $blueprint.find('.json-editor')
    if $shortDescription[0]
      $shortDescription.on 'mousedown', (e) ->
        e.stopPropagation()

      pseudoID = makePseudoID(6)
      $blueprint.find(".js-question-short-description-field").attr('pseudoid', pseudoID)
      shortDescFieldClassname = ".js-question-short-description-field[pseudoid='#{pseudoID}']"

      $shortDescription.on 'json-editor-load', ->
        hideDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)
        loadJSONComponentAndSchema($shortDescription, shortDescFieldClassname, "smart_strings")
        if $.isEmptyObject($shortDescription[0].jsoneditor.get()) || !$question.find('.json-editor')[0] || !$question.find('.json-editor')[0].jsoneditor
          $shortDescription[0].jsoneditor.set(defaultJSONValue)
        else
          $shortDescription[0].jsoneditor.set($question.find('.json-editor')[0].jsoneditor.get())
      initJSONEditor($shortDescription[0])

      $('form.simple_form.survey-builder').on 'submit', ->
        addDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)

      $shortDescription.on "json-editor-change", ->
        handleDefaultLanguageChange($shortDescription)

      handleSupportedLanguagesEdit($shortDescription, shortDescFieldClassname)

      $('.survey-builder #survey_default_language').on "change", ->
        if $(@).val() == ""
          addDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)
        else
          hideDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)

    $liveConversationWebChatConfig = $blueprint.find('.json-editor')
    if $liveConversationWebChatConfig[1]
      $liveConversationWebChatConfig.on 'mousedown', (e) ->
        e.stopPropagation()

      pseudoID = makePseudoID(6)
      $blueprint.find(".js-question-live-conversation-web-chat-field").attr('pseudoid', pseudoID)
      liveConversationWebChatConfigFieldClassname = ".js-question-live-conversation-web-chat-field[pseudoid='#{pseudoID}']"

      $liveConversationWebChatConfig.on 'json-editor-load', ->
        loadJSONComponentAndSchema($liveConversationWebChatConfig, liveConversationWebChatConfigFieldClassname, "live_conversation_web_chat_config")
        if $.isEmptyObject($liveConversationWebChatConfig[0].jsoneditor.get()) || !$question.find('.json-editor')[0] || !$question.find('.json-editor')[0].jsoneditor
          $liveConversationWebChatConfig[0].jsoneditor.set({})
        else
          $liveConversationWebChatConfig[0].jsoneditor.set($question.find('.json-editor')[0].jsoneditor.get())
      initJSONEditor($liveConversationWebChatConfig[0])

    updateQuestionPositions()
    refreshCarouselHeight $blueprint

    return

  $('.survey-builder').on 'click', '.js-new-branch', ->
    $blueprint = $('.js-branch-new-blueprint')

    $carousel.flickity 'append', $($blueprint.html())
    questionNum = $('.js-question:visible').length

    $branches = $('.js-branch')
    $branches.last().data 'branch', $branches.length
    $branches = $('.js-branches .js-branch')

    $carousel.flickity 'selectCell', $branches.length-1, false, false

    $nextBranchButton = $('.js-branches-nav .js-branch-nav:last-of-type')
    $nextBranchButton.before "<button class='js-branch-nav btn btn-default' data-action='slide' data-branch='#{$branches.length}' data-max='#{questionNum}' data-min='#{questionNum}' type='button'>Q#{questionNum}</button>"

    # data-from is "question" or "answer"
    from = $(@).data 'from'
    $from = $(@).closest ".js-#{from}"
    $from.find ".js-#{from}-next-question-hidden-field"
    .val questionNum

    $label = if from == 'question'
      $from.find '.js-question-next-question'
    else
      $from.find '.js-answer-next-question'
    $label.removeClass("hide").html("Q#{questionNum}")

    updateQuestionPositions()

    false

  $('.survey-builder').on 'click', '.js-question-next-picker', ->
    # keep track of what has triggered this for callbacks
    $nextQuestionPickerTriggeredBy = $(@)
    $nextQuestionPicker = $(@).closest '.js-question'
    $nextQuestionPicker.find '.bubble-question'
    .addClass 'question-picker-disabled'

    # AND visually
    $(@).addClass 'js-picking-next-question'
    $(@).parent().addClass 'active'

    # now enable the special hover tyles
    $('.js-questions').addClass 'question-picker'

    # hide dropdown menu
    $(@).closest('.dropdown-menu').hide()

    false

  $('.survey-builder').on 'click', '.question-picker .bubble-question:not(.question-picker-disabled)', ->

    questionNumber = $(@).closest('.js-question').find('.js-question-position-hidden-field').val()

    if $nextQuestionPickerTriggeredBy.hasClass 'js-question-next-picker-from-answer'
      # remove highlight
      $nextQuestionPickerTriggeredBy.parent().removeClass 'active'

      $answer = $nextQuestionPickerTriggeredBy.closest('.js-answer')

      $answer.find('.js-question-goto')
      .removeClass("hide")
      .html "Q#{questionNumber}"

      $hiddenField = $answer.find '.js-answer-next-question-hidden-field'
    else
      $triggeredBy = $nextQuestionPicker.find '.js-picking-next-question'
      # remove highlight
      $triggeredBy.parent().removeClass 'active'

      $question = $triggeredBy.closest('.js-question')

      $question.find '.js-question-next-question'
      .removeClass 'survey-icon'
      .removeClass 'survey-icon-next-question'
      .addClass 'next-question'
      .html questionNumber
      $hiddenField = $question.find '.js-question-next-question-hidden-field'
    $hiddenField.val questionNumber

    $nextQuestionPicker.find '.bubble-question'
    .removeClass 'question-picker-disabled'

    $('.js-questions').removeClass 'question-picker'

    return

  $('.survey-builder').on 'click', '.js-question-delete', ->
    $question = $(@).closest '.js-question'
    questionNumber = $question.find '.js-question-position-hidden-field'
    .val()

    # clicking [x] will hide the question and mark it for deletion upon save
    if confirm("Are you sure you want to delete question #{questionNumber}?")
      $question.hide()
      .find '.js-question-delete-hidden-field'
      .val 1

      # delete any required fields to save validation issues for no longer visible fields
      $question.find 'input[required], select[required], textarea[required]'
      .remove()

      $branch = $(@).closest '.js-branch'

      $visibleQuestions = $branch.find '.js-question:visible'
      if $visibleQuestions.length > 0
        refreshCarouselHeight $(@)
      else
        # removing branch
        branchNumber = $branch.data 'branch'
        console.log 'Removing branch', branchNumber
        # $(".js-branch-nav[data-branch=#{branchNumber}]").remove()
        #$branch.detach().appendTo $('.js-trashed-branches')
        $('.js-trashed-branches').append $branch.clone()
        $carousel.flickity 'remove', $branch
        $carousel.flickity 'previous', true
        # decrement branch data for all branch nav > branchNumber
        $('.js-branch-nav').each ->
          navBranchNumber = $(@).data 'branch'
          if navBranchNumber == branchNumber
            $(@).remove()
          else if navBranchNumber > branchNumber
            $(@).data 'branch', navBranchNumber - 1
            $(@).attr 'data-branch', navBranchNumber - 1
          return
        # decrement branch numbers for all branches and questions > questionNumber
        $('.js-branches .js-branch').each ->
          otherBranchNumber = $(@).data 'branch'
          if otherBranchNumber > branchNumber
            $(@).data 'branch', otherBranchNumber - 1
            $(@).attr 'data-branch', otherBranchNumber - 1
            $(@).find('.js-question-branch-hidden-field').each ->
              $(@).val otherBranchNumber - 1
              return
          return

      updateQuestionPositions()

    return

  $('.survey-builder').on 'click', '.js-answer-add', ->
    $question = $(@).closest '.js-question'
    $blueprint = $question.find '.js-answer-new-blueprint'
    $answer = $(@).closest '.js-answer'
    $answer.after $blueprint.html().replace(/\[answers_attributes\]\[[^\]]+\]/g, "[answers_attributes][#{Date.now()}]")
    $newAnswer = $answer.next('.js-answer')
    $newAnswer.find('.js-answer-pseudo-id').attr( 'pseudoid', makePseudoID(5) )

    $question.find '.js-answer-position-hidden-field'
    .each (answerIndex) ->
      $(@).val answerIndex + 1
      return

    refreshCarouselHeight $answer
    return

  $('.survey-builder').on 'click', '.js-answer-delete', ->
    if confirm('Are you sure you want to delete this answer?')
      $(@).closest('.js-answer')
      .hide()
      .find('.js-answer-delete-hidden-field').val 1
    return

  $('.survey-builder').on 'change', '.js-scale-min, .js-scale-max', ->
    $question = $(@).closest('.js-question')

    min = parseInt( $question.find('.js-scale-min').val() )
    min = null if isNaN( min )
    max = parseInt( $question.find('.js-scale-max').val() )
    max = null if isNaN( max )

    if min? && max? && min <= max
      $answersWrapper = $question.find('.js-answers')
      $blueprint = $question.find('.js-answer-new-blueprint')

      # remove existing answers
      $answersWrapper.find('.js-answer').each ->
        $(@).hide()
        .find('.js-answer-delete-hidden-field')
        .val 1

      # add full range
      position = 1
      for i in [min..max]
        answer = $blueprint.html().replace(/\[answers_attributes\]\[[^\]]+\]/g, "[answers_attributes][#{Date.now()}#{i}]")
        $answer = $(answer)
        pseudoID = makePseudoID(5)
        $answer.find('.js-answer-position-hidden-field').val position
        $answer.find('.js-answer-content-field').val i
        $answer.find('.js-answer-content-indicator').val i
        $answer.find('.js-answer-display-value-field').val i
        $answer.find('.js-answer-display-value-field').attr('pseudoid', pseudoID)
        $answer.find('.js-answer-interpreted-value-field').val i
        $answer.find('.js-answer-pseudo-id').attr( 'pseudoid', pseudoID )
        $answersWrapper.append $answer
        position += 1

    refreshCarouselHeight $question
    return

  $('.survey-builder').on 'click', '.js-answer-content-indicator', ->
    $jsAnswer = $(this).closest('.js-answer')
    $jsAnswer.find('.js-answer-editor-modal').trigger 'click'

  $('.survey-builder').on 'click', '.js-answer-editor-modal', ->
    $editModal = $('.js-modal-answer-editor')
    $jsAnswer = $(this).closest('.js-answer')

    # copy in the values from the answer being looked at
    pseudoID = $jsAnswer.find('.js-answer-pseudo-id').attr('pseudoid')
    $editModal.find('.answer-edit-pseudo-id').val( pseudoID )
    displayValueFieldClassname = ".js-answer-display-value-field[pseudoid='#{pseudoID}']"
    $editModal.find('.json-editor').each (i) ->
      $displayValue = $(this)

      $displayValue.children().removeClass("input-error")
      $displayValue.siblings('.json-editor-error').remove()

      # Prevent race condition between JS files: check if the json editor was already loaded, or wait for load event
      if $displayValue[0] && $displayValue[0].jsoneditor
        hideDefaultLanguageIfRequired($displayValue, displayValueFieldClassname)
        loadJSONComponentAndSchema($displayValue, displayValueFieldClassname, "smart_strings")
        $displayValue[0].jsoneditor.set(defaultJSONValue) if $.isEmptyObject($displayValue[0].jsoneditor.get())
      else
        $displayValue.on 'json-editor-load', ->
          hideDefaultLanguageIfRequired($displayValue, displayValueFieldClassname)
          loadJSONComponentAndSchema($displayValue, displayValueFieldClassname, "smart_strings")
          $displayValue[0].jsoneditor.set(defaultJSONValue) if $.isEmptyObject($displayValue[0].jsoneditor.get())

      $displayValue.on "json-editor-change", ->
        handleDefaultLanguageChange($displayValue)

    $editModal.find('.answer-edit-interpreted-value').val( $jsAnswer.find('.js-answer-interpreted-value-field').val() )
    $editModal.find('.answer-edit-content').val( $jsAnswer.find('.js-answer-content-field').val() )

  $('.js-modal-answer-editor').on 'keyup', ->
    if $(this).find('.input-error').length > 0
      $('.js-modal-answer-editor-save').prop('disabled', true)
    else
      $('.js-modal-answer-editor-save').prop('disabled', false)

  $('.js-modal-answer-editor').on 'click', '.js-modal-answer-editor-save', ->
    $editModal = $(this).closest('.js-modal-answer-editor')
    pseudoID = $editModal.find('.answer-edit-pseudo-id').val()
    $jsAnswer = $('.survey-builder').find(".js-answer-pseudo-id[pseudoid='#{pseudoID}']").closest('.js-answer')
    displayValueJSONEditorComponent = $editModal.find('.json-editor')[0]
    jsonValue = {}
    if displayValueJSONEditorComponent
      try
        editorValue = displayValueJSONEditorComponent.jsoneditor.get()
        isValid = displayValueJSONEditorComponent.jsoneditor.validateSchema(editorValue)
        defaultLanguageValue = $('.survey-builder #survey_default_language').val()
        if defaultLanguageValue != ""
          editorValue["default"] = editorValue[defaultLanguageValue] || ""
        jsonValue = editorValue if isValid
      catch e
        jsonValue = defaultJSONValue
    $jsAnswer.find('.js-answer-display-value-field').val( JSON.stringify jsonValue )
    $jsAnswer.find('.js-answer-interpreted-value-field').val( $editModal.find('.answer-edit-interpreted-value').val() )
    $jsAnswer.find('.js-answer-content-indicator').val( $editModal.find('.answer-edit-interpreted-value').val() )
    $jsAnswer.find('.js-answer-content-field').val( $editModal.find('.answer-edit-content').val() )

    $editModal.modal 'hide'

  $('.js-modal-answer-editor').on 'hidden.bs.modal', ->
    $editModal = $(this).closest('.js-modal-answer-editor')

    # erase the values
    $editModal.find('.answer-edit-pseudo-id').val( '' )
    $editModal.find('.answer-edit-display-value').val( '' )
    $editModal.find('.answer-edit-interpreted-value').val( '' )
    $editModal.find('.answer-edit-content').val( '' )

  $('.survey-builder').on 'click', '.js-answer-entity-modal', ->
    $entityPending = $(this).parents('.js-answer').find('.js-answer-content-field')
    $entityIDPending = $(this).parents('.js-answer').find('.js-answer-entity-id-field')
    return

  $('.js-entity-picker').on 'click', ->
    $entityPending.val $(this).data('entity-options')
    $entityIDPending.val $(this).data('entity-id')
    return

  $('.js-answer-image-modal').on 'click', ->
    $imageIdField = $(this).parents('.js-answer').find('.js-answer-image-id-field')
    $imageUrlField = $(this).parents('.js-answer').find('.js-answer-image-url-field')

    $('.js-modal-image-picker').find(".selected").removeClass('selected')
    $('#removeImageButton').hide()
    imageId = $imageIdField.val()
    if (imageId)
      selectedImage = $('.js-modal-image-picker').find("#js-image-picker-image-#{imageId}")
      selectedImage.addClass('selected')
      $('#removeImageButton').show()

  $('#removeImageButton').on 'click', ->
    $imageIdField.val ""
    $imageUrlField.val ""
    currentImageButton = $imageUrlField.parents('.js-answer').find('.js-answer-image-modal')
    currentImageButton.empty()
    newImageHtml = '<i class="fas fa-image" title="Choose image" />'
    currentImageButton.append(newImageHtml)

  $('.js-image-picker').on 'click', ->
    $imageIdField.val $(this).data('image-id')
    $imageUrlField.val $(this).data('image-url')
    currentImageButton = $imageUrlField.parents('.js-answer').find('.js-answer-image-modal')
    currentImageButton.empty()
    newImageHtml = "<img src='#{$imageUrlField.val()}' style='height: 16px; width: 16px;' />"
    currentImageButton.append(newImageHtml)

  $('form.simple_form.survey-builder').on 'submit', ->
    # remove the answer blueprints prior to submission
    $(@).find( '.js-answer-new-blueprint' ).remove()
    return

  # *** STARTUP CODE

  # Survey support languages values
  $supportedLanguages = $('.survey-builder #survey_supported_languages')
  $defaultLanguage = $('.survey-builder #survey_default_language')
  supportedLanguages = []
  if $supportedLanguages.length
    $supportedLanguages.val($supportedLanguages.val().replace(/\s/g, ","))
    supportedLanguages = $supportedLanguages.val().split(",") if $supportedLanguages.val().length > 0

  defaultJSONValue = { }
  supportedLanguages.forEach((language) ->
    defaultJSONValue[language.trim()] = "" if language.trim().length == 2
  )

  if $defaultLanguage.val() == ""
    defaultJSONValue["default"] = ""

  # ENABLE BRANCH TOOLBAR STALKER
  if $('.branches-stalker').length
    makeStick = ($item, stickyOffset) ->
      minScreenWidth = 1024
      if $(window).width() > minScreenWidth
        $(window).on 'scroll', ->
          if $(window).scrollTop() >= stickyOffset
            $item.addClass 'branches-stalker-fixed'
          else
            $item.removeClass 'branches-stalker-fixed'
          return

    # init
    makeStick $('.branches-stalker'), $('.branches-stalker').offset().top

    #reset values if window resizes
    $(window).on 'resize', ->
      makeStick $('.branches-stalker'), $('.branches-stalker').offset().top

  # ENABLE CAROUSEL BRANCHES
  $carousel = $('.js-branches').flickity
    # adaptiveHeight: true
    # draggable: false
    dragThreshold: 20
    # groupCells: 2
    pageDots: false
    prevNextButtons: false
    # setGallerySize: false

  alertToggle $('.js-send-alert')

  $('.survey-builder').find('.survey-name-editor').find('.json-editor').each (i) ->
    $surveyName = $(this)
    $surveyName.on 'mousedown', (e) ->
      e.stopPropagation()

    pseudoID = makePseudoID(6)
    $hiddenField = $surveyName.closest('.panel-body').find('.js-survey-name-field')
    $hiddenField.attr('pseudoid', pseudoID)
    surveyNameFieldClassname = ".js-survey-name-field[pseudoid='#{pseudoID}']"

    # Prevent race condition between JS files: check if the json editor was already loaded, or wait for load event
    if $surveyName[0] && $surveyName[0].jsoneditor
      hideDefaultLanguageIfRequired($surveyName, surveyNameFieldClassname)
      loadJSONComponentAndSchema($surveyName, surveyNameFieldClassname, "smart_strings")
      $surveyName[0].jsoneditor.set(defaultJSONValue) if $.isEmptyObject($surveyName[0].jsoneditor.get())
    else
      $surveyName.on 'json-editor-load', ->
        hideDefaultLanguageIfRequired($surveyName, surveyNameFieldClassname)
        loadJSONComponentAndSchema($surveyName, surveyNameFieldClassname, "smart_strings")
        $surveyName[0].jsoneditor.set(defaultJSONValue) if $.isEmptyObject($surveyName[0].jsoneditor.get())

    $surveyName.on "json-editor-change", ->
      handleDefaultLanguageChange($surveyName)

    $('form.simple_form.survey-builder').on 'submit', ->
      addDefaultLanguageIfRequired($surveyName, surveyNameFieldClassname)

    handleSupportedLanguagesEdit($surveyName, surveyNameFieldClassname)

    $('.survey-builder #survey_default_language').on "change", ->
      if $(@).val() == ""
        addDefaultLanguageIfRequired($surveyName, surveyNameFieldClassname)
      else
        hideDefaultLanguageIfRequired($surveyName, surveyNameFieldClassname)

  $('.survey-builder').find('.short-description-editor').find('.json-editor').each (i) ->
    $shortDescription = $(this)
    $shortDescription.on 'mousedown', (e) ->
      e.stopPropagation()

    pseudoID = makePseudoID(6)
    $hiddenField = $shortDescription.closest('.js-question').find('.js-question-short-description-field')
    $hiddenField.attr('pseudoid', pseudoID)
    shortDescFieldClassname = ".js-question-short-description-field[pseudoid='#{pseudoID}']"

    # Prevent race condition between JS files: check if the json editor was already loaded, or wait for load event
    if $shortDescription[0] && $shortDescription[0].jsoneditor
      hideDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)
      loadJSONComponentAndSchema($shortDescription, shortDescFieldClassname, "smart_strings")
      $shortDescription[0].jsoneditor.set(defaultJSONValue) if $.isEmptyObject($shortDescription[0].jsoneditor.get())
    else
      $shortDescription.on 'json-editor-load', ->
        hideDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)
        loadJSONComponentAndSchema($shortDescription, shortDescFieldClassname, "smart_strings")
        $shortDescription[0].jsoneditor.set(defaultJSONValue) if $.isEmptyObject($shortDescription[0].jsoneditor.get())
    
    $shortDescription.on "json-editor-change", ->
      handleDefaultLanguageChange($shortDescription)

    $('form.simple_form.survey-builder').on 'submit', ->
      addDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)

    handleSupportedLanguagesEdit($shortDescription, shortDescFieldClassname)

    $('.survey-builder #survey_default_language').on "change", ->
      if $(@).val() == ""
        addDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)
      else
        hideDefaultLanguageIfRequired($shortDescription, shortDescFieldClassname)

  $('.survey-builder').find('.live-conversation-web-chat-editor').find('.json-editor').each (i) ->
    $liveConversationWebChatConfig = $(this)
    $liveConversationWebChatConfig.on 'mousedown', (e) ->
      e.stopPropagation()

    pseudoID = makePseudoID(6)
    $hiddenField = $liveConversationWebChatConfig.closest('.js-question').find('.js-question-live-conversation-web-chat-field')
    $hiddenField.attr('pseudoid', pseudoID)
    liveConversationWebChatConfigFieldClassname = ".js-question-live-conversation-web-chat-field[pseudoid='#{pseudoID}']"

    # Prevent race condition between JS files: check if the json editor was already loaded, or wait for load event
    if $liveConversationWebChatConfig[0] && $liveConversationWebChatConfig[0].jsoneditor
      loadJSONComponentAndSchema($liveConversationWebChatConfig, liveConversationWebChatConfigFieldClassname, "live_conversation_web_chat_config")
      $liveConversationWebChatConfig[0].jsoneditor.set({}) if $.isEmptyObject($liveConversationWebChatConfig[0].jsoneditor.get())
    else
      $liveConversationWebChatConfig.on 'json-editor-load', ->
        loadJSONComponentAndSchema($liveConversationWebChatConfig, liveConversationWebChatConfigFieldClassname, "live_conversation_web_chat_config")
        $liveConversationWebChatConfig[0].jsoneditor.set({}) if $.isEmptyObject($liveConversationWebChatConfig[0].jsoneditor.get())

  # EXPANDING TEXTAREAS
  autosize.update( $('.js-textarea-autosize') )

  # SORTABLE QUESTIONS
  $('.js-questions').sortable
    axis: 'y'
    # containment: 'parent'
    # connectWith: '' could be handy for branches
    dropOnEmpty: true
    # forceHelperSize: true
    # forcePlaceholderSize: true
    handle: '.js-question-drag'
    items: '>.js-question:visible'
    # placeholder: 'sortable-problem-placeholder'
    update: updateQuestionPositions

  $('.survey-builder').find('.js-answer-display-value-field').each(() ->
    pseudoID = $(@).attr('pseudoid')
    displayValueFieldClassname = ".js-answer-display-value-field[pseudoid='#{pseudoID}']"
    currentDisplayValue = JSON.parse($(displayValueFieldClassname).val())
    newJSONValue = `{ ...defaultJSONValue, ...currentDisplayValue }`
    $(displayValueFieldClassname).val(JSON.stringify(newJSONValue))

    $('.survey-builder #survey_default_language').on "change", ->
      if $(@).val() == ""
        addDefaultLanguageIfRequired(null, displayValueFieldClassname)
      else
        hideDefaultLanguageIfRequired(null, displayValueFieldClassname)
  )

  # ENTITY PICKER
  $entityPending = null
  $entityIDPending = null

  updateQuestionPositions()
  # refreshCarouselHeight $('.js-question:first')

  # SEARCHABLE TIMEZONE SELECT
  $('.js-survey-editor-timezone').chosen()
