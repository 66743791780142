import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import TrendIcon from "../../shared/trend-icon/trend-icon";
import TooltipHelper from "../../../utils/tooltip-helper";
import { AlertsContentType } from "../../../types";
import { GotoLink } from "../../shared";

import AlertsWrapper from "../alerts-wrapper/alerts-wrapper";
import AlertsIcon from "../alerts-icon/alerts-icon";

const AlertsContent = ({
  newAlertsCount = 0,
  totalAlertsCount = 0,
  newAlertText = "New Alerts",
  totalAlertText = "Total Alerts",
  totalIcon,
  totalTooltipMessage,
  alertDetailsPath,
}) => {
  return (
    <AlertsWrapper>
      <AlertsIcon hasNewAlerts={newAlertsCount > 0} />
      <div className={cx("wrapper")}>
        <div className={cx("content")}>
          <div className={cx("section", "new")}>
            <div className={cx("count")}>{getAlertsCount(newAlertsCount)}</div>
            <div className={cx("text")}>{newAlertText}</div>
          </div>
          <div className={cx("section", "total")}>
            <div className={cx("count")}>{getAlertsCount(totalAlertsCount)}</div>
            <TooltipHelper tooltipMessage={totalTooltipMessage}>
              <div className={cx("icon")}>
                <TrendIcon icon={totalIcon} />
              </div>
            </TooltipHelper>
            <div className={cx("text")}>{totalAlertText}</div>
          </div>
        </div>
      </div>
      {alertDetailsPath && <GotoLink url={alertDetailsPath} openInNewTab={true} />}
    </AlertsWrapper>
  );
};

AlertsContent.propTypes = AlertsContentType;

const getAlertsCount = (count) => {
  if (count > 1000) return "1000+";
  else if (count === null || count < 0) return 0;
  else return count;
};

export default AlertsContent;
