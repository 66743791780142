import React, { HTMLProps, forwardRef } from "react";
import { Typography } from "stingray-components";

import styles from "./Checkbox.module.scss";

const Checkbox = forwardRef<HTMLInputElement, HTMLProps<HTMLInputElement>>(({ children, ...props }, ref) => {
  return (
    <div className={styles.checkboxContainer}>
      <input ref={ref} type="checkbox" style={{ cursor: "pointer", margin: 0 }} {...props} />
      <Typography variant="buttonMd">{children}</Typography>
    </div>
  );
});

export default Checkbox;
