import * as monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"

jQuery ->

  return unless $(".filter-selector").length

  require("chosen-js")
  require('chosen-js/chosen.css')
  { ScreenToPng } = require('./utils/screen-util')

# tab click events

  $(".js-filter-poc-selector, .js-filter-dictionary-selector, .js-filter-date-range-selector, .js-filter-segment-selector").on 'click', ->
    setActivePanel($(@))

# filter chip remove filter event
  $(".filter-chip.removable .cross").on 'click', ->
    # we get value from <span class="cross" value="param_name:param_value_to_remove" />
    [param_name, param_value_to_remove] = $(this).attr("value").split(/:(.*)/)

    url = new URL(window.location.href)
    url_search_params = url.searchParams

    new_param_values = url_search_params.getAll(param_name).filter (value) -> value != param_value_to_remove
    url_search_params.delete(param_name)
    for value in new_param_values
      url_search_params.append(param_name, value)

    url.search = url_search_params.toString()
    window.location.href = url.href

# apply and reset button events

  $("button#apply-filters").on 'click', ->

    url = new URL(window.location.href)

    old_url_search_params = url.searchParams;
    new_url_search_params = new URLSearchParams();

    # collect poc filter params
    for x in $(".dash-poc-filter select") when $(x).val().length > 0
      for val in [].concat($(x).val())
        new_url_search_params.append("fltrpoc_#{$(x).data("code")}", val)

    # collect dictionary parameters
    for x in $(".dash-dictionary-filter select, .dash-dictionary-filter input") when $(x).val().length > 0
      new_url_search_params.append("fltrrd_#{$(x).data("code")}", $(x).val())

    # collect date range and interval parameters
    for x in $(".dash-date-range-filters .flatpickr-input") when $(x).val().length > 0
      new_url_search_params.append("fltr_#{$(x).data("code")}", $(x).val())

    if !!$(".dash-date-range-filters #interval-dropdown").val()
      if $(".dash-date-range-filters #interval-dropdown").val().length > 0
        new_url_search_params.append("fltr_interval", $(".dash-date-range-filters #interval-dropdown").val())
    else if old_url_search_params.getAll("fltr_interval").length > 0
      new_url_search_params.append("fltr_interval", old_url_search_params.getAll("fltr_interval").pop())
    else
      new_url_search_params.append("fltr_interval", "week")

    # collect segmentation info
    if $("#question-answer-dropdown").val() && $("#question-answer-dropdown").val().length
      question_id = $("#question-dropdown").val()
      answer = $("#question-answer-dropdown").val()
      for val in answer
        new_url_search_params.append("fltrseg_qid_#{question_id}[]", val)

    # collect completed conversations filter
    if $("#completed-checkbox").prop("checked")
      new_url_search_params.append("completed_conversations", $("#completed-checkbox").prop("checked"))

    # collect purchaser filter
    if $("#purchaser-question").val() && $("#purchaser-question").val().length
      new_url_search_params.append("fltrpurc_#{$("#purchaser-question").data("question-id")}", $("#purchaser-question").val())

    keepPermanentQueryParams(old_url_search_params, new_url_search_params)

    url.search = new_url_search_params.toString()
    window.location.href = url.href

  $("#reset-filters").on 'click', ->
    url = new URL(window.location.href)

    old_url_search_params = url.searchParams;
    new_url_search_params = new URLSearchParams();

    keepPermanentQueryParams(old_url_search_params, new_url_search_params)

    url.search = new_url_search_params.toString()
    window.location.href = url.href

  $('#take-screenshot-button').click ->
    ScreenToPng(".container-main", document.title + " screenshot at " + new Date().toString())

# other events

  $("#question-dropdown").on 'change',(e, props = {}) ->
    $("#question-answer-dropdown").empty()
    return if $(@).children(":selected").length == 0
    url = $(@).children(":selected").first().data("url")
    q_id = $(@).children(":selected").first().val()
    # when the empty element is chosen, there will be no url
    if !url
      setEmptyQuestionAnswerDropdown()
      return
    $.ajax(
      url: url
      success: (data) ->
        if data.length < 1
          setEmptyQuestionAnswerDropdown()
        else
          for answer in data
            opt = I18n.t(answer, defaultValue: "#{answer}")
            escapedAnswer = encodeURIComponent(answer)
            $("#question-answer-dropdown").append("<option value=\"#{escapedAnswer}\">#{opt}</option>")
            $("#question-answer-dropdown").prop("disabled", false)
          if props.answers
            $("#question-answer-dropdown").val(props.answers)
          $('#question-answer-dropdown').trigger("chosen:updated")
      error: (data) ->
        setEmptyQuestionAnswerDropdown()
    )

  $(".dash-poc-filter").on "change", ->
    checked = 0
    $('.dash-poc-filter select').each ->
      $opt = $( this ).val()
      if $opt != "" && $opt != null
        if $(this).is("#multi-choice")
          $opt = $opt.filter((value, index, arr) ->
            value != ''
          )
        if $opt.length > 0
          checked = 1
    if checked == 1
      $(".poc-check").css("display", "inline-block")
    else
      $(".poc-check").css("display", "none")

  $(".dash-dictionary-filter").on "change", ->
    checked = 0
    $('.dash-dictionary-filter select').each ->
      $opt = $( this ).val()
      if $opt != "" && $opt != null
        if $opt.length > 0
          checked = 1
    if checked == 1
      $(".dictionary-check").css("display", "inline-block")
    else
      $(".dictionary-check").css("display", "none")

  $(".dash-date-range-filter").on "change", ->
    $(".date-check").removeClass("hide")

  $(".dash-segment-filter.question").on "change", ->
    checked_segment = 0
    $('.dash-segment-filter.question select').each ->
      $opt = $( this ).val()
      if $opt != "" && $opt != null
        checked_segment = 1
    if checked_segment == 1
      $(".segment-check").css("display", "inline-block")
    else
      $(".segment-check").css("display", "none")

  $("#purchaser-question").on 'change',(e, props = {}) ->
    $("button#apply-filters").trigger('click');

# functions

  # thoses params were injected in the page, so we want to keep them
  keepPermanentQueryParams = (old_url_search_params, new_url_search_params) ->
    if old_url_search_params.getAll("question_id").length > 0
      new_url_search_params.append("question_id", old_url_search_params.getAll("question_id").pop())
    if old_url_search_params.getAll("topic_level").length > 0
      new_url_search_params.append("topic_level", old_url_search_params.getAll("topic_level").pop())
    if old_url_search_params.getAll("topic_name").length > 0
      new_url_search_params.append("topic_name", old_url_search_params.getAll("topic_name").pop())


  # needed this because we don't support ES6 iterator in coffeescript
  getUniqueKeys = (url_search_params) =>
    unique_keys = new Set()
    iterator = url_search_params.keys()

    while (entry = iterator.next()) && !entry.done
      unique_keys.add(entry.value)

    return unique_keys

  initializeFilters = () ->
    $(".poc-search, .dictionary-search").chosen({ width: "100%" })
    $("#question-answer-dropdown").chosen({allow_single_deselect: true, width: "100%"})

    standard_flatpickr_options =
      locale: I18n.locale,
      altInput: true,
      altFormat: I18n.t("date.flatpickr_formats.default", defaultValue: "F j, Y")

    $('#start-date').flatpickr(standard_flatpickr_options)
    $('#end-date').flatpickr(standard_flatpickr_options)

    monthly_flatpickr_options =
      locale: I18n.locale
      altInput: true
      altFormat: I18n.t("date.flatpickr_formats.monthly", defaultValue: "F Y"),
      maxDate: "today"
      plugins: [new monthSelectPlugin({})]
      disableMobile: "true"
    $('#month-select-date').flatpickr(monthly_flatpickr_options)

    setActivePanel($(".js-filter-date-range-selector"))

    url_search_params = new URL(window.location.href).searchParams;

    getUniqueKeys(url_search_params).forEach (key) =>
      if key.indexOf('fltr_sd') == 0 || key.indexOf('fltr_ed') == 0
        $(".filter-date-range-check").removeClass("hide")
      else if key.indexOf('fltrpoc_') == 0
        $(".filter-poc-check").removeClass("hide")
      else if key.indexOf('fltrrd_') == 0
        $(".filter-dictionary-check").removeClass("hide")
      else if key.indexOf('fltrseg_') == 0
        $(".filter-segment-check").removeClass("hide")
        if key.indexOf('fltrseg_qid_') == 0
          question_id = key.replace("[]", "").substring(12)
          $("#question-dropdown").val(question_id)
          $("#question-dropdown").trigger 'change', [{answers: url_search_params.getAll(key) }]
      else if key.indexOf('completed_conversations') == 0 && url_search_params.get('completed_conversations') == "true"
        $(".filter-segment-check").removeClass("hide")
        $("#completed-checkbox").prop("checked", true)


  setEmptyQuestionAnswerDropdown = () ->
    $("#question-answer-dropdown").prop("disabled", true)
    $('#question-answer-dropdown').trigger("chosen:updated")

  setActivePanel = ($currentSelector) ->
    panels = $(".js-dash-date-range-filters, .js-dash-poc-filters, .js-dash-dictionary-filters, .js-dash-segment-filters")
    currentPanel = $(".js-dash-date-range-filters") if $currentSelector.hasClass("js-filter-date-range-selector")
    currentPanel = $(".js-dash-dictionary-filters") if $currentSelector.hasClass("js-filter-dictionary-selector")
    currentPanel = $(".js-dash-poc-filters") if $currentSelector.hasClass("js-filter-poc-selector")
    currentPanel = $(".js-dash-segment-filters") if $currentSelector.hasClass("js-filter-segment-selector")
    panels.not(currentPanel).addClass("hide")
    currentPanel.removeClass("hide")

    selectors = $(".js-filter-poc-selector, .js-filter-dictionary-selector, .js-filter-date-range-selector, .js-filter-segment-selector")
    selectors.not($currentSelector).removeClass("active")
    $currentSelector.addClass("active")

  # run on load

  initializeFilters()
