import React from "react";
import styles from "./styles.module.scss";
import WeaknessIcon from "images/weakness.svg";
import StrengthIcon from "images/strength.svg";
import { TopTopicIconType, topicIconType } from "../../../types";

const TopTopicIcon = ({ icon = "none", size }) => {
  let image = null;

  switch (icon) {
    case topicIconType.weakness:
      image = <WeaknessIcon />;
      break;
    case topicIconType.strength:
      image = <StrengthIcon />;
      break;
    case topicIconType.none:
      image = null;
      break;
    default:
      image = null;
      break;
  }

  return image ? (
    <div className={styles.wrapper} style={{ width: size, height: size }}>
      {image}
    </div>
  ) : null;
};

TopTopicIcon.propTypes = TopTopicIconType;

export default TopTopicIcon;
