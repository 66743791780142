import React, { MouseEvent, useState } from "react";
import { useMutation } from "react-query";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Card, Loading, Themes, Typography, styled } from "stingray-components";

import { MutationFetcherProps } from "../../src/hooks/useConfig";
import withReactQuery from "../../src/hocs/withReactQuery";
import SelectInput, { SelectOption } from "../shared/Select";

import styles from "./DeleteLiveConversations.module.scss";

type DeleteLiveConversationsFormFields = {
  pocPhoneNumber: SelectOption;
  themPhoneNumber: SelectOption;
}

type DeleteLiveConversationsProps = {
  api: {
    csrfToken: string;
  };
  company: {
    id: number;
    name: string;
  };
  liveConversationPocPhoneNumbers: string[];
  themPhoneNumbers: string[];
}

type DeleteLiveConversationsFetcherProps = MutationFetcherProps & {
  data: DeleteLiveConversationsFormFields;
  companyId: number;
}

const ErrorTypography = styled(Typography, { color: "$red400" });
const SuccessTypography = styled(Typography, { color: "$green400" });

const deleteLiveConversations = async ({ data, csrfToken, companyId }: DeleteLiveConversationsFetcherProps) => {
  const response = await fetch(`/api/companies/${companyId}/delete_live_conversations_by_phone_numbers`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      pocPhoneNumber: data.pocPhoneNumber.value,
      themPhoneNumber: data.themPhoneNumber.value,
    }),
  });

  if (!response.ok) throw new Error("Could not delete the live conversations.");
  return await response.json();
}

const DeleteLiveConversations = (props: DeleteLiveConversationsProps) => {
  const [successText, setSuccessText] = useState<string>();

  const methods = useForm<DeleteLiveConversationsFormFields>({
    defaultValues: {
      pocPhoneNumber: {},
      themPhoneNumber: {},
    },
  });

  const { api, company } = props;
  const liveConversationPocPhoneNumbers = props.liveConversationPocPhoneNumbers.map((phoneNumber) => ({ value: phoneNumber, label: phoneNumber }));
  const themPhoneNumbers = props.themPhoneNumbers.map((phoneNumber) => ({ value: phoneNumber, label: phoneNumber }));

  const returnToPreviousPage = (e: MouseEvent) => location.assign(document.referrer);

  const { handleSubmit } = methods;
  const { mutate, isLoading, error: submitError } = useMutation({ mutationFn: deleteLiveConversations, onError: console.error, onSuccess: (data) => onSuccess(data) });

  const onSubmit = (data: DeleteLiveConversationsFormFields) => mutate({ data, csrfToken: api.csrfToken, companyId: company.id });
  const onSuccess = (data: { count: number }) => {
    setSuccessText(`Deleted ${data.count} live conversations`);
  };

  return (
    <div className={Themes.CHATTER.theme}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.sectionContainer}>
            <Typography variant="h4" css={{ fontWeight: 500 }}>
              Delete all Live Conversations for {company.name} between two phone numbers
            </Typography>

            <Card className={styles.card}>
              <SelectInput options={liveConversationPocPhoneNumbers} name="pocPhoneNumber">
                POC Phone Number
              </SelectInput>
              <SelectInput options={themPhoneNumbers} name="themPhoneNumber">
                Them Phone Number
              </SelectInput>
            </Card>

            <div className={styles.statusTextContainer}>
              <SuccessTypography>{successText}</SuccessTypography>
            </div>

            <div className={styles.statusTextContainer}>
              <ErrorTypography>{submitError?.message}</ErrorTypography>
            </div>

            <div className={styles.submitSectionContainer}>
              <Button
                size="large"
                width="150px"
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  returnToPreviousPage(e);
                }}>
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                size="large"
                width="150px"
                onClick={handleSubmit(onSubmit)}>
                {isLoading ? <Loading size="25px" /> : "Delete Live Conversations"}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default withReactQuery(DeleteLiveConversations as any);
