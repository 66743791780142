$ ->  

  return unless window.location.pathname.includes("/admin/")

  flatpickr_opts = {
    locale: I18n.locale,
    altInput: true,
    altFormat: "F j, Y",
    maxDate: new Date().fp_incr(1)
  }
  $(".admin-panel-date-range #clear_start_date").flatpickr(flatpickr_opts)
  $(".admin-panel-date-range #clear_end_date").flatpickr(flatpickr_opts)

  $(".admin-users-form select#user_email").chosen()
  