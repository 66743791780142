jQuery ->
  $(".sc-apply-to-all-pocs input").on "change", (e) ->
    $(".solicitation-configuration-poc-restrictions").toggleClass("hide")

  $("form.simple_form").on "submit", ->
    pointOfContactSelection = $(".sc-poc-accesses .point-of-contact-selections").attr("data-selected-values")
    if (!$(".sc-apply-to-all-pocs input").is(":checked"))
      $(".sc-poc-restrictions-restriction-values input").val(pointOfContactSelection)

  filter_counter = $(".row-filter").length

  $(".add-filter").on "click", (e) ->
    e.preventDefault()
    filter_counter += 1
    button = $(e.currentTarget)
    content = button.data("content").replace(/new_\w+/g, filter_counter)
    $(".transaction-filters").append(content)

  $(".transaction-filters").on "click", ".remove-filter", (e) ->
    e.preventDefault()
    field = $(e.currentTarget).closest(".row-filter")
    field.find("input, select").prop("required", false)
    field.find("input[name*='_destroy']").val("1")
    field.hide()

  $(".solicitation_configuration_transaction_required input").on "change", (e) ->
    if $(e.currentTarget).is(":checked")
      $(".transaction-filters-container").removeClass("hide")
    else
      $(".transaction-filters-container").addClass("hide")
      $(".row-filter").each (index, element) ->
        $(element).find("input[name*='_destroy']").val("1")
        $(element).hide()
