import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faComments, faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faEllipsisVertical,
  faMapLocationDot,
  faMessage,
  faMobileScreen,
  faPen,
  faRotateLeft,
  faTowerCell
} from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import { screenSize } from "../utils/constants";
import { fetchData } from "../utils/request-helper";

import SlidingPage from "./SlidingPage";
import Dimmer from "./Dimmer";
import Modal from "./Modal";
import Header from "./Header";
import UserIcon from "./UserIcon";
import IconButton from "./IconButton";
import InputMessage from "./InputMessage";
import { CopyToClipboardButton } from "../shared/CopyToClipboardButton";

import I18n from "i18n-js";
import classNames from "classnames";
import styles from "./ConversationInfo.module.scss";

const ConversationInfo = ({
  api,
  user,
  isOpen,
  setIsOpen,
  selectedConversation,
  setConversationReceivedCallback,
  userScreenSize,
}) => {
  const [isSlidingOut, setIsSlidingOut] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isGenerateLinkToSurveyConfirmationModalOpen, setIsGenerateLinkToSurveyConfirmationModalOpen] = useState(false);

  const [timeline, setTimeline] = useState([]);

  const conversationNameInputRef = useRef(null);
  const [isRenamingConversation, setIsRenamingConversation] = useState(false);
  const [conversationNameInputValue, setConversationNameInputValue] = useState(selectedConversation?.name === selectedConversation?.phone ? "" : selectedConversation?.name);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [showActionPopper, setShowActionPopper] = useState(false);
  const [isActionPopperPhasingOut, setIsActionPopperPhasingOut] = useState(false);

  const onChevronClicked = () => {
    setIsOpen(false);
    setIsSlidingOut(true);
  }

  useEffect(() => {
    if (isRenamingConversation) conversationNameInputRef.current.focus();
    if (!isRenamingConversation && conversationNameInputValue === selectedConversation?.phone) setConversationNameInputValue("");
  }, [isRenamingConversation]);

  useEffect(() => {
    if (userScreenSize === screenSize.SMALL) {
      document.getElementById("live-conversations").style.overflowY = showActionPopper ? "hidden" : "auto";
    }
  }, [showActionPopper, userScreenSize]);

  useEffect(() => {
    if (isOpen && selectedConversation && userScreenSize === screenSize.SMALL) {
      document.getElementById("live-conversations").style.overflowY = "hidden";
    }
  }, [isOpen, selectedConversation, userScreenSize]);

  useEffect(() => {
    if (isOpen && selectedConversation) {
      fetchTimeline();
    }
  }, [isOpen, selectedConversation]);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(referenceElement, popperElement, {
    placement: "left-start",
    modifiers: [
      {
        name: "offset",
        options: { offset: [-10, 10] },
      },
    ],
  });

  const { apiEndpoints, id, location, phone, name, us, start, latest } = selectedConversation || {};

  const fetchTimeline = async () => {
    let metadata = await fetchData(apiEndpoints.getMetadata, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": api.csrfToken,
      },
    });
    setTimeline(metadata?.timeline || []);
  };

  const generateLinkToSurvey = async () => {
    const surveyId = 9679; // FIXME Hard-coded to L'Oréal survey

    const conversation = await fetchData(`${apiEndpoints.generateLinkToSurvey}&target_survey_id=${surveyId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": api.csrfToken,
      },
    });

    if (!conversation) {
      setIsErrorModalOpen(true);
      return;
    }

    setConversationReceivedCallback({ conversation, bumpToTop: false });
  };

  const onSendComment = async (comment) => {
    const response = await fetchData(apiEndpoints.addComment, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": api.csrfToken,
      },
      body: JSON.stringify({
        conversation_id: id,
        comment,
      }),
    });

    if (response) {
      fetchTimeline();
      return true;
    }

    setIsErrorModalOpen(true);
    return false;
  };

  const onKeyUp = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleConversationNameChanged();
    } else if (e.key === "Escape") {
      setIsRenamingConversation(false);
      setConversationNameInputValue(name);
    }
  };

  const handleCloseActionPopper = () => {
    setShowActionPopper(false);
    setIsActionPopperPhasingOut(true);
  }

  const handleConversationNameChanged = async () => {
    const conversation = await fetchData(api.renameConversationUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": api.csrfToken,
      },
      body: JSON.stringify({
        conversation_id: id,
        conversation_name: conversationNameInputValue,
      }),
    });

    if (conversation) {
      setConversationReceivedCallback({ conversation, bumpToTop: false });
      setIsRenamingConversation(false);
    } else {
      setIsErrorModalOpen(true);
    }
  }

  const handleOpenCloseConversation = async () => {
    const conversation = await fetchData(selectedConversation.apiEndpoints.openCloseConversation, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": api.csrfToken,
      },
      body: JSON.stringify({
        conversation_id: id,
        is_conversation_closed: !selectedConversation.isConversationClosed,
      }),
    });

    if (conversation) {
      setConversationReceivedCallback({ conversation, bumpToTop: false });
    } else {
      setIsErrorModalOpen(true);
    }
  }

  const handleGenerateLinkToSurvey = () => {
    if (selectedConversation?.linkToSurvey) setIsGenerateLinkToSurveyConfirmationModalOpen(true);
    else generateLinkToSurvey();
  }

  return (
    <>
      <SlidingPage
        isClosed={!isOpen}
        isSlidingOut={isSlidingOut}
        setIsSlidingOut={setIsSlidingOut}
        closeCallback={() => setIsOpen(false)}
        userScreenSize={userScreenSize}>
        <Header>
          {userScreenSize === screenSize.SMALL &&(
            <IconButton onClick={() => onChevronClicked()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </IconButton>
          )}
          <span>{ I18n.t("live_conversations.conversation_details") }</span>
          {userScreenSize !== screenSize.SMALL && (
            <IconButton className={styles.iconButton} onClick={() => onChevronClicked()}>
              <FontAwesomeIcon className={styles.chevronRightIcon} icon={faChevronRight} />
            </IconButton>
          )}
        </Header>
        <div className={styles.body} onClick={() => {
          if (isRenamingConversation) {
            setIsRenamingConversation(false);
            setConversationNameInputValue(name);
          }
        }}>
          <div className={styles.conversationNameSection}>
            <div className={styles.conversationNameWrapper} onClick={(e) => e.stopPropagation()}>
              <UserIcon conversation={selectedConversation} />
              { !isRenamingConversation && <div className={styles.conversationName}>{ name }</div> }
              { isRenamingConversation && (
                <div className={styles.conversationNameInputWrapper}>
                  <input
                    ref={conversationNameInputRef}
                    className={styles.conversationNameInput}
                    disabled={!isRenamingConversation}
                    value={ conversationNameInputValue }
                    onKeyUp={onKeyUp}
                    onChange={(e) => setConversationNameInputValue(e.target.value)} />
                  <IconButton onClick={handleConversationNameChanged}>
                    <FontAwesomeIcon color="#2ab82a" icon={faCheck} size={"sm"} />
                  </IconButton>
                </div>
              ) }
            </div>
            { selectedConversation && (
              <IconButton onClick={() => setShowActionPopper(true)}>
                <FontAwesomeIcon ref={setReferenceElement} icon={faEllipsisVertical} />
              </IconButton>
            ) }
          </div>
          <div className={styles.infoSection}>
            <FontAwesomeIcon icon={faMapLocationDot} /> <span>{ location?.name || "" }</span>
            <FontAwesomeIcon icon={faTowerCell} /> <span>{ us || "" }</span>
            <FontAwesomeIcon icon={faMobileScreen} /> <span>{ phone || "" }</span>
            <FontAwesomeIcon icon={faCalendar} /> <span>{ start && `${I18n.t("live_conversations.conversation_started")} ${moment.unix(start).fromNow()}`}</span>
            <FontAwesomeIcon icon={faCalendar} /> <span>{ latest && `${I18n.t("live_conversations.last_updated")} ${moment.unix(latest).fromNow()}`}</span>
          </div>
          { (user.is_admin || user.accessible_features.includes("live_conversation_instant_url")) && (
            <>
              <div className={styles.titleSection}>{ I18n.t("live_conversations.link_to_survey") }</div>
              <div className={styles.linkToSurveySection}>
                <span className={styles.linkToSurvey}>{ selectedConversation?.linkToSurvey }</span>
                <div className={styles.linkToSurveyActionButtons}>
                  <IconButton disabled={!selectedConversation} title={I18n.t("live_conversations.generate_link_to_survey")} onClick={handleGenerateLinkToSurvey}>
                    <FontAwesomeIcon icon={faSquarePlus} />
                  </IconButton>
                  <CopyToClipboardButton textToCopy={selectedConversation?.linkToSurvey} />
                  <IconButton
                    disabled={!selectedConversation?.linkToSurvey}
                    title={I18n.t("live_conversations.view_survey_results")}
                    onClick={() => window.open(selectedConversation?.linkToSurveyResults, "_blank")}>
                    <FontAwesomeIcon icon={faComments} />
                  </IconButton>
                </div>
              </div>
            </>
          ) }
          <div className={styles.titleSection}>{ I18n.t("live_conversations.timeline_and_comments") }</div>
          { timeline.map(([ timestamp, user, content ]) => (
            <div key={timestamp} className={styles.timelineItem}>
              <span className={styles.timelineItemTimestamp}>
                { moment.unix(timestamp).calendar() }
                { user && ` - ${user}` }
              </span>
              <div className={styles.timelineItemContent}>
                <FontAwesomeIcon color={user ? "#FFCF55" : "#C4C4C4"} icon={user ? faMessage : faClock} />
                { content }
              </div>
            </div>
          )) }
        </div>
        <InputMessage
          disabled={!selectedConversation}
          placeholder={I18n.t("live_conversations.your_comment")}
          onSendMessageCallback={onSendComment} />
      </SlidingPage>
      <Modal
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        body={I18n.t("live_conversations.general_error")}
        actionButtonText={I18n.t("live_conversations.ok")} />
      <Modal
        isOpen={isGenerateLinkToSurveyConfirmationModalOpen}
        setIsOpen={setIsGenerateLinkToSurveyConfirmationModalOpen}
        body={I18n.t("live_conversations.generate_link_to_survey_confirmation")}
        actionButtonText={I18n.t("live_conversations.yes")}
        onActionButtonClicked={generateLinkToSurvey}
        cancelButtonText={I18n.t("live_conversations.no")} />
      { (showActionPopper || isActionPopperPhasingOut) && ReactDOM.createPortal(
        <Dimmer
          isPhasingOut={isActionPopperPhasingOut}
          setIsPhasingOut={setIsActionPopperPhasingOut}
          onClick={handleCloseActionPopper}>
          <div
            ref={setPopperElement}
            style={popperStyles.popper}
            {...popperAttributes.popper}>
            <div
              className={classNames(styles.actionPopper, {
                [styles.actionPopperPhasingOut]: isActionPopperPhasingOut,
              })}
              onClick={(e) => e.stopPropagation()}>
              <button
                type="button"
                onClick={() => {
                  handleCloseActionPopper();
                  setIsRenamingConversation(true);
                }}>
                <FontAwesomeIcon icon={faPen} />
                { I18n.t("live_conversations.rename") }
              </button>
              <button
                type="button"
                onClick={() => {
                  handleCloseActionPopper();
                  handleOpenCloseConversation();
                }}>
                  {
                    selectedConversation.isConversationClosed ? (
                      <>
                        <FontAwesomeIcon icon={faRotateLeft} />
                        { I18n.t("live_conversations.reopen") }
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faCircleCheck} />
                        { I18n.t("live_conversations.close") }
                      </>
                    )
                  }
              </button>
            </div>
          </div>
        </Dimmer>, document.body) }
    </>
  );
}

export default ConversationInfo;
