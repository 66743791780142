import I18n from "i18n-js";
import React, { MouseEvent } from "react";
import { Controller, FieldArrayWithId, useFormContext } from "react-hook-form";
import { Button, Typography } from "stingray-components";

import { ModalContext, ModalProvider } from "../../src/hooks/useModal";
import Checkbox from "../shared/Checkbox";
import SelectInput from "../shared/Select";
import {
  POCRestrictionsModalReturnData,
  PointOfContactSelectorModal,
} from "./PointOfContactSelectorModal";
import { UserManagementFormFields, contactMethodOptions, countPOCRestrictions } from "./userManagementTypes";

import styles from './SurveyConfigTable.module.scss'

type SurveyConfigTableProps = {
  configs: FieldArrayWithId<UserManagementFormFields, "surveyConfigs", "id">[];
  companyId: string | undefined;
}

const SurveyConfigTable = ({ configs, companyId }: SurveyConfigTableProps) => {
  return (
    <table className={styles.tableContainer}>
      <thead>
        <tr>
          <th>
            <Typography variant="h8">
              {I18n.t("user_management.read_access")}
            </Typography>
          </th>
          <th>
            <Typography variant="h8">
              {I18n.t("user_management.survey_name")}
            </Typography>
          </th>
          <th>
            <Typography variant="h8">
              {I18n.t("user_management.alerts_configuration")}
            </Typography>
          </th>
          <th>
            <Typography variant="h8">
              {I18n.t("user_management.point_of_contact_restrictions")}
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {configs.map((field, index) => (
          <SurveyConfigRow key={field.id} index={index} companyId={companyId} />
        ))}
      </tbody>
    </table>

  );
};

type SurveyConfigRowProps = {
  index: number;
  companyId: string | undefined;
};

const SurveyConfigRow = ({ index, companyId }: SurveyConfigRowProps) => {
  const { watch, setValue, register } = useFormContext<UserManagementFormFields>();

  const {readAccess, hasReadAccessByDefault, surveyName, surveyAlertConfiguration: {pocRestrictions}} = watch(`surveyConfigs.${index}`)

  const onPOCModalSubmit = (pocRestrictions: POCRestrictionsModalReturnData) => {
    setValue(`surveyConfigs.${index}.surveyAlertConfiguration.pocRestrictions`, pocRestrictions);
  };

  return (
    <ModalProvider onSubmit={onPOCModalSubmit} initialData={{ companyId, pocRestrictions }}>
      <ModalContext.Consumer>
        {(modalContext) => (
          <tr>
            <td>
              <Checkbox {...register(`surveyConfigs.${index}.readAccess`)} disabled={hasReadAccessByDefault}  />
            </td>
            <td>
              <Typography variant="h9">{surveyName}</Typography>
            </td>
            <td className={styles.contactMethodColumnContainer}>
              <SelectInput
                name={`surveyConfigs.${index}.surveyAlertConfiguration.contactMethod`}
                isDisabled={!readAccess}
                options={contactMethodOptions}
              />
            </td>
            <td>
              {modalContext &&
                <div className={styles.pocRestrictionColumnContainer}>
                  <Button
                    size="medium"
                    onClick={(e: MouseEvent) => {
                      e.preventDefault();
                      modalContext.toggleOpen();
                    }}
                    disabled={!readAccess}
                  >
                    <Typography variant="h8">{I18n.t("user_management.configure")}</Typography>
                  </Button>

                  {pocRestrictions?.length > 0 ? (
                    <Typography variant="h9">
                      {I18n.t("user_management.restricted_to_locations", {
                        locations: countPOCRestrictions(pocRestrictions)
                      })}
                    </Typography>
                  ) : (
                    <Typography variant="h9">
                      {I18n.t("user_management.using_points_of_contact_from_data_access")}
                    </Typography>
                  )}
                  {modalContext.open && <PointOfContactSelectorModal />}
                </div>
              }
            </td>
          </tr>)}
      </ModalContext.Consumer>
    </ModalProvider>
  )
}

export default SurveyConfigTable;
