import I18n from "i18n-js";
import React from "react";

import Tile from "./tile";

const InitiationsTile = ({ filters, initiationsData }) => {
  const overall = initiationsData && initiationsData.initiations;
  const overallHistorical = initiationsData && initiationsData.initiationsHistorical;
  const counts = initiationsData && [
    {
      category: I18n.t("dashboard_lite.initiations"),
      number: initiationsData.initiations,
      percentage: "-",
      state: "neutral",
    },
    {
      category: I18n.t("dashboard_lite.answered_first_question"),
      number: initiationsData.answeredFirstQuestion,
      percentage: `${
        initiationsData.answeredFirstQuestion && initiationsData.initiations
          ? Math.round((initiationsData.answeredFirstQuestion / initiationsData.initiations) * 100)
          : 0
      }%`,
      state: "neutral",
    },
    {
      category: I18n.t("dashboard_lite.completions"),
      number: initiationsData.completions,
      percentage: `${
        initiationsData.completions && initiationsData.answeredFirstQuestion
          ? Math.round((initiationsData.completions / initiationsData.answeredFirstQuestion) * 100)
          : 0
      }%`,
      state: "positive",
    },
  ];

  return (
    <Tile
      counts={counts}
      filters={filters}
      loading={!initiationsData}
      overall={overall}
      overallHistorical={overallHistorical}
      title={I18n.t("dashboard_lite.initiations_tile_title")}
      tooltip={I18n.t("dashboard_lite.initiations_tile_tooltip")}
    />
  );
};

export default InitiationsTile;
