import React from "react";
import I18n from "i18n-js";

import ConversationListItem from "./ConversationListItem";
import Loading from "./Loading";

import styles from "./ConversationList.module.scss";

const ConversationList = ({ conversations, isLoading, hasMoreConversations, selectedConversation, setSelectedConversationCallback }) => {
  return (
    <div className={styles.conversationList}>
      { isLoading && <Loading /> }
      { !isLoading && conversations.length === 0 && (
        <div className={styles.noConversationsFound}>{I18n.t("live_conversations.no_conversations_found")}</div>
      )}
      { !isLoading && conversations?.map((conversation, index) => (
        <ConversationListItem
          key={index}
          conversation={conversation}
          selectedConversation={selectedConversation}
          setSelectedConversationCallback={setSelectedConversationCallback} />
      )) }
      { conversations.length !== 0 && hasMoreConversations && (
        <div className={styles.endOfListLoadingContainer}>
          <Loading />
        </div>
      ) }
    </div>
  );
}

export default ConversationList;
