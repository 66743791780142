import React from "react";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";

import defaultOptions from "./default-options.json";

import {
  extendChartConfig,
  modelSeriesArray,
  chartSeriesHasData,
} from "../../../utils/chart-helper";

const cx = classNames.bind(styles);

//extendOptions should be used for overriding other properties than series.data
const TopicsChart = ({
  categories,
  mixedData,
  neutralData,
  negativeData,
  positiveData,
  extraOptions = {},
}) => {
  const options = fillData(categories, neutralData, negativeData, positiveData, mixedData);
  const extendedOptions = extendChartConfig(options, extraOptions);

  const noData = !chartSeriesHasData(extendedOptions.series);

  return (
    <div className={cx("topics-chart")}>
      {noData ? (
        <div className={cx("no-data")}>{I18n.t("no_data")}</div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={extendedOptions} />
      )}
    </div>
  );
};

TopicsChart.propTypes = {
  categories: PropTypes.array.isRequired,
  extraOptions: PropTypes.object,
  negativeData: PropTypes.array.isRequired,
  neutralData: PropTypes.array.isRequired,
  positiveData: PropTypes.array.isRequired,
  mixedData: PropTypes.array.isRequired,
};

export default TopicsChart;

//fills series and categories with defined data
const fillData = (categories, neutralData, negativeData, positiveData, mixedData) => {
  const series = modelSeriesArray(mixedData, neutralData, negativeData, positiveData);
  const options = {
    series,
    xAxis: { categories },
  };

  //merge it with defaultOptions
  return extendChartConfig(defaultOptions, options);
};
