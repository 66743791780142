$ ->

  common_button_options = {
    exportOptions: {
      format: {
        header: (data, columnIdx) ->
          tableHeaderRowTh = $('#table-header-row th')
          if tableHeaderRowTh.length > 0
            exportTitle = tableHeaderRowTh[columnIdx].getAttribute('export-title')
            if exportTitle
              return exportTitle
            else
              return data
          else
            return data
      }
    },
    fieldSeparator: ',',
    filename: $('.poc-stats-table').data('title')?.toLowerCase()?.replace(/ /g, '_'),
    title: $('.poc-stats-table').data('title'),
  }

  $('table.poc-stats-table').DataTable({
    dom: "<'row'<'col-sm-6 dt-dom-buttons'B><'col-sm-6'f>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-5'i><'col-sm-7'p>>"
    buttons: [
      $.extend({
        extend: 'copy',
        text: "<i class='fas fa-copy'></i> #{I18n.t("copy")}",
        titleAttr: I18n.t("copy"),
        style: "font-size: 1.3rem",
      }, common_button_options),
      $.extend({
        extend: 'excel',
        text: '<i class="fas fa-file-excel"></i> Excel',
        titleAttr: 'Excel',
      }, common_button_options),
      $.extend({
        extend: 'csv',
        text: '<i class="fas fa-file-csv"></i> CSV',
        titleAttr: 'CSV',
      }, common_button_options),
      $.extend({
        extend: 'pdf',
        text: '<i class="fas fa-file-pdf"></i> PDF',
        titleAttr: 'PDF',
      }, common_button_options),
      $.extend({
        extend: 'print',
        text: "<i class='fas fa-print'></i> #{I18n.t("print")}",
        titleAttr: I18n.t("print"),
      }, common_button_options),
    ],
    language: window.jQueryDataTableLanguagePacks[I18n.locale] || {}
    paging: false
    searching: true
    info: true
    ordering: true
    order: []
  })
