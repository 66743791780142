import I18n from "i18n-js";
import React from "react";

import Tile from "./tile";

const SatTile = ({ filters, satData }) => {
  const overall = satData && satData.satScore;
  const overallHistorical = satData && satData.satScoreHistorical;
  const counts = satData && [
    {
      category: I18n.t("dashboard_lite.satisfied"),
      number: satData.satisfied,
      percentage: `${satData.total ? Math.round((satData.satisfied / satData.total) * 100) : 0}%`,
      state: "positive",
    },
    {
      category: I18n.t("dashboard_lite.unsatisfied"),
      number: satData.total ? satData.total - satData.satisfied : 0,
      percentage: `${
        satData.total ? Math.round(((satData.total - satData.satisfied) / satData.total) * 100) : 0
      }%`,
      state: "negative",
    },
  ];

  return (
    <Tile
      counts={counts}
      filters={filters}
      loading={!satData}
      overall={overall}
      overallHistorical={overallHistorical}
      sign="%"
      title={I18n.t("dashboard_lite.sat_tile_title")}
      tooltip={I18n.t("dashboard_lite.sat_tile_tooltip")}
    />
  );
};

export default SatTile;
