import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { AlertsContentType } from "components/types";
import AlertsWrapper from "../alerts-wrapper/alerts-wrapper";
import AlertIcon from "images/alert-icon.svg";
import { GotoLink } from "components/views/shared";
import I18n from "i18n-js";

const cx = classNames.bind(styles);

const AlertsContent = ({ unresolvedAlertsCount = 0, alertDetailsPath }) => {
  return (
    <AlertsWrapper>
      <div className={cx("wrapper")}>
        <div className={cx("logo")}>
          <img src={AlertIcon} />
        </div>
        <div className={cx("content")}>
          <div className={cx("counts")}>
            <div className={cx("count")}>{getAlertsCount(unresolvedAlertsCount)}</div>
            <div className={cx("status")}>
              <div className={cx("bot")}>{I18n.t("unresolved_alerts")}</div>
            </div>
          </div>
        </div>
        <div className={cx("detail")}>
          <GotoLink url={alertDetailsPath} openInNewTab={true} />
        </div>
      </div>
    </AlertsWrapper>
  );
};

AlertsContent.propTypes = AlertsContentType;

const getAlertsCount = (count) => {
  if (count > 1000) return "1000+";
  else if (count === null || count < 0) return 0;
  else return count;
};

export default AlertsContent;
