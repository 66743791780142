import I18n from "i18n-js";
import React from "react";
import Tile from "./tile";

const GoogleReviewTile = ({ filters, googleReviewData }) => {
  const { rating, ratingHistorical, starCounts } = googleReviewData || {};
  const total = starCounts ? starCounts.reduce((acc, value) => acc + value, 0) : 0;

  const generateReviewCategory = (category, index, total, state) => ({
    category,
    number: starCounts[index],
    percentage: `${total ? Math.round((starCounts[index] / total) * 100) : 0}%`,
    state,
  });

  const counts = googleReviewData && [
    generateReviewCategory("★☆☆☆☆", 0, total, "negative"),
    generateReviewCategory("★★☆☆☆", 1, total, "negative"),
    generateReviewCategory("★★★☆☆", 2, total, "negative"),
    generateReviewCategory("★★★★☆", 3, total, "positive"),
    generateReviewCategory("★★★★★", 4, total, "positive"),
  ];

  return (
    <Tile
      counts={counts}
      filters={filters}
      loading={!googleReviewData}
      overall={rating}
      sign="%"
      hideTrend={ratingHistorical===0}
      isGoogleReview={true}
      overallHistorical={ratingHistorical}
      title={I18n.t("dashboard_lite.google_reviews_title")}
      tooltip={I18n.t("dashboard_lite.google_reviews_tile_tooltip")}
    />
  );
};

export default GoogleReviewTile;
