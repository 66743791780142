import React from "react";
import classNames from "classnames/bind";
import I18n from "i18n-js";
import QuestionIcon from "images/question-icon.svg";
import TooltipHelper from "../../utils/tooltip-helper";
import { LoadingStatus } from "../shared";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const Tile = ({
  counts,
  filters,
  inversePositiveness = false,
  hideTrend = false,
  link,
  loading,
  overall,
  overallHistorical,
  sign = "",
  title,
  tooltip,
  isGoogleReview = false,
  isSaveOurSales = false,
}) => {
  const direction =
    overall > overallHistorical ? "increased" : overall < overallHistorical ? "decreased" : "";
  let difference;
  function delta(current, previous) {
    if (previous === 0 || previous === null) {
      return 0;
    }
    if (current === previous) {
      return 0;
    }
    return Math.round(((current - previous) / previous) * 100);
  }

  if (isGoogleReview) {
    difference = delta(overall,overallHistorical)
  } else {
    difference = Math.abs(overallHistorical - overall);
  }
  const state =
    (direction === "increased" && !inversePositiveness) ||
    (direction === "decreased" && inversePositiveness)
      ? "positive"
      : (direction === "decreased" && !inversePositiveness) ||
        (direction === "increased" && inversePositiveness)
      ? "negative"
      : "";
  const isCurrency = isSaveOurSales;
  const trend = hideTrend
    ? "&nbsp;"
    : I18n.t(`dashboard_lite.trend${direction ? `_${direction}` : ""}`, {
        date_range: I18n.t(`${filters["fltr_daterange"] || "last_week"}`),
        difference: `${isCurrency ? sign:''}${difference}${!isCurrency ? sign:''}`
      });

  return (
    <div
      className={cx("tile", direction, state, link ? "has-link" : "")}
      onClick={() => {
        if (link) {
          location.href = link;
        }
      }}
    >
      <div className={cx("tile-header")}>
        <div className={cx("title-container")}>
          <span className={cx("question-icon")}></span>
          <div className={cx("title")}>{title}</div>
          <TooltipHelper tooltipMessage={tooltip}>
            <img src={QuestionIcon} className={cx("question-icon")} />
          </TooltipHelper>
        </div>
        {loading && (
          <div className={cx("loading")}>
            <LoadingStatus centered={false} />
          </div>
        )}
        {!loading && (
          <>
            <div className={cx("overall")}>{`${
              overall !== null ? `${isCurrency ? sign : ''}${isCurrency && overall > 10000 ? `${Math.round(overall / 1000)}k` : overall}${!isGoogleReview && !isCurrency ? sign : ''}` : I18n.t("not_available")
            }`}</div>
          </>
        )}
        <div
          className={cx("trend")}
          dangerouslySetInnerHTML={{
            __html: !loading ? trend : "&nbsp;",
          }}
        />
      </div>
      <div className={cx("tile-footer")}>
        <div className={cx("tile-category")}>
          <div className={cx("left-section")}>
            {isGoogleReview ? I18n.t("dashboard_lite.rating") : I18n.t("dashboard_lite.category")}
          </div>
          <div className={cx("middle-section")}>#</div>
          <div className={cx("right-section")}>%</div>
        </div>
        <div className={cx(`${isGoogleReview || isSaveOurSales ? "tile-counts-compact" : "tile-counts"}`)}>
          {counts &&
            counts.map((count, index) => (
              <div
                key={index}
                className={cx(
                  `${isGoogleReview || isSaveOurSales ? "tile-count-compact" : "tile-count"}`,
                  count.state
                )}
              >
                <div className={cx("left-section")}>{count.category}</div>
                <div className={cx("middle-section")}>{count.number}</div>
                <div className={cx("right-section")}>{count.percentage}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Tile;
