/* eslint-disable camelcase */
import React from "react";
import DataEntryGrid from "../data-entry-grid/data-entry-grid";

const columns = ["First Name", "Last Name", "Email address"];
const emptyRow = [
  { value: "" },
  { value: "" },
  { value: "" },
  ];

const UserDataGrid = ({ values, name = "user_data_grid", rows = 10, title = "Users" }) => {
  return (
    <DataEntryGrid
      title={title}
      initialValues={values}
      name={name}
      rows={rows}
      columns={columns}
      emptyRow={emptyRow}
      rowSetter={rowSetter}
      dataParser={dataToObject}
      templatePath="/deployment-user-list-sample.csv"
    />
  );
};

const dataToObject = (grid) => {
  return grid
    .map((row, index) => {
      if (index > 0 && row[0].value !== "") {
        return {
          first_name: row[0].value,
          last_name: row[1].value,
          email: row[2].value
        };
      }
    })
    .filter((poc) => poc != null);
};

const rowSetter = (data) => {
  if (
    data.first_name == null ||
    data.last_name == null ||
    data.email_address == null
  ) {
    return null;
  }

  const newRow = [...emptyRow];

  newRow[0] = { ...newRow[0], value: data.first_name };
  newRow[1] = { ...newRow[1], value: data.last_name };
  newRow[2] = { ...newRow[2], value: data.email_address };

  return newRow;
};

export default UserDataGrid;
