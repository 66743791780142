import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const AlertsWrapper = ({ children }) => <div className={styles.wrapper}>{children}</div>;

AlertsWrapper.propTypes = {
  children: PropTypes.node,
};

export default AlertsWrapper;
