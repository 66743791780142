import React from "react";
// import Tweet from "react-tweet";

const Tweets = ({ tweets }) => {
  return (
    <div className="tweets-container">
      {/* {tweets.map((tweet, index) => (
        // <Tweet
        //   key={index}
        //   data={tweet}
        //   linkProps={{ target: "_blank", rel: "noreferrer" }}
        // />
      ))} */}
    </div>
  );
};
export default Tweets;
