import classNames from "classnames";
import I18n from "i18n-js";
import React, { MouseEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Button, Card, Typography } from "stingray-components";

import { ModalContext, ModalProvider } from "../../src/hooks/useModal";
import Checkbox from "../shared/Checkbox";
import {
  POCRestrictionsModalReturnData,
  PointOfContactSelectorModal
} from "./PointOfContactSelectorModal";
import { UserManagementFormFields, countPOCRestrictions } from "./userManagementTypes";

import styles from "./UserManagementForm.module.scss";

type DataAccessCardProps = {
  companyId: string | undefined;
};

const DataAccessCard = ({ companyId }: DataAccessCardProps) => {
  const { setValue, register, control } = useFormContext<UserManagementFormFields>();

  const pocRestrictions = useWatch({
    control,
    name: "pocRestrictions",
  })

  const grantAccessToAllPoc = useWatch({
    control,
    name: "grantAccessToAllPoc"
  })

  const onPOCModalSubmit = (pocRestrictions: POCRestrictionsModalReturnData) => {
    setValue("pocRestrictions", pocRestrictions);
  };

  return (
    <Card className={styles.cardContainer}>
      <div className={styles.cardSubsection}>
        <Typography variant="h6">{I18n.t("user_management.data_access")} </Typography>
        <div className={styles.cardSubsectionSettings}>
          <Checkbox {...register("hidePII")}>{I18n.t("user_management.hide_pii_from_user")}</Checkbox>
          <Checkbox {...register("grantAccessToAllPoc")}>{I18n.t("user_management.grant_access_to_all_pocs")}</Checkbox>
        </div>
      </div>
      <ModalProvider onSubmit={onPOCModalSubmit} initialData={{ companyId, pocRestrictions }}>
        <ModalContext.Consumer>
          {(modalContext) =>
            modalContext && (
              <div
                className={classNames(styles.cardSubsection, styles.feature, grantAccessToAllPoc ? styles.featureDisabled : styles.featureEnabled)}
              >
                <div className={styles.buttonHeaderContainer}>
                  <Typography variant="h6">{I18n.t("user_management.points_of_contact")}</Typography>
                  <Button
                    disabled={grantAccessToAllPoc || !companyId}
                    size="medium"
                    onClick={(e: MouseEvent) => {
                      e.preventDefault();
                      modalContext.toggleOpen();
                    }}
                  >
                    <Typography variant="h8">{I18n.t("user_management.configure")}</Typography>
                  </Button>
                </div>
                <div className={styles.cardSubsectionSettings}>
                  {pocRestrictions?.length > 0 && (
                    <Typography variant="h9">
                      {I18n.t("user_management.restricted_to_locations", {
                        locations: countPOCRestrictions(pocRestrictions)
                      })}
                    </Typography>
                  )}
                </div>
                {modalContext.open && <PointOfContactSelectorModal />}
              </div>
            )
          }
        </ModalContext.Consumer>
      </ModalProvider>
    </Card>
  );
};

export default DataAccessCard;
