import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

//This is dummy wrapper container for engagement component to render corectly in center
const DummyWrapper = (props) => <div className={styles.wrapper}>{props.children}</div>;

DummyWrapper.propTypes = {
  children: PropTypes.node,
};

export default DummyWrapper;
