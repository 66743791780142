import React, { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

import Overlay from "react-bootstrap/lib/Overlay";
import Tooltip from "react-bootstrap/lib/Tooltip";
import IconButton from "../live_conversations/IconButton";

import I18n from "i18n-js";

export const CopyToClipboardButton = ({ textToCopy, size }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    let timerId;
    if (showTooltip) timerId = setTimeout(() => setShowTooltip(false), 1000);

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [showTooltip]);

  const tooltip = (
    <Tooltip id="copy-to-clipboard-tooltip">
      { I18n.t("live_conversations.copied") }
    </Tooltip>
  );

  return (
    <>
      <div ref={target}>
        <IconButton
          disabled={!textToCopy}
          title={I18n.t("live_conversations.copy_to_clipboard")}
          onClick={(e) => {
            e.preventDefault();

            navigator.clipboard.writeText(textToCopy);
            setShowTooltip(true);
          }}>
          <FontAwesomeIcon icon={faCopy} size={size || "1x"} />
        </IconButton>
      </div>
      <Overlay target={target.current} placement="top" show={showTooltip}>
        { tooltip }
      </Overlay>
    </>
  );
}
