import React from "react";
import hasLoader from "../hoc/has-loader";
import { PrettyLoader } from "../../views/shared";
import NPSTrendingContent from "../../views/tile-nps-trending";
import { NPSTrendingContainerType } from "../../types";
import { trimArrayLeft } from "../../utils/general-helper";
import I18n from "i18n-js";

class NPSTrendingContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;

    const data = this.prepareData(componentData);

    return (
      <PrettyLoader status={status} size="medium">
        <NPSTrendingContent {...data} />
      </PrettyLoader>
    );
  }

  prepareData(data) {
    const colors = ["#3D75F2", "#0BD1E5"];
    const { categories, series = [], questionType, interval } = data;

    const leftTrimCounts = [];

    //adding color to series from the color stack
    //so far we have only 2 colors (and this graph should have only two lines)
    for (let i = 0; i < series.length; i++) {
      series[i].color = colors[i];

      //checking smallest amount of trim necessary
      const leftTrim = this.getCountOfLeftNullValues(series[i].data);
      leftTrimCounts.push(leftTrim);
    }

    const leftTrimCount = Math.min(...leftTrimCounts);
    //trim series and categories based on leftTrimCount

    //deep copy of nested array so we don't mutate series array
    const trimSeries = JSON.parse(JSON.stringify(series));

    for (let i = 0; i < trimSeries.length; i++) {
      trimSeries[i].data = trimArrayLeft(trimSeries[i].data, leftTrimCount);
    }

    const trimCategories = trimArrayLeft(categories, leftTrimCount);
    //trim end

    let xTitle = I18n.t("nps");
    let yTitle = "Month";
    let headerText = I18n.t("nps_trending");

    if (questionType?.toLowerCase() !== "nps") {
      xTitle = I18n.t("satisfaction");
      headerText = I18n.t("satisfaction_trending");
    }

    if (interval) {
      yTitle = this.capitalizeFirstLetter(I18n.t(interval, { defaultValue: interval }));
    }

    const result = {
      chartData: {
        categories: trimCategories,
        series: trimSeries,
        yAxisTitle: xTitle,
        xAxisTitle: yTitle,
      },
      headerText,
    };

    return result;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getCountOfLeftNullValues(array) {
    let result = 0;

    for (let i = 0; i < array.length; i++) {
      const current = array[i];

      const { y } = current;
      if (y === null) {
        result++;
      } else {
        break;
      }
    }

    return result;
  }
}

NPSTrendingContainer.propTypes = NPSTrendingContainerType;

export default hasLoader(NPSTrendingContainer);
