import React, { useEffect, useState } from "react";
import { Loading, Typography } from "stingray-components";

import { fetchData } from "../utils/request-helper";
import TooltipHelper from "../utils/tooltip-helper";

import I18n from "i18n-js";
import styles from "./MetricDrivers.module.scss";
import classnames from "classnames";

interface MetricDriversProps {
  url?: string;
}

interface MetricData {
  current_metric: MetricDriversData[];
  title: string;
}

interface MetricDriversData {
  type: "positive" | "negative";
  topic_name: string;
}

export const MetricDrivers: React.FC<MetricDriversProps> = ({ url }) => {
  useEffect(() => {
    if (url) {
      updateMetricData();
    }
  }, [url]);

  const updateMetricData = async () => {
    setMetricData(undefined);

    const data = await fetchData(url);

    setMetricData(data);
  }

  const [metricData, setMetricData] = useState<MetricData>();

  return (
    <div className={classnames(styles.metricDrivers, { [styles.centered]: !metricData })}>
      {!metricData && <Loading size="50px" />}
      {metricData && <>
        <Typography variant="h4">{ `${metricData?.title}` }</Typography>
        <ul className={styles.driverList}>
          { metricData.current_metric.length === 0 && <>
            <Typography className={styles.notEnoughData} variant="h6">{I18n.t("not_enough_data")}</Typography>
          </> }
          { metricData.current_metric.map(({ type, topic_name }) => (
            <li key={topic_name} className={classnames(styles.driver, styles[type])}>
              <TooltipHelper tooltipMessage={topic_name}>
                <Typography variant="h7" css={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", maxWidth: "90%" }}>{ topic_name }</Typography>
              </TooltipHelper>
            </li>
          )) }
        </ul>
      </>}
    </div>
  );
}
