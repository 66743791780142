translateTopicName = (topicName) ->
  # When it is available, also translate the tag name
  tag_name_index = topicName.indexOf("(")
  if tag_name_index != -1
    tag_name = topicName[tag_name_index + 1..-2]
    translated_tag_name = I18n.t("topic_tags.#{tag_name}", defaultValue: tag_name)

    topicName = topicName[0...tag_name_index].trim()
    translated_topic_name = I18n.t("topic_names.#{topicName}", defaultValue: topicName)

    return "#{translated_topic_name} (#{translated_tag_name})"
  else
    return I18n.t("topic_names.#{topicName}", defaultValue: topicName)

module.exports = {translateTopicName}
