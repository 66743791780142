import merge from "deepmerge";

import { isArrayEmpty } from "./general-helper";

//Custom method for Combining Arrays with deepmerge package
//https://github.com/TehShrike/deepmerge
//usage: merge(obj1,obj2, { arrayMerge: combineMerge })
const emptyTarget = (value) => (Array.isArray(value) ? [] : {});
const clone = (value, options) => merge(emptyTarget(value), value, options);

const combineMerge = (target, source, options) => {
  const destination = target.slice();

  source.forEach((e, i) => {
    if (typeof destination[i] === "undefined") {
      const cloneRequested = options.clone !== false;
      const shouldClone = cloneRequested && options.isMergeableObject(e);
      destination[i] = shouldClone ? clone(e, options) : e;
    } else if (options.isMergeableObject(e)) {
      destination[i] = merge(target[i], e, options);
    } else if (target.indexOf(e) === -1) {
      destination.push(e);
    }
  });

  return destination;
};

//99 percent in Highchart creates full filled circle -> so we need to show around 93%
export const getDisplayPercentage = (percentage) => {
  if (percentage > 93 && percentage < 100) {
    return 93;
  } else if (percentage < 5) {
    return 0;
  }

  return percentage;
};

export const extendChartConfig = (defaultOptions, options = null) => {
  if (defaultOptions) {
    if (Array.isArray(defaultOptions)) {
      if (options && Array.isArray(options)) {
        return merge(defaultOptions, options, { arrayMerge: combineMerge });
      }

      return defaultOptions;
    }

    const translatedDefaultOptions = { ...defaultOptions };

    if (defaultOptions.tooltip?.valueSuffix) {
      translatedDefaultOptions.tooltip.valueSuffix = I18n.t(defaultOptions.tooltip.valueSuffix, {
        defaultValue: defaultOptions.tooltip.valueSuffix,
      });
    }

    if (defaultOptions.series) {
      translatedDefaultOptions.series = defaultOptions.series.map((o) => {
        const translatedSeriesItem = { ...o };

        if (o.name) {
          translatedSeriesItem.name = I18n.t(o.name, { defaultValue: o.name });
        }

        if (o.tooltip?.valueSuffix) {
          translatedSeriesItem.tooltip.valueSuffix = I18n.t(o.tooltip.valueSuffix, {
            defaultValue: o.tooltip.valueSuffix,
          });
        }

        return translatedSeriesItem;
      });
    }

    if (options) {
      return merge(translatedDefaultOptions, options, { arrayMerge: combineMerge });
    }

    return translatedDefaultOptions;
  }

  return null;
};

//structuring each parameter as one object with data attribute combining it into array
//Example:
//input - (10,20)
//output - [{data:10},{data:20}]
export const modelSeriesArray = (...series) => {
  return series.map((series) => ({
    data: series,
  }));
};

//check if at least one series object has data attribute
export const chartSeriesHasData = (series = []) => {
  return !!series.find((s) => !isArrayEmpty(s.data));
};
