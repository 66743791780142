import React from "react";
import I18n from "i18n-js";

import styles from "./styles.module.scss";

import TrendIcon from "../../shared/trend-icon/trend-icon";

import { MultitableTableType } from "../../../types";

//Represents one table in MultitableContent
const MultitableTable = ({ rows = [], scoreLabel = "NPS" }) => {
  return (
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr>
          <th>{I18n.t("rank")}</th>
          <th colSpan={3}>{I18n.t("location")}</th>
          <th>{scoreLabel}</th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {rows.map((row) => {
          return (
            <tr key={row.rank}>
              <td className={styles.first}>{row.rank}</td>
              <td colSpan={3} title={row.name} className={styles.truncated}>
                {row.name}
              </td>
              <td className={styles.score}>
                {row.score != null && <div className={styles.value}>{row.score.toFixed(1)}</div>}
                <div className={styles.icon}>{row.trend && <TrendIcon icon={row.trend} />}</div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

MultitableTable.propTypes = MultitableTableType;

export default MultitableTable;
