import I18n from 'i18n-js';
import React, { MouseEvent } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { Button, Card, Loading, Typography } from "stingray-components";

import SelectInput, { SelectOption } from "../shared/Select";

import styles from "./CompanyFeaturesForm.module.scss";

export type CompanyFeaturesFormFields = {
    companyId: number;
    features: SelectOption[];
};

export type Feature = {
    id: number;
    key: string;
    needs_company_feature: boolean;
}

export type Company = {
    id: number;
    name: string;
    features: Feature[];
}

type CompanyFeaturesFormProps = {
    availableFeatures: SelectOption[];
    company: Company;
    onSubmit: (data: CompanyFeaturesFormFields) => void;
    isSubmitting: boolean;
    onCancel: (e: MouseEvent) => void;
}

const CompanyFeaturesForm = ({ availableFeatures, company, onSubmit, onCancel, isSubmitting }: CompanyFeaturesFormProps) => {
    const methods = useForm<CompanyFeaturesFormFields>({
        defaultValues: {
            companyId: company.id,
            features: company.features
                .filter((feature) => feature.needs_company_feature)
                .map((feature) => ({ value: feature.key, label: feature.key })),
        },
    });

    const { handleSubmit } = methods;

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.sectionContainer}>
                    <Typography variant="h3" css={{ fontWeight: 500 }}>{company.name}</Typography>
                    <Card className={styles.verticalCard}>
                        {availableFeatures && <SelectInput isMulti options={availableFeatures} name="features">Features</SelectInput>}
                    </Card>
                    <div className={styles.submitSectionContainer}>
                        <Button
                            size="large"
                            width="100px"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                onCancel(e);
                            }}>
                            {I18n.t("cancel")}
                        </Button>
                        <Button disabled={isSubmitting}
                            size="large"
                            width="100px"
                            onClick={handleSubmit(onSubmit)}>
                            {isSubmitting ? <Loading size="25px" /> : I18n.t("save")}
                        </Button>
                    </div>
                </div>
            </form>
        </FormProvider>);
}

export default CompanyFeaturesForm;
