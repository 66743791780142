import React from "react";
import { Loading, Typography } from "stingray-components";

import { DateRangeType } from "./ExecutiveDashboard";
import { MetricData } from "./Metric";
import { formatMetric, formatTrend } from "./metricUtils";

import styles from "./MetricTile.module.scss";

interface MetricTileProps {
  dateRange: DateRangeType;
  metricData?: MetricData;
  asPercentage: boolean;
}

export const MetricTile: React.FC<MetricTileProps> = ({ dateRange, metricData, asPercentage  }) => {
  return (
    <div className={styles.metricTile}>
      { !metricData && <Loading size="50px" /> }
      { metricData && (
        <>
          <Typography variant="h6">{ metricData.title }</Typography>
          <Typography variant="h2" className={styles.score}>{ formatMetric(metricData.current_metric, asPercentage) }</Typography>
          <Typography variant="h9" dangerouslySetInnerHTML={{ __html: formatTrend(metricData.current_metric, metricData.previous_metric, asPercentage, dateRange) }} />
        </>
      ) }
    </div>
  );
}
