# inspired by https://jsplumbtoolkit.com/community/demo/statemachine/
jQuery ->
  if $('#tree').length > 0

    questionDetail = $('#tree').data('questionDetail')
    questionNodeLeftMultiplier = "50"
    questionNodeWidth = "22em"

    if questionDetail == "position"
      questionNodeLeftMultiplier = "18"
      questionNodeWidth = "5em"

    jsPlumb.ready ->
      # setup some defaults for jsPlumb.
      instance = jsPlumb.getInstance(
        Endpoint: [
          'Dot'
          { radius: 4 }
        ]
        Connector: ['Straight', {stub: 30, gap: 0}] 
        HoverPaintStyle:
          stroke: '#49DDDB'
          strokeWidth: 2
        ConnectionOverlays: [
          [
            'Arrow'
            {
              location: 1
              id: 'arrow'
              length: 14
              foldback: 0.8
            }
          ]
          [
            'Label'
            {
              label: ''
              id: 'label'
              cssClass: 'aLabel'
            }
          ]
        ]
        Container: 'tree')

      instance.registerConnectionType 'basic',
        anchor: 'Continuous'
        paintStyle:{ stroke:"#73e364", strokeWidth: 2 }

      window.jsp = instance
      canvas = document.getElementById('tree')
      questions = jsPlumb.getSelector('.survey-tree .w')

      # bind a connection listener. note that the parameter passed to this function contains more than
      # just the new connection - see the documentation for a full list of what is included in 'info'.
      # this listener sets the connection's internal
      # id as the label overlay's text.
      # instance.bind 'connection', (info) ->
        # console.log info
        # if info.content?
          # info.connection.getOverlay('label').setLabel info.content
        # return

      # initialise element as connection targets and source.
      initNode = (el) ->
        # initialise draggable elements.
        instance.draggable el,
          stop: (e) -> 
            $("##{e.el.id}").addClass 'user-position'
        instance.makeSource el,
          filter: '.ep'
          anchor: 'Continuous'
          connectorStyle:
            stroke: '#F0F0F0'
            strokeWidth: 2
            outlineStroke: 'transparent'
            outlineWidth: 4
          connectionType: 'basic'
          extract: 'action': 'the-action'
        return

      newNode = (x, y) ->
        d = document.createElement('div')
        id = jsPlumbUtil.uuid()
        d.className = 'w'
        d.id = id
        d.innerHTML = '<div class="tree-content">' + id.substring(0, 7) + '</div><div class="ep"></div>'
        d.style.left = x + 'px'
        d.style.top = y + 'px'
        instance.getContainer().appendChild d
        initNode d
        d

      # suspend drawing and initialise.
      instance.batch ->
        for question in questions
          initNode question, true
        # and finally, make a few connections
        $questions = $('#tree .w')
        $questions.each ->
          $question = $(@)
          qdata = $question.data 'qdata'
          # The old way of doing things
          # branch = qdata['branch']
          # number_within_branch = qdata['position_within_branch']
          # $question.css
          #   left: "#{2 + (branch - 1) * questionNodeLeftMultiplier}em"
          #   top: "#{(number_within_branch - 1) * 16}em"
          #   width: questionNodeWidth
          width = qdata['width']
          depth = qdata['depth']
          tree_position = qdata['tree_position']
          if tree_position?
            $question.css
              left: tree_position.left
              top: tree_position.top
            $question.addClass "user-position"
          else
            $question.css
              left: "#{2 + width * questionNodeLeftMultiplier}em"
              top: "#{depth * 16}em"
          if Object.keys(qdata["inbound_branches"]).length < 1 || Object.keys(qdata["outbound_branches"]).length < 1
            $question.addClass "circle"          
          else if qdata["answer_type"] == "Statement"
            $question.addClass "diamond"
          else 
            $question.css
              width: questionNodeWidth
          question_next_question = $question.data 'next-question'
          if question_next_question?
            connection = instance.connect
              source: $question.attr('id')
              target: question_next_question
              type: 'basic'
            connection.getOverlay('label').setLabel '*'
          $answers = $question.find('.tree-answer')
          if $answers.length
            $answers.each ->
              $answer = $(@)
              answer_next_question = $answer.data 'next-question'
              answer_next_question = question_next_question if !answer_next_question? && question_next_question?
              next_qdata = $("##{answer_next_question}").data 'qdata'
              if answer_next_question?
                connection = instance.connect
                  source: $question.attr('id')
                  target: answer_next_question
                  type: 'basic'
                  # overlays: [
                    # [
                      # 'Answer',
                        # cssClass: 'aLabel'
                        # id: "label_#{$answer.attr('id')}"
                        # label: $answer.html()
                    # ]
                  # ]
                connection.getOverlay('label').setLabel $answer.html()
        return
      jsPlumb.fire 'jsPlumbDemoLoaded', instance
      return
  $('#save-btn').click ->
    positions = {}
    questions = $('#tree .w')
    for question in questions
      qdata = $("##{question.id}").data 'qdata'
      style = $("##{question.id}").attr "style"
      styles = style.split "; "
      left = styles[0].split(" ")[1].replace(";", "")
      top = styles[1].split(" ")[1].replace(";", "")
      positions[qdata.id] = {left: left, top: top}
    url = $("#tree").data 'save-url'
    $.ajax
      type: "POST"
      url: url
      data: { positions: positions }
      success: (response) ->
        location.reload()
      error: (xhr, status, error) ->
        alert xhr.responseText

  $('#reset-btn').click ->
    url = $("#tree").data 'reset-url'
    $.ajax
      type: "POST"
      url: url
      data: {}
      success: (response) ->
        location.reload()
      error: (xhr, status, error) ->
        alert xhr.responseText

  $('#tree div').click ->
    data = $(this).data 'qdata'
    if data?
      $('.question-content').html("")
      $('.question-drawer').removeClass("hidden")
      $('.question-title').text("Question #{data.position}")
      Object.keys(data).forEach((key) -> 
        value = if typeof data[key] == 'object' then JSON.stringify(data[key]) else data[key]
        property = "<label for='#{key}'>#{key}</label><p>#{value}</p>"
        $('.question-content').append(property);
      )

  $('.close-drawer').click ->
    $('.question-drawer').addClass("hidden")
