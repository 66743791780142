Highcharts = require('highcharts')

jQuery ->

  renderChartRankingDetails = (element) ->
    ret = "not set"
    try
      data = element.data 'rankings'
      medianSatisfaction = data.median_satisfaction.value
      medianSampleSize = data.median_sample_size.value


      # the series to display
      # we add an initial fake entry to control scaling of bubble sizes
      series = [{
        x: 200
        y: 200
        z: 100
        poc_name: 'fake entry'
      }]

      minSampleSize = data.met_quota[0]["c_answered_nps"]
      maxSampleSize = data.met_quota[0]["c_answered_nps"]

      temp_sat = parseFloat data.met_quota[0]["satisfaction"]
      temp_sat = Math.round(temp_sat*Math.pow(10,2))/Math.pow(10,2)
      minSatisfaction = temp_sat
      maxSatisfaction = temp_sat

      for point in data.met_quota.concat( data.under_quota )
        sat = parseFloat  point[ "satisfaction" ]
        sat = Math.round(sat*Math.pow(10,2))/Math.pow(10,2)

        z = point[ "can_see_poc" ] * 5

        if point[ "c_answered_nps" ] < medianSampleSize && point[ "c_answered_nps" ] >= data.min_sample_size
          color = '#a5a5a5'
          z = 2
        else if point[ "c_answered_nps" ] < data.min_sample_size
          color = '#a5a5a5'
          z = 0
        else if sat < medianSatisfaction
          color = '#FC7256'
        else
          color = '#22A875'

        point_poc_name = point[ "poc_name" ]
        if point[ "can_see_poc" ] == 0
          point_poc_name = 'Name Hidden'

        series.push {
          x: point[ "c_answered_nps" ]
          y: sat
          z: z
          color: color
          poc_name: point_poc_name
        } if point[ "c_answered_nps" ] > 0
        minSampleSize = point[ "c_answered_nps" ] if point[ "c_answered_nps" ] < minSampleSize
        maxSampleSize = point[ "c_answered_nps" ] if point[ "c_answered_nps" ] > maxSampleSize
        minSatisfaction = sat if sat < minSatisfaction
        maxSatisfaction = sat if sat > maxSatisfaction

      minSampleSize = minSampleSize - 1
      maxSampleSize = maxSampleSize + 1
      minSatisfaction = Math.floor( minSatisfaction ) - 5
      maxSatisfaction = Math.floor( maxSatisfaction ) + 5

      ret = Highcharts.chart element[0],
        chart:
          type: 'bubble'
          plotBorderWidth: 1
          zoomType: 'xy'
          backgroundColor: "#FCFCFC"
          style:
            fontWeight: 300
          spacingLeft: 0
          spacingRight: 0
          marginTop: 50
        credits: enabled: false
        legend: enabled: false
        title: text: null
        subtitle: text: null
        xAxis:
          gridLineWidth: 1
          title: text: 'Sample Size'
          min: minSampleSize
          max: maxSampleSize
          allowDecimals: false
          plotBands: [ {
            # color: '#E4E4E4'
            from: 0
            to: data.min_sample_size
            label:
              rotation: 0
              text: 'Insuficient<br/>Responses'
              verticalAlign: 'top'
              style:
                fontWeight: 'bold'
              y: -20
          }, {
            # color: '#FCFCFC'
            from: data.min_sample_size
            to: medianSampleSize
            label:
              rotation: 0
              text: 'Low<br/>Responses'
              verticalAlign: 'top'
              style:
                fontWeight: 'bold'
              y: -20
          }, {
            # color: '#FCFCFC'
            from: medianSampleSize
            to: maxSampleSize
            label:
              rotation: 0
              text: 'High<br/>Responses'
              verticalAlign: 'top'
              style:
                fontWeight: 'bold'
              y: -20
          }]
          plotLines: [ {
            color: 'red'
            dashStyle: 'dot'
            width: 2
            value: medianSampleSize
            label:
              rotation: 90
              y: 15
              style: fontStyle: 'italic'
              text: 'Median Sample Size ('+medianSampleSize+')'
            zIndex: 3
          }, {
            color: 'red'
            dashStyle: 'dot'
            width: 2
            value: data.min_sample_size
            label:
              rotation: 90
              y: 15
              style: fontStyle: 'italic'
              text: 'Minimum Sample Size ('+data.min_sample_size+')'
            zIndex: 3
          }]
        yAxis:
          startOnTick: false
          endOnTick: false
          title: text: 'Satisfaction (%)'
          maxPadding: 0.2
          min: minSatisfaction
          max: maxSatisfaction
          plotLines: [ {
            color: 'red'
            dashStyle: 'dot'
            width: 2
            value: medianSatisfaction
            label:
              rotation: 0
              y: 15
              style: fontStyle: 'italic'
              text: 'Median Satisfaction ('+medianSatisfaction+'%)'
            zIndex: 3
          } ]
        zAxis:
          floor: 0
          ceiling: 125
        tooltip:
          useHTML: true
          headerFormat: '<table>'
          pointFormat: '<tr><th colspan="2"><h3>{point.poc_name}</h3></th></tr>' + '<tr><th>Sample Size:</th><td>{point.x}</td></tr>' + '<tr><th>Satisfaction:</th><td>{point.y}%</td></tr>'
          footerFormat: '</table>'
          followPointer: true
        plotOptions: series: dataLabels:
          enabled: true
          format: '{point.name}'
        series: [ { data: series
        } ]
    catch e
      alert( "renderChartRankingDetails exception: " + e )
    finally
      # alert( "renderChartRankingDetails ret =" + ret )

  # ranking details graph
  $rankingDetails = $('.js-ranking-details-graph')
  renderChartRankingDetails $rankingDetails if $rankingDetails.length

  $('.ranking-details-table').DataTable( {
    columnDefs: [
      { targets: 0, className: "dt-head-left"},
      { targets: "_all", className: "dt-head-center"},
    ]
    order: [[ 1, "asc" ]]
    paging: true
    pagingType: "simple_numbers"
    lengthChange: false
    pageLength: 50
    searching: false
  } )

