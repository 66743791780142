import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import { ConversationBubbleType } from "../../../types";

const ConversationBubble = ({ text, info = [] }) => {
  return (
    <div className={cx("bubble-container")}>
      <div className={cx("text")}>{text}</div>
      {info && (
        <div className={cx("info")}>
          {info.map((infoText, index, array) => {
            if (array.length > index + 1) {
              return (
                <span key={index}>
                  <span className={cx("info-text")}>{infoText}</span>
                  <span className={cx("dot")}>·</span>
                </span>
              );
            } else {
              return (
                <span key={index} className={cx("info-text")}>
                  {infoText}
                </span>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

ConversationBubble.propTypes = ConversationBubbleType;

export default ConversationBubble;
