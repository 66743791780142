import React, { PropsWithChildren } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography } from "stingray-components";
import styles from './FilePicker.module.scss';

export type FilePickerProps = PropsWithChildren<{
    name: string;
    isRequired?: boolean;
    accept?: string; // To specify the file types that the input should accept
}>;

const FilePicker = ({ children, name, isRequired = true, accept }: FilePickerProps) => {
    const { control } = useFormContext();
    return (
        <Controller
            control={control}
            name={name}
            rules={{ required: isRequired, validate: (value) => !isRequired || (value && value.length > 0) }}
            render={({ field: { onChange, onBlur, ref } }) => (
                <div className={styles.filePickerContainer}><Typography variant="h7">{children}</Typography><input
                        type="file"
                        onChange={(e) => onChange(e.target.files)}
                        onBlur={onBlur}
                        ref={ref}
                        accept={accept}
                        className={styles.fileInput}
                    /></div>
            )}
        />
    );
};
export default FilePicker;
