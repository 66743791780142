$ ->
  { loadJSONComponentAndSchema } = require('../jsoneditor.coffee')
  { IsJsonString } = require('../utils/string_util')
  $("#js-admin-json-dashboard-save, #js-admin-json-dashboard-create").on 'click', ->
    json = $("#json_dashboard_json_definition").val()
    try 
      JSON.parse(json)
    catch e
      alert("The JSON entered is invalid, please review and fix before saving.")
      return false
    return true
  $('.json-dashboard-editor').find('.json-dashboard-name-editor').find('.json-editor').each (i) ->
    $dashboardName = $(this)
    # Prevent race condition between JS files: check if the json editor was already loaded, or wait for load event
    if $dashboardName[0] && $dashboardName[0].jsoneditor
      loadJSONComponentAndSchema($dashboardName, ".js-json-dashboard-name-field:eq(#{i})", "smart_strings")
      $dashboardName[0].jsoneditor.set({ default: "" }) if $.isEmptyObject($dashboardName[0].jsoneditor.get())
    else
      $dashboardName.on 'json-editor-load', ->
        loadJSONComponentAndSchema($dashboardName, ".js-json-dashboard-name-field:eq(#{i})", "smart_strings")
        $dashboardName[0].jsoneditor.set({ default: "" }) if $.isEmptyObject($dashboardName[0].jsoneditor.get())

  
  $('.json-dashboard-editor').on 'keyup', ->
    if $(this).find('.input-error').length > 0
      $('#js-admin-json-dashboard-save, #js-admin-json-dashboard-create').prop('disabled', true)
    else
      $('#js-admin-json-dashboard-save, #js-admin-json-dashboard-create').prop('disabled', false)