import React from "react";
import { Button, Card, Dialog, DialogTrigger, DialogContent, Typography } from "stingray-components";

import { Survey } from "./SurveyCategoryPanel";

import styles from "./SurveySelectorDialog.module.scss";
import I18n from "i18n-js";

interface SurveySelectorDialogProps {
  parentElem: HTMLElement | null;
  surveys: Survey[];
  viewMoreUrl?: string;
}

export const SurveySelectorDialog: React.FC<SurveySelectorDialogProps> = ({ parentElem, surveys, viewMoreUrl }) => {
  return (
    <Dialog>
      <DialogTrigger>
        <Button size="large">
          <Typography variant="h8">{ I18n.t("select_a_survey") }</Typography>
        </Button>
      </DialogTrigger>
      <DialogContent closeIcon="close" dialogParent={parentElem}>
        <Typography variant="h6">{ I18n.t("select_a_survey") }</Typography>
        <div className={styles.surveyList}>
        { surveys.map(({ id, name }: Survey) => (
          <Card key={id}>
            <div className={styles.surveyListItem}>
              <Typography variant="h6">{ name }</Typography>
              { viewMoreUrl && <>
                <a className={styles.surveyLink} href={viewMoreUrl.replace("%{survey_id}", id.toString())} target="_blank">
                  <Button>{ I18n.t("view_more") }</Button>
                </a>
              </> }
            </div>
          </Card>
        )) }
        </div>
      </DialogContent>
    </Dialog>
  );
}
