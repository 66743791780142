import React from "react";
import I18n from "i18n-js";

import { PrettyLoader } from "../../views/shared";

import hasLoader from "../hoc/has-loader";

import { getTrendIconFromDelta, isArrayEmpty } from "../../utils/general-helper";

import MultitableContent from "../../views/tile-multitable";

import { TopAndBottomPerformanceContainerType } from "../../types";

class TopAndBottomPerformanceContainer extends React.Component {
  render() {
    const { status } = this.props;

    return (
      <PrettyLoader status={status} size="medium">
        {this.contentToRender()}
      </PrettyLoader>
    );
  }

  contentToRender() {
    const { componentData, disableViewMore } = this.props;
    const {
      topPerformers,
      bottomPerformers,
      medianValue,
      detailsPath,
      questionType,
      additionalText,
    } = componentData;

    const isOnlyTopPerformers = isArrayEmpty(bottomPerformers) && !isArrayEmpty(topPerformers);

    const scoreLabel = questionType === "NPS" ? "NPS" : "Satisfaction";
    const viewMore = disableViewMore ? null : detailsPath;

    if (isOnlyTopPerformers) {
      return (
        <MultitableContent
          headerText={I18n.t("top_performers")}
          tableLeft={this.prepareTableData("", topPerformers)}
          median={medianValue}
          detailsPath={viewMore}
          scoreLabel={scoreLabel}
          additionalText={additionalText}
        />
      );
    } else {
      return (
        <MultitableContent
          headerText={I18n.t("top_bottom_performers")}
          tableLeft={this.prepareTableData(I18n.t("top_performers"), topPerformers)}
          tableRight={this.prepareTableData(I18n.t("bottom_performers"), bottomPerformers)}
          median={medianValue}
          detailsPath={viewMore}
          scoreLabel={scoreLabel}
          additionalText={additionalText}
        />
      );
    }
  }

  //prepares tableData fro MultitableContent
  prepareTableData(tableCaption, rows) {
    let updatedData =
      rows &&
      rows.map((row) => {
        let delta = !row.scoreHistorical ? 0 : row.score - row.scoreHistorical;

        return {
          ...row,
          trend: getTrendIconFromDelta(delta),
        };
      });

    return {
      data: updatedData,
      title: tableCaption,
    };
  }
}

TopAndBottomPerformanceContainer.propTypes = TopAndBottomPerformanceContainerType;

export default hasLoader(TopAndBottomPerformanceContainer);
