/* eslint-disable camelcase */
import React from "react";
import I18n from "i18n-js";

import { PrettyLoader } from "../../views/shared";

import { TopicMentionsChart } from "../../views/tile-topics";

import hasLoader from "../hoc/has-loader";

const TopicMentionsContainer = ({ status, componentData }) => {
  const { positive_mentions, negative_mentions, neutral_mentions, mixed_mentions } =
    componentData.data || {};
  return (
    <PrettyLoader status={status} size="medium">
      <TopicMentionsChart
        positiveData={positive_mentions || []}
        neutralData={neutral_mentions || []}
        negativeData={negative_mentions || []}
        mixedData={mixed_mentions || []}
        categories={componentData.dates || []}
        headerText={I18n.t("topic_mentions_over_time")}
      />
    </PrettyLoader>
  );
};

export default hasLoader(TopicMentionsContainer);
