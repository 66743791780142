# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

jQuery ->

  renderChartKDAOverallImportance = ($element) ->
    try
      data = $element.data( 'data' )
      medianSatisfaction = $element.data('median-satisfaction')
      medianImportance = $element.data('median-importance')

      minImportance = data[0]["importance"]
      maxImportance = data[0]["importance"]

      minSampleSize = data[0]["sampleSize"]
      maxSampleSize = data[0]["sampleSize"]

      temp_sat = parseFloat data[0]["satisfaction"]
      temp_sat = Math.round(temp_sat*Math.pow(10,2))/Math.pow(10,2)
      minSatisfaction = temp_sat
      maxSatisfaction = temp_sat

      series = []

      for point in data
        importance = Math.abs( parseFloat( point[ "importance" ] ) )
        continue if importance < 0.2
        sat = parseFloat  point[ "satisfaction" ]
        sat = Math.round(sat*Math.pow(10,2))/Math.pow(10,2)
        series.push {
          x: importance
          y: sat
          z: point[ "sampleSize" ]
          pos: point[ "qpos" ]
          name: point[ "qname" ]
          importance: point[ "importance" ]
        }
        minImportance = importance if importance < minImportance
        maxImportance = importance if importance > maxImportance
        minSampleSize = point[ "sampleSize" ] if point[ "sampleSize" ] < minSampleSize
        maxSampleSize = point[ "sampleSize" ] if point[ "sampleSize" ] > maxSampleSize
        minSatisfaction = sat if sat < minSatisfaction
        maxSatisfaction = sat if sat > maxSatisfaction


      minSampleSize = minSampleSize - 1
      maxSampleSize = maxSampleSize + 1
      minImportance = Math.floor( minImportance * Math.pow(10,2) ) / Math.pow(10,2) - 0.1
      maxImportance = Math.ceil( maxImportance * Math.pow(10,2) ) / Math.pow(10,2) + 0.1
      minSatisfaction = Math.floor( minSatisfaction ) - 5
      maxSatisfaction = Math.floor( maxSatisfaction ) + 5

      Highcharts.chart $element[0],
        chart:
          type: 'bubble'
          plotBorderWidth: 1
          zoomType: 'xy'
        legend: enabled: false
        title: text: null
        subtitle: text: null
        xAxis:
          gridLineWidth: 1
          title: text: 'Derived Importance'
          min: minImportance
          max: maxImportance
          plotLines: [ {
            color: 'red'
            dashStyle: 'dot'
            width: 2
            value: medianImportance
            label:
              rotation: 0
              y: 15
              style: fontStyle: 'italic'
              text: 'Median Importance'
            zIndex: 3
          }]
        yAxis:
          startOnTick: false
          endOnTick: false
          title: text: 'Satisfaction (%)'
          maxPadding: 0.2
          min: minSatisfaction
          max: maxSatisfaction
          plotLines: [ {
            color: 'red'
            dashStyle: 'dot'
            width: 2
            value: medianSatisfaction
            label:
              rotation: 0
              y: 15
              style: fontStyle: 'italic'
              text: 'Median Satisfaction'
            zIndex: 3
          } ]
        tooltip:
          useHTML: true
          headerFormat: '<table>'
          pointFormat: '<tr><th colspan="2"><h3>{point.pos}</h3></th></tr>' + '<tr><th colspan="2">{point.name}</th></tr>' + '<tr><th>Importance:</th><td>{point.importance}</td></tr>' + '<tr><th>Satisfaction:</th><td>{point.y}%</td></tr>' + '<tr><th>Sample Size:</th><td>{point.z}</td></tr>'
          footerFormat: '</table>'
          followPointer: true
        plotOptions: series: dataLabels:
          enabled: true
          format: '{point.pos}'
        series: [ { data: series
        } ]
    catch e
      alert( "renderChartOverallImportance exception: " + e )
    finally
      # alert( "renderChartOverallImportance ret =" + ret )


  # analyze overall importance
  $overallImportance = $('.js-kda-overall-importance-graph')
  renderChartKDAOverallImportance $overallImportance if $overallImportance.length

