import React from "react";

import CompletionContent from "../../views/tile-completion";
import { PrettyLoader } from "../../views/shared";

import { getTrendIconFromDelta, getPercentageRate as getRate } from "../../utils/general-helper";

import hasLoader from "../hoc/has-loader";

import { CompletionRateContainerType } from "../../types";

class CompletionRateContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;
    const {
      preferResponded,
      initiated,
      initiatedHistorical,
      completed,
      completedHistorical,
      responded,
      respondedHistorical,
    } = componentData;

    const completedValue = preferResponded ? responded : completed;
    let rate = getRate(initiated, completedValue);

    const completedValueHistorical = preferResponded ? respondedHistorical : completedHistorical;
    let historicRate = getRate(initiatedHistorical, completedValueHistorical);

    let trendingIcon = getTrendIconFromDelta(rate - historicRate);

    return (
      <PrettyLoader status={status}>
        <CompletionContent
          percentage={rate}
          preferResponded={preferResponded}
          initiated={initiated}
          completed={completedValue}
          icon={trendingIcon}
        />
      </PrettyLoader>
    );
  }
}

CompletionRateContainer.propTypes = CompletionRateContainerType;

export default hasLoader(CompletionRateContainer);
