import React from "react";
import classNames from "classnames/bind";
import I18n from "i18n-js";
import DiagonalUpArrow from "images/diagonal-up-arrow.svg";
import { formatDate, formatTopicName, topicLink } from "../../utils/general-helper";
import { LoadingStatus } from "../shared";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const Header = ({
  allCustomersTopicsData,
  filterLocationName,
  filters,
  generalData,
  survey,
  who,
}) => {
  const dateRangeFilters = ["last_day", "last_week", "last_month", "last_quarter"];
  const dateRangeFilterValue = filters["fltr_daterange"];
  const dateRangeFilterChanged = (e) => {
    const newDateRangeFilterValue = e.target.value;
    window.location.href = `?${new URLSearchParams({
      ...(newDateRangeFilterValue && {
        fltr_daterange: newDateRangeFilterValue,
      }),
    })}`;
  };
  const locale = I18n.locale;
  const mostPositiveIncreaseTopics = allCustomersTopicsData &&
    allCustomersTopicsData.mostPositiveIncrease &&
    allCustomersTopicsData.mostPositiveIncrease
      .filter((topic) => topic.positive.diff > 0)
  const timeZone = survey && survey.time_zone;

  return (
    <div className={cx("header")}>
      <div className={cx("container")}>
        <div
          className={cx("welcome-back")}
          dangerouslySetInnerHTML={{
            __html: I18n.t("dashboard_lite.welcome_back", {
              name: who.first_name,
            }),
          }}
        />
        <div className={cx("survey-container")}>
          {generalData !== null && !generalData && (
            <div className={cx("loading")}>
              <LoadingStatus centered={true} />
            </div>
          )}
          <span
            className={cx("survey-reached")}
            dangerouslySetInnerHTML={{
              __html:
                generalData && generalData.initiationsData
                  ? I18n.t("dashboard_lite.survey_reached", {
                      count: generalData.initiationsData.initiations,
                    })
                  : "&nbsp;",
            }}
          />
          {(generalData === null || generalData) && (
            <span>
              <select
                className={cx("date-range-filter")}
                defaultValue={dateRangeFilterValue}
                onChange={(e) => dateRangeFilterChanged(e)}
              >
                {dateRangeFilters.map((value) => (
                  <option key={value} className={cx("date-range-option")} value={value}>
                    {I18n.t(value)}
                  </option>
                ))}
              </select>
            </span>
          )}
        </div>
        <div className={cx("topic-container")}>
          <>
            {allCustomersTopicsData !== null && !allCustomersTopicsData && (
              <div className={cx("loading")}>
                <LoadingStatus centered={true} />
              </div>
            )}
            {mostPositiveIncreaseTopics && !!mostPositiveIncreaseTopics.length && filterLocationName && (
              <div className={cx("actionable-topic-helper")}>
                {I18n.t("dashboard_lite.actionable_topics_helper")}
              </div>
            )}
            {mostPositiveIncreaseTopics && mostPositiveIncreaseTopics.map((topic) => (
              <div key={topic.name} className={cx("topic-row")}>
                <img src={DiagonalUpArrow} className={cx("topic-icon")} />
                  <a href={topicLink({filters, survey, topicName: topic.name})} target="_blank">
                    <div className={cx("topic")}>
                      {I18n.t("dashboard_lite.topic", {
                        topic: formatTopicName(topic.name),
                        count: topic.positive.diff,
                      })}
                    </div>
                  </a>
              </div>
              ))}
          </>
        </div>
      </div>
      <div
        className={cx("filters")}
        dangerouslySetInnerHTML={{
          __html:
            generalData && generalData.globalData
              ? I18n.t(
                  filterLocationName ? "dashboard_lite.filters_with_location" : "dashboard_lite.filters",
                  {
                    survey: survey.name,
                    location: filterLocationName,
                    start_date: formatDate({
                      date: new Date(generalData.globalData.filters["fltr_sd"]),
                      locale,
                      timeZone,
                    }),
                    end_date: formatDate({
                      date: new Date(generalData.globalData.filters["fltr_ed"]),
                      locale,
                      timeZone,
                    }),
                    start_date_historical: formatDate({
                      date: new Date(generalData.globalData.filtersHistorical["fltr_sd"]),
                      locale,
                      timeZone,
                    }),
                    end_date_historical: formatDate({
                      date: new Date(generalData.globalData.filtersHistorical["fltr_ed"]),
                      locale,
                      timeZone,
                    }),
                  }
                )
              : "&nbsp;",
        }}
      />
    </div>
  );
};

export default Header;
