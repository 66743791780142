import React from "react";
import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";
import { extendChartConfig } from "../../utils/chart-helper";
import defaultOptions from "./default-options.json";

const NPSTrendingGraph = ({ segmentInfo, timeIntervals, npsInfo, answerType }) => {
  let extendedOptions = parseData(segmentInfo, timeIntervals);
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={extendedOptions} />
    </div>
  )
}

export default NPSTrendingGraph;

const parseData = (segmentInfo, timeIntervals) => {
  let minNPS = 100;
  let maxNPS = -100;
  segmentInfo.forEach(segment => {
    Object.values(segment.interval_info).forEach(interval => {
      minNPS = minNPS > interval.nps ? interval.nps : minNPS;
      maxNPS = maxNPS < interval.nps ? interval.nps : maxNPS;
    })
  });
  minNPS = Math.max(minNPS - (minNPS % 10), -100);
  maxNPS = Math.min(maxNPS + 10 - (maxNPS % 10), 100);

  const valueOptions = {
    xAxis: [
      {
        categories: timeIntervals
      }
    ],
    yAxis:
    {
      min: minNPS,
      max: maxNPS
    },
    series: segmentInfo.map((segment) => {
      return { name: segment.cohort, data: Object.values(segment.interval_info).map(interval => interval.nps) }
    })
  }
  return extendChartConfig(defaultOptions, valueOptions);
}