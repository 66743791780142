jQuery ->
  JSONEditor = require('jsoneditor')
  _ = require("lodash")

  allowedMenuItems = ["Insert", "Duplicate", "Remove", "Append"]
  $('.json-editor').each((i, container) ->
    extraOptions = $(container).data('options')
    onCreateMenu = (items, node) ->
      insertionMenus = ["Insert", "Append"]
      for menu in insertionMenus
        menuObj = items.find((item) -> item.text == menu)
        if menuObj
          # Look for templates made for this node and add them
          nodeSection = if _.isEmpty(_.get(container.jsoneditor.get(), node.path)) then node.path[node.path.length - 1] else node.path[node.path.length - 2]
          menuObj.submenu = menuObj.submenu.filter((menuItem) -> menuItem.className && (!menuItem.className.match("template_") || (nodeSection && menuItem.className.match("template_" + nodeSection))))
          # If we added templates for the context menu of this node, remove default insertion options
          if menuObj.submenu.find((item) -> item.className && item.className.match("template_"))
            menuObj.submenu = menuObj.submenu.filter((item) -> item.className.match("template_"))
      return items.filter((item) -> item.text && allowedMenuItems.indexOf(item.text) != -1)

    `options = {
        mode: 'tree',
        modes: ['tree', 'code'],
        mainMenuBar: true,
        navigationBar: false,
        enableSort: false,
        enableTransform: false,
        search: false,
        onCreateMenu,
        ...extraOptions
      }`
    if container.jsoneditor
      container.jsoneditor.destroy()
    editor = new JSONEditor(container, options)
    container.jsoneditor = editor
    $(container).trigger("json-editor-load")
  )

loadJSONComponentAndSchema = ($component, fieldClassName, schemaId) ->
  componentHTMLElement = $component[0]
  if componentHTMLElement
    getSchemaUrl = $component.parent().data('schema-path')
    $.ajax
      type: "GET"
      url: getSchemaUrl
      data: {
        schemaId
      }
      success: (config) ->
        if (config.schema)
          componentHTMLElement.jsoneditor.setSchema(config.schema)
        if (config.templates)
          `componentHTMLElement.jsoneditor.options = { ...componentHTMLElement.jsoneditor.options, templates: config.templates }`

      error: (xhr, status, error) ->
        console.error("Failed to load the JSON schema.")
    onJSONChange = () ->
      try
        editorValue = componentHTMLElement.jsoneditor.get()
        isValid = componentHTMLElement.jsoneditor.validateSchema(editorValue)
        if isValid
          $component.removeClass("input-error")
          $(fieldClassName).val(JSON.stringify editorValue)
        else
          $component.addClass("input-error")
      catch e
        $component.addClass("input-error")
      $(componentHTMLElement).trigger("json-editor-change")
    `componentHTMLElement.jsoneditor.options = { ...componentHTMLElement.jsoneditor.options, onChange: onJSONChange }`
    elementValue = $(fieldClassName).val()
    if elementValue
      jsonValue = JSON.parse(elementValue)
    else
      jsonValue = {}
    componentHTMLElement.jsoneditor.set(jsonValue)

initJSONEditor = (container) ->
  JSONEditor = require('jsoneditor')
  _ = require("lodash")
  extraOptions = $(container).data('options')
  onCreateMenu = (items, node) ->
    insertionMenus = ["Insert", "Append"]
    for menu in insertionMenus
      menuObj = items.find((item) -> item.text == menu)
      if menuObj
        # Look for templates made for this node and add them
        nodeSection = if _.isEmpty(_.get(container.jsoneditor.get(), node.path)) then node.path[node.path.length - 1] else node.path[node.path.length - 2]
        menuObj.submenu = menuObj.submenu.filter((menuItem) -> menuItem.className && (!menuItem.className.match("template_") || (nodeSection && menuItem.className.match("template_" + nodeSection))))
        # If we added templates for the context menu of this node, remove default insertion options
        if menuObj.submenu.find((item) -> item.className && item.className.match("template_"))
          menuObj.submenu = menuObj.submenu.filter((item) -> item.className.match("template_"))
    return items.filter((item) -> item.text && allowedMenuItems.indexOf(item.text) != -1)

  `options = {
      mode: 'tree',
      modes: ['tree', 'code'],
      mainMenuBar: true,
      navigationBar: false,
      enableSort: false,
      enableTransform: false,
      search: false,
      onCreateMenu,
      ...extraOptions
    }`
  if container.jsoneditor
    container.jsoneditor.destroy()
  editor = new JSONEditor(container, options)
  container.jsoneditor = editor
  $(container).trigger("json-editor-load")

module.exports = { loadJSONComponentAndSchema, initJSONEditor }