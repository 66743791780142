import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";

import styles from "./UserIcon.module.scss";

const UserIcon = ({ conversation }) => {
  const IMAGE_COLORS = [
    "purple",
    "blue",
    "lightcoral",
    "orange",
    "brown",
  ];

  const color = IMAGE_COLORS[(conversation?.id || 0) % IMAGE_COLORS.length];

  return (
    <div className={styles.userIcon} style={{ backgroundColor: color }}>
      <FontAwesomeIcon className={styles.icon} icon={faUser} />
    </div>
  );
}

export default UserIcon;
