/* eslint-disable camelcase */
import React from "react";
import i18n from "i18n-js";

import { PrettyLoader } from "../../views/shared";

import TopicSegmentationTable from "../../views/tile-topic-segmentation-table/index";

import styles from "../../views/tile-topic-segmentation-table/styles.module.scss";

import hasLoader from "../hoc/has-loader";

import ReactToPrint from "react-to-print";

import classNames from "classnames/bind";

import PropTypes from "prop-types";

const cx = classNames.bind(styles);

// mixpanel event vars
const graphRef = React.createRef();
let inViewTriggered = false;

const handleScroll = () => {
  if (
    graphRef.current.getBoundingClientRect().bottom < window.innerHeight ||
    graphRef.current.getBoundingClientRect().top < 0
  ) {
    if (!inViewTriggered) {
      inViewTriggered = true;
      // mixpanel is defined in the global scale and only on prod, eslint does not like this, but we are catching it with the if
      try {
        // eslint-disable-next-line no-undef
        mixpanel.track("Topic Segmentation viewed");
      } catch (e) {
        if (e instanceof ReferenceError) {
          // ignore it just means were not in prod
        } else {
          console.error(e);
        }
      }
    }
  }
};

class TopicSegmentationTableContainer extends React.Component {
  componentDidMount() {
    if (graphRef.current) {
      window.addEventListener("scroll", handleScroll, true);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", handleScroll);
  }
  render() {
    const { status, componentData } = this.props;
    if (componentData && componentData.error) {
      console.warn(componentData.error || "could not load the topic segmentation data");
    }
    return (
      <div
        className="report-segmentation-graph-container"
        style={{ position: "relative" }}
        ref={graphRef}
      >
        {componentData && componentData.error ? null : (
          <PrettyLoader
            status={status}
            size="medium"
            style={{ margin: "10px 0px" }}
            title={i18n.t("topics_by_response")}
            titleClass="report-title"
          >
            <ReactToPrint
              content={() => this.componentRef}
              trigger={() => (
                <button
                  style={{ position: "absolute", top: "38px", right: "20px" }}
                  className="btn btn-primary"
                >
                  <i className="fas fa-print" />
                </button>
              )}
              bodyClass={cx("print")}
              documentTitle={"Chatter_Topics_By_Response_Report"}
            />
            <TopicSegmentationTable
              ref={(response) => (this.componentRef = response)}
              topicInfo={componentData.curated_topics}
            />
          </PrettyLoader>
        )}
      </div>
    );
  }
}

TopicSegmentationTableContainer.propTypes = {
  status: PropTypes.string,
  componentData: PropTypes.any,
};

export default hasLoader(TopicSegmentationTableContainer);
