jQuery ->
  # VERTICAL ... MENUS
  $('.actions').dropdown()
  .on 'click', (e) ->
    e.stopPropagation()
    @

  # NESTED LINK CLICKED
  $('[data-href] a').on 'click', (e) ->
    unless $(@).hasClass 'js-action'
      e.stopPropagation()
    @

  # ENTIRE ROWS LINKED
  $('body').on 'click', '[data-href]', (e) ->
    unless $(e.originalEvent.target).hasClass 'js-action'
      # Turbolinks.visit $(@).data('href')
      window.location.href = $(@).data('href')
    return
