import React, { useEffect, useState } from "react";

import I18n from "i18n-js";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { screenSize } from "../utils/constants";
import IconButton from "./IconButton";
import Dimmer from "./Dimmer";

import logo from "../../images/chatter-logo-with-text.svg";
import classNames from "classnames";
import styles from "./LiveConversationsMenu.module.scss";

const LiveConversationsMenu = ({ show, setShow, userScreenSize }) => {
  const [isSlidingOut, setIsSlidingOut] = useState(false);

  useEffect(() => {
    if (userScreenSize === screenSize.SMALL) {
      document.getElementById("live-conversations").style.overflowY = show ? "hidden" : "auto";
    }
  }, [show, userScreenSize]);

  if (!show && !isSlidingOut) return <></>;

  return (
    <Dimmer
      isPhasingOut={isSlidingOut}
      setIsPhasingOut={setIsSlidingOut}
      onClick={() => {
        setShow(false);
        setIsSlidingOut(true);
      }}>
      <div
        className={classNames(styles.menu, { [styles.menuSlideOut]: isSlidingOut })}
        onClick={(e) => e.stopPropagation()}>
        <IconButton onClick={() => window.location.href = window.location.origin}>
          <img className={styles.logo} src={logo} />
        </IconButton>
        <button className={styles.logoutButton} type="button" onClick={() => window.location.href = "/logout"}>
          <FontAwesomeIcon icon={faArrowRightFromBracket} />
          { I18n.t("live_conversations.logout") }
        </button>
        { /* Temporarily added empty div to display the Logout button at the center of the menu */ }
        <div />
      </div>
    </Dimmer>
  );
}

export default LiveConversationsMenu;
