jQuery ->

  $('.poc-details-table').DataTable( {
    paging: true
    pagingType: "simple_numbers"
    lengthChange: false
    pageLength: 3
    searching: true
    ordering: false
    # dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" + "<'row'<'col-sm-12'B>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-5'i><'col-sm-7'p>>"
    # dom: 'ift'
    dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" + "<'row'<'col-sm-12'B>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-5'i><'col-sm-7'p>>"
    language:
      infoEmpty: "No " + $('.poc-details-table').data('alias') + " to show"
      info: "<b>Please select a " + $('.poc-details-table').data('alias-singular') + " from this searchable list.</b><br/>Showing _START_ to _END_ of _TOTAL_ " + $('.poc-details-table').data('alias')
  } )

  $('.js-check-mobile-icon').on 'click', ->
    phoneField = $('.js-them-field')
    if phoneField.val() == ""
      return false
    window.open 'https://www.searchbug.com/tools/reverse-phone-lookup.aspx?TYPE=phonerev&TAG=tools&FULLPHONE='+phoneField.val(), '_blank'
    return false

  $('.submit-feedback-form .js-them-field').on 'keypress', (e) ->
    form = $(this).parents( '#submit-feedback-form-tag' )
    if e.keyCode == 13 && $(this).val().trim() != ''
      e.preventDefault()
      return form.dispatchEvent(new Event('submit', { bubbles: true }))
    return
