
trackEvent = (event, segment_event, properties={}) ->
  fetch("/track_event", {
    method: "POST", 
    headers: {
      "x-csrf-token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      "Content-Type": "application/json"
    }, 
    body: JSON.stringify({
        event, 
        segment_event, 
        properties,
    })
  })

trackPageView = (event, page_name, page_subname="", properties={}) ->
  fetch("/track_pageview", {
    method: "POST", 
    headers: {
      "x-csrf-token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      "Content-Type": "application/json"
    }, 
    body: JSON.stringify({
        event, 
        page_name,
        page_subname,
        properties,
    })
  })

module.exports = {trackEvent, trackPageView}
