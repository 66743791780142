jQuery ->

  options =
    paging: true
    searching: true
    info: true
    ordering: true
    order: [[2, "desc"]]
    pageLength: 10

  $("table.js-lists-table").DataTable(options)

  options.order = [0,2]
  $("table.js-contacts-table").DataTable(options)