import React from "react";

import { PrettyLoader } from "../../../views/shared";

import { getTrendIconFromDelta } from "../../../utils/general-helper";

import NPSDetail from "../../../views/report-page/detail";

import hasLoader from "../../hoc/has-loader";

const NPSDetailContainer = ({ status, componentData }) => {
  const { title, value, previousValue, ranks } = componentData;

  let trendingIcon;
  if (value != null && previousValue != null) {
    trendingIcon = getTrendIconFromDelta(value - previousValue);
  }

  return (
    <PrettyLoader status={status} size="medium">
      <NPSDetail
        title={title}
        value={value}
        previousValue={previousValue}
        ranks={ranks}
        trendIcon={trendingIcon}
      />
    </PrettyLoader>
  );
};

export default hasLoader(NPSDetailContainer);
