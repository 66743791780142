Highcharts = require('highcharts')

jQuery ->
  Url = require('url-parse')
  parse = require('url-parse')

  { translateTopicName } = require('./utils/translation_helper')

  $('.topic-trending-report .mode-all-mentions').on 'click', ->
    $('.topic-trending-report .number-or-percentage-selector').removeClass('hide')
    $('.details-report-content.all-mentions').show()
    $('.details-report-content.only-negative, .details-report-content.only-positive').hide()
    $(@).addClass('selected')
    $('.mode-only-negative, .mode-only-positive').removeClass('selected')

  $('.topic-trending-report .mode-only-negative').on 'click', ->
    $('.topic-trending-report .number-or-percentage-selector').addClass('hide')
    renderNegativeMentionsTable()
    $('.details-report-content.all-mentions, .details-report-content.only-positive').hide()
    $('.details-report-content.only-negative').show()
    $(@).addClass('selected')
    $('.mode-all-mentions, .mode-only-positive').removeClass('selected')

  $('.topic-trending-report .mode-only-positive').on 'click', ->
    $('.topic-trending-report .number-or-percentage-selector').addClass('hide')
    renderPositiveMentionsTable()
    $('.details-report-content.all-mentions, .details-report-content.only-negative').hide()
    $('.details-report-content.only-positive').show()
    $(@).addClass('selected')
    $('.mode-only-negative, .mode-all-mentions').removeClass('selected')



  $('.details-report-content.all-mentions').show()
  $('.details-report-content.only-negative, .details-report-content.only-positive').hide()


  sparkDefaultOptions = (options, lineColor, color, intervalLabels) ->
    defaultOptions =
      chart:
        backgroundColor: null
        borderWidth: 0
        type: 'areaspline'
        margin: [ 2, 0, 2, 0 ]
        width: 120
        height: 20
        style: overflow: 'visible'
        skipClone: true
      title: text: ''
      credits: enabled: false
      xAxis:
        labels: enabled: false
        title: text: null
        startOnTick: false
        endOnTick: false
        tickPositions: []
        categories: intervalLabels
      yAxis:
        endOnTick: false
        startOnTick: false
        labels: enabled: false
        title: text: null
        tickPositions: [ 0 ]
      legend: enabled: false
      tooltip:
        hideDelay: 0
        outside: true
        shared: true
      plotOptions:
        series:
          animation: false
          lineWidth: 1
          shadow: false
          states: hover: lineWidth: 1
          marker:
            enabled: false
            radius: 1
            states: hover: radius: 2
        areaspline:
          color: color
          lineColor: lineColor

    options = Highcharts.merge(defaultOptions, options)

    options

  renderTrendGraphs = (tableClass, topicKey, topics, color) ->
    $(".topic-trending-report #{tableClass} .trend-graph").each(() ->
      return unless $(@).hasClass('never-rendered')
      topicName = $(@).data('topicname')
      topicData = topics.find((topic) -> topic.topic_name == topicName)

      ema = topicData[topicKey]
      ema = $.map ema, (element, index) ->
        val = element
        val = 0.0 if val == null
        val = Math.round( (val + Number.EPSILON) *100.0 ) / 100.0
        val

      minMA = Math.min.apply(null, ema)
      maxMA = Math.max.apply(null, ema)

      intervalLabels = $('.topic-trending-report').data('intervallabels')

      $element = $(@)

      Highcharts.chart $element[0],
        chart:
          type: 'areaspline'
          spacingTop: 0,
          spacingRight: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          plotBorderWidth: 0,
          margin: [0,0,0,0],
          backgroundColor: null
        title:
          text: null
        legend:
          enabled: false
        xAxis:
          enabled: false
          labels: enabled: false
          tickWidth: 0
          categories: intervalLabels
        yAxis:
          enabled: false
          title: text: null
          min: minMA
          max: maxMA
          tickWidth: 0
          labels: enabled: false
          gridLineWidth: 0
        tooltip:
          enabled: false
        credits: enabled: false
        plotOptions:
          series:
            enableMouseTracking: false
        series: [
          {
            name: 'Short Moving Average'
            data: ema
            color: color
            lineColor: color
            marker: enabled: false
          }
        ]
      $(@).removeClass('never-rendered')
    )

  renderTopicTrendingAllMentionsTable = () ->

    url = $('.topic-trending-report .all-mentions').data('moving-avg-url')
    return unless !!url
    $.ajax
      type: "GET"
      url: url
      success: (topics) ->
        totalMentions = $('.topic-trending-report .all-mentions').data('total-mentions')
        newRows = ""
        for topic in topics
          topicNegativeCountValue = topic.total_negative_mentions
          topicPositiveCountValue = topic.total_positive_mentions
          topicMixedCountValue = topic.total_mixed_mentions
          topicNeutralCountValue = topic.total_neutral_mentions
          topicCountValue = topic.total_mentions

          escaped_topic_name = topic.topic_name.replace(/'/g, "&#39;")
          translated_topic_name = translateTopicName(topic.topic_name)
          escaped_function_topic_name = topic.function_name.replace(/'/g, "&#39;")
          newRow = "
            <tr class='report-rows' data-topicname='#{escaped_topic_name}' data-topicdata=#{topic.negative_mentions.join(",")}>
              <td class='js-tooltip topic-name' title='#{escaped_function_topic_name}' data-container='body'>#{escaped_function_topic_name}</td>
              <td class='js-tooltip topic-name' title='#{translated_topic_name}' data-container='body'>#{translated_topic_name}</td>
              <td class='align-center positive-count' data-count=#{topicPositiveCountValue}>#{topicPositiveCountValue}</td>
              <td class='align-center neutral-count' data-count=#{topicNeutralCountValue}>#{topicNeutralCountValue}</td>
              <td class='align-center negative-count' data-count=#{topicNegativeCountValue}>#{topicNegativeCountValue}</td>
              <td class='align-center mixed-count' data-count=#{topicMixedCountValue}>#{topicMixedCountValue}</td>
              <td class='align-center total-count' data-count=#{topicCountValue}>#{topicCountValue}</td>
              <td class='align-center trend-graph-container'><div class='trend-graph never-rendered' data-topicname='#{escaped_topic_name}'></div></td>
            </tr>
          "
          newRows += newRow
        tbody = $('.topic-trending-report .all-mentions .table tbody')
        tbody.html(newRows)
        tbody.removeClass('never-rendered')
        $table = $('.topic-trending-report .details-report .all-mentions .details-table.number table')
        $table.DataTable(
          language: window.jQueryDataTableLanguagePacks[I18n.locale] || {}
          paging: true
          searching: true
          info: false
          ordering: true
          order: [[5, 'desc']]
          lengthMenu: [
            [ 10, 25, 50, 100, -1 ]
            [ 10, 25, 50, 100, "All" ]
          ]
          pageLength: 100
          columnDefs: [
            { targets: [-1], sorting: false, orderable: false}
          ]
        )
        renderTrendGraphs(".all-mentions .number", "total_ema", topics, '#3666F6')
        $(".topic-trending-report .all-mentions .number tbody").removeClass("hidden")
        $(".topic-trending-report .all-mentions .number .loading-text-container").addClass("hide")

        $table.on "draw.dt", ->
          renderTrendGraphs(".all-mentions .number", "total_ema", topics, '#3666F6')
          if $('.topic-trending-report .number-display-mode').hasClass('selected')
            $('.topic-trending-report .all-mentions .table tbody tr').each(() ->
              $totalCount = $(@).find('.total-count')
              totalCount = $totalCount.data('count')
              $totalCount.html(totalCount)
              $positiveCount = $(@).find('.positive-count')
              $positiveCount.html($positiveCount.data('count'))
              $neutralCount = $(@).find('.neutral-count')
              $neutralCount.html($neutralCount.data('count'))
              $negativeCount = $(@).find('.negative-count')
              $negativeCount.html($negativeCount.data('count'))
              $mixedCount = $(@).find('.mixed-count')
              $mixedCount.html($mixedCount.data('count'))
              $table.DataTable().rows().invalidate()
            )
          else
            $('.topic-trending-report .all-mentions .table tbody tr').each(() ->
              $totalCount = $(@).find('.total-count')
              totalCount = $totalCount.data('count')
              $totalCount.html((totalCount / totalMentions * 100).toFixed(1) + " %")
              $positiveCount = $(@).find('.positive-count')
              $positiveCount.html(($positiveCount.data('count') / totalCount * 100 || 0).toFixed(0) + " %")
              $neutralCount = $(@).find('.neutral-count')
              $neutralCount.html(($neutralCount.data('count') / totalCount * 100 || 0).toFixed(0) + " %")
              $negativeCount = $(@).find('.negative-count')
              $negativeCount.html(($negativeCount.data('count') / totalCount * 100 || 0).toFixed(0) + " %")
              $mixedCount = $(@).find('.mixed-count')
              $mixedCount.html(($mixedCount.data('count') / totalCount * 100 || 0).toFixed(0) + " %")
              $table.DataTable().rows().invalidate()
            )

        $('.topic-trending-report .number-display-mode').on 'click', ->
          $(@).addClass('selected')
          $('.topic-trending-report .percentage-display-mode').removeClass('selected')
          $('.topic-trending-report .all-mentions .table tbody tr').each(() ->
            $totalCount = $(@).find('.total-count')
            totalCount = $totalCount.data('count')
            $totalCount.html(totalCount)
            $positiveCount = $(@).find('.positive-count')
            $positiveCount.html($positiveCount.data('count'))
            $neutralCount = $(@).find('.neutral-count')
            $neutralCount.html($neutralCount.data('count'))
            $negativeCount = $(@).find('.negative-count')
            $negativeCount.html($negativeCount.data('count'))
            $mixedCount = $(@).find('.mixed-count')
            $mixedCount.html($mixedCount.data('count'))
            $table.DataTable().rows().invalidate()
          )


        $('.topic-trending-report .percentage-display-mode').on 'click', ->
          $(@).addClass('selected')
          $('.topic-trending-report .number-display-mode').removeClass('selected')
          $('.topic-trending-report .all-mentions .table tbody tr').each(() ->
            $totalCount = $(@).find('.total-count')
            totalCount = $totalCount.data('count')
            $totalCount.html((totalCount / totalMentions * 100).toFixed(1) + " %")
            $positiveCount = $(@).find('.positive-count')
            $positiveCount.html(($positiveCount.data('count') / totalCount * 100 || 0).toFixed(0) + " %")
            $neutralCount = $(@).find('.neutral-count')
            $neutralCount.html(($neutralCount.data('count') / totalCount * 100 || 0).toFixed(0) + " %")
            $negativeCount = $(@).find('.negative-count')
            $negativeCount.html(($negativeCount.data('count') / totalCount * 100 || 0).toFixed(0) + " %")
            $mixedCount = $(@).find('.mixed-count')
            $mixedCount.html(($mixedCount.data('count') / totalCount * 100 || 0).toFixed(0) + " %")
            $table.DataTable().rows().invalidate()
          )

  renderTopicTrendingAllMentionsTable()

  renderNegativeMentionsTable = () ->
    if $('.topic-trending-report .only-negative .table .never-rendered').length
      url = $('.topic-trending-report .only-negative').data('moving-avg-url')
      return unless !!url
      $.ajax
        type: "GET"
        url: url
        success: (topics) ->
          totalNegativeMentions = $('.topic-trending-report .only-negative').data('total-negative-mentions')
          newRows = ""
          for topic in topics
            translated_topic_name = translateTopicName(topic.topic_name)
            topicCountValue = topic.total_negative_mentions
            percentageIncrease = topic.percentage_of_global_negative_mentions || 0
            percentageLastInterval = topic.percentage_of_global_negative_mentions_last_interval || 0
            increaseInGlobalMentions = topic.increase_in_global_negative_mentions || 0
            newRow = "
              <tr class='report-rows' data-topicname='#{topic.topic_name}' data-topicdata=#{topic.negative_mentions.join(",")}>
                <td class='js-tooltip' title='#{topic.function_name}' data-container='body'>#{topic.function_name}</td>
                <td class='js-tooltip' title='#{translated_topic_name}' data-container='body'>#{translated_topic_name}</td>
                <td class='align-center topic-count'>#{topicCountValue}</td>
                <td class='align-center' data-order=#{percentageIncrease}>#{percentageIncrease.toFixed(1)} %</td>
                <td class='align-center' data-order=#{percentageLastInterval}>#{percentageLastInterval.toFixed(1)} %</td>
                <td class='align-center'>#{-increaseInGlobalMentions.toFixed(1)} %</td>
                <td class='align-center trend-graph-container'><div class='trend-graph never-rendered' data-topicname='#{topic.topic_name}'></div></td>
              </tr>
            "
            newRows += newRow

          tbody = $('.topic-trending-report .only-negative .table tbody')
          tbody.html(newRows)
          tbody.removeClass('never-rendered')
          $table = $('.topic-trending-report .details-report .only-negative table')
          $table.DataTable(
            language: window.jQueryDataTableLanguagePacks[I18n.locale] || {}
            paging: true
            searching: true
            info: false
            ordering: true
            columnDefs: [
              { targets: [-1], sorting: false, orderable: false}
            ]
            order: [[1, 'desc']]
          )

          $('.topic-trending-report .only-negative .loading-text-container').addClass('hidden')

          renderTrendGraphs(".only-negative", "negative_ema", topics, '#ff7a9a')

          $table.on "draw.dt", ->
            renderTrendGraphs(".only-negative", "negative_ema", topics, '#ff7a9a')


  renderPositiveMentionsTable = () ->
    if $('.topic-trending-report .only-positive .table .never-rendered').length
      url = $('.topic-trending-report .only-positive').data('moving-avg-url')
      return unless !!url
      $.ajax
        type: "GET"
        url: url
        success: (topics) ->
          totalPositiveMentions = $('.topic-trending-report .only-positive').data('total-positive-mentions')
          newRows = ""
          for topic in topics
            translated_topic_name = translateTopicName(topic.topic_name)
            topicCountValue = topic.total_positive_mentions
            percentageIncrease = topic.percentage_of_global_positive_mentions || 0
            percentageLastInterval = topic.percentage_of_global_positive_mentions_last_interval || 0
            increaseInGlobalMentions = topic.increase_in_global_positive_mentions || 0
            newRow = "
              <tr class='report-rows' data-topicname='#{topic.topic_name}' data-topicdata=#{topic.positive_mentions.join(",")}>
                <td class='js-tooltip' title='#{topic.function_name}' data-container='body'>#{topic.function_name}</td>
                <td class='js-tooltip' title='#{translated_topic_name}' data-container='body'>#{translated_topic_name}</td>
                <td class='align-center topic-count'>#{topicCountValue}</td>
                <td class='align-center' data-order=#{percentageIncrease}>#{percentageIncrease.toFixed(1)} %</td>
                <td class='align-center' data-order=#{percentageLastInterval}>#{percentageLastInterval.toFixed(1)} %</td>
                <td class='align-center'>#{increaseInGlobalMentions.toFixed(1)} %</td>
                <td class='align-center trend-graph-container'><div class='trend-graph never-rendered' data-topicname='#{topic.topic_name}'></div></td>
              </tr>
            "
            newRows += newRow

          tbody = $('.topic-trending-report .only-positive .table tbody')
          tbody.html(newRows)
          tbody.removeClass('never-rendered')
          $table = $('.topic-trending-report .details-report .only-positive table')
          $table.DataTable(
            language: window.jQueryDataTableLanguagePacks[I18n.locale] || {}
            paging: true
            searching: true
            info: false
            ordering: true
            columnDefs: [
              { targets: [-1], sorting: false, orderable: false}
            ]
            order: [[1, 'desc']]
          )

          $('.topic-trending-report .only-positive .loading-text-container').addClass('hidden')

          renderTrendGraphs(".only-positive", "positive_ema", topics, '#54eec2')

          $table.on "draw.dt", ->
            renderTrendGraphs(".only-positive", "positive_ema", topics, '#54eec2')

  renderExplorerStackedNegativeGraph = ($element) ->
    topicData = $element.data('topicdata')

    negativeData = topicData['negative_mentions']
    positiveData = topicData['positive_mentions']
    neutralData = topicData['neutral_mentions']

    intervalLabels = $('.topic-trending-report').data('intervallabels')

    Highcharts.chart $element[0],
      chart:
        type: 'areaspline'
        margin: [2, 0, 2, 0]
      title:
        text: null
      legend:
        enabled: false
      xAxis:
        enabled: false
        labels: enabled: false
        tickWidth: 0
        categories: intervalLabels
      yAxis:
        title: text: null
        enabled: false
        labels: enabled: false
        gridLineWidth: 0
      tooltip:
        shared: true
        valueSuffix: ' mentions'
      credits: enabled: false
      plotOptions:
        areaspline:
          stacking: 'normal'
          lineWidth: 1
          marker:
            enabled: false
      series: [
        {
          name: 'Negative'
          data: negativeData
          color: '#ff5883'
          lineColor: '#FF5983'
        }
        {
          name: 'Positive'
          data: positiveData
          color: '#1cebb7'
          lineColor: '#1DE9B6'
        }
        {
          name: 'Neutral'
          data: neutralData
          color: '#C6CEDB'
          lineColor: '#ADB9CC'
        }
      ]

  fetchNegativeGraphData = () ->
    container = $('.topic-trending-report .negative-mentions-container ')
    url = container.data("topics-avg-path")
    # If the page does is loaded with a warning, the section where the url is created is not rendered
    return unless !!url
    $.ajax
      type: "GET"
      url: url
      success: (data) ->
        top_three_increasing_negative_mentions = data.top_three_increasing_negative_mentions
        container = $('.topic-trending-report .negative-mentions-container ')
        $(".topic-trending-report .loader-container").remove()
        if top_three_increasing_negative_mentions.length == 0
          container.append("<div class='col-xs-12'><div class='no-negative-mentions'>#{I18n.t('no_topics_increasing_negative')}</div></div>")
        else
          top_three_increasing_negative_mentions.forEach((neg_mention, index) ->
            totalNegativeMentionsPercentage = (neg_mention.total_negative_mentions / data.topic_stats.total_negative_mentions) * 100
            increaseOverTime = neg_mention.increase_in_global_negative_mentions.toFixed(1) + " %"
            dateRange = container.data('period')
            url = new Url(window.location.href)
            url = parse(url, true)
            existing_params = url.query
            if existing_params["fltr_interval"] != undefined
              interval = I18n.t(existing_params["fltr_interval"])
            else
              interval = I18n.t("week")
            graph = "
              <div class='col-xs-12 col-lg-4' style='flex:1; max-width: 33.33333%;'>
                <div class='negative-mention chatter-standard-box-shadow'>
                  <div class='floating-date'>#{dateRange}</div>
                  <div class='negative-mention-header'>
                    <div class='topic js-tooltip' title='#{neg_mention.topic_name}' data-container='body' data-url='#{container.data('url')}' data-topicname='#{neg_mention.topic_name}'>
                      #{neg_mention.topic_name}
                    </div>
                    <div class='total-mentions'>#{I18n.t('total_mentions_content', mentions: neg_mention.total_negative_mentions, total: neg_mention.total_mentions )}</div>
                  </div>
                  <div>
                    <div>
                      <div class='mentions-title'>#{I18n.t('increase_over_time_period', { timeUnit: interval })}</div>
                      <div class='mentions-number'><i class='fas fa-long-arrow-alt-up'></i>#{increaseOverTime}</div>
                    </div>
                    <div>
                      <div class='mentions-title'>#{I18n.t('percent_of_total_negative_mentions')}</div>
                      <div class='mentions-number'>#{totalNegativeMentionsPercentage.toFixed(1)} %</div>
                    </div>
                  </div>

                </div>
              </div>
            "
            container.append(graph)

            $('.topic-trending-report .negative-mention .topic').on 'click', ->
              url = $(@).data('url')
              topicName = $(@).data('topicname')

              aUrl = document.createElement('a')
              aUrl.href = url
              paramSeparator = "?"
              if aUrl.search
                paramSeparator = "&"

              url = url + paramSeparator + "topic_name=" + encodeURIComponent( topicName )
              window.open(url, "_blank")
          )
      error: (data) ->
        console.error data

  if $('.topic-trending-report').length > 0
    fetchNegativeGraphData()

  $('.topic-trending-report .details-table').on 'click', 'tr.report-rows', ->
    row = $(event.target).parents( 'tr.report-rows' )
    table = $(event.target).parents( 'table.table' )
    topicName = row.data( 'topicname' )
    url = table.data( 'url' )

    aUrl = document.createElement('a')
    aUrl.href = url
    paramSeparator = "?"
    if aUrl.search
      paramSeparator = "&"

    url = url + paramSeparator + "topic_name=" + encodeURIComponent( topicName )
    window.open(url, "_blank")

  $('.topic-trending-report .top-5-box .topic').on 'click', ->
    url = $(@).data('url')
    topicName = $(@).data('topicname')

    aUrl = document.createElement('a')
    aUrl.href = url
    paramSeparator = "?"
    if aUrl.search
      paramSeparator = "&"

    url = url + paramSeparator + "topic_name=" + encodeURIComponent( topicName )
    window.open(url, "_blank")

  $('.topic-forecaster-header span').on 'click', ->
    $('.row.js-topic-forecaster-explanation').removeClass('hide')

  $('.topic-forecaster-explanation .topic-forecaster-explanation-close').on 'click', ->
    $('.row.js-topic-forecaster-explanation').addClass('hide')

  $('.topic-forecaster .topics-to-watch-container .top-topics .top-topic').on 'click', (e) ->
    $(this).parents('.top-topics').find('.top-topic').removeClass('selected')
    $(this).addClass('selected')
    renderTopTopic $(this)

  renderTopTopic = ($topTopic) ->
    topicType = $topTopic.data('topictype')

    switch topicType
      when 'tpi'
        renderTrendingTPI $topTopic
      when 'most_negative'
        renderTPIStackedGraph $topTopic
      when 'decreasing_positive'
        renderTPIStackedGraph $topTopic

  renderTPIStackedGraph = ($topTopic) ->
    topicData = $topTopic.data('topicdata')

    negativeData = topicData['negative_mentions']
    positiveData = topicData['positive_mentions']
    neutralData = topicData['neutral_mentions']
    lastDate = $topTopic.data('lastdate')
    $graph = $('.topic-forecaster .topics-to-watch-container .topic-graph-and-legend .topic-graph')

    xAxisDate = moment(lastDate, "YYY-MM-DD")
    xAxisDates = [ xAxisDate.format('MM/DD' ) ]
    i = 0
    while i < negativeData.length-1
      xAxisDate = xAxisDate.subtract(1, 'days')
      xAxisDates.unshift( xAxisDate.format('MM/DD' ) )
      i++

    Highcharts.chart $graph[0],
      chart:
        type: 'areaspline'
      title:
        text: null
      legend:
        enabled: false
      xAxis:
        tickInterval: 7
        categories: xAxisDates
      yAxis:
        title: text: "Mentions"
        gridLineWidth: 0
      tooltip:
        shared: true
        valueSuffix: ' mentions'
      credits: enabled: false
      plotOptions:
        areaspline:
          stacking: 'normal'
          lineWidth: 1
          marker:
            enabled: false
      series: [
        {
          name: 'Positive'
          data: positiveData
          color: '#1cebb7'
          lineColor: '#1DE9B6'
        }
        {
          name: 'Negative'
          data: negativeData
          color: '#ff5883'
          lineColor: '#FF5983'
        }
        {
          name: 'Neutral'
          data: neutralData
          color: '#C6CEDB'
          lineColor: '#ADB9CC'
        }
      ]

  renderTrendingTPI = ($topTopic) ->
    topicData = $topTopic.data('topicdata')
    month = $topTopic.data('month')
    lastDate = $topTopic.data('lastdate')
    $graph = $('.topic-forecaster .topics-to-watch-container .topic-graph-and-legend .topic-graph')

    xAxisDate = moment(lastDate, "YYY-MM-DD")
    xAxisDates = [ xAxisDate.format('MM/DD' ) ]
    i = 0
    while i < topicData['tpi_data'].length-1
      xAxisDate = xAxisDate.subtract(1, 'days')
      xAxisDates.unshift( xAxisDate.format('MM/DD' ) )
      i++

    tpiSeries = topicData['tpi_data']
    mentionsSeries = topicData['mentions']

    bandStart = null
    bandEnd = null
    if topicData.hasOwnProperty('tpi_predictions') == true
      i = 0
      xAxisDate = moment(lastDate, "YYY-MM-DD")
      while i < topicData['tpi_predictions'].length
        xAxisDate = xAxisDate.add(1, 'days')
        xAxisDates.push( xAxisDate.format('MM/DD' ) )
        tpiSeries.push( topicData['tpi_predictions'][i] )
        i++
      bandStart = xAxisDates.length-topicData['tpi_predictions'].length - 0.5
      bandEnd = xAxisDates.length-1

    tpiSeries = $.map tpiSeries, (element, index) ->
      val = element
      val = 0.0 if val == null
      val = Math.round( (val + Number.EPSILON) *100.0 ) / 100.0
      val

    minTPI = Math.min.apply(null, tpiSeries)
    maxTPI = Math.max.apply(null, tpiSeries)
    minMentions = Math.min.apply(null, mentionsSeries)
    maxMentions = Math.max.apply(null, mentionsSeries)

    minTPI = Math.max(0.0, minTPI - 0.05)
    maxTPI = Math.min(maxTPI+0.05, 1.0)
    showLastTPILabel = true
    if maxTPI == 1.0
      maxTPI = 1.0 + (maxTPI-minTPI)*0.05
      showLastTPILabel = false

    minMentions = Math.max(minMentions - (minMentions % 10) - 10, 0)
    maxMentions = (maxMentions+10) - (maxMentions % 10)

    chartOptions =
      chart: zoomType: 'xy'
      title: text: null
      subtitle: text: null
      xAxis: [ {
        categories: xAxisDates
        crosshair: true
        tickInterval: 7
      } ]
      plotOptions:
        column: opacity: 0.8
        areaspline: fillOpacity: 0.3
      yAxis: [
        {
          labels:
            format: '{value}'
            style: color: '#37404D'
          title:
            text: 'Topic Performance Index'
            style: color: '#37404D'
          alignTicks: false
          gridLineWidth: 0
          min: minTPI
          max: maxTPI
          allowDecimals: true
          showLastLabel: showLastTPILabel
        }
        {
          title:
            text: 'Mentions'
            style: color: '#37404D'
          labels:
            format: '{value}'
            style: color: '#37404D'
          opposite: true
          alignTicks: false
          gridLineWidth: 0
          min: minMentions
          max: maxMentions
          allowDecimals: false
        }
      ]
      credits: enabled: false
      legend: enabled: false
      tooltip: shared: true
      series: [
        {
          name: 'Mentions'
          type: 'column'
          yAxis: 1
          data: mentionsSeries
          marker:
            enabled: false
            radius: 2
            hover: radius: 3
          color: '#E4E4EE'
        }
        {
          name: 'TPI'
          type: 'areaspline'
          data: tpiSeries
          marker:
            enabled: false
            radius: 2
            hover: radius: 3
          color: '#2962FF'
          zoneAxis: 'x'
          zones: [{
            value: Math.floor( bandStart )
          }, {
            dashStyle: 'dash'
            fillColor:
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 }
              stops: [
                [0, '#bed0ff'],
                [1, '#FFFFFF']
              ]
          }]
        }
      ]

    Highcharts.chart $graph[0], chartOptions

  $('.topic-forecaster .topics-to-watch-container .top-topics .top-topic:first-of-type').trigger( 'click' )

  $('.topic-forecaster .all-topics table').DataTable(
    language: window.jQueryDataTableLanguagePacks[I18n.locale] || {}
    paging: false
    searching: false
    info: false
    ordering: true
  )

  $('.topic-forecaster .all-topics table tbody').on 'click', 'tr', ->
    $row = $(event.target).parents( 'tr' )
    $table = $(event.target).parents( 'table.table' )
    topicName = $row.data( 'topicname' )
    url = $table.data( 'url' )

    aUrl = document.createElement('a')
    aUrl.href = url
    paramSeparator = "?"
    if aUrl.search
      paramSeparator = "&"

    url = url + paramSeparator + "topic_name=" + encodeURIComponent( topicName )
    window.location.href = url

