import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import HighchartsReact from "highcharts-react-official";

const ChartEngagement = ({ text, percentage, options, highcharts, chartComponent }) => {
  makeTitle(options, text, percentage);

  return (
    <div className={cx("container")}>
      <div className={cx("graph")}>
        <HighchartsReact highcharts={highcharts} options={options} ref={chartComponent} />
      </div>
    </div>
  );
};

const makeTitle = (options, text, percentage) => {
  const { title } = options;
  title.text = `<div class="${cx("title")}"><div class="${cx(
    "label"
  )}">${text}</div><div class="${cx("separator")}"></div><div class="${cx(
    "percentage"
  )}">${percentage}%</div></div>`;
};

ChartEngagement.propTypes = {
  chartComponent: PropTypes.object.isRequired,
  highcharts: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  percentage: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default ChartEngagement;
