import React, { useState } from "react";

import Modal from "./Modal";
import Template from "./Template";

import I18n from "i18n-js";
import styles from "./TemplatesModal.module.scss";

const TemplatesModal = ({ isOpen, setIsOpen, templates, onClose }) => {
  const [selectedTemplate, setSelectedTemplate] = useState();

  const handleModalButtonClicked = () => {
    onClose(selectedTemplate);
  }

  const handleClose = () => {
    setSelectedTemplate();
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={I18n.t("live_conversations.templates")}
      bodyClassName={styles.body}
      body={
        <div className={styles.templateList}>
          { templates.map((template) => {
            const { id, name, description, content } = template;

            return (
              <Template
                key={id}
                title={name}
                description={description}
                content={content}
                isSelected={id === selectedTemplate?.id}
                onClick={() => setSelectedTemplate(template)} />
            );
          }) }
        </div>
      }
      actionButtonText={I18n.t("live_conversations.select")}
      cancelButtonText={I18n.t("live_conversations.cancel")}
      onClose={handleClose}
      onActionButtonClicked={handleModalButtonClicked} />
  );
}

export default TemplatesModal;
