import React from "react";
import DataEntryGrid from "../data-entry-grid/data-entry-grid";
import {
  LanguageSelector,
  ProvinceSelector,
  StateSelector,
  CountrySelector,
} from "../data-entry-grid/cell-editors";

const columns = ["Name", "City", "Country", "State / Province", "Preferred Language"];
const emptyRow = [
  { value: "" },
  { value: "" },
  {
    value: "Canada",
    dataEditor: CountrySelector,
    editOnClick: true,
    id: "country",
  },
  {
    value: "Ontario",
    dataEditor: ProvinceSelector,
    editOnClick: true,
    dependsOn: "country",
    dependanceChange: {
      object: "dataEditor",
      change: {
        Canada: { field: ProvinceSelector, value: "Ontario" },
        USA: { field: StateSelector, value: "New York" },
      },
    },
  },
  { value: "English", dataEditor: LanguageSelector, editOnClick: true },
];

const POCDataGrid = ({ values, name = "poc_data_grid", rows = 10, title = "POCs" }) => {
  return (
    <DataEntryGrid
      title={title}
      initialValues={values}
      name={name}
      rows={rows}
      columns={columns}
      emptyRow={emptyRow}
      dataParser={dataToObject}
      rowSetter={rowSetter}
      templatePath="/deployment-poc-list-sample.csv"
    />
  );
};

const dataToObject = (grid) => {
  return grid
    .map((row, index) => {
      if (index > 0 && row[0].value !== "") {
        return {
          name: row[0].value,
          city: row[1].value,
          country: row[2].value,
          state: row[3].value,
          language: row[4].value,
        };
      }
    })
    .filter((poc) => poc != null);
};

const rowSetter = (data) => {
  if (
    data.name == null ||
    data.city == null ||
    data.country == null ||
    data.state == null ||
    data.preferred_language == null
  ) {
    return null;
  }

  const newRow = [...emptyRow];
  newRow[0] = { ...newRow[0], value: data.name };
  newRow[1] = { ...newRow[1], value: data.city };
  newRow[2] = { ...newRow[2], value: data.country };
  newRow[3] = { ...newRow[3], value: data.state };
  newRow[4] = { ...newRow[4], value: data.preferred_language };
  return newRow;
};

export default POCDataGrid;
