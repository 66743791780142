import React from "react";
import I18n from "i18n-js";

import { PrettyLoader } from "../../views/shared";

import hasLoader from "../hoc/has-loader";

import { isArrayEmpty } from "../../utils/general-helper";

import MultitableContent from "../../views/tile-multitable";

import { FastestClimbersContainerType } from "../../types";

class FastestClimbersContainer extends React.Component {
  render() {
    const { status } = this.props;

    return (
      <PrettyLoader status={status} size="medium">
        {this.contentToRender()}
      </PrettyLoader>
    );
  }

  contentToRender() {
    const { componentData, disableViewMore } = this.props;
    const { topClimbers, bottomClimbers, detailsPath, medianValue, questionType, additionalText } =
      componentData;

    const isOnlyTopClimbers = isArrayEmpty(bottomClimbers) && !isArrayEmpty(topClimbers);

    const scoreLabel = questionType === "NPS" ? "NPS" : "Satisfaction";
    const viewMore = disableViewMore ? null : detailsPath;

    if (isOnlyTopClimbers) {
      return (
        <MultitableContent
          headerText={I18n.t("fastest_climbers")}
          tableLeft={this.prepareTableData("", topClimbers)}
          detailsPath={viewMore}
          median={medianValue}
          scoreLabel={scoreLabel}
          additionalText={additionalText}
        />
      );
    } else {
      return (
        <MultitableContent
          headerText={I18n.t("fastest_climbers")}
          tableLeft={this.prepareTableData(I18n.t("top_five"), topClimbers)}
          tableRight={this.prepareTableData(I18n.t("bottom_five"), bottomClimbers)}
          detailsPath={viewMore}
          median={medianValue}
          scoreLabel={scoreLabel}
          additionalText={additionalText}
        />
      );
    }
  }

  //prepares tableData fro MultitableContent
  prepareTableData(tableCaption, rows) {
    return {
      data: rows,
      title: tableCaption,
    };
  }
}

FastestClimbersContainer.propTypes = FastestClimbersContainerType;

export default hasLoader(FastestClimbersContainer);
