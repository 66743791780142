# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

jQuery ->

  require("chosen-js")
  require('chosen-js/chosen.css')

  #initiate the dropdowns
  $(".topic-name select").chosen({allow_single_deselect: true})
  $(".topic-sentiment select").chosen({allow_single_deselect: true})
  $(".topic-tag-name select").chosen({allow_single_deselect: true})

  # initiate the tables

  # #######################################################################################
  # # Review Topics DataTable
  # #######################################################################################
  theTable = null
  $('.js-data-table-topics').each ->
    randomSample = $(@).data('random')?

    entriesString = if $(@).data('replies')?
      $(@).data('replies')
    else
      I18n.t("replies")

    languageSettings = window.jQueryDataTableLanguagePacks[I18n.locale] || {}
    languageSettings.info = if randomSample
      "Showing a sample of _END_ out of _TOTAL_ #{entriesString}"
    else
      "Showing _START_ to _END_ of _TOTAL_ #{entriesString}"

    if $(@).data('emptyTable')
      languageSettings.emptyTable = $(@).data('empty-table')

    options =
      dom: '<"top"f<"row"<"col-sm-6"l><"col-sm-6"p>><"clear">>rt<"bottom"i<"clear">>'
      language: languageSettings
      order: []
      columns: [
        {
          className: 'topic-details-control'
          orderable: false
          data: null
          defaultContent: '<span class="topics-open-one js-tooltip" data-container="body" title="" data-original-title="Click to expand this reply"><i class="topic-expander fa fa-plus-circle"></i></span>'
        }
        { data: 'company_name', orderable: false }
        { data: 'survey_name', orderable: false }
        { data: 'question_name', orderable: false }
        { data: 'no_topics', orderable: false }
        { data: 'labelling_status', orderable: false }
        { data: 'c_topics', orderable: false }
      ]
      paging: !randomSample
      pagingType: "full_numbers"
      processing: true
      responsive: true
      serverSide: true
      deferLoading: 0
      ajax: $(@).data('source')

    theTable = $(@).DataTable options

    $table = $(@)
    search = $table.data 'search'
    if search?
      theTable.search search

    theTable.on 'draw', ->
      $table.find('.js-tooltip').tooltip()
      # Open all of the topic editors
      processTopicsOpenAll(true)

    theTable.on 'page.dt', ->
      window.location.href = "#top_of_table_anchor"

    theTable.draw()

  # #######################################################################################
  # # Review CEC DataTable
  # #######################################################################################
  $('.js-data-table-cec').each ->
    randomSample = $(@).data('random')?
    entriesString = if $(@).data('replies')?
      $(@).data('replies')
    else
      'replies'

    emptyTableString = if $(@).data('emptyTable')
      $(@).data('empty-table')
    else
      "No data available in table"

    options =
      dom: '<"top"f<"row"<"col-sm-6"l><"col-sm-6"p>><"clear">>rt<"bottom"i<"clear">>'
      language:
        info: if randomSample then "Showing a sample of _END_ out of _TOTAL_ #{entriesString}" else "Showing _START_ to _END_ of _TOTAL_ #{entriesString}"
        emptyTable: emptyTableString
        lengthMenu: "Show _MENU_ #{entriesString} per page"
      order: []
      columns: [
        {
          className: 'topic-details-control'
          orderable: false
          data: null
          defaultContent: '<span class="topics-open-one js-tooltip" data-container="body" title="" data-original-title="Click to expand this reply"><i class="topic-expander fa fa-plus-circle"></i></span>'
        }
        { data: 'company_name', orderable: false }
        { data: 'survey_name', orderable: false }
        { data: 'question_name', orderable: false }
        { data: 'labelling_status', orderable: false }
        { data: 'question_categorization_required', orderable: false }
      ]
      paging: !randomSample
      pagingType: "full_numbers"
      processing: true
      responsive: true
      serverSide: true
      deferLoading: 0
      ajax: $(@).data('source')

    theTable = $(@).DataTable options

    $table = $(@)
    search = $table.data 'search'
    if search?
      theTable.search search

    theTable.on 'draw', ->
      $table.find('.js-tooltip').tooltip()
      # Open all of the topic editors
      processTopicsOpenAll(true)

    theTable.on 'page.dt', ->
      window.location.href = "#top_of_table_anchor"

    theTable.draw()

  # #######################################################################################
  # # Review ISE labels DataTable
  # #######################################################################################
  $('.js-data-table-ise-labels').each ->
    randomSample = $(@).data('random')?
    entriesString = if $(@).data('replies')?
      $(@).data('replies')
    else
      'replies'

    emptyTableString = if $(@).data('emptyTable')
      $(@).data('empty-table')
    else
      "No data available in table"

    options =
      dom: '<"top"f<"row"<"col-sm-6"l><"col-sm-6"p>><"clear">>rt<"bottom"i<"clear">>'
      language:
        info: if randomSample then "Showing a sample of _END_ out of _TOTAL_ #{entriesString}" else "Showing _START_ to _END_ of _TOTAL_ #{entriesString}"
        emptyTable: emptyTableString
        lengthMenu: "Show _MENU_ #{entriesString} per page"
      order: []
      columns: [
        {
          className: 'topic-details-control'
          orderable: false
          data: null
          defaultContent: '<span class="topics-open-one js-tooltip" data-container="body" title="" data-original-title="Click to expand this reply"><i class="topic-expander fa fa-plus-circle"></i></span>'
        }
        { data: 'company_name', orderable: false }
        { data: 'survey_name', orderable: false }
        { data: 'question_name', orderable: false }
        { data: 'labelling_status', orderable: false }
      ]
      paging: !randomSample
      pagingType: "full_numbers"
      processing: true
      responsive: true
      serverSide: true
      deferLoading: 0
      ajax: $(@).data('source')

    theTable = $(@).DataTable options

    $table = $(@)
    search = $table.data 'search'
    if search?
      theTable.search search

    theTable.on 'draw', ->
      $table.find('.js-tooltip').tooltip()
      # Open all of the topic editors
      processTopicsOpenAll(true)

    theTable.on 'page.dt', ->
      window.location.href = "#top_of_table_anchor"

    theTable.draw()


  # events

  $(".topics-edit-topics, .topics-mass-edit, .topics-index").on 'focusout', '.chosen-container-single', ->
    $(this).css({
      "box-shadow": "0px 0px 0px 0px rgba(0,0,0,0)"
    })

  $(".topics-edit-topics, .topics-mass-edit, .topics-index").on 'focusin', '.chosen-container-single', ->
    $(this).css({
      "box-shadow": "0px 0px 0px 4px rgba(177,103,188,0.7)",
      "border-radius": "4px"
    })

  $(window).on 'resize', ->
    $('.chosen-container').each ->
      $(this).attr 'style', 'width: 100%'
      return
    return

  $(".edit-topics-page-topic-no-topics").on 'click', ->
    $("select").each ->
      if $(this).prop("disabled") == false
        $(this).prop('disabled', true).trigger("chosen:updated")
      else
        $(this).prop('disabled', false).trigger("chosen:updated")

  $(".topic-no-topics").on 'click', ->
    if $(this).children('input[type="checkbox"]').is(":checked")
      $(this).parent().parent().parent().next().css("pointer-events","none")
      $(this).parent().parent().parent().next().children().each ->
        $(this).children().children().children().first().prop('disabled', true).trigger("chosen:updated")
        $(this).children().children().children().first().val("")
        $(this).children().children().children().first().trigger("chosen:updated")
    else
      $(this).parent().parent().parent().next().css("pointer-events","auto")
      $(this).parent().parent().parent().next().children().each ->
        $(this).children().children().children().first().prop('disabled', false).trigger("chosen:updated")

  $(".topic-name").on 'change', ->
    # empty the tag names
    $(this).siblings(".topic-tag-name").children(".tag-name-field").empty()
    $(this).siblings(".topic-tag-name").children(".tag-name-field").val("").trigger("chosen:updated")

    # fill the tag names
    setTopicDropDowns($(this).parents(".topic-container"))

    topic_name_value = $(this).children(".chosen-container").children(".chosen-single").children("span").text()
    tag_name_value = $(this).siblings(".topic-tag-name").children("select").val()
    if (topic_name_value == "" || topic_name_value ==  "Select an Option" || topic_name_value == undefined)
      $(this).siblings(".topic-tag-name").children("select").val("").trigger("chosen:updated")
    else
      $(this).siblings(".topic-tag-name").children("select").val(tag_name_value).trigger("chosen:updated")

  $(".topic-tag-name").on 'change', ->
    topic_name_value = $(this).siblings('.topic-name').children("select").val()
    tag_name_value = $(this).children("select").val()
    if isTopicNameValueNotPresent(topic_name_value)
      if tag_name_value == "Create new"
        tag_name = prompt("enter tag name")
        if tag_name != null && tag_name != undefined && tag_name != "" && tag_name.length > 0
          $(this).children("select").append(
            "<option value='#{tag_name}' selected>" + tag_name + "</option>"
          ).trigger("chosen:updated")
          $(this).children("select").trigger("chosen:updated")
      else if tag_name_value == "See All"
        $(this).children("select").val("").trigger("chosen:updated")
        for tag in array_of_tag_names
          $(this).children("select").append(
            "<option value='#{tag}' selected>" + tag + "</option>"
          )
        $(this).children("select").trigger("chosen:updated")
      else
        $(this).children("select").val(tag_name_value).trigger("chosen:updated")
        $(this).children("select").trigger("chosen:updated")

  $(".human-topic-container .review-topic-name").on 'change', 'select', ->
    processTopicNameChange( $(this) )

  $(".human-topic-container .review-tag-name").on 'change', 'select', ->
    processTagNameChange( $(this) )

  $(".topic-container").on 'keyup', (e) ->
    if e.keyCode == 9
      if !isDropdownPopulated($(this).attr("id")) && $(this).parents(".topic-name").length > 0
        for el in array_of_topics
          $(this).children(".chosen-drop").children(".chosen-results").append(
            "<li class='active-result'>" + el+ "</li>"
          )
          $(this).siblings("select").append("<option value=\"#{el}\">"+ el+"</option>")
        $(this).siblings("select").trigger("chosen:updated")
        array_of_ids.push($(this).attr("id"))
    if e.keyCode == 8 && $(this).children(".chosen-drop").children(".chosen-results").children(".result-selected").text() != ""
      $(this).siblings("select").first().val("")
      $(this).siblings("select").first().trigger("chosen:updated")

  $(".chosen-container").on 'click focusin', ->
    select_id = $(this).siblings("select").attr("id")
    if !isDropdownPopulated(select_id) && $(this).parents(".topic-name").length > 0
      for el in array_of_topics
        $(this).children(".chosen-drop").children(".chosen-results").append(
          "<li class='active-result'>" + el+ "</li>"
        )
        $(this).siblings("select").append("<option value=\"#{el}\">"+ el+"</option>")
      $(this).siblings("select").trigger("chosen:updated")
      array_of_ids.push(select_id)

  $('.js-topics-company-selector').on 'change', ->
    companySelectHandleChange()

  $('.js-topics-survey-selector').on 'change', ->
    surveySelectHandleChange()

  $('.js-topics-industry-selector').on 'change', ->
    industrySelectHandleChange()

  $('.js-topics-question-selector').on 'change', ->
    questionSelectHandleChange()

  $('.apply-filters-button').on 'click', ->
    questionSelectHandleChange()

  handleQuickSearchToggle = ->
    p = $('.question-selector')
    if p.length > 0
      companySelect = p.find( ".js-topics-company-selector" )
      companyID = companySelect.val()

      surveySelect = p.find( ".js-topics-survey-selector" )
      surveyID = surveySelect.val()

      if $('.js-getrandomdatasample-selector')[0].checked && (companyID == "" || surveyID == "")
        $('.quick-search').removeClass('hide')
      else
        $('.quick-search').addClass('hide')

  $('.js-getrandomdatasample-selector').on 'change', ->
    handleQuickSearchToggle()

  handleQuickSearchToggle()

  handleCompanyFilter = ->
    p = $('.question-selector')
    if p.length > 0
      companySelect = p.find('.js-topics-company-selector')
      if $('.js-filterlivecompany-selector')[0].checked
        companySelect.find('.hidable-company').addClass('hidden')
      else
        companySelect.find('.hidable-company').removeClass('hidden')
      companySelect.trigger("chosen:updated")

  $('.js-filterlivecompany-selector').on 'change', ->
    handleCompanyFilter()

  handleCompanyFilter()

  handleSurveyFilter = ->
    p = $('.question-selector')
    if p.length > 0
      surveySelect = p.find('.js-topics-survey-selector')
      if $('.js-filterlivesurvey-selector')[0].checked
        surveySelect.find('.hidable-survey').addClass('hidden')
      else
        surveySelect.find('.hidable-survey').removeClass('hidden')
      surveySelect.trigger("chosen:updated")

  $('.js-filterlivesurvey-selector').on 'change', ->
    handleSurveyFilter()

  handleSurveyFilter()

  $(document).on 'keydown', 'form#topics-form-tag', (event) ->
    if event.target.type == "select-one"
      return true

    #handle up
    if event.keyCode == 38 && !topicDropdownIstoggled
      return findStartOfNextRow event.target, "up"

    #handle down
    if event.keyCode == 40 && !topicDropdownIstoggled
      return findStartOfNextRow event.target, "down"

  $(document).on 'keypress', 'form#topics-form-tag', (event) ->
    if event.target.type == "select-one"
      return true

    # ignore the keypress if it was not an enter
    if event.keyCode != 13
      return true

    if event.target.type == "submit"
      return true

    if event.target.type == "checkbox"
      event.target.checked = !event.target.checked

    return false

  $('.table-topics tbody').on 'click', 'td.topic-details-control', ->
    tr = $(this).closest('tr')
    row = theTable.row(tr)

    $tooltip_span = $(this).find('span')
    $tooltip_span.tooltip('hide')
    $ctl_icon = $(this).find('.topic-expander')
    row_is_open = $ctl_icon.hasClass('fa-minus-circle')
    reply_id_range = $(".js-reply-id-range")
    unless reply_id_range.hasClass('reply-id-range-added') || !row.data()["first_reply_id"] || !row.data()["last_reply_id"]
      reply_id_range.text("Matching reply IDs from #{row.data()["first_reply_id"]} to #{row.data()["last_reply_id"] }")
      reply_id_range.addClass 'reply-id-range-added'
    if row.child.isShown()
      # This row is already open - close it
      row.child.hide()
      tr.removeClass 'shown'
      $ctl_icon.removeClass('fa-minus-circle')
      $ctl_icon.addClass('fa-plus-circle')
      $tooltip_span.attr('data-original-title', 'Click to expand this reply')
    else
      # Open this row
      html_was_set = false
      if !tr.hasClass('html-added')
        row.child(row.data()["topic_html"] || row.data()["cec_html"] || row.data()["ise_html"])
        tr.addClass 'html-added'
        html_was_set = true

      row.child.show()
      tr.addClass 'shown'
      $ctl_icon.removeClass('fa-plus-circle')
      $ctl_icon.addClass('fa-minus-circle')
      $tooltip_span.attr('data-original-title', 'Click to collapse this reply')

      if html_was_set
        setTimeout (->
          # Topics review page
          row.child().find(".human-topic-widget:not(.template) .topic-sentiment select").chosen({allow_single_deselect: true})
          row.child().find(".human-topic-widget:not(.template) .human-topic-container .review-tag-name select").chosen({allow_single_deselect: true})
          row.child().find(".human-topic-widget:not(.template) .human-topic-container .review-topic-name select").chosen({allow_single_deselect: true})

          row.child().find(".human-topic-container .review-topic-name").on 'change', 'select', ->
            processTopicNameChange( $(this) )
          row.child().find(".human-topic-container .review-tag-name").on 'change', 'select', ->
            processTagNameChange( $(this) )
          row.child().find(".human-topic-container").on 'change', 'select', ->
            handleFormWidgetChange( $(this) )
          row.child().find(".human-topics-form").on 'change', '.topic-form-checkbox', ->
            handleFormWidgetChange( $(this) )

          # ISE labelling page
          row.child().find(".js-ise-answered").chosen({allow_single_deselect: true})
          row.child().find(".js-ise-triggers").chosen({allow_single_deselect: true})
          row.child().find(".js-ise-overall-sentiment").chosen({allow_single_deselect: true})
          row.child().find(".js-ise-requires-probing").chosen({allow_single_deselect: true})


          return
        ), 500

    return

  $('.js-data-table-topics .topics-open-all').on 'click', ->
    $ctl_icon = $('.js-data-table-topics .topics-open-all .topic-expander')
    perform_open_all = $ctl_icon.hasClass('fa-plus-circle')
    processTopicsOpenAll(perform_open_all)

  $('.topics-report-table tbody').on 'hover', ->
    $(this).find('tr').css('cursor','pointer')

  $('.topics-report-table').on 'click', 'tr.report-rows', ->
    row = $(event.target).parents( 'tr.report-rows' )
    table = $(event.target).parents( 'table.topics-report-table' )
    topicName = row.data( 'topicname' )
    url = table.data( 'url' )
    if topicName == undefined || url == undefined
      document.addEventListener 'click', ( (event) ->
        event = event or window.event
        target = event.target or event.srcElement
        topicName = target.textContent or target.innerText
        url = $( 'table.topics-report-table' ).attr('value')
        aUrl = document.createElement('a')
        aUrl.href = url
        paramSeparator = "?"
        if aUrl.search
          paramSeparator = "&"

        url = url + paramSeparator + "topic_name=" + encodeURIComponent( topicName )
        window.location.href = url
      )

    aUrl = document.createElement('a')
    aUrl.href = url
    paramSeparator = "?"
    if aUrl.search
      paramSeparator = "&"

    url = url + paramSeparator + "topic_name=" + encodeURIComponent( topicName )
    window.location.href = url

  $('.topic-curation .industry-container .js-industry').on 'change', ->
    optionSelected = $(this).find("option:selected")
    url = optionSelected.data 'url'
    window.location.href = url

  $('.topic-curation .curation-panel .curation-title .js-search-textedit').on 'keydown', ->
    if event.keyCode != 13
      return true

    url = $(@).data 'url'

    if !$(@).val().trim()
      window.location.href = url
      return false

    paramSeparator = "?"
    if url.search
      paramSeparator = "&"

    url = url + paramSeparator + "search=" + encodeURIComponent( $(@).val() )

    window.location.href = url

    return false

  $('.topic-curation .curation-headers .curation-header-container .header-select-all').on 'click', ->
    isSelected = $(@).hasClass("selected")

    rows = $('.topic-curation .curation-panel .curation-topic-row-container .curation-topic-row .row-select')
    delete_all = $('.topic-curation .curation-headers .curation-header-container .header-bulk-delete')
    if isSelected
      rows.removeClass("selected")
      delete_all.addClass("disabled")
    else
      rows.addClass("selected")
      delete_all.removeClass("disabled")

    $(@).toggleClass("selected")

  $('.topic-curation .curation-panel .curation-topic-row-container .curation-topic-row .row-select').on 'click', ->
    isSelected = $(@).hasClass("selected")
    $(@).toggleClass("selected")
    selectAll = $('.topic-curation .curation-headers .curation-header-container .header-select-all')
    delete_all = $('.topic-curation .curation-headers .curation-header-container .header-bulk-delete')
    cRows = $('.topic-curation .curation-panel .curation-topic-row-container .curation-topic-row .row-select').length
    cSelectedRows = $('.topic-curation .curation-panel .curation-topic-row-container .curation-topic-row .row-select.selected').length

    if cSelectedRows == 0
      delete_all.addClass("disabled")
    else
      delete_all.removeClass("disabled")

    if cRows == cSelectedRows
      selectAll.addClass( "selected" )
    else
      selectAll.removeClass( "selected" )

  $('.topic-curation .curation-panel .curation-headers .curation-header-container .header-bulk-delete').on 'click', ->
    if $(@).hasClass("disabled")
      return true

    selectedRows = $('.topic-curation .curation-panel .curation-topic-row-container .curation-topic-row .row-select.selected')

    if selectedRows.length == 0
      return true

    url = $('.topic-curation .curation-panel').data 'bulkdeleteurl'
    selectedIDs = []
    selectedRows.each ->
      row = $(@)
      row_id = row.parents('.curation-topic-row-container').data('id')
      selectedIDs.push(row_id)

    data =
      topic_name_ids: selectedIDs

    if selectedIDs.length > 0
      $.ajax
        type: "POST"
        url: url
        data: data
        success: (response) ->
          location.reload(true)

        error: (xhr, status, error) ->
          alert xhr.responseText

  $('.tag-curation .curation-panel .curation-title .js-search-textedit').on 'keydown', ->
    if event.keyCode != 13
      return true

    url = $(@).data 'url'

    if !$(@).val().trim()
      window.location.href = url
      return false

    paramSeparator = "?"
    if url.search
      paramSeparator = "&"

    url = url + paramSeparator + "search=" + encodeURIComponent( $(@).val() )

    window.location.href = url

    return false

  $('.tag-curation .curation-headers .curation-header-container .header-select-all').on 'click', ->
    isSelected = $(@).hasClass("selected")

    rows = $('.tag-curation .curation-panel .curation-tag-row-container .curation-tag-row .row-select')
    delete_all = $('.tag-curation .curation-headers .curation-header-container .header-bulk-delete')
    if isSelected
      rows.removeClass("selected")
      delete_all.addClass("disabled")
    else
      rows.addClass("selected")
      delete_all.removeClass("disabled")

    $(@).toggleClass("selected")

  $('.tag-curation .curation-panel .curation-tag-row-container .curation-tag-row .row-select').on 'click', ->
    isSelected = $(@).hasClass("selected")
    $(@).toggleClass("selected")
    selectAll = $('.tag-curation .curation-headers .curation-header-container .header-select-all')
    delete_all = $('.tag-curation .curation-headers .curation-header-container .header-bulk-delete')
    cRows = $('.tag-curation .curation-panel .curation-tag-row-container .curation-tag-row .row-select').length
    cSelectedRows = $('.tag-curation .curation-panel .curation-tag-row-container .curation-tag-row .row-select.selected').length

    if cSelectedRows == 0
      delete_all.addClass("disabled")
    else
      delete_all.removeClass("disabled")

    if cRows == cSelectedRows
      selectAll.addClass( "selected" )
    else
      selectAll.removeClass( "selected" )

  $('.tag-curation .curation-panel .curation-headers .curation-header-container .header-bulk-delete').on 'click', ->
    if $(@).hasClass("disabled")
      return true

    selectedRows = $('.tag-curation .curation-panel .curation-tag-row-container .curation-tag-row .row-select.selected')

    if selectedRows.length == 0
      return true

    url = $('.tag-curation .curation-panel').data 'bulkdeleteurl'
    selectedIDs = []
    selectedRows.each ->
      row = $(@)
      row_id = row.parents('.curation-tag-row-container').data('id')
      selectedIDs.push(row_id)

    data =
      tag_name_ids: selectedIDs

    if selectedIDs.length > 0
      $.ajax
        type: "POST"
        url: url
        data: data
        success: (response) ->
          location.reload(true)

        error: (xhr, status, error) ->
          alert xhr.responseText

  $(".topics-index.review").on 'click', '.row.js-ml-topic-section .topic-ml-subtitle span', ->
    topicVersion = $(this).data("topicversion")
    $humanTopicsForm = $(this).parents(".reply-info").find(".human-topics-form")

    hidden = $humanTopicsForm.find(".topic-form-topics-modified")
    if hidden.length > 0
      hidden[0].value = "true"

    $containers = $humanTopicsForm.find(".row.js-topic-containers[data-topicversion='"+topicVersion+"']")
    $containers.find(".human-topic-widget:not(.template)").remove()
    $template = $containers.find(".human-topic-widget.template")

    $topicSection = $(this).parents(".js-ml-topic-section").next()
    $topicMLContainers = $topicSection.find(".topic-ml-container")
    aiTopicCount = $topicMLContainers.length

    xferCount = aiTopicCount + aiTopicCount % 2

    for x in [0..xferCount-1]
      $topicMLContainer = null
      topicName = tagName = topicSentiment = null
      if x < aiTopicCount
        $topicMLContainer = $($topicMLContainers[x])
        topicName = $topicMLContainer.find(".topic-name").data("topicname")
        tagName = $topicMLContainer.find(".topic-name").data("tagname")
        tagName = null if tagName == undefined
        topicSentiment = $topicMLContainer.find(".topic-sentiment").data("topicsentiment")

      $newSection = $template.clone(true)

      $widget = $newSection.find('.review-topic-name select')
      $widget.attr("id", $widget.attr("id").replace("999", x))
      $widget.attr("name", $widget.attr("name").replace("999", x))
      $widget.val(topicName) if topicName != null
      $widget.chosen({allow_single_deselect: true})
      processTopicNameChange( $widget, true )

      $widget = $newSection.find('.topic-sentiment select')
      $widget.attr("id", $widget.attr("id").replace("999", x))
      $widget.attr("name", $widget.attr("name").replace("999", x))
      $widget.val(topicSentiment) if topicSentiment != null
      $widget.chosen({allow_single_deselect: true})

      $widget = $newSection.find('.review-tag-name select')
      if $widget.length
        $widget.attr("id", $widget.attr("id").replace("999", x))
        $widget.attr("name", $widget.attr("name").replace("999", x))
        $widget.val(tagName) if tagName != null
        $widget.chosen({allow_single_deselect: true})

      $widget = $newSection.find('.review-topic-version')
      $widget.attr("id", $widget.attr("id").replace("999", x))
      $widget.attr("name", $widget.attr("name").replace("999", x))

      $widget = $newSection.find('.review-topic-id')
      $widget.attr("id", $widget.attr("id").replace("999", x))
      $widget.attr("name", $widget.attr("name").replace("999", x))

      $newSection.find('.chosen-container').each ->
        $(this).attr 'style', 'width: 100%'

      $newSection.find('.js-tooltip').tooltip()

      $newSection.removeClass('template')
      $newSection.removeClass('hidden')
      $containers.append($newSection)

  $(".topics-index.review").on 'click', 'span.show-all-ai-topics', ->
    $replyInfo = $(this).parents(".reply-info")
    $replyInfo.find(".row.js-ml-topic-section").removeClass 'hidden'
    $replyInfo.find("span.show-all-ai-topics").addClass 'hidden'
    $replyInfo.find("span.hide-non-default-ai-topics").removeClass 'hidden'

  $(".topics-index.review").on 'click', 'span.hide-non-default-ai-topics', ->
    $replyInfo = $(this).parents(".reply-info")
    $replyInfo.find(".row.js-ml-topic-section.js-hide-by-default").addClass 'hidden'
    $replyInfo.find("span.show-all-ai-topics").removeClass 'hidden'
    $replyInfo.find("span.hide-non-default-ai-topics").addClass 'hidden'

  handleHumanAddNewRowClick = (plusButton) ->
    topicVersion = plusButton.parents(".human-add-new-row").data("topicversion")
    $humanTopicsForm = plusButton.parents(".human-topics-form")
    $containers = $humanTopicsForm.find(".row.js-topic-containers[data-topicversion='"+topicVersion+"']")
    containerCount = $containers.find(".human-topic-widget:not(.template)").length
    $template = $containers.find(".human-topic-widget.template")

    templateID = "999"
    numToCreate = 2
    for x in [0..numToCreate-1]
      $newSection = $template.clone(true)

      $widget = $newSection.find('.review-topic-name select')
      $widget.attr("id", $widget.attr("id").replace("999", containerCount+x))
      $widget.attr("name", $widget.attr("name").replace("999", containerCount+x))
      $widget.chosen({allow_single_deselect: true})

      $widget = $newSection.find('.topic-sentiment select')
      $widget.attr("id", $widget.attr("id").replace("999", containerCount+x))
      $widget.attr("name", $widget.attr("name").replace("999", containerCount+x))
      $widget.chosen({allow_single_deselect: true})

      $widget = $newSection.find('.review-tag-name select')
      if $widget.length
        $widget.attr("id", $widget.attr("id").replace("999", containerCount+x))
        $widget.attr("name", $widget.attr("name").replace("999", containerCount+x))
        $widget.chosen({allow_single_deselect: true})

      $widget = $newSection.find('.review-topic-version')
      $widget.attr("id", $widget.attr("id").replace("999", containerCount+x))
      $widget.attr("name", $widget.attr("name").replace("999", containerCount+x))

      $widget = $newSection.find('.review-topic-id')
      $widget.attr("id", $widget.attr("id").replace("999", containerCount+x))
      $widget.attr("name", $widget.attr("name").replace("999", containerCount+x))

      $newSection.find('.chosen-container').each ->
        $(this).attr 'style', 'width: 100%'

      $newSection.find('.js-tooltip').tooltip()

      $newSection.removeClass('template')
      $newSection.removeClass('hidden')
      $containers.append($newSection)

  $(".topics-index.review").on 'click', '.human-add-new-row svg', ->
    handleHumanAddNewRowClick($(this))

  $(".topics-index.review").on 'keypress', '.human-add-new-row svg', (e) ->
    if e.keyCode == 13
      e.preventDefault()
      handleHumanAddNewRowClick($(this))
    return

  # functions

  isDropdownPopulated = (element_id) ->
    return array_of_ids.includes(element_id)

  isTopicNameValueNotPresent = (value) ->
    return value != null && value != "" && value != undefined && value != "Select an Option" && value.length > 0

  processTopicNameChange = ( $theSelect, forceAllOptions = false ) ->
    topicVersion = $theSelect.data('topicversion')
    optionSelected = $theSelect.find("option:selected").val()
    # $theSelect.parents('.review-topic-name').attr('title', optionSelected).tooltip('fixTitle').tooltip('show')
    $theSelect.attr('title', optionSelected)
    $theSelect.parent().find('.chosen-container').attr('title', optionSelected)

    $humanTopicContainer = $theSelect.parents(".human-topic-container")

    if optionSelected == ''
      $tagSelect = $humanTopicContainer.find('.review-tag-name select')
      $tagSelect.val("").trigger("chosen:updated")
      return

    industry = $humanTopicContainer.data("industry")
    $tagSelect = $humanTopicContainer.find('.review-tag-name select:not(.tag-name-full-list)')
    return if $tagSelect.length == 0

    return if allTopicObjects[industry] == undefined  || allTopicObjects[industry][topicVersion] == undefined

    topicOption = undefined
    topicObjects = allTopicObjects[industry][topicVersion]
    $.each topicObjects, (i, topicObject) ->
      if topicObject.topic_name == optionSelected
        topicOption = topicObject
        return false

    return if topicOption == undefined

    tagAssociation = topicOption["tag_association"]
    $tagSelect.empty()
    if forceAllOptions || !tagAssociation || 0 == tagAssociation.length
      $tagSelect.append $('<option>',
        value: ''
        text: '')
      $tagSelect.append $('<option>',
        value: 'Create new'
        text: 'Create new')
      versionTagNames = []
      versionTagNames = allTagNames[topicVersion] if allTagNames[topicVersion] instanceof Array
      $.each versionTagNames, (i, tag) ->
        tag = tag.trim()
        $tagSelect.append $('<option>',
          value: tag
          text: tag)
      $tagSelect.addClass('.tag-name-full-list')
    else
      $tagSelect.append $('<option>',
        value: ''
        text: '')
      $tagSelect.append $('<option>',
        value: 'See All'
        text: 'See All')
      $tagSelect.append $('<option>',
        value: 'Create new'
        text: 'Create new')
      $.each tagAssociation.split(','), (i, tag) ->
        $tagSelect.append $('<option>',
          value: tag.trim()
          text: tag.trim())

    $tagSelect.val(null)
    $tagSelect.trigger('chosen:updated')

  processTagNameChange = ( $theSelect ) ->
    optionSelected = $theSelect.find("option:selected").val()
    $tagSelect = $theSelect
    $topicSelect = $theSelect.parents(".human-topic-container").find('.review-topic-name select')
    topicVersion = $topicSelect.data('topicversion')

    if $topicSelect.find("option:selected").val() == ""
      $tagSelect.val(null)
      $tagSelect.trigger('chosen:updated')
      return

    if optionSelected == "See All"
      $tagSelect.empty()
      $tagSelect.append $('<option>',
        value: ''
        text: '')
      $tagSelect.append $('<option>',
        value: 'Create new'
        text: 'Create new')
      versionTagNames = []
      versionTagNames = allTagNames[topicVersion] if allTagNames[topicVersion] instanceof Array
      $.each versionTagNames, (i, tag) ->
        tag = tag.trim()
        $tagSelect.append $('<option>',
          value: tag
          text: tag)
      $tagSelect.addClass('.tag-name-full-list')
      $tagSelect.val(null)
      $tagSelect.trigger('chosen:updated')
      return

    if optionSelected == "Create new"
      tag_name = prompt("enter tag name")
      return if tag_name == null
      tag_name = tag_name.trim()

      found_tag = false
      versionTagNames = []
      versionTagNames = allTagNames[topicVersion] if allTagNames[topicVersion] instanceof Array
      $.each versionTagNames, (i, tag) ->
        if tag.trim().toLowerCase() == tag_name.toLowerCase()
          found_tag = true
      return if found_tag

      $tagSelect.append $('<option>',
        value: tag_name
        text: tag_name)

      $tagSelect.val(tag_name)
      $tagSelect.trigger('chosen:updated')

      return

  setTopicDropDowns = (element) ->
    tag_chosen = element.children('.topic-tag-name').find('select').children("option:selected").val()
    selected_topic = element.children(".topic-name").find("select").children("option:selected").val()
    topic_name_select_child_id = element.children(".topic-name").find("select").attr("id")
    obj = [ topic_name_select_child_id, selected_topic ]
    tag_name_id = element.children(".topic-tag-name").children(".tag-name-field").attr("id")


    if selected_topic != ""
      element.children(".topic-tag-name").children(".tag-name-field").prepend(
        "<option value='Create new'>" + "Create new" + "</option>"
      )
      element.children(".topic-tag-name").children(".tag-name-field").prepend(
        "<option value='See All'>" + "See All" + "</option>"
      )
      array_of_ids.push(tag_name_id)

    if selected_topic != "" && selected_topic != "Select an Option" && selected_topic != undefined && selected_topic != null
      dropdown_id = element.children('.topic-name').children('.topic-name-field').attr('id')
      for obj in array_of_topics_json
        if obj["topic_name"] == selected_topic
          if obj["tag_association"] != null && obj["tag_association"] != undefined
            tag_association = obj["tag_association"].split(",")
            i = 0
            while i < tag_association.length
              tag_association[i] = tag_association[i].trim()
              i++

            element.children(".topic-tag-name select").val("").trigger("chosen:updated")
            for tag in tag_association
              element.children(".topic-tag-name").children(".tag-name-field").append(
                "<option value='#{tag}'>" + tag + "</option>"
              ).trigger("chosen:updated")
            element.children(".topic-tag-name").trigger("chosen:updated")
          else
            element.children(".topic-tag-name select").val("").trigger("chosen:updated")
            for tag in array_of_tag_names
              element.children(".topic-tag-name").children(".tag-name-field").append(
                "<option value='#{tag}'>" + tag + "</option>"
              ).trigger("chosen:updated")
            element.children(".topic-tag-name").trigger("chosen:updated")
            array_of_tag_names_modified.push(tag_name_id)
            array_of_ids.push(dropdown_id)
          return

    if selected_topic == ""
      array_of_ids.splice( array_of_ids.indexOf(tag_name_id), 1 )
      element.children(".topic-tag-name").children("select").empty()
      element.children(".topic-tag-name").children("select").val("").trigger("chosen:updated")

  companySelectHandleChange = () ->
    dictionary = buildSelectorParameters()
    dictionary.surveyID = null
    dictionary.questionID = null
    url = buildUrlFromSelectorParameters( dictionary )
    window.location.href = url if url

  surveySelectHandleChange = () ->
    dictionary = buildSelectorParameters()
    dictionary.questionID = null
    url = buildUrlFromSelectorParameters( dictionary )
    window.location.href = url if url

  industrySelectHandleChange = () ->
    dictionary = buildSelectorParameters()
    url = buildUrlFromSelectorParameters( dictionary )
    window.location.href = url if url

  questionSelectHandleChange = () ->
    dictionary = buildSelectorParameters()
    url = buildUrlFromSelectorParameters( dictionary )
    window.location.href = url if url

  buildSelectorParameters = () ->
    dictionary = {}

    p = $(".topics-index .question-selector" )
    p = $(".topics-mass-edit .question-selector" ) if p.length == 0
    p = $(".cec-index .question-selector" ) if p.length == 0
    p = $(".ise-index .question-selector" ) if p.length == 0
    return dictionary if p.length == 0

    companySelect = p.find( ".js-topics-company-selector" )
    dictionary.companyID = companySelect.val()

    surveySelect = p.find( ".js-topics-survey-selector" )
    dictionary.surveyID = surveySelect.val()

    questionSelect = p.find( ".js-topics-question-selector" )
    dictionary.questionID = questionSelect.val()

    noTopics = $('.js-topics-notopics-selector')
    dictionary.show_no_topics = false
    dictionary.show_no_topics = noTopics[0].checked if noTopics.length > 0

    onlyShowCategorizationDesired = $('.js-ise-onlyshowcategorizationdesired-selector')
    dictionary.only_show_categorization_desired = false
    dictionary.only_show_categorization_desired = onlyShowCategorizationDesired[0].checked if onlyShowCategorizationDesired.length > 0

    onlyShowEligibleForCategorization = $('.js-ise-onlyshoweligibleforcategorization-selector')
    dictionary.only_show_eligible_for_categorization = false
    dictionary.only_show_eligible_for_categorization = onlyShowEligibleForCategorization[0].checked if onlyShowEligibleForCategorization.length > 0

    labellingStatuses = $('.js-labelling-status-selector')
    dictionary.labelling_statuses = []
    dictionary.labelling_statuses = labellingStatuses.chosen().val() if labellingStatuses.length > 0

    qcStatuses = $('.js-qc-status-selector')
    dictionary.qc_statuses = []
    dictionary.qc_statuses = qcStatuses.chosen().val() if qcStatuses.length > 0

    labelledBy = $('.js-labelled-by-selector')
    [labellerId, labelledType] = labelledBy.chosen().val().split("::") if labelledBy.chosen().val() && labelledBy.chosen().val().includes("::")
    dictionary.labelled_by = {id: labellerId, type: labelledType } if labellerId && labelledType

    topicEligible = $('.js-topic-eligible-selector')
    dictionary.topic_eligible = []
    dictionary.topic_eligible = topicEligible.chosen().val() if topicEligible.length > 0

    getRandomDataSample = $('.js-getrandomdatasample-selector')
    dictionary.get_random_data_sample = false
    dictionary.get_random_data_sample = getRandomDataSample[0].checked if getRandomDataSample.length > 0

    if dictionary.get_random_data_sample
      quickSearchReplies = $('.js-quicksearchreplies-selector')
      dictionary.quick_search_replies = true
      dictionary.quick_search_replies = quickSearchReplies[0].checked if quickSearchReplies.length > 0

    filterLiveCompany = $('.js-filterlivecompany-selector')
    dictionary.filter_live_company = false
    dictionary.filter_live_company = filterLiveCompany[0].checked if filterLiveCompany.length > 0

    filterLiveSurvey = $('.js-filterlivesurvey-selector')
    dictionary.filter_live_survey = false
    dictionary.filter_live_survey = filterLiveSurvey[0].checked if filterLiveSurvey.length > 0

    dictionary.topicFilter = $('.js-topics-filter-selector').val()
    dictionary.answerFilter = $('.js-answer-filter').val()
    dictionary.questionFilter = $('.js-question-filter').val()
    dictionary.replyIDStart = $('.js-replyid-start').val()
    dictionary.replyIDEnd = $('.js-replyid-end').val()
    dictionary.topicVersionOverride = $('.js-topic-version-override').val()
    dictionary.industry = $('.js-topics-industry-selector').val()

    dictionary.replyCreationStartDate = $("#reply-creation-start-date").val()
    dictionary.replyCreationEndDate = $("#reply-creation-end-date").val()

    dictionary.labellingStartDate = $("#labelling-start-date").val()
    dictionary.labellingEndDate = $("#labelling-end-date").val()

    return dictionary

  buildUrlFromSelectorParameters = ( dictionary ) ->
    p = $(".topics-index .question-selector" )
    p = $(".topics-mass-edit .question-selector" ) if p.length == 0
    p = $(".cec-index .question-selector" ) if p.length == 0
    p = $(".ise-index .question-selector" ) if p.length == 0
    return null if p.length == 0

    url = p.data ( 'url' )
    url = url + "?company_id=" + dictionary.companyID
    url = url + "&survey_id=" + dictionary.surveyID if dictionary.surveyID
    url = url + "&question_id=" + dictionary.questionID if dictionary.questionID
    url = url + "&show_no_topics=" + dictionary.show_no_topics if dictionary.show_no_topics
    url = url + "&only_show_categorization_desired=" + dictionary.only_show_categorization_desired if dictionary.only_show_categorization_desired
    url = url + "&only_show_eligible_for_categorization=" + dictionary.only_show_eligible_for_categorization if dictionary.only_show_eligible_for_categorization
    url = url + "&get_random_data_sample=" + dictionary.get_random_data_sample if dictionary.get_random_data_sample
    url = url + "&quick_search_replies=" + dictionary.quick_search_replies if dictionary.quick_search_replies && (!dictionary.companyID || !dictionary.surveyID)
    if dictionary.labelling_statuses.length > 0
      for status in dictionary.labelling_statuses
        url = url + "&labelling_statuses[]=" + status
    if dictionary.qc_statuses.length > 0
      for status in dictionary.qc_statuses
        url = url + "&qc_statuses[]=" + status
    url = url + "&labeller_type=" + dictionary.labelled_by.type if dictionary.labelled_by
    url = url + "&labeller_id=" + dictionary.labelled_by.id if dictionary.labelled_by
    url = url + "&topic_eligible=" + dictionary.topic_eligible if dictionary.topic_eligible.length > 0
    url = url + "&topic_filter=" + encodeURIComponent(dictionary.topicFilter) if dictionary.topicFilter
    url = url + "&reply_id_start=" + dictionary.replyIDStart if dictionary.replyIDStart
    url = url + "&reply_id_end=" + dictionary.replyIDEnd if dictionary.replyIDEnd
    url = url + "&reply_creation_start_date=" + dictionary.replyCreationStartDate if dictionary.replyCreationStartDate
    url = url + "&reply_creation_end_date=" + dictionary.replyCreationEndDate if dictionary.replyCreationEndDate
    url = url + "&labelling_start_date=" + dictionary.labellingStartDate if dictionary.labellingStartDate
    url = url + "&labelling_end_date=" + dictionary.labellingEndDate if dictionary.labellingEndDate
    url = url + "&industry=" + dictionary.industry if dictionary.industry
    url = url + "&filter_live_company=" + dictionary.filter_live_company if dictionary.filter_live_company
    url = url + "&filter_live_survey=" + dictionary.filter_live_survey if dictionary.filter_live_survey
    return url

  findStartOfNextRow = ( widget, direction ) ->
    p = widget.closest( ".row" )
    if direction == "up"
      sib = $(p).prevAll( ".row" )
      if sib.length != 0
        firstEdit = $(sib[0]).find( ".topic-name input" )[0]
        firstEdit.trigger( 'focus' )
        return false
    else
      sib = $(p).nextAll( ".row" )
      if sib.length != 0
        firstEdit = $(sib[0]).find( ".topic-name input" )
        if firstEdit.length != 0
          firstEdit[0].trigger( 'focus' )
          return false
        else
          submitBtn = $(sib[0]).find( ".topics-form-submit input" )
          if submitBtn.length != 0
            submitBtn.trigger( 'focus' )
            return false

    return true

  topicDropdownIstoggled = () ->
    return $(".chosen-container.chosen-container-single").hasClass("chosen-with-drop")

  processTopicsOpenAll = (perform_open_all) ->
    $ctl_icon = $('.js-data-table-topics .topics-open-all .topic-expander')
    $('.table-topics tbody td.topic-details-control').each ->
      $row_icon = $(@).find('.topic-expander')
      row_is_open = $row_icon.hasClass('fa-minus-circle')
      if row_is_open != perform_open_all
        $(@).trigger 'click'

    $ctl_icon.parent().tooltip('hide')
    if perform_open_all
      $ctl_icon.removeClass('fa-plus-circle')
      $ctl_icon.addClass('fa-minus-circle')
      $ctl_icon.parent().attr('data-original-title', 'Click to collapse all replies')
    else
      $ctl_icon.removeClass('fa-minus-circle')
      $ctl_icon.addClass('fa-plus-circle')
      $ctl_icon.parent().attr('data-original-title', 'Click to expand all replies')

  fillTopicAndTagNames = ($topicContainer) ->
    $topicContainer.removeClass('topic-names-empty')
    topicVersion = $topicContainer.data('topicversion')
    industry = $topicContainer.data('industry')

    topicNames = getReviewTopicNames(topicVersion, industry)
    $topicSelect = $topicContainer.find('.review-topic-name select')
    rememberedTopicName = $topicSelect.data('topicname')
    rememberedTopicName = "" if rememberedTopicName == undefined || rememberedTopicName == null
    setTimeout (->
      # console.time('FillTopicSelect: '+topicNames.length)
      $topicSelect.find("option").remove()
      $topicSelect.append("<option value=''>"+"Select an Option"+"</option>")
      $.each topicNames, (i, topicName) ->
        if topicName == rememberedTopicName
          $topicSelect.append("<option selected value=\"#{topicName}\">"+topicName+"</option>")
        else
          $topicSelect.append("<option value=\"#{topicName}\">"+topicName+"</option>")
      # console.timeEnd('FillTopicSelect: '+topicNames.length)
      # console.time('ChosenUpdate: '+topicNames.length)
      $topicSelect.trigger("chosen:updated")
      # console.timeEnd('ChosenUpdate: '+topicNames.length)
    ), 10

    return if topicVersion == "1.0" || rememberedTopicName == ""

    $tagSelect = $topicContainer.find('.review-tag-name select')
    tagName = $tagSelect.data("tagname")
    tagAssociation = []
    if reviewAllTopicData[industry] != undefined && reviewAllTopicData[industry][topicVersion] != undefined
      # find the tag association data
      topicOption = undefined
      $.each reviewAllTopicData[industry][topicVersion], (i, topicObject) ->
        if topicObject.topic_name == rememberedTopicName
          topicOption = topicObject
          return false
      if topicOption != undefined && topicOption["tag_association"]
        tagAssociation = topicOption["tag_association"].split(",")

    tagFullList = false
    initialTagOptions = []
    if tagAssociation.includes(tagName)
      initialTagOptions = ["", "See All", "Create new"].concat( tagAssociation )
    else
      tagNames = reviewAllTagNames[topicVersion]
      tagNames = [] if allTagNames == undefined
      initialTagOptions = ["", "Create new"].concat( tagNames )
      tagFullList = true

    # $tagSelect.find("option").remove();
    $.each initialTagOptions, (i, initialTagName) ->
      if initialTagName == tagName
        $tagSelect.append("<option selected value=\"#{initialTagName}\">"+initialTagName+"</option>")
      else
        $tagSelect.append("<option value=\"#{initialTagName}\">"+initialTagName+"</option>")
    $tagSelect.trigger("chosen:updated")

    $tagSelect.addClass("tag-name-full-list") if tagFullList

    return

  handleScrollEvent = () ->
    # Function to detect if an element has been scrolled into view
    topicContainerScrolled = (elem) ->
      docViewTop = $(window).scrollTop()
      docViewBottom = docViewTop + $(window).height()
      elemTop = $(elem).offset().top
      elemTop <= docViewBottom and elemTop >= docViewTop

    $topicContainers = $('.human-topic-container.topic-names-empty')
    if $topicContainers.length
      $topicContainers.each ->
        if topicContainerScrolled( $(@) )
          # console.time('fillTopicAndTagNames')
          fillTopicAndTagNames($(@))
          # console.timeEnd('fillTopicAndTagNames')
    else
      $(window).off( "scroll" )

    return

  getReviewTopicNames = (topicVersion, industry) ->
    industry = "retail" if topicVersion == "1.0"

    # have we already cached these topics?
    if (industry in reviewAllTopicDataCache) && (topicVersion in reviewAllTopicDataCache[industry])
      return reviewAllTopicDataCache[industry][topicVersion]

    # do topic names for this topic version and industry exist?
    if reviewAllTopicData[industry] == undefined || reviewAllTopicData[industry][topicVersion] == undefined
      reviewAllTopicDataCache[industry] = { topicVersion: [] }
      return reviewAllTopicDataCache[industry][topicVersion]

    topicNames = []
    fullTopicData = reviewAllTopicData[industry][topicVersion]
    if fullTopicData.length > 0
      $.each fullTopicData, (i, topicData) ->
        topicNames.push(topicData.topic_name)

    # cache the results
    reviewAllTopicDataCache[industry] = { topicVersion: topicNames }

    return topicNames

  handleFormWidgetChange = ( $widget ) ->
    hidden = $widget.closest(".human-topics-form").find(".topic-form-topics-modified")
    if hidden.length > 0
      hidden[0].value = "true"

  # init code

  flatpickr_opts = {
    locale: I18n.locale,
    altInput: true,
    altFormat: "F j, Y",
    maxDate: "today"
  }
  $(".topics-index #reply-creation-start-date").flatpickr(flatpickr_opts)
  $(".topics-index #reply-creation-end-date").flatpickr(flatpickr_opts)
  $(".topics-index #labelling-start-date").flatpickr(flatpickr_opts)
  $(".topics-index #labelling-end-date").flatpickr(flatpickr_opts)
  $(".cec-index #reply-creation-start-date").flatpickr(flatpickr_opts)
  $(".cec-index #reply-creation-end-date").flatpickr(flatpickr_opts)
  $(".cec-index #labelling-start-date").flatpickr(flatpickr_opts)
  $(".cec-index #labelling-end-date").flatpickr(flatpickr_opts)
  $(".ise-index #reply-creation-start-date").flatpickr(flatpickr_opts)
  $(".ise-index #reply-creation-end-date").flatpickr(flatpickr_opts)
  $(".ise-index #labelling-start-date").flatpickr(flatpickr_opts)
  $(".ise-index #labelling-end-date").flatpickr(flatpickr_opts)

  array_of_topics = []
  array_of_topics_json = []
  array_of_tag_names_modified = []
  array_of_tag_names = []
  array_of_ids = []

  $(".mass-update-dropdown").chosen()
  $(".mass-update-dropdown-container").children(".chosen-container").css("margin-bottom", "20px")

  allTopicObjects = $('.topics-edit-topics .topic-name-list, .topics-index .topic-name-list').data('topicnames')
  allTopicObjects = [] if !allTopicObjects
  allTagNames = $('.topics-edit-topics .tag-name-list, .topics-index .tag-name-list').data('tagnames')

  $("#hidden-topics option").each ->
    opt = $(this).text()
    opt_to_json = JSON.parse(opt)
    opt_topic_name = opt_to_json["topic_name"]
    array_of_topics.push(opt_topic_name)
    array_of_topics_json.push(opt_to_json)

    $(this).children(".chosen-drop").children(".chosen-results").append(
      "<li class='active-result'></li>"
    )
    $(this).siblings("select").append("<option value=''>"+ "Select an Option"+"</option>")


    $(this).children(".chosen-drop").children(".chosen-results").append(
      "<li class='active-result'></li>"
    )
    $(this).siblings("select").append("<option value=''>"+ "Select an Option"+"</option>")

  $("#hidden-topics-tag-name option").each ->
    opt = $(this).text()
    array_of_tag_names.push(opt)

    $(this).children(".chosen-drop").children(".chosen-results").append(
      "<li class='active-result'></li>"
    )
    $(this).siblings("select").append("<option value=''>"+ "Select an Option"+"</option>")


    $(this).children(".chosen-drop").children(".chosen-results").append(
      "<li class='active-result'></li>"
    )
    $(this).siblings("select").append("<option value=''>"+ "Select an Option"+"</option>")

  # #######################################################################################
  # # ???
  # #######################################################################################
  $(".js.table-topic-data").dataTable()

  # #######################################################################################
  # # Mass Edit JavaScript
  # #######################################################################################
  $($('.topic-container')[0]).find( '.topic-name input' ).trigger( 'focus' )

  # #######################################################################################
  # # View Topics JavaScript
  # #######################################################################################
  viewTable = $(".view-topics-table")
  viewTopLevelOnly = viewTable.data "toplevel"
  viewColumns = [
    { orderable: true},
    { orderable: true}
  ]
  if !viewTopLevelOnly
    viewColumns.push { orderable: false}

  viewTable.DataTable( {
    paging: false
    columns: viewColumns
  } )

  # #######################################################################################
  # # Review Topics Page / ISE Labelling Page
  # #######################################################################################

  $(".human-topic-container .review-topic-name select").chosen({allow_single_deselect: true})
  $(".human-topic-container .review-tag-name select").chosen({allow_single_deselect: true})

  $(".review-topic-dropdown").chosen({allow_single_deselect: true})

  $(".js-labelling-status-selector").chosen({allow_single_deselect: true})
  $(".js-qc-status-selector").chosen({allow_single_deselect: true})
  $(".js-labelled-by-selector").chosen({allow_single_deselect: true})

  $(".cec-index.review .js-topic-eligible-selector").chosen({allow_single_deselect: true})

  # pull the stored topic and tag names
  reviewAllTopicData = $('.topics-index.review .topic-name-list').data('topicnames')
  reviewAllTagNames = $('.topics-index.review .tag-name-list').data('tagnames')

  # a growing cache of topic names per verion per industry
  reviewAllTopicDataCache = {}

  if reviewAllTopicData != undefined
    setTimeout (->
      # pre-fetch the 1.0 retail names
      getReviewTopicNames("1.0", "retail")
    ), 100
  if $('.topics-index.review').length > 0
    $('.wrapper').on 'scroll', ->
      # console.time('handleScrollEvent')
      handleScrollEvent()
      # console.timeEnd('handleScrollEvent')

  $(document).ajaxSuccess (event, xhr, settings) ->
    if settings.url.includes("edit_topic_name")
      removeRow = (e) ->
        $(e.target).parents(".tag-association-row")[0].remove()

      $(".remove-tag-association-row").on 'click', removeRow

      $(".add-tag-association-row").on 'click', (e) ->
        # increase the hidden row count
        $rowCountInput = $($("#topic_name_tag_association_form_row_count")[0])
        rowCount = parseInt($rowCountInput.val()) + 1
        $rowCountInput.val(rowCount)

        # take the first hidden row, clone it and add it to the end of the table
        rowToAdd = $(".tag-association-row").get(0).cloneNode(true)
        rowToAdd.removeAttribute("hidden")
        rowToAdd.innerHTML = rowToAdd.innerHTML.replaceAll("_0", "_" + rowCount)
        $(rowToAdd.getElementsByClassName("remove-tag-association-row")).on 'click', removeRow
        $(".tag-association-row").get(-1).after(rowToAdd)
