import React from "react";

const BooleanViewer = ({ value }) => {
  return (
    <div className="text-center">
      <i className={`fa ${value ? "fa-check" : "fa-times"}`} />
    </div>
  );
};

export { BooleanViewer };
