import React from "react";
import NoInitiationIcon from "images/no-initiation-data.svg";

const NoInitiationDataIcon = () => {
  return (
    <div>
      <img src={NoInitiationIcon} />
    </div>
  );
};

export default NoInitiationDataIcon;
