jQuery ->

  renderChartOverallImportance = ($element) ->
    try
      data = $element.data( 'data' )
      medianSatisfaction = $element.data('median-satisfaction')
      medianImportance = $element.data('median-importance')
      series = []

      for point in data
        series.push {
          x: point[ "importance" ]
          y: point[ "satisfaction" ]
          z: point[ "sampleSize" ]
          pos: point[ "qpos" ]
          name: point[ "qname" ]
        }

      Highcharts.chart $element[0],
        chart:
          type: 'bubble'
          plotBorderWidth: 1
          zoomType: 'xy'
        legend: enabled: false
        title: text: 'Key Drivers Analysis'
        subtitle: text: 'Overall'
        xAxis:
          gridLineWidth: 1
          title: text: 'Derived Importance'
          min: -1.1
          max: 1.1
          plotLines: [ {
            color: 'black'
            dashStyle: 'dot'
            width: 2
            value: 0.5
            label:
              rotation: 0
              y: 15
              style: fontStyle: 'italic'
              text: 'High Importance'
            zIndex: 3
          },  {
            color: 'red'
            dashStyle: 'dot'
            width: 2
            value: medianImportance
            label:
              rotation: 0
              y: 15
              style: fontStyle: 'italic'
              text: 'Median Importance'
            zIndex: 3
          }]
        yAxis:
          startOnTick: false
          endOnTick: false
          title: text: 'Satisfaction (%)'
          maxPadding: 0.2
          min: 0
          max: 100
          plotLines: [ {
            color: 'black'
            dashStyle: 'dot'
            width: 2
            value: 50
            label:
              align: 'right'
              style: fontStyle: 'italic'
              text: 'Strong Satisfaction'
              x: -10
            zIndex: 3
          }, {
            color: 'red'
            dashStyle: 'dot'
            width: 2
            value: medianSatisfaction
            label:
              rotation: 0
              y: 15
              style: fontStyle: 'italic'
              text: 'Median Satisfaction'
            zIndex: 3
          } ]
        tooltip:
          useHTML: true
          headerFormat: '<table>'
          pointFormat: '<tr><th colspan="2"><h3>{point.pos}</h3></th></tr>' + '<tr><th colspan="2">{point.name}</th></tr>' + '<tr><th>Importance:</th><td>{point.x}</td></tr>' + '<tr><th>Satisfaction:</th><td>{point.y}%</td></tr>' + '<tr><th>Sample Size:</th><td>{point.z}</td></tr>'
          footerFormat: '</table>'
          followPointer: true
        plotOptions: series: dataLabels:
          enabled: true
          format: '{point.pos}'
        series: [ { data: series
        } ]
    catch e
      alert( "renderChartOverallImportance exception: " + e )
    finally
      # alert( "renderChartOverallImportance ret =" + ret )


  # analyze overall importance
  $overallImportance = $('.js-overall-importance-graph')
  renderChartOverallImportance $overallImportance if $overallImportance.length

  $('.survey-trigger-rankings').DataTable( {
    paging: false
  } )

  renderChartLocationRanks = (element) ->
    ret = "not set"
    try
      data = $(element).data 'rankings'
      medianSatisfaction = data.median_satisfaction.value
      medianSampleSize = data.median_sample_size.value


      # the series to display
      # we add an initial fake entry to control scaling of bubble sizes
      series = [{
        x: 200
        y: 200
        z: 100
        poc_name: 'fake entry'
      }]

      minSampleSize = data.met_quota[0]["c_answered_nps"]
      maxSampleSize = data.met_quota[0]["c_answered_nps"]

      temp_sat = parseFloat data.met_quota[0]["satisfaction"]
      temp_sat = Math.round(temp_sat*Math.pow(10,2))/Math.pow(10,2)
      minSatisfaction = temp_sat
      maxSatisfaction = temp_sat

      for point in data.met_quota
        sat = parseFloat  point[ "satisfaction" ]
        sat = Math.round(sat*Math.pow(10,2))/Math.pow(10,2)

        z = point[ "can_see_poc" ] * 5

        if point[ "c_answered_nps" ] < medianSampleSize
          color = '#a5a5a5'
          z = 0
        else if sat < medianSatisfaction
          color = '#FC7256'
        else
          color = '#22A875'

        point_poc_name = point[ "poc_name" ]
        if point[ "can_see_poc" ] == 0
          point_poc_name = 'Name Hidden'

        series.push {
          x: point[ "c_answered_nps" ]
          y: sat
          z: z
          color: color
          poc_name: point_poc_name
        } if point[ "c_answered_nps" ] > 0
        minSampleSize = point[ "c_answered_nps" ] if point[ "c_answered_nps" ] < minSampleSize
        maxSampleSize = point[ "c_answered_nps" ] if point[ "c_answered_nps" ] > maxSampleSize
        minSatisfaction = sat if sat < minSatisfaction
        maxSatisfaction = sat if sat > maxSatisfaction

      minSampleSize = minSampleSize - 1
      maxSampleSize = maxSampleSize + 1
      minSatisfaction = Math.floor( minSatisfaction ) - 5
      maxSatisfaction = Math.floor( maxSatisfaction ) + 5

      ret = Highcharts.chart element,
        chart:
          type: 'bubble'
          plotBorderWidth: 1
          zoomType: 'xy'
          backgroundColor: "#FCFCFC"
          style:
            fontWeight: 300
          spacingLeft: 0
          spacingRight: 0
        credits: enabled: false
        legend: enabled: false
        title: text: null
        subtitle: text: null
        xAxis:
          gridLineWidth: 1
          title: text: 'Sample Size'
          min: minSampleSize
          max: maxSampleSize
          allowDecimals: false
          plotLines: [ {
            color: 'red'
            dashStyle: 'dot'
            width: 2
            value: medianSampleSize
            label:
              rotation: 0
              y: 15
              style: fontStyle: 'italic'
              text: 'Median Sample Size'
            zIndex: 3
          }]
        yAxis:
          startOnTick: false
          endOnTick: false
          title: text: 'Satisfaction (%)'
          maxPadding: 0.2
          min: minSatisfaction
          max: maxSatisfaction
          plotLines: [ {
            color: 'red'
            dashStyle: 'dot'
            width: 2
            value: medianSatisfaction
            label:
              rotation: 0
              y: 15
              style: fontStyle: 'italic'
              text: 'Median Satisfaction'
            zIndex: 3
          } ]
        zAxis:
          floor: 0
          ceiling: 125
        tooltip:
          useHTML: true
          headerFormat: '<table>'
          pointFormat: '<tr><th colspan="2"><h3>{point.poc_name}</h3></th></tr>' + '<tr><th>Sample Size:</th><td>{point.x}</td></tr>' + '<tr><th>Satisfaction:</th><td>{point.y}%</td></tr>'
          footerFormat: '</table>'
          followPointer: true
        plotOptions: series: dataLabels:
          enabled: true
          format: '{point.name}'
        series: [ { data: series
        } ]
    catch e
      alert( "renderChartLocationRanks exception: " + e )
    finally
      # alert( "renderChartLocationRanks ret =" + ret )

  # location ranks insight
  $locationRanks = $('.js-analyze-rankings-graph')
  for ranks in $locationRanks
    renderChartLocationRanks ranks

