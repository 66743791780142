import React from "react";
import styles from "./styles.module.scss";
import ErrorIcon from "images/error.svg";

const ErrorImage = () => {
  return (
    <div className={styles.image}>
      <ErrorIcon className={styles.error} />
    </div>
  );
};

export default ErrorImage;
