import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { HeaderText, ChartLegend } from "../../shared";

import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";
import { isArrayEmpty } from "../../../utils/general-helper";

import defaultOptions from "./default-options.json";

import {
  extendChartConfig,
  modelSeriesArray,
  chartSeriesHasData,
} from "../../../utils/chart-helper";
import I18n from "i18n-js";

const cx = classNames.bind(styles);

//extendOptions should be used for overriding other properties than series.data
const TopicMentionsChart = ({
  categories,
  mixedData,
  neutralData,
  negativeData,
  positiveData,
  headerText,
  extraOptions = {},
}) => {
  const options = fillData(categories, neutralData, negativeData, positiveData, mixedData);
  const extendedOptions = extendChartConfig(options, extraOptions);

  const noData = !chartSeriesHasData(extendedOptions.series);

  return (
    <div className={cx("topics-chart")}>
      {noData ? (
        <div className={cx("no-data")}>{I18n.t("no_data")}</div>
      ) : (
        <>
          <HeaderText value={headerText}>
            <div className={styles["legend-wrapper"]}>
              {extendedOptions.series?.map((s) => {
                if (!isArrayEmpty(s.data)) {
                  return <ChartLegend key={s.name} value={s.name} color={s.color} />;
                }
              })}
            </div>
          </HeaderText>
          <div className={cx("chart-content")}>
            <HighchartsReact highcharts={Highcharts} options={extendedOptions} />
          </div>
        </>
      )}
    </div>
  );
};

TopicMentionsChart.propTypes = {
  categories: PropTypes.array.isRequired,
  extraOptions: PropTypes.object,
  negativeData: PropTypes.array.isRequired,
  neutralData: PropTypes.array.isRequired,
  positiveData: PropTypes.array.isRequired,
  mixedData: PropTypes.array.isRequired,
  headerText: PropTypes.string.isRequired,
};

export default TopicMentionsChart;

//fills series and categories with defined data
const fillData = (categories, neutralData, negativeData, positiveData, mixedData) => {
  const series = modelSeriesArray(mixedData, neutralData, negativeData, positiveData);
  const options = {
    series,
    xAxis: { categories },
  };

  //merge it with defaultOptions
  return extendChartConfig(defaultOptions, options);
};
