
import React from "react";

import styles from "./ErrorMessage.module.scss";

type ErrorMessageProps = {
  errorMessages?: string;
  warningMessages?: string;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessages, warningMessages }) => {
  if (!errorMessages && !warningMessages) {
    return null;
  }

  return (
    <div className={styles.errorContainer}>
      {errorMessages &&
        <>
          Errors:<br />
          <div className={styles.error}>{errorMessages}</div>
        </>
      }
      {warningMessages &&
        <>
          Warnings:<br />
          <div className={styles.warning}>{warningMessages}</div>
        </>
      }
    </div>
  );
};
export default ErrorMessage;
