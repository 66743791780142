import React from "react";

import { screenSize } from "../constants";

import { largeScreenMinWidth, mediumScreenMinWidth } from "./ScreenBreakpoints.module.scss";

const ScreenBreakpoints = ({ userScreenSize, setUserScreenSizeCallback }) => {
  React.useEffect(() => {
    const largeBreakpoint = window.matchMedia(`(min-width: ${largeScreenMinWidth})`);
    const mediumBreakpoint = window.matchMedia(`(min-width: ${mediumScreenMinWidth})`);

    const checkUserScreen = () => {
      let newUserScreenSize;

      if (largeBreakpoint.matches) {
        newUserScreenSize = screenSize.LARGE;
      } else if (mediumBreakpoint.matches) {
        newUserScreenSize = screenSize.MEDIUM;
      } else {
        newUserScreenSize = screenSize.SMALL;
      }

      if (newUserScreenSize !== userScreenSize) {
        setUserScreenSizeCallback(newUserScreenSize);
      }
    };

    checkUserScreen();

    largeBreakpoint.addListener(checkUserScreen);
    mediumBreakpoint.addListener(checkUserScreen);

    return () => {
      largeBreakpoint.removeListener(checkUserScreen);
      mediumBreakpoint.removeListener(checkUserScreen);
    };
  }, [userScreenSize]);

  return null
}

export default ScreenBreakpoints;
