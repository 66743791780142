import classNames from "classnames";
import I18n from "i18n-js";
import React, { MouseEvent, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Button, Card, Typography } from "stingray-components";

import { ModalContext, ModalProvider } from "../../src/hooks/useModal";
import Checkbox from "../shared/Checkbox";
import SelectInput from "../shared/Select";
import { POCRestrictionsModalReturnData, PointOfContactSelectorModal } from "./PointOfContactSelectorModal";
import { UserManagementFormFields, contactMethodOptions, countPOCRestrictions } from "./userManagementTypes";

import styles from "./UserManagementForm.module.scss";

type FeaturesCardProps = {
  companyId: string | undefined;
};

export const FeaturesCard = ({ companyId }: FeaturesCardProps) => {
  const { setValue, register, control } = useFormContext<UserManagementFormFields>();

  const liveConversationsEnabled = useWatch({
    control,
    name: "liveConversationsEnabled",
  })

  const liveConversationsPocRestrictions = useWatch({
    control,
    name: "liveConversationsPocRestrictions",
  })

  const targetUserAccessibleFeatures = useWatch({ control, name: "targetUserAccessibleFeatures" })

  useEffect(() => {
    if (!targetUserAccessibleFeatures) return;

    if (!targetUserAccessibleFeatures.includes("live_conversations")) {
      setValue("liveConversationsEnabled", false)
    }

    if (!targetUserAccessibleFeatures.includes("message_center")) {
      setValue("messageCenterEnabled", false)
    }
  }, [targetUserAccessibleFeatures])

  useEffect(() => {
    if (!liveConversationsEnabled) {
      setValue("liveConversationsConfiguration.contactMethod", contactMethodOptions.find((option) => option.value === "none"))
      setValue("liveConversationsConfiguration", null)
      setValue("liveConversationsPocRestrictions", [])
    } else {
      setValue("liveConversationsConfiguration", {
        contactMethod: contactMethodOptions.find((option) => option.value === "none")
      })
    }
  }, [liveConversationsEnabled, setValue])

  const onPOCModalSubmit = (pocRestrictions: POCRestrictionsModalReturnData) => {
    setValue("liveConversationsPocRestrictions", pocRestrictions);
  };

  return (
    <Card className={styles.cardContainer}>
      <div className={styles.cardSubsection}>
        <Typography variant="h6">{I18n.t("user_management.features")}</Typography>
        <div className={styles.cardSubsectionSettings}>
          <div className={classNames(styles.feature, targetUserAccessibleFeatures?.includes("message_center") ? styles.featureEnabled : styles.featureDisabled)}>
            <Checkbox {...register("messageCenterEnabled", { disabled: !targetUserAccessibleFeatures?.includes("message_center") })}>{I18n.t("user_management.enable_message_center")}</Checkbox>
          </div>
          <div className={classNames(styles.feature, targetUserAccessibleFeatures?.includes("live_conversations") ? styles.featureEnabled : styles.featureDisabled)}>
            <Checkbox {...register("liveConversationsEnabled", { disabled: !targetUserAccessibleFeatures?.includes("live_conversations") })}>
              {I18n.t("user_management.grant_access_to_live_conversations")}
            </Checkbox>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.cardSubsection, styles.feature, liveConversationsEnabled ? styles.featureEnabled : styles.featureDisabled)}
      >
        <SelectInput options={contactMethodOptions} isDisabled={!liveConversationsEnabled} name="liveConversationsConfiguration.contactMethod">{I18n.t("user_management.live_conversations_notifications")}</SelectInput>
      </div>
      <ModalProvider onSubmit={onPOCModalSubmit} initialData={{ companyId, pocRestrictions: liveConversationsPocRestrictions }}>
        <ModalContext.Consumer>
          {(modalContext) =>
            modalContext && (
              <>
                <div
                  className={classNames(styles.cardSubsection, styles.feature, liveConversationsEnabled ? styles.featureEnabled : styles.featureDisabled)}
                >
                  <div className={styles.verticalLayout}>
                    <div className={styles.buttonHeaderContainer}>
                      <Typography variant="h7">{I18n.t("user_management.live_conversations_pocs")}</Typography>
                      <Button
                        size="medium"
                        disabled={!liveConversationsEnabled}
                        onClick={(e: MouseEvent) => {
                          e.preventDefault();
                          modalContext.toggleOpen();
                        }}
                      >
                        <Typography variant="h8">
                          {I18n.t("user_management.configure")}
                        </Typography>
                      </Button>
                    </div>
                    {liveConversationsPocRestrictions.length > 0 ? (
                      <Typography variant="h9">{I18n.t("user_management.restricted_to_locations", { locations: countPOCRestrictions(liveConversationsPocRestrictions) })}</Typography>
                    ) : (
                      <Typography variant="h9">
                        {I18n.t("user_management.using_points_of_contact_from_data_access")}
                      </Typography>
                    )}
                  </div>
                </div>
                {modalContext.open && <PointOfContactSelectorModal />}
              </>
            )
          }
        </ModalContext.Consumer>
      </ModalProvider>
    </Card>
  );
};

export default FeaturesCard;
