jQuery ->

  clear_ml_versions = () ->
      $("#ml_processor_topic_ml_version").val("");
      $("#ml_processor_tag_ml_version").val("");
      $("#ml_processor_unified_ml_version").val("");

  filter_selections_for_industry = (industry) ->
    $("#ml_processor_topic_ml_version option").each ()->  
      if $(this).text().includes(industry)
        $(this).removeClass('hidden')
      else
        $(this).addClass('hidden')
    $("#ml_processor_tag_ml_version option").each ()->  
      if $(this).text().includes(industry)
        $(this).removeClass('hidden')
      else
        $(this).addClass('hidden')
    $("#ml_processor_unified_ml_version option").each ()->  
      if $(this).text().includes(industry)
        $(this).removeClass('hidden')
      else
        $(this).addClass('hidden')
  
  $(document).ready ->
    industry = $('#ml_processor_industry').find(":selected").text()
    filter_selections_for_industry(industry)
    # change the collection topic_ml_version based on the selected industry

    if $("#ml_processor_unified_model").is(':checked')
      $(".ml_processor_topic_ml_version").toggleClass("invisible");
      $(".ml_processor_tag_ml_version").toggleClass("invisible");
      $("#ml_processor_topic_ml_version").val("");
      $("#ml_processor_tag_ml_version").val("");
    else
      $("#ml_processor_unified_ml_version").val("");
      $(".ml_processor_unified_ml_version").toggleClass("invisible");


  $('#ml_processor_industry').on 'change', ->
    industry = $('#ml_processor_industry').find(":selected").text()
    filter_selections_for_industry(industry)
    clear_ml_versions()

  $("#ml_processor_unified_model").on "change", (e) ->
    $(".ml_processor_unified_ml_version").toggleClass("invisible");
    $(".ml_processor_topic_ml_version").toggleClass("invisible");
    $(".ml_processor_tag_ml_version").toggleClass("invisible");
    if e.target.checked
      $("#ml_processor_topic_ml_version").val("");
      $("#ml_processor_tag_ml_version").val("");
    else
      $("#ml_processor_unified_ml_version").val("");

    

