import React from "react";

import classNames from "classnames";
import styles from "./Dimmer.module.scss";

const Dimmer = ({ children, className, isPhasingOut, setIsPhasingOut, onClick, visible=true }) => {
  if (!visible) return children;

  return (
    <div
      className={classNames(className, styles.dimmer, { [styles.dimmerPhaseOut]: isPhasingOut })}
      onAnimationEnd={() => setIsPhasingOut(false)}
      onClick={() => onClick && onClick()}>
      { children }
    </div>
  );
}

export default Dimmer;
