import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const SimpleProgressBar = ({ value, color = "blue" }) => {
  let valuePercent = getPercentageValue(value);

  return (
    <div className={cx("progressbar")}>
      <div className={cx("bar", "full")} />
      <div
        className={cx("bar", "percent")}
        style={{ width: valuePercent, backgroundColor: color }}
      />
    </div>
  );
};

//get corrent percentage string from value
//values such as -1,"x" will be converted to 0%
//values such as 101 will be converted to 100%
let getPercentageValue = (val) => {
  let result = 0;

  if (typeof val === "number") {
    if (val <= 0) {
      result = 0;
    } else if (val >= 100) {
      result = 100;
    } else {
      result = val;
    }
  }

  return `${result}%`;
};

export default SimpleProgressBar;
