import React from "react";
import styles from "./styles.module.scss";
import GotoIcon from "images/goto.svg";

import { DetailLinkType } from "../../../types";

const DetailLink = ({ url, openInNewTab = false }) => {
  return (
    <a className={styles.wrapper} href={url} target={openInNewTab ? "_blank" : "_self"}>
      <GotoIcon className={styles.icon} />
    </a>
  );
};

DetailLink.propTypes = DetailLinkType;

export default DetailLink;
