import React, { FC, PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Config } from "../hooks/useConfig";

const queryClient = new QueryClient();

type PageProps = PropsWithChildren<Config>

const withReactQuery = (Page: FC<PageProps>) => {
  return (props: PageProps) => {
    return (
      <QueryClientProvider client={queryClient}>
        <Page {...props} />
      </QueryClientProvider>
    );
  };
};

export default withReactQuery;
