import React from "react";
import Select from "react-select";

const LanguageSelector = ({ value, onCommit }) => {
  return (
    <select value={value} onChange={(e) => onCommit(e.target.value)} className="form-control">
      <option>English</option>
      <option>French</option>
      <option>Ask</option>
    </select>
  );
};

const CountrySelector = ({ value, onCommit }) => {
  return (
    <select
      value={value}
      onChange={(e) => onCommit(e.target.value)}
      className="form-control"
      name="country"
      id="country"
    >
      <option>Canada</option>
      <option>USA</option>
    </select>
  );
};

const ProvinceSelector = ({ value, onCommit }) => {
  const provinces = [
    { label: "Alberta", value: "Alberta" },
    { label: "British Columbia", value: "British Columbia" },
    { label: "Manitoba", value: "Manitoba" },
    { label: "New Brunswick", value: "New Brunswick" },
    { label: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
    { label: "Northwest Territories", value: "Northwest Territories" },
    { label: "Nova Scotia", value: "Nova Scotia" },
    { label: "Nunavut", value: "Nunavut" },
    { label: "Ontario", value: "Ontario" },
    { label: "Prince Edward Island", value: "Prince Edward Island" },
    { label: "Quebec", value: "Quebec" },
    { label: "Saskatchewan", value: "Saskatchewan" },
    { label: "Yukon", value: "Yukon" },
  ];
  return (
    <Select
      value={{ value: value, label: value }}
      onChange={(p) => onCommit(p.value)}
      className="basic-single"
      classNamePrefix="select"
      isSearchable={true}
      name="color"
      options={provinces}
    />
  );
};

const StateSelector = ({ value, onCommit }) => {
  const states = [
    { value: "Alaska", label: "Alaska" },
    { value: "Alabama", label: "Alabama" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "Arizona", label: "Arizona" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "District of Columbia", label: "District of Columbia" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Guam", label: "Guam" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Iowa", label: "Iowa" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Maryland", label: "Maryland" },
    { value: "Maine", label: "Maine" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Missouri", label: "Missouri" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Montana", label: "Montana" },
    { value: "North Carolina", label: "North Carolina" },
    { value: " North Dakota", label: " North Dakota" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "Nevada", label: "Nevada" },
    { value: "New York", label: "New York" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Virginia", label: "Virginia" },
    { value: "Virgin Islands", label: "Virgin Islands" },
    { value: "Vermont", label: "Vermont" },
    { value: "Washington", label: "Washington" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "West Virginia", label: "West Virginia" },
    { value: "Wyoming", label: "Wyoming" },
  ];

  return (
    <Select
      value={{ value: value, label: value }}
      onChange={(s) => onCommit(s.value)}
      className="basic-single"
      classNamePrefix="select"
      isSearchable={true}
      name="color"
      options={states}
    />
  );
};

const CheckBox = ({ value, onCommit }) => {
  return (
    <input
      checked={value}
      type="checkbox"
      className="form-check-input"
      onChange={(e) => onCommit(e.target.checked)}
    />
  );
};

export { LanguageSelector, ProvinceSelector, StateSelector, CountrySelector, CheckBox };
