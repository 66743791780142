jQuery ->

  $('#blacklist-table').DataTable({
    language: window.jQueryDataTableLanguagePacks[I18n.locale] || {}
    dom: "<'row'<'col-sm-12 dt-dom-buttons'B>>" +  "<'row'<'col-sm-6'l><'col-sm-6'f>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-5'i><'col-sm-7'p>>"
    buttons: [
      {
        extend: 'copy',
        text: "<i class='fas fa-copy'></i> #{I18n.t("copy")}",
        titleAttr: I18n.t("copy"),
        title: $('#blacklist-table').data('title')
        fieldSeparator: ','
      },
      {
        extend: 'excel',
        text: '<i class="fas fa-file-excel"></i> Excel',
        titleAttr: 'Excel'
        title: $('#blacklist-table').data('title')
        filename: 'blacklist'
      },
      {
        extend: 'csv',
        text: '<i class="fas fa-file-csv"></i> CSV',
        titleAttr: 'CSV'
        fieldSeparator: ','
        filename: 'blacklist'
      },
      {
        extend: 'pdf',
        text: '<i class="fas fa-file-pdf"></i> PDF',
        titleAttr: 'PDF'
        title: $('#blacklist-table').data('title')
        filename: 'blacklist'
      },
      {
        extend: 'print',
        text: "<i class='fas fa-print'></i> #{I18n.t("print")}",
        titleAttr: I18n.t("print"),
        title: $('#blacklist-table').data('title')
      }
    ]
    paging: true
    pagingType: "simple_numbers"
    searching: true
    ordering: true
    lengthMenu: [ 15, 25, 50, 100 ]
    pageLength: 15
    order: [[ 2, "desc" ]]
  })
