import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import { PrettyButtonType } from "../../../types";

const PrettyButton = ({ children, path, classNames = [], openInNewTab = false }) => {
  return (
    <a href={path} className={cx("link", ...classNames)} target={openInNewTab ? "_blank" : "_self"}>
      {children}
    </a>
  );
};

PrettyButton.propTypes = PrettyButtonType;

export default PrettyButton;
