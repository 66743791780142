import I18n from "i18n-js";
import React from "react";
import Tile from "./tile";

const SaveOurSalesDataTile = ({ filters, saveOurSalesData }) => {
    const { salesRecovered,
        linksClicked,
        savedSalesOpportunities,
        savedSalesOpportunitiesHistorical,
        linksSent,
         } = saveOurSalesData || {};

    const counts = saveOurSalesData && [
        {
            category: I18n.t("dashboard_lite.save_our_sales_links_sent"),
            number: linksSent,
            percentage : '-',
            state: "neutral"
        },
        {
            category: I18n.t("dashboard_lite.save_our_sales_links_clicked"),
            number: linksClicked,
            percentage : linksSent ? `${Math.round((linksClicked / linksSent) * 100)}%` : '-',
            state: "neutral"
        },
        {
            category: I18n.t("dashboard_lite.save_our_sales_potential_sales"),
            number: `$${savedSalesOpportunities}`,
            percentage : '-',
            state: "positive"
        },
        {
            category: I18n.t("dashboard_lite.save_our_sales_recovered_sales"),
            number: `$${salesRecovered}`,
            percentage : savedSalesOpportunities ? `${Math.round((salesRecovered /savedSalesOpportunities ) * 100)}%` : '-',
            state: "positive"
        }
    ];

    return (
        <Tile
            counts={counts}
            filters={filters}
            loading={!saveOurSalesData}
            overall={savedSalesOpportunities}
            sign="$"
            hideTrend={savedSalesOpportunitiesHistorical === 0}
            overallHistorical={savedSalesOpportunitiesHistorical}
            isSaveOurSales={true}
            title={I18n.t("dashboard_lite.save_our_sales_title")}
            tooltip={I18n.t("dashboard_lite.save_our_sales_tooltip")}
        />
    );
};

export default SaveOurSalesDataTile;