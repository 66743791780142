import classNames from "classnames/bind";
import I18n from "i18n-js";
import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const DashboardLitePopup = () => {
  const dismissed = document.cookie.includes("_chatter_dashboard_lite_popup_dismissed=true");
  const [opened, setOpened] = useState(!dismissed);
  const [style, setStyle] = useState({});

  const onClose = () => {
    document.cookie = `_chatter_dashboard_lite_popup_dismissed=true; path=/; max-age=${10*365*24*60*60};`; // expire in 10 years
    setOpened(false);
  }

  useEffect(() => {
    const dashboardLiteSwitchContainer = $(".dashboard-lite-switch-container")[0];
    const top = dashboardLiteSwitchContainer.offsetTop - 103;
    const left = dashboardLiteSwitchContainer.offsetLeft + dashboardLiteSwitchContainer.offsetWidth - 15;

    setStyle({
      top: `${top}px`,
      left: `${left}px`
    })
  }, []);


  return opened && 
    <div className={cx("dashboard-lite-popup")} style={style}>
      <div className={cx("close-button")} onClick={() => onClose()}>
        <i className={cx("fa-solid", "fa-x")}></i>
      </div>
      <div className={cx("container")}>
        <div className={cx("message")}>{I18n.translate("dashboard_lite.popup_message") + ""}</div>
        <i className={cx("left-arrow", "fa-solid", "fa-arrow-left-long")}></i>
      </div>
    </div>
};

export default DashboardLitePopup;
