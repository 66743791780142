import React, { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { screenSize } from "../utils/constants";
import { fetchData } from "../utils/request-helper";

import IconButton from "./IconButton";
import InboundMessage from "./InboundMessage";
import OutboundMessage from "./OutboundMessage";
import SlidingPage from "./SlidingPage";
import Header from "./Header";
import Modal from "./Modal";
import InputMessage from "./InputMessage";

import I18n from "i18n-js";
import infoCircle from "../../images/info-circle.svg";
import styles from "./Conversation.module.scss";

const Conversation = ({
  api,
  limitations,
  templates,
  user,
  userScreenSize,
  isOpen,
  setIsOpen,
  conversationInfoIsOpen,
  setConversationInfoIsOpen,
  selectedConversation,
  setSelectedConversationCallback,
  setConversationReceivedCallback,
}) => {
  const [isSlidingOut, setIsSlidingOut] = useState(false);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [latestSelectedConversation, setLatestSelectedConversation] = useState();

  const messagesEndRef = useRef();

  useEffect(() => {
    if (isOpen && selectedConversation) {
      scrollToBottom(selectedConversation?.id === latestSelectedConversation?.id ? "smooth" : "instant");
      setLatestSelectedConversation(selectedConversation);
    } else {
      setLatestSelectedConversation();
    }
  }, [isOpen, selectedConversation]);

  useEffect(() => {
    if (!isOpen && !selectedConversation && userScreenSize === screenSize.SMALL) {
      setSelectedConversationCallback();
      setLatestSelectedConversation();
    }
  }, [isOpen, selectedConversation, userScreenSize]);

  const onChevronClicked = () => {
    setIsOpen(false);
    setIsSlidingOut(true);
  }

  const scrollToBottom = (scrollToBottomBehaviour) => {
    messagesEndRef?.current?.scrollIntoView({ behavior: scrollToBottomBehaviour });
  };

  const onSendMessage = async (message, attachments) => {
    const body = new FormData();
    body.append("conversation_id", selectedConversation.id);
    body.append("message", message);
    attachments?.forEach((attachment) => body.append("mms_attachments[]", attachment.file));

    const updatedConversation = await fetchData(selectedConversation.apiEndpoints.sendMessage, {
      method: "POST",
      body,
      headers: {
        "X-CSRF-Token": api.csrfToken,
      },
    });

    if (updatedConversation) {
      setConversationReceivedCallback({ conversation: updatedConversation, bumpToTop: true });

      return true;
    } else {
      setIsErrorModalOpen(true);

      return false;
    }
  }

  const setConversationAsRead = async (conversation) => {
    const updatedConversation = await fetchData(conversation.apiEndpoints.markAsRead, {
      method: "POST",
      body: JSON.stringify({
        conversation_id: conversation.id,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": api.csrfToken,
      },
    });
    if (updatedConversation) {
      setConversationReceivedCallback({ conversation: updatedConversation, bumpToTop: false });
    }
  };

  useEffect(() => {
    if (user && !user.is_admin && selectedConversation?.isUnread) {
      setConversationAsRead(selectedConversation);
    }
  }, [selectedConversation, user]);

  const getTimestampWithoutSeconds = (timestamp) => moment.unix(timestamp).startOf("minute");
  const getFormattedTimestamp = (timestamp) => getTimestampWithoutSeconds(timestamp).calendar(null, { sameElse: "LLL" });

  const getShouldDisplayTimestamp = (previousTimestamp, replyTimestamp) => {
    return getTimestampWithoutSeconds(replyTimestamp).diff(getTimestampWithoutSeconds(previousTimestamp), "minutes") >= 1;
  }

  const conversationStart = selectedConversation?.start;
  let previousReplyTimestamp = conversationStart;

  return (
    <>
      <SlidingPage
        isClosed={!isOpen}
        isSlidingOut={isSlidingOut}
        setIsSlidingOut={setIsSlidingOut}
        closeCallback={() => setSelectedConversationCallback()}
        userScreenSize={userScreenSize}>
        <Header>
          {userScreenSize === screenSize.SMALL && (
            <IconButton onClick={() => onChevronClicked()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </IconButton>
          )}
          <span>{ selectedConversation?.name }</span>
          {!conversationInfoIsOpen && (
            <IconButton className={styles.conversationInfoButton} onClick={() => setConversationInfoIsOpen(true)}>
              <img src={infoCircle} />
            </IconButton>
          )}
        </Header>
        <div className={styles.conversation}>
          {!selectedConversation && (
            <div className={styles.welcomeMessage}>{I18n.t("live_conversations.welcome_message")}</div>
          )}
          {selectedConversation && (
          <div className={styles.container}>
            <div className={styles.date}>
              { I18n.t("live_conversations.start_of_conversation") }
              <br />
              { conversationStart ? getFormattedTimestamp(conversationStart) : "" }
            </div>
            { selectedConversation?.replies?.map((reply, index) => {
              if (!reply) return <></>;

              const { answeredAt, answer, createdAt, sentText, senderInfo, sendingFailed, sendingErrorDetails, replyAttachments } = reply;
              const inboundAttachments = replyAttachments.filter((attachment) => attachment.inbound);
              const outboundAttachments = replyAttachments.filter((attachment) => !attachment.inbound);

              let shouldDisplayTimestamp, shouldDisplayAnswerTimestamp = false;

              if (sentText || outboundAttachments.length > 0) {
                shouldDisplayTimestamp = getShouldDisplayTimestamp(previousReplyTimestamp, createdAt);
                if (shouldDisplayTimestamp) previousReplyTimestamp = createdAt;
              }

              if (answeredAt || inboundAttachments.length > 0) {
                shouldDisplayAnswerTimestamp = getShouldDisplayTimestamp(previousReplyTimestamp, answeredAt);
                if (shouldDisplayAnswerTimestamp)  previousReplyTimestamp = answeredAt;
              }

              return (
                <div key={index}>
                  { shouldDisplayTimestamp && <div className={styles.date}>{ getFormattedTimestamp(createdAt) }</div> }
                  { (sentText || outboundAttachments.length > 0) && (
                    <OutboundMessage attachments={outboundAttachments} limitations={limitations} message={sentText} senderInfo={senderInfo} sendingFailed={sendingFailed} sendingErrorDetails={sendingErrorDetails} />
                  )}

                  { shouldDisplayAnswerTimestamp && <div className={styles.date}>{ getFormattedTimestamp(answeredAt) }</div> }
                  { (answeredAt || inboundAttachments.length > 0) && (
                    <InboundMessage attachments={inboundAttachments} limitations={limitations} message={answer} conversation={selectedConversation} />
                  )}
                </div>
              );
            }) }
            <div ref={messagesEndRef} />
          </div>
          )}
        </div>
        <InputMessage disabled={!selectedConversation} hasLimit={true} limitations={limitations} templates={templates} onSendMessageCallback={onSendMessage} />
      </SlidingPage>
      <Modal
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        body={I18n.t("live_conversations.general_error")}
        actionButtonText={I18n.t("live_conversations.ok")} />
    </>
  );
}

export default Conversation;
