import React from "react";
import PropTypes from "prop-types";

import ChartEngagementWrapper from "./chart-engagement";

import { SummaryEngagement } from "../../views/engagement";
import { LoadingStatus, ErrorStatus, DummyWrapper } from "../../views/shared";

import hasLoader from "../hoc/has-loader";

import { fetchStatusType } from "../../types";

class SummaryEngagementContainer extends React.Component {
  render() {
    return (
      <div style={{ minHeight: 200, position: "relative" }}>{this.contentToRender(this.props)}</div>
    );
  }

  contentToRender(props) {
    const { status, componentData } = props;
    const { preferResponded, initiated, completed, responded } = componentData;
    const rateFactor = preferResponded ? responded : completed;
    let rate = initiated > 0 ? Math.floor((rateFactor * 100.0) / initiated) : 0;

    if (status === "error") {
      return (
        <DummyWrapper>
          <ErrorStatus />
        </DummyWrapper>
      );
    } else if (status === "loading") {
      return <LoadingStatus />;
    } else {
      return (
        <React.Fragment>
          <ChartEngagementWrapper percentage={rate} preferResponded={preferResponded} />
          <SummaryEngagement {...componentData} rate={rate} />
        </React.Fragment>
      );
    }
  }
}

SummaryEngagementContainer.propTypes = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

export default hasLoader(SummaryEngagementContainer);
