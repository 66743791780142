import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import { SimpleTable, HeaderText, PrettyButton } from "../../shared";

const TopTableContent = ({ headerText, rows, columns, detailsPath }) => {
  return (
    <div>
      <HeaderText value={headerText} />
      <SimpleTable rows={rows} columns={columns} />
      {detailsPath && (
        <div className={cx("divider")}>
          <PrettyButton className="inverted" path={detailsPath} openInNewTab={true}>
            View Report
          </PrettyButton>
        </div>
      )}
    </div>
  );
};

export default TopTableContent;
