import React from "react";
import InitiationContent from "../../views/tile-initiation";
import { PrettyLoader } from "../../views/shared";
import { getTrendIconFromDelta } from "../../utils/general-helper";
import hasLoader from "../hoc/has-loader";
import { InitiationContainerType } from "../../types";
import I18n from "i18n-js";

class InitiationContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;
    const { initiated, initiatedHistorical, initiationSeries, initiationDetailsPath } =
      componentData;
    let trendingIcon = getTrendIconFromDelta(initiated - initiatedHistorical);
    return (
      <PrettyLoader status={status}>
        <InitiationContent
          value={initiated}
          description={I18n.t("initiations")}
          initiatedHistorical={initiatedHistorical}
          initiationSeries={initiationSeries}
          icon={trendingIcon}
          detailsPath={initiationDetailsPath}
        />
      </PrettyLoader>
    );
  }
}

InitiationContainer.propTypes = InitiationContainerType;

export default hasLoader(InitiationContainer);
