import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import TooltipHelper from "../utils/tooltip-helper";

import styles from "./Attachment.module.scss";

const Attachment = ({ attachment, limitations }) => {
  const fileName = attachment.url.split("/").pop().split("?").shift();
  const attachmentIsImage = limitations?.acceptedImageMimeTypes?.includes(attachment.contentType)  

  return (
    <a href={attachment.url} target="_blank">
      <TooltipHelper tooltipMessage={fileName}>
        <div className={styles.container}>
          { attachmentIsImage && (
            <img src={attachment.url} />
          )}
          { !attachmentIsImage && (
            <>
              <FontAwesomeIcon icon={faFile}></FontAwesomeIcon>
              <span>{fileName}</span>
            </>
          )}
        </div>
      </TooltipHelper>
    </a>
  );
}

export default Attachment;
