import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import defaultOptions from "./default-options.json";

import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";

import {
  extendChartConfig,
  modelSeriesArray,
  getDisplayPercentage,
} from "../../../utils/chart-helper";

const CompletionChart = ({ percentage, extraOptions = {} }) => {
  let options = prepareData(percentage);
  let extendedOptions = extendChartConfig(options, extraOptions);
  return (
    <div className={cx("graph")}>
      <HighchartsReact highcharts={Highcharts} options={extendedOptions} />
    </div>
  );
};

CompletionChart.propTypes = {
  extraOptions: PropTypes.object,
  percentage: PropTypes.number,
};

const prepareData = (percentage) => {
  let seriesColor;
  if (!percentage) {
    seriesColor = "#e8e8e8";
  } else {
    seriesColor = "#2962FF";
  }
  let series = modelSeriesArray([{ color: seriesColor, y: getDisplayPercentage(percentage) }]);
  const extended = {
    series: series,
  };
  return extendChartConfig(defaultOptions, extended);
};

export default CompletionChart;
