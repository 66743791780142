import { trendingIconType } from "../types";

//for given delta difference return appropriate trendingIconType
const getTrendIconFromDelta = (delta) => {
  if (delta < 0) return trendingIconType.decrease;
  else if (delta === 0) return trendingIconType.none;
  else if (delta > 0) return trendingIconType.increase;
  return trendingIconType.none;
};

const getPercentageRate = (initiated, completed) => {
  return initiated > 0 ? Math.floor((completed * 100.0) / initiated) : 0;
};

const initiationSeriesHasEnoughDataPoints = (series) => {
  if (!isArrayEmpty(series)) {
    let nullCount = 0;
    series.forEach((element) => {
      if (element == null) nullCount += 1;
    });
    return nullCount <= 5;
  }
};

const isArrayEmpty = (array) => {
  if (!Array.isArray(array) || !array.length) {
    return true;
  } else {
    //check if array has other value than only nulls or undefined
    return array.filter((v) => v !== null && v !== undefined).length > 0 ? false : true;
  }
};

const isMobileOrTablet = () => {
  return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
};

const truncateString = (str, maxLen, appendDots = false, separator = " ") => {
  if (str.length <= maxLen) return str;
  let result = str.substr(0, str.lastIndexOf(separator, maxLen));
  return appendDots ? result + "..." : result;
};

const addQueryParams = (url, attributes) => {
  let result = "";
  let keys = attributes && Object.keys(attributes);

  if (keys && keys.length > 0) {
    let i = 0;

    keys.forEach((key) => {
      let keyValue = attributes[key];
      if (keyValue) {
        let eKey = encodeURIComponent(key);
        let eKeyValue = encodeURIComponent(keyValue);
        let encodedAttr = `${eKey}=${eKeyValue}`;

        result = result.concat(`${i > 0 ? "&" : ""}${encodedAttr}`);
        i++;
      }
    });

    //check if we actually added something to the URL
    if (i > 0) {
      if (url.indexOf("?") === -1) {
        result = "?" + result;
      } else {
        result = "&" + result;
      }
    }
  }

  return `${url}${result}`;
};

//Trims array from the left by deleteCount element
//Example:
//input: [3,2,1,2]
//trimArrayLeft(input,2)
//output: [1,2]
const trimArrayLeft = (array = [], deleteCount) => {
  let result = [];

  if (!isArrayEmpty(array) && array.length > deleteCount) {
    result = [...array];
    result.splice(0, deleteCount);
  }

  return result;
};

const charInsteadOfEmpty = (input, char = "-") => (input ? input : char);

const formatDate = ({ date, locale, timeZone }) => {
  return date.toLocaleDateString(locale, {
    timeZone,
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const formatTopicName = (topicName) => {
  return topicName && topicName.replace(/:/g, " / ");
};

const topicLink = ({ filters, survey, topicName }) => {
  const endDate = filters && filters.fltr_ed && filters.fltr_ed.substr(0, 10);
  const startDate = filters && filters.fltr_sd && filters.fltr_sd.substr(0, 10);
  return `/surveys/${
    survey.id
  }/topics?fltr_ed=${endDate}&fltr_interval=week&fltr_sd=${startDate}&topic_level=4&topic_name=${encodeURIComponent(
    topicName
  )}`;
};

export {
  getTrendIconFromDelta,
  initiationSeriesHasEnoughDataPoints,
  getPercentageRate,
  isArrayEmpty,
  isMobileOrTablet,
  truncateString,
  addQueryParams,
  trimArrayLeft,
  charInsteadOfEmpty,
  formatDate,
  formatTopicName,
  topicLink,
};
