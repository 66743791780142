import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { LoadingStatusType } from "../../../types";
import LoadingIcon from "images/loading-trimmed.gif";

const cx = classNames.bind(styles);

const LoadingStatus = ({ centered = true, relative = false }) => (
  <img src={LoadingIcon} className={cx("loader", { centered: centered, relative: relative })} />
);

LoadingStatus.propTypes = LoadingStatusType;

export default LoadingStatus;
