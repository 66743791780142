# These translations are lifted directly from  jQuery DataTable I18n "plug-in's". https://datatables.net/plug-ins/i18n/
jQuery ->
  jQueryDataTableLanguagePacks = {}

  jQueryDataTableLanguagePacks["fr"] = {
    "emptyTable": "Aucune donnée disponible dans le tableau",
    "lengthMenu": "Afficher _MENU_ éléments",
    "loadingRecords": "Chargement...",
    "processing": "Traitement...",
    "zeroRecords": "Aucun élément correspondant trouvé",
    "paginate": {
        "first": "Premier",
        "last": "Dernier",
        "previous": "Précédent",
        "next": "Suivant"
    },
    "aria": {
        "sortAscending": ": activer pour trier la colonne par ordre croissant",
        "sortDescending": ": activer pour trier la colonne par ordre décroissant"
    },
    "select": {
        "rows": {
            "_": "%d lignes sélectionnées",
            "0": "Aucune ligne sélectionnée",
            "1": "1 ligne sélectionnée"
        },
        "1": "1 ligne selectionnée",
        "_": "%d lignes selectionnées",
        "cells": {
            "1": "1 cellule sélectionnée",
            "_": "%d cellules sélectionnées"
        },
        "columns": {
            "1": "1 colonne sélectionnée",
            "_": "%d colonnes sélectionnées"
        }
    },
    "autoFill": {
        "cancel": "Annuler",
        "fill": "Remplir toutes les cellules avec <i>%d<\/i>",
        "fillHorizontal": "Remplir les cellules horizontalement",
        "fillVertical": "Remplir les cellules verticalement",
        "info": "Exemple de remplissage automatique"
    },
    "searchBuilder": {
        "conditions": {
            "date": {
                "after": "Après le",
                "before": "Avant le",
                "between": "Entre",
                "empty": "Vide",
                "equals": "Egal à",
                "not": "Différent de",
                "notBetween": "Pas entre",
                "notEmpty": "Non vide"
            },
            "number": {
                "between": "Entre",
                "empty": "Vide",
                "equals": "Egal à",
                "gt": "Supérieur à",
                "gte": "Supérieur ou égal à",
                "lt": "Inférieur à",
                "lte": "Inférieur ou égal à",
                "not": "Différent de",
                "notBetween": "Pas entre",
                "notEmpty": "Non vide"
            },
            "string": {
                "contains": "Contient",
                "empty": "Vide",
                "endsWith": "Se termine par",
                "equals": "Egal à",
                "not": "Différent de",
                "notEmpty": "Non vide",
                "startsWith": "Commence par"
            },
            "array": {
                "equals": "Egal à",
                "empty": "Vide",
                "contains": "Contient",
                "not": "Différent de",
                "notEmpty": "Non vide",
                "without": "Sans"
            }
        },
        "add": "Ajouter une condition",
        "button": {
            "0": "Recherche avancée",
            "_": "Recherche avancée (%d)"
        },
        "clearAll": "Effacer tout",
        "condition": "Condition",
        "data": "Donnée",
        "deleteTitle": "Supprimer la règle de filtrage",
        "logicAnd": "Et",
        "logicOr": "Ou",
        "title": {
            "0": "Recherche avancée",
            "_": "Recherche avancée (%d)"
        },
        "value": "Valeur"
    },
    "searchPanes": {
        "clearMessage": "Effacer tout",
        "count": "{total}",
        "title": "Filtres actifs - %d",
        "collapse": {
            "0": "Volet de recherche",
            "_": "Volet de recherche (%d)"
        },
        "countFiltered": "{shown} ({total})",
        "emptyPanes": "Pas de volet de recherche",
        "loadMessage": "Chargement du volet de recherche..."
    },
    "buttons": {
        "copyKeys": "Appuyer sur ctrl ou u2318 + C pour copier les données du tableau dans votre presse-papier.",
        "collection": "Collection",
        "colvis": "Visibilité colonnes",
        "colvisRestore": "Rétablir visibilité",
        "copy": "Copier",
        "copySuccess": {
            "1": "1 ligne copiée dans le presse-papier",
            "_": "%ds lignes copiées dans le presse-papier"
        },
        "copyTitle": "Copier dans le presse-papier",
        "csv": "CSV",
        "excel": "Excel",
        "pageLength": {
            "-1": "Afficher toutes les lignes",
            "1": "Afficher 1 ligne",
            "_": "Afficher %d lignes"
        },
        "pdf": "PDF",
        "print": "Imprimer"
    },
    "decimal": ",",
    "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
    "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
    "infoThousands": ".",
    "search": "Rechercher: ",
    "thousands": ".",
    "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
    "datetime": {
        "previous": "Précédent",
        "next": "Suivant",
        "hours": "Heures",
        "minutes": "Minutes",
        "seconds": "Secondes",
        "unknown": "-",
        "amPm": [
            "am",
            "pm"
        ]
    },
    "editor": {
        "close": "Fermer",
        "create": {
            "button": "Nouveaux",
            "title": "Créer une nouvelle entrée",
            "submit": "Envoyer"
        },
        "edit": {
            "button": "Editer",
            "title": "Editer Entrée",
            "submit": "Modifier"
        },
        "remove": {
            "button": "Supprimer",
            "title": "Supprimer",
            "submit": "Supprimer"
        },
        "error": {
            "system": "Une erreur système s'est produite"
        },
        "multi": {
            "title": "Valeurs Multiples",
            "restore": "Rétablir Modification"
        }
    }
  }

  jQueryDataTableLanguagePacks["es"] = {
    "emptyTable": "No hay datos disponibles en la tabla",
    "lengthMenu": "Mostrar _MENU_ elementos",
    "loadingRecords": "Cargando...",
    "processing": "Procesando...",
    "zeroRecords": "No se encontraron elementos coincidentes",
    "paginate": {
      "first": "Primero",
      "last": "Último",
      "previous": "Anterior",
      "next": "Siguiente"
    },
    "aria": {
      "sortAscending": ": activar para ordenar la columna en orden ascendente",
      "sortDescending": ": activar para ordenar la columna en orden descendente"
    },
    "select": {
      "rows": {
        "_": "%d filas seleccionadas",
        "0": "Ninguna fila seleccionada",
        "1": "1 fila seleccionada"
      },
      "1": "1 fila seleccionada",
      "_": "%d filas seleccionadas",
      "cells": {
        "1": "1 célula seleccionada",
        "_": "%d células seleccionadas"
      },
      "columns": {
        "1": "1 columna seleccionada",
        "_": "%d columnas seleccionadas"
      }
    },
    "autoFill": {
      "cancel": "Cancelar",
      "fill": "Rellenar todas las células con <i>%d<\/i>",
      "fillHorizontal": "Rellenar células horizontalmente",
      "fillVertical": "Rellenar células verticalmente",
      "info": "Ejemplo de autorelleno"
    },
    "searchBuilder": {
      "conditions": {
        "date": {
          "after": "Después de",
          "before": "Antes de",
          "between": "Entre",
          "empty": "Vacío",
          "equals": "Igual a",
          "not": "Diferente de",
          "notBetween": "No entre",
          "notEmpty": "No vacío"
        },
        "number": {
          "between": "Entre",
          "empty": "Vacío",
          "equals": "Igual a",
          "gt": "Mayor que",
          "gte": "Mayor o igual que",
          "lt": "Menor que",
          "lte": "Menor o igual que",
          "not": "Diferente de",
          "notBetween": "No entre",
          "notEmpty": "No vacío"
        },
        "string": {
          "contains": "Contiene",
          "empty": "Vacío",
          "endsWith": "Termina en",
          "equals": "Igual a",
          "not": "Diferente de",
          "notEmpty": "No vacío",
          "startsWith": "Empieza por"
        },
        "array": {
          "equals": "Igual a",
          "empty": "Vacío",
          "contains": "Contiene",
          "not": "Diferente de",
          "notEmpty": "No vacío",
          "without": "Sin"
        }
      },
      "add": "Agregar una condición",
      "button": {
        "0": "Búsqueda avanzada",
        "_": "Búsqueda avanzada (%d)"
      },
      "clearAll": "Limpiar todo",
      "condition": "Condición",
      "data": "Dato",
      "deleteTitle": "Eliminar regla de filtrado",
      "logicAnd": "Y",
      "logicOr": "O",
      "title": {
        "0": "Búsqueda avanzada",
        "_": "Búsqueda avanzada (%d)"
      },
      "value": "Valor"
    },
    "searchPanes": {
      "clearMessage": "Limpiar todo",
      "count": "{total}",
      "title": "Filtros activos - %d",
      "collapse": {
        "0": "Panel de búsqueda",
        "_": "Panel de búsqueda (%d)"
      },
      "countFiltered": "{shown} ({total})",
      "emptyPanes": "No hay paneles de búsqueda",
      "loadMessage": "Cargando panel de búsqueda..."
    },
    "buttons": {
      "copyKeys": "Presione ctrl o u2318 + C para copiar los datos de la tabla al portapapeles.",
      "collection": "Colección",
      "colvis": "Visibilidad de columnas",
      "colvisRestore": "Restaurar visibilidad",
      "copy": "Copiar",
      "copySuccess": {
        "1": "1 línea copiada al portapapeles",
        "_": "%d líneas copiadas al portapapeles"
      },
      "copyTitle": "Copiar al portapapeles",
      "csv": "CSV",
      "excel": "Excel",
      "pageLength": {
        "-1": "Mostrar todas las filas",
        "1": "Mostrar 1 fila",
        "_": "Mostrar %d filas"
      },
      "pdf": "PDF",
      "print": "Imprimir"
    },
    "decimal": ",",
    "info": "Mostrando de _START_ a _END_ de _TOTAL_ elementos",
    "infoEmpty": "Mostrando de 0 a 0 de 0 elementos",
    "infoThousands": ".",
    "search": "Buscar: ",
    "thousands": ".",
    "infoFiltered": "(filtrado de un total de _MAX_ elementos)",
    "datetime": {
      "previous": "Anterior",
      "next": "Siguiente",
      "hours": "Horas",
      "minutes": "Minutos",
      "seconds": "Segundos",
      "unknown": "-",
      "amPm": [
        "am",
        "pm"
      ]
    },
    "editor": {
      "close": "Cerrar",
      "create": {
        "button": "Nuevo",
        "title": "Crear una nueva entrada",
        "submit": "Enviar"
      },
      "edit": {
        "button": "Editar",
        "title": "Editar entrada",
        "submit": "Modificar"
      },
      "remove": {
        "button": "Eliminar",
        "title": "Eliminar",
        "submit": "Eliminar"
      },
      "error": {
        "system": "Se ha producido un error del sistema"
      },
      "multi": {
        "title": "Valores Múltiples",
        "restore": "Restaurar Modificación"
      }
    }
  }

  window.jQueryDataTableLanguagePacks = jQueryDataTableLanguagePacks
