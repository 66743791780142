import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);
import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";
import defaultOptions from "./default-options.json";
import { extendChartConfig, chartSeriesHasData } from "../../../utils/chart-helper";
import { NPSTrendingChartType } from "../../../types";
import I18n from "i18n-js";

const NPSTrendingChart = ({
  categories,
  series,
  xAxisTitle = I18n.t("datetime.prompts.month"),
  yAxisTitle = I18n.t("nps"),
}) => {
  const options = !noData && fillData(xAxisTitle, yAxisTitle, categories, series);
  const noData = !chartSeriesHasData(series);

  return (
    <div className={cx("trending-chart")}>
      {noData ? (
        <div className={cx("no-data")}>{I18n.t("no_data")}</div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

NPSTrendingChart.propTypes = NPSTrendingChartType;

export default NPSTrendingChart;

const fillData = (xAxisTitle, yAxisTitle, categories, series = []) => {
  const options = {
    series,
    tooltip: {
      useHTML: true,
      formatter: tooltipFormatter(yAxisTitle),
      backgroundColor: "#1D3060",
      style: {
        color: "#FFFFFF",
      },
    },
    xAxis: {
      categories,
      title: {
        text: xAxisTitle,
      },
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
  };

  return extendChartConfig(defaultOptions, options);
};

//Generating custom HTML for tooltip
const tooltipFormatter = (yAxisTitle) => {
  return function () {
    let s = `<div style="display:table;padding:5px;font-family: "Open Sans", sans-serif;"><div style="display:table-cell;vertical-align:middle;border-right:0.5px solid #999da0;"><b style="padding-right:15px;font-size:14px;">${this.x}</b></div>`;

    this.points.forEach((point) => {
      const { responses } = point.point;
      s += `<div style="display:table-cell;padding-left:15px;">`;

      if (this.points.length > 1) {
        s += `<div style="padding-bottom:5px;font-size:12px"><span style="color:${point.color};padding-right:5px;font-size:16px;">●</span>${point.series.name}</div>`;
      }

      s += `<div style="font-size:12px;">${yAxisTitle}: ${point.y}</div>`;
      s += `<div style="font-size:12px;">${I18n.t("responses")}: ${
        responses ? responses : 0
      }</div></div>`;
    });

    return s + "</div>";
  };
};
