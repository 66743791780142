import classNames from "classnames/bind";
import I18n from "i18n-js";
import React, { useState } from 'react';
import styles from "../styles.module.scss";
import config from '../config.json';

const cx = classNames.bind(styles);

const processInputFilters = (filters) => {
  const processedFilters = [];
  filters.forEach(filter => {
    switch (filter.type) {
      case "device_type":
        processedFilters.push({
          type: filter.type,
          value: filter.value
        });
        break;
      case "url":
        processedFilters.push({
          type: filter.type,
          value: filter.value.join(", ")
        });
        break;
      case "user_sample":
        processedFilters.push({
          type: filter.type,
          value: filter.value
        });
        break;
    }
  });
  return processedFilters;
}

const processAvailableFiltersInput = (filters) => {
  return config.types.filters.filter(type => !filters.find(filter => filter.type === type));
}

const TriggerFiltersConfiguration = ({ filters }) => {
  const inputCurrentFilters = processInputFilters(filters);
  const inputAvailableFilters = processAvailableFiltersInput(inputCurrentFilters);
  const [currentFilters, setCurrentFilters] = useState(inputCurrentFilters);
  const [availableFilters, setAvailableFilters] = useState(inputAvailableFilters);
  const [newFilterOverlayEnabled, setNewFilterOverlayEnabled] = useState(false);

  const handleAddFilter = (e) => {
    e.preventDefault();
    setNewFilterOverlayEnabled(true);
  }

  const handleAddFilterOption = (e, newFilterType) => {
    e.preventDefault();
    setNewFilterOverlayEnabled(false);
    setAvailableFilters(availableFilters.filter(filter => filter !== newFilterType));
    const newFilter = {
      type: newFilterType,
      value: null
    };
    switch (newFilterType) {
      case "url":
        newFilter.value = "";
        break;
      case "device_type":
        newFilter.value = [];
        break;
      case "user_sample":
        newFilter.value = 100;
        break;
    }
    setCurrentFilters([...currentFilters, newFilter]);
  }

  const handleRemoveTriggerType = (e, type) => {
    e.preventDefault();
    const newCurrentFilters = [...currentFilters].filter((filter) => filter.type !== type);

    setAvailableFilters([...availableFilters, type]);
    setCurrentFilters(newCurrentFilters);
  }

  const toggleDeviceType = (deviceType) => {
    const deviceTypeFilter = currentFilters.find((filter) => filter.type === "device_type");
    if (!deviceTypeFilter) {
      return;
    }
    if (deviceTypeFilter.value.includes(deviceType)) {
      deviceTypeFilter.value = deviceTypeFilter.value.filter((device) => device !== deviceType);
    } else {
      deviceTypeFilter.value.push(deviceType);
    }
    const filters = [...currentFilters];
    filters[filters.findIndex((filter) => filter.type === "device_type")] = deviceTypeFilter;
    setCurrentFilters(filters);
  }

  const handleUserSampleChange = (e) => {
    const userSampleFilter = currentFilters.find((filter) => filter.type === "user_sample");
    userSampleFilter.value = e.target.value;
    const filters = [...currentFilters];
    filters[filters.findIndex((filter) => filter.type === "user_sample")] = userSampleFilter;
    setCurrentFilters(filters);
  }

  const handleURLChange = (e) => {
    const urlFilter = currentFilters.find((filter) => filter.type === "url");
    urlFilter.value = e.target.value;
    const filters = [...currentFilters];
    filters[filters.findIndex((filter) => filter.type === "url")] = urlFilter;
    setCurrentFilters(filters);
  }
  return (
    <div className={cx("trigger-filters")}>
      <h2>{I18n.t("web_tag_configuration.trigger_filters")}</h2>
      <div className={cx("trigger-configuration-container")}>
        {currentFilters.length === 0 && (
          <div id="no_trigger_filters" dangerouslySetInnerHTML={{ __html: I18n.t("web_tag_configuration.no_filter_selected") }}></div>
        )}
        {currentFilters.map((filter) => {
          switch (filter.type) {
            
            case "url":
              return (
                <div id={"filter-type-configuration-" + filter.type} key={"filter-type-configuration-" + filter.type} className={cx(["trigger-type", "filter-url"])}>
                  <h4 className={cx("trigger-type-header")}>{I18n.t("web_tag_configuration.filter_trigger_type_url")}</h4>
                  <button onClick={(e) => handleRemoveTriggerType(e, "url")} className={cx("trigger-type-remove")}>&#10006;</button>
                  <textarea name="trigger_filter[url]" id="trigger_filter[url]" value={filter.value} onChange={(e) => handleURLChange(e)} required="required" className="form-control" />
                  <div className={cx("trigger-type-info")}>
                    <div className={cx("trigger-type-info-line")}><div className={cx("trigger-type-info-icon")}>i</div>
                      {I18n.t("web_tag_configuration.url_filter_description")}</div>
                    <div className={cx("trigger-type-info-line")} dangerouslySetInnerHTML={{ __html: I18n.t("web_tag_configuration.url_filter_example") }}></div>
                  </div>
                </div>
              );
            case "device_type":
              return (
                <div id={"filter-type-configuration-" + filter.type} key={"filter-type-configuration-" + filter.type} className={cx(["trigger-type", "filter-device-type"])}>
                  <h4 className={cx("trigger-type-header")}>{I18n.t("web_tag_configuration.filter_trigger_type_device_type")}</h4>
                  <button onClick={(e) => handleRemoveTriggerType(e, "device_type")} className={cx("trigger-type-remove")}>&#10006;</button>
                  <div className={cx("filter-device-type-devices")}>
                    <div onClick={() => toggleDeviceType("mobile")} className={cx(["filter-device-type-device", `${filter.value.includes("mobile") ? "enabled" : ""}`])}>
                      <i className={cx("fa fa-mobile")} />
                      <div className={cx("filter-device-type-device-label")}>{I18n.t("web_tag_configuration.mobile")}</div>
                    </div>
                    <div onClick={() => toggleDeviceType("tablet")} className={cx(["filter-device-type-device", `${filter.value.includes("tablet") ? "enabled" : ""}`])}>
                      <i className={cx("fa fa-tablet-alt")} />
                      <div className={cx("filter-device-type-device-label")}>{I18n.t("web_tag_configuration.tablet")}</div>
                    </div>
                    <div onClick={() => toggleDeviceType("desktop")} className={cx(["filter-device-type-device", `${filter.value.includes("desktop") ? "enabled" : ""}`])}>
                      <i className={cx("fa fa-desktop")} />
                      <div className={cx("filter-device-type-device-label")}>{I18n.t("web_tag_configuration.desktop")}</div>
                    </div>
                    <input id="trigger_filter[device_type]" name="trigger_filter[device_type]" type="hidden" value={filter.value} required="required" />
                  </div>
                </div>
              );
            case "user_sample":
              return (
                <div id={"filter-type-configuration-" + filter.type} key={"filter-type-configuration-" + filter.type} className={cx(["trigger-type", "filter-user-sample"])}>
                  <h4 className={cx("trigger-type-header")}>{I18n.t("web_tag_configuration.filter_trigger_type_user_sample")}</h4>
                  <button onClick={(e) => handleRemoveTriggerType(e, "user_sample")} className={cx("trigger-type-remove")}>&#10006;</button>
                  <div className={cx("filter-user-sample-container")}>
                    <input name="trigger_filter[user_sample]" id="trigger_filter[user_sample]" onChange={(e) => handleUserSampleChange(e)} className={cx("filter-user-sample-slider")}
                      type="range" step="1" min="1" max="100" value={filter.value} />
                    <div className={cx("filter-user-sample-label")}>{filter.value}%</div>
                  </div>
                </div>
              );
          }
        })}
        {availableFilters.length > 0 && (
          <div className={cx("add-trigger-type")}>
            <button onClick={handleAddFilter} className={cx("add-trigger-type-button")}>+</button>
            <div className={cx(["add-trigger-type-options", `${newFilterOverlayEnabled ? "enabled" : ""}`])}>
              {availableFilters.map(availableFilter => (
                <button onClick={(e) => handleAddFilterOption(e, availableFilter)} key={"filter-option-" + availableFilter} className={cx("add-trigger-type-option")}>
                  {I18n.t("web_tag_configuration.filter_trigger_type_" + availableFilter)}
                </button>
              ))}
            </div>
            <div onClick={() => setNewFilterOverlayEnabled(false)} className={cx(["add-trigger-type-overlay", `${newFilterOverlayEnabled ? "enabled" : ""}`])} />
          </div>
        )}

      </div>
    </div>

  )
}

export default TriggerFiltersConfiguration;