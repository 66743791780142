import React, { MouseEvent } from "react";
import { useMutation, useQuery } from "react-query";
import { Themes } from "stingray-components";

import withReactQuery from "../../src/hocs/withReactQuery";
import LoadingPage from "../shared/LoadingPage";
import { SelectOption } from "../shared/Select";
import CompanyFeaturesForm, { Company, CompanyFeaturesFormFields } from "./CompanyFeaturesForm";
import { Config, MutationFetcherProps } from "../../src/hooks/useConfig";

const getAvailableFeatures = async (): Promise<SelectOption[]> => {
    const response = await fetch("/api/companies/features");

    if (!response.ok) throw new Error("Could not fetch accessible features.");

    const features = await response.json()

    const formattedFeatures = features
        .filter((availableFeature: any) => availableFeature.needs_company_feature)
        .map((availableFeature: any) => ({ value: availableFeature.key, label: availableFeature.key }))

    return formattedFeatures
}

const getCompany = async (): Promise<Company> => {
    const response = await fetch("/api/companies/current_company");

    if (!response.ok) throw new Error("Could not fetch company.");

    return response.json();
};

type FeaturesFetcherProps = MutationFetcherProps & {
    data: CompanyFeaturesFormFields;
}

const updateFeatures = async ({ data, csrfToken }: FeaturesFetcherProps) => {
    const response = await fetch(`/api/companies/${data.companyId}/features`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify(
            {
                features: data.features.map((featuresOption) => featuresOption.value),
            }
        )
    })

    if (!response.ok) throw new Error("Could not update the company features.")

    return await response.json()
}

const CompanyFeatures = (config: Config) => {
    const { data: availableFeatures } = useQuery("availableFeatures", getAvailableFeatures);
    const { data: company } = useQuery("company", getCompany);

    const returnToPreviousPage = (e: MouseEvent) => location.assign(document.referrer);

    const { mutate, isLoading, isSuccess } = useMutation({ mutationFn: updateFeatures, onError: console.error, onSuccess: returnToPreviousPage });

    if (!company || !availableFeatures) return <LoadingPage />;

    return (
        <div className={Themes.CHATTER.theme}>
            <CompanyFeaturesForm
                availableFeatures={availableFeatures}
                onSubmit={(data) => mutate({ csrfToken: config.api.csrfToken, data })}
                company={company}
                onCancel={returnToPreviousPage}
                isSubmitting={isLoading || isSuccess}
            />
        </div>
    )
};

export default withReactQuery(CompanyFeatures);
