import React, { useEffect, useState } from "react";
import {  Card } from "stingray-components";

import styles from "./Metric.module.scss";
import { fetchData } from "../utils/request-helper";
import { MetricTile } from "./MetricTile";
import { Survey } from "./SurveyCategoryPanel";
import { DateRangeType } from "./ExecutiveDashboard";
import { SurveySelectorDialog } from "./SurveySelectorDialog";

interface MetricProps {
  asPercentage: boolean;
  dateRange: DateRangeType;
  parentElem: HTMLElement | null;
  surveys: Survey[];
  url?: string;
  viewMoreUrl?: string;
}

export interface MetricData {
  current_metric: number | null;
  previous_metric: number | null;
  title: string;
}

export const Metric: React.FC<MetricProps> = ({ asPercentage, dateRange, parentElem, surveys, url, viewMoreUrl }) => {
  const [metricData, setMetricData] = useState<MetricData>();

  useEffect(() => {
    if (url) {
      updateMetricData();
    }
  }, [url]);

  const updateMetricData = async () => {
    setMetricData(undefined);

    const data = await fetchData(url);

    setMetricData(data);
  }

  return (
    <Card css={{ width: "100%" }}>
      <div className={styles.metricTileWrapper}>
        <MetricTile
          dateRange={dateRange}
          metricData={metricData}
          asPercentage={asPercentage} />
        <SurveySelectorDialog parentElem={parentElem} surveys={surveys} viewMoreUrl={viewMoreUrl} />
      </div>
    </Card>
  );
}
