# Code that applies to all the application
Highcharts = require('highcharts')

jQuery ->

  if window.matchMedia
    mediaQueryList = window.matchMedia('print')
    mediaQueryList.addListener (mql) ->
      for i in [0..Highcharts.charts.length-1]
        if Highcharts.charts[i] != undefined
          Highcharts.charts[i].reflow()
      # the timeout messes with the timimg of this events
      # setTimeout (->
      #   for i in [0..Highcharts.charts.length-1]
      #     if Highcharts.charts[i] != undefined
      #       Highcharts.charts[i].reflow()
      # ), 500
