import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import TrendIcon from "../../shared/trend-icon/trend-icon";
import TooltipHelper from "../../../utils/tooltip-helper";
import SatWrapper from "../sat-wrapper/sat-wrapper";
import SatChart from "../sat-chart/sat-chart";
import { NPSContentType } from "../../../types";
import { GotoLink } from "../../shared";
import I18n from "i18n-js";

const cx = classNames.bind(styles);

const SatContent = ({ value, text = "satisfaction", icon, tooltipMessage, detailsPath }) => {
  const contentClass = cx("content", { negative: value < 0 });

  const hasData = value != null;

  return (
    <SatWrapper>
      <SatChart value={value} />
      <div className={cx("wrapper")}>
        <div className={cx("percentage")}>
          <div className={contentClass}>{hasData ? `${value}%` : I18n.t("no_data")}</div>
          {hasData && (
            <TooltipHelper tooltipMessage={tooltipMessage}>
              <div className={cx("icon")}>
                <TrendIcon icon={icon} />
              </div>
            </TooltipHelper>
          )}
        </div>
        <div className={cx("text")}>{I18n.t(text, { defaultValue: text })}</div>
      </div>
      {detailsPath && <GotoLink url={detailsPath} openInNewTab={true} />}
    </SatWrapper>
  );
};

SatContent.propTypes = NPSContentType;

export default SatContent;
