import React, { PropsWithChildren } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from "stingray-components";
import styles from './DatePicker.module.scss';

export type DatePickerInputProps = PropsWithChildren<{
    name: string;
    isRequired?: boolean;
}>;

const DatePickerInput = ({ children, name, isRequired }: DatePickerInputProps) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            rules={{ required: isRequired, validate: (value) => !!value }}
            render={({ field: { onChange, onBlur, ref } }) => (
                <div className={styles.datePickerContainer}>
                    <Typography variant="h7">{children}</Typography>
                    <Input
                        type="date"
                        onChange={onChange}  // bind onChange
                        onBlur={onBlur}
                        ref={ref}
                        className={styles.textInput}
                    />
                </div>
            )}
        />
    );
};


export default DatePickerInput;
