jQuery ->

  require("chosen-js")
  require('chosen-js/chosen.css')

  getConversationAnswerWidget = ->
    $widget = $('.js-conversation-answer')
    return $widget

  $ ->
    $conversationAnswer = $('.js-conversation-answer')
    if $conversationAnswer.length
      $conversationAnswer.trigger( 'focus' )

    $onlineSurveyActivity = $('.online-survey-activity')
    if $onlineSurveyActivity.length
      idata = $onlineSurveyActivity.data('instanceinfo')
      prefilled_answer = $onlineSurveyActivity.data('prefilled-answer')
      if idata
        processConversationResponse(idata)
      if prefilled_answer
        processPrefilledAnswer(prefilled_answer)


    # pressing Enter/Return will send the answer
    $('.js-conversation-form').on 'keypress', '.js-conversation-answer', (e) ->
      $answer = getConversationAnswerWidget()
      if e.keyCode == 13 && $answer.val().trim() != ''
        e.preventDefault()
        return addAnswerAndSubmit( $answer, true )
      return

    $('.js-conversation-button').on 'click', (e) ->
      $answer = getConversationAnswerWidget()
      if $answer.val().trim() == ''
        return
      e.preventDefault()
      return addAnswerAndSubmit( $answer, true )

    # quick select events
    $('.online-survey-activity').on 'click', '.online-survey-accepted-answer.single-click', (e) ->
      return setTextAndClick($(this).text())

    # image select events
    $('.online-survey-activity').on 'click', '.online-survey-image-answer-container', (e) ->
      $(this).parent().addClass("hide")
      reply = $(this).data('value')
      return setTextAndClick(reply)

  imageAnswerTemplate     =  "<label class='image-answer-container' ><span class='image-answer-wrapper'><span class='image-answer-icon'><i class='fas fa-image'></i></span><span class='upload-image-text'><i class='fas fa-upload' style='margin-right: 5px'></i>#{I18n.t('upload_image')}</span></span><input class='image-answer js-conversation-answer' type='file' name='answer' required='required' aria-required='true' accept='image/png, image/jpeg' /></label>"
  textAnswerTemplate      = '<textarea class="js-textarea-autosize js-conversation-answer text-answer" name="answer" rows="1" placeholder="' + I18n.t("type_your_message_here") + '" required="required" aria-required="true"/>'
  pocPickerAnswerTemplate = '<select class="form-control js-conversation-answer js-poc-picker poc-picker" name="answer" required="required" aria-required="true"/>'

  setTextAndClick = (text) ->
      # populate the sent text
      $editBox = $('.online-survey-edit-container .js-conversation-answer')
      $editBox.val(text)

      # click the button
      $('.js-conversation-button').trigger( 'click' )

  insertAcceptedAnswers = ($acceptedElt, answers, useLargeQuickSelects = false) ->
    $acceptedAnswerContainer = $acceptedElt.find( '.online-survey-accepted-answer-container' )
    $acceptedAnswerTemplate = $acceptedAnswerContainer.find( '.online-survey-accepted-answer.hide' )
    if useLargeQuickSelects
      $acceptedAnswerContainer.addClass('large')
    for answer in answers
      $newAnswer = $acceptedAnswerTemplate.clone(true)
      $newAnswer.removeClass( 'hide' )
      $newAnswer.addClass( 'large' ) if useLargeQuickSelects
      $newAnswer.html( answer )
      $acceptedAnswerContainer.append($newAnswer)

  setUpImageAnswer = (element, answers) ->
    $imageTemplate = $(".chatter-online-survey-templates").find(".online-survey-image-answer-container")
    return if $imageTemplate.length == 0
    for answer in answers
      $newImage = $imageTemplate.clone(true)
      $newImage.data('value', answer.display_value)
      $newImageCaption = $newImage.find('.online-survey-image-answer-caption')
      $newImage.removeClass('hide')
      if answer.image && answer.image.image_url
        $newImage.css('background-image', "url('#{answer.image.image_url}')")
      $newImageCaption.html(answer.display_value)
      element.append($newImage)

  insertResponsePossibilities = (instance_info, $activity) ->
    return if (instance_info.accepted_answers == undefined)

    switch instance_info.accepted_answers.type
      when 'scale'
        return if (instance_info.accepted_answers.min == undefined)
        return if (instance_info.accepted_answers.max == undefined)

        numEntries = instance_info.accepted_answers.max - instance_info.accepted_answers.min + 1
        # WesN: This limit has been temporarily raised to 30 for a demo
        # return if numEntries > 12
        return if numEntries > 30

        $scaleTemplate = $(".chatter-online-survey-templates").find(".online-survey-accepted-answers.scale.hide")
        return if $scaleTemplate.length == 0

        answers = []
        i = instance_info.accepted_answers.min
        while i <= instance_info.accepted_answers.max
          answers.push i
          i = i + 1

        $newScale = $scaleTemplate.clone()
        $newScale.removeClass( 'hide' )
        $newScale.removeClass( 'answer-given' )
        insertAcceptedAnswers($newScale, answers)
        $activity.append($newScale)

      when 'yes_no'
        return if (instance_info.accepted_answers.answers == undefined)
        return if (instance_info.accepted_answers.answers.length == 0)

        $yesnoTemplate = $(".chatter-online-survey-templates").find(".online-survey-accepted-answers.yes-no")
        return if $yesnoTemplate.length == 0

        $newYesNo = $yesnoTemplate.clone()
        $newYesNo.removeClass( 'hide' )
        $newYesNo.removeClass( 'answer-given' )
        answers = instance_info.accepted_answers.answers.map (answer) -> answer.display_value
        insertAcceptedAnswers($newYesNo, answers)
        $activity.append($newYesNo)
      when 'single_select'
        return if (instance_info.accepted_answers.answers == undefined)
        return if (instance_info.accepted_answers.answers.length == 0)

        image_answers = instance_info.accepted_answers.answers.filter (answer) ->
          answer.image && answer.image.image_url != null
        $imageContainerTemplate = $(".chatter-online-survey-templates").find(".online-survey-image-answers.hide")
        shouldShowImages = (image_answers.length > 0 && $imageContainerTemplate.length > 0)

        $singleSelectTemplate = $(".chatter-online-survey-templates").find(".online-survey-accepted-answers.single-select.hide")
        # WesN: This limit has been temporarily raised to 30 for a demo
        # shouldShowSingleSelects = (instance_info.accepted_answers.answers.length <= 6 && $singleSelectTemplate.length > 0)
        shouldShowSingleSelects = (instance_info.accepted_answers.answers.length <= 30 && $singleSelectTemplate.length > 0)

        if shouldShowImages
          $newImageContainer = $imageContainerTemplate.clone()
          $newImageContainer.removeClass('hide')
          $newImageContainer.addClass('large') if instance_info.accepted_answers && instance_info.accepted_answers.use_large_quick_selects
          setUpImageAnswer($newImageContainer, instance_info.accepted_answers.answers)
          $activity.append($newImageContainer)
        else if shouldShowSingleSelects
          $newSingleSelect = $singleSelectTemplate.clone(true)
          $newSingleSelect.removeClass( 'hide' )
          $newSingleSelect.removeClass( 'answer-given' )
          answers = instance_info.accepted_answers.answers.map (answer) -> answer.display_value
          insertAcceptedAnswers($newSingleSelect, answers, instance_info.accepted_answers.use_large_quick_selects)
          $activity.append($newSingleSelect)

  insertPocPickerPossibilities = (instance_info, $picker) ->
    return if (instance_info.accepted_answers == undefined)
    return if (instance_info.accepted_answers.type != 'point_of_contact_picker')

    $picker.empty()
    pocAlias = $('.chatter-online-survey .text-button-container').data("pocalias")
    defaultText = "#{I18n.t('select')} #{pocAlias}"
    $picker.append('<option selected="selected" value="">'+defaultText+'</option>')

    return if (instance_info.accepted_answers.answers == undefined)
    return if (instance_info.accepted_answers.answers.length == 0)

    for answer in instance_info.accepted_answers.answers
      $picker.append('<option value="'+answer.id+'">'+answer.display_value+'</option>')

    $picker.chosen({ width: "100%" })
    $picker.trigger('chosen:activate')

  processConversationResponse = (instance_info) ->
    $onlineSurvey = $('.chatter-online-survey')
    $answer = $('.chatter-online-survey .js-conversation-answer')
    $sendButton = $('.chatter-online-survey .js-conversation-button')
    $activity = $('.chatter-online-survey .online-survey-activity' )
    $scroller = $('.chatter-online-survey .online-survey-activity-container' )
    $editContainer = $onlineSurvey.find('.online-survey-edit-container')

    got_replies = instance_info["aReplies"] != undefined
    if got_replies
      sentTemplate = $(".chatter-online-survey-templates").find(".online-survey-message.sent.hide")
      receivedTemplate = $(".chatter-online-survey-templates").find(".online-survey-message.received.hide")
      sentImageTemplate = $(".chatter-online-survey-templates").find(".online-survey-message.sentimage.hide")
      idx = 0
      while idx < instance_info['aReplies'].length
        answer_type = instance_info[ "aReplies" ][ idx ][ "answer_type" ]
        reply = instance_info[ "aReplies" ][ idx ][ "content" ]
        received = instance_info[ "aReplies" ][ idx ][ "received" ]

        # if we are processing the last reply lets check to see if we
        # should place the large image buttons on top
        if idx == instance_info['aReplies'].length- 1
          if instance_info['accepted_answers'] &&
             instance_info['accepted_answers']['use_large_quick_selects'] &&
             instance_info['accepted_answers']['large_quick_selects_on_top']
            insertResponsePossibilities(instance_info, $activity)

          $inputContainer = $editContainer.find('.text-button-container')
          $pocPicker = $inputContainer.find('.poc-picker')
          if $pocPicker.length > 0
            $pocPicker.chosen('destroy')
            $pocPicker.remove()
          $inputContainer.find('.text-answer').remove()
          $inputContainer.find('.image-answer-container').remove()

          # Check which input should be rendered based on last question type
          if answer_type == 'Ingest Image'
            $inputContainer.append(imageAnswerTemplate)
            $inputContainer.find('.image-answer').on 'change', (e) ->
              if e.target.files && e.target.files[0]
                $uploadImageText = $('.image-answer-container').find('.upload-image-text')
                $uploadImageText.empty()
                reader = new FileReader()
                reader.addEventListener("load", (e) ->
                  $uploadImageText.append("<img src='#{e.target.result}'/>")
                  $uploadImageText.append("<div class='cancel-image'><i class='fas fa-times'></i></div>")
                  $uploadImageText.find('.cancel-image').on "click", (e) ->
                    e.stopPropagation()
                    e.preventDefault()
                    $uploadImageText.empty()
                    $uploadImageText.append("<i class='fas fa-upload' style='margin-right: 5px'></i>#{I18n.t('upload_image')}")
                    $('.js-conversation-answer').val('')
                )
                reader.readAsDataURL(e.target.files[0])
          else if answer_type == 'Point of Contact Picker'
            $inputContainer.append(pocPickerAnswerTemplate)
            $inputContainer.find('.js-conversation-answer').focus()
          else
            $inputContainer.append(textAnswerTemplate)
            $inputContainer.find('.js-conversation-answer').focus()
        if answer_type == "Image URL"
          newSent = sentImageTemplate.clone()
          newSent.removeClass( 'hide' )
          newSent.find( '.online-survey-message-image img' ).attr( "src", reply )
        else
          newSent = received && receivedTemplate.clone() || sentTemplate.clone()
          newSent.removeClass( 'hide' )
          newSent.find( '.online-survey-message-content' ).html( reply )

        $activity.append(newSent)
        idx++

      # lets check to see if we have already included the large image buttons on top
      if !instance_info['accepted_answers'] || !instance_info['accepted_answers']['use_large_quick_selects'] || !instance_info['accepted_answers']['large_quick_selects_on_top']
        insertResponsePossibilities(instance_info, $activity)

      if answer_type == 'Point of Contact Picker'
        $picker = getConversationAnswerWidget()
        insertPocPickerPossibilities(instance_info, $picker)

      # reset form and focus on textarea
      $('.js-conversation-form')[0].reset()
      autosize.update( $answer )
      $answer.prop('disabled', false)
      $sendButton.prop('disabled', false)

    if instance_info[ "complete" ]
      $textButtonContainer = $('.chatter-online-survey .text-button-container')
      $textButtonContainer.addClass( 'complete' )
      $textButtonContainer.empty()
      $textButtonContainer.append("<div class='complete'>#{I18n.t('thank_you_for_participating')}</div>")
      if $sendButton.length > 0
        $sendButton.parent().remove()

      $auto_redirect_url = instance_info["redirecturl"]
      if $auto_redirect_url
        setTimeout (->
          window.location.replace( $auto_redirect_url )
          return
        ), 3000
      else
        $autoreturn = $('.chatter-online-survey.auto-return')
        if $autoreturn.length > 0
          setTimeout (->
            window.history.back()
            return
          ), 3000

    # scroll to bottom of conversation
    $scroller.stop().animate( {scrollTop: $activity[0].scrollHeight}, 600)

  processPrefilledAnswer = (prefilled_answer) ->
    setTimeout (->
      setTextAndClick(prefilled_answer)
    )

  addAnswerAndSubmit = (answer, bSubmit) ->
    sendButton = $('.js-conversation-button')
    # hide the accepted answers
    $acceptedAnswers = $('.online-survey-accepted-answers').addClass('answer-given')

    $activity = $('.chatter-online-survey .online-survey-activity' )
    receivedTemplate = $(".chatter-online-survey-templates").find(".online-survey-message.received.hide")
    $dummyAnswer = receivedTemplate.clone()
    $dummyAnswer.removeClass('hide')
    # If question type was an image digest, read the file and upload it as an answer
    if answer.hasClass('image-answer')
      file = answer[0].files[0]
      reader = new FileReader()
      reader.addEventListener('load', (e) ->
        loadedFile = e.target.result
        $dummyAnswer.find('.online-survey-message-content').append("<img style='max-height:250px; max-width:250px; border-radius: 5px;' src='#{loadedFile}'/>")
        $activity.append $dummyAnswer
        form = $("#online_textbox_form")

        # submit form on enter
        if bSubmit
          # https://github.com/rails/rails/issues/29546
          # Dont use jQuery.submit()
          #form = document.forms["online_textbox_form"]
          #form.dispatchEvent(new Event('submit', { bubbles: true }))
          #$("#online_textbox_form").submit()
          form = $("#online_textbox_form")

          data_params =
            from: form.find('input[name=from]').val()
            answer: loadedFile
            requires_image_upload: true

          if form.find('input[name=simulator]').length > 0
            data_params['simulator'] = form.find('input[name=simulator]').val()

          if form.find('input[name=online]').length > 0
            data_params['online'] = form.find('input[name=online]').val()

          $imageAnswerContainer = $('.chatter-online-survey').find('.image-answer-container')
          $imageAnswerContainer.addClass('uploading')
          $imageAnswerContainer.empty()
          $imageAnswerContainer.append("<span>#{I18n.t('uploading')}</span>")

          $.ajax
            url: form.attr('action')
            type: form.attr('method')
            data: data_params
            success: (data) ->
              processConversationResponse(data)
            error: (data) ->
              alert( "Error uploading image!" )

        # scroll to bottom of conversation
        $activity.scrollTop($activity[0].scrollHeight);

        # disable future answers until we get response from server
        answer.attr 'disabled', 'disabled'
        .val ''

        sendButton.attr('disabled', 'disabled') if sendButton.length

        return true
      )
      reader.readAsDataURL(file)
    else
      theAnswer = answer.val().trim()
      answer.val( theAnswer )
      if answer.val() == ""
        answer.css("border-color", "#FC7256")
        answer.stop(true,true).delay( 2000 ).animate({
          borderTopColor: '#E8E8E8',
          borderBottomColor: '#E8E8E8',
          borderLeftColor: '#E8E8E8',
          borderRightColor: '#E8E8E8'
        }, 1000)
        return false


      $dummyAnswer.find( '.online-survey-message-content' ).text theAnswer

      # add an answer speech bubble with submission
      $activity.append $dummyAnswer

      # submit form on enter
      if bSubmit
        # https://github.com/rails/rails/issues/29546
        # Dont use jQuery.submit()
        #form = document.forms["online_textbox_form"]
        #form.dispatchEvent(new Event('submit', { bubbles: true }))
        #$("#online_textbox_form").submit()
        form = $("#online_textbox_form")

        data_params =
          from: form.find('input[name=from]').val()
          answer: theAnswer

        if form.find('input[name=simulator]').length > 0
          data_params['simulator'] = form.find('input[name=simulator]').val()

        if form.find('input[name=online]').length > 0
          data_params['online'] = form.find('input[name=online]').val()

        $.ajax
          url: form.attr('action')
          type: form.attr('method')
          data: data_params
          success: (data) ->
            processConversationResponse(data)
          error: (request) ->
            alert(request.statusText + "(" + request.status + "): " + request.responseText)

      # scroll to bottom of conversation
      $activity.scrollTop($activity[0].scrollHeight);

      # disable future answers until we get response from server
      answer.attr('disabled', 'disabled').val ''

      sendButton.attr('disabled', 'disabled') if sendButton.length

      return true

  addAnswer = (answer, answer_type) ->
    $activity = $('.chatter-online-survey .online-survey-activity' )
    receivedTemplate = $(".chatter-online-survey-templates").find(".online-survey-message.received.hide")
    dummyAnswer = receivedTemplate.clone()
    dummyAnswer.removeClass('hide')
    if answer_type == 'Ingest Image'
      dummyAnswer.find( '.online-survey-message-content' ).html( "<img style='max-height:250px; max-width:250px; border-radius: 5px;' src='#{answer}' />" )
    else
      dummyAnswer.find( '.online-survey-message-content' ).html( answer )
    # add an answer speech bubble with submission
    $activity.append dummyAnswer


  addQuestion = (question) ->
    $activity = $('.chatter-online-survey .online-survey-activity' )
    sentTemplate = $(".chatter-online-survey-templates").find(".online-survey-message.sent.hide")
    newSent = sentTemplate.clone()
    newSent.removeClass( 'hide' )
    newSent.find( '.online-survey-message-content' ).html( question )

    $activity.append(newSent)
    $scroller = $('.chatter-online-survey .online-survey-activity-container' )
    $scroller.stop().animate( {scrollTop: $activity[0].scrollHeight}, 600)

  $('.chatter-online-survey .conversation-history').each (i) ->
    content = $(@).data 'content'
    type = $(@).data 'type'
    answer_type = $(@).data 'answer-type'
    if type == "question"
      addQuestion(content)
      if i == $('.chatter-online-survey .conversation-history').length - 1
        $inputContainer = $('.chatter-online-survey').find('.text-button-container')
        $inputContainer.find('.text-answer').remove()
        $inputContainer.find('.image-answer-container').remove()
        if answer_type == 'Ingest Image'
          $inputContainer.append(imageAnswerTemplate)
          $inputContainer.find('.image-answer').on 'change', (e) ->
            if e.target.files && e.target.files[0]
              $uploadImageText = $('.image-answer-container').find('.upload-image-text')
              $uploadImageText.empty()
              reader = new FileReader()
              reader.addEventListener("load", (e) ->
                $uploadImageText.append("<img src='#{e.target.result}'/>")
                $uploadImageText.append("<div class='cancel-image'><i class='fas fa-times'></i></div>")
                $uploadImageText.find('.cancel-image').on "click", (e) ->
                  e.stopPropagation()
                  e.preventDefault()
                  $uploadImageText.empty()
                  $uploadImageText.append("<i class='fas fa-upload' style='margin-right: 5px'></i>#{I18n.t('upload_image')}")
                  $('.js-conversation-answer').val('')
              )
              reader.readAsDataURL(e.target.files[0])
        else if answer_type == 'Point of Contact Picker'
          $inputContainer.append(pocPickerAnswerTemplate)
          $inputContainer.find('.js-conversation-answer').focus()
        else
          $inputContainer.append(textAnswerTemplate)
          $inputContainer.find('.js-conversation-answer').focus()
    else
      addAnswer(content, answer_type)

  $('.chatter-online-survey .accepted_answers').each ->
    accepted_answers = $(@).data 'acceptedanswers'
    if accepted_answers.type == 'point_of_contact_picker'
      $picker = getConversationAnswerWidget()
      insertPocPickerPossibilities({accepted_answers: accepted_answers}, $picker)
    else
      $activity = $('.chatter-online-survey .online-survey-activity' )
      insertResponsePossibilities({accepted_answers: accepted_answers}, $activity )
