import Highcharts from "highcharts"
import parse from "url-parse"

jQuery ->

  return unless $('.js-overview-question.fullwidth').length

  # functions

  renderQuestionGraph = (element) ->
    return false unless $(element).hasClass('never-shown')
    $(element).removeClass('never-shown')

    overviewQuestion = $(element).closest('.js-overview-question')
    return false unless overviewQuestion.length
    graphView = overviewQuestion.find('.fullwidth-tab-graph-view')
    return false unless graphView.length

    overviewPanel = $(overviewQuestion).find('.js-overview-panel-graph')
    overviewPanel.addClass('loading')
    $.ajax graphView.data('api'),
      type: 'GET'
      error: (e) ->
        console.warn(e)
      success: (data, textStatus, jqXHR) ->
        overviewPanel.removeClass('loading').append(data)
        questionGraph = $(overviewPanel).find('.fullwidth-question-summary')
        if questionGraph.length
          switch overviewQuestion.data('answerType')
            when 'Yes/No'
              renderYesNoGraph questionGraph
            when 'NPS'
              npsGauge = $(overviewPanel).find('.fullwidth-question-nps-gauge')
              npsGauge.length && renderNPSGauge npsGauge
              renderNPSGraph questionGraph
            when 'Scale'
              osatGauge = $(overviewPanel).find('.fullwidth-question-osat-gauge')
              osatGauge.length && renderSatGauge osatGauge
              renderSatGraph questionGraph
            when 'Multiple Choice'
              renderMultipleChoiceSelectGraph questionGraph
            when 'Multiple Select'
              renderMultipleChoiceSelectGraph questionGraph
            when 'State/Prov'
              renderStateProvGraph questionGraph

        if overviewQuestion.data('answerType') == 'Free Text'
          if $(overviewQuestion).find('.fullwidth-question-free-text-graph').data('topicCount') == 0
            $(overviewQuestion).find('.js-action.js-overview-tab-responses-button').click()
            $(overviewQuestion).find('.js-action.js-overview-tab-graph-button').remove()

        sampleSize = overviewPanel.find('.fullwidth-question-graph-container').data('count')
        overviewPanel.parent().find('.js-sample-size-count').text(sampleSize)

  renderQuestionResponses = (element) ->
    overviewQuestion = $(element)
    return false unless overviewQuestion.length
    responsesView = overviewQuestion.find('.fullwidth-tab-responses-view')
    return false unless responsesView.hasClass('never-shown')

    responsesView.removeClass('never-shown')
    overviewPanel = $(overviewQuestion).find('.js-overview-panel-log')
    overviewPanel.addClass('loading')

    $.ajax responsesView.data('api'),
      type: 'GET'
      error: (e) ->
        console.warn(e)
      success: (data, textStatus, jqXHR) ->
        overviewPanel.removeClass('loading').append(data)
        t = $(overviewQuestion).find('table.table-log')
        t.DataTable({
          language: window.jQueryDataTableLanguagePacks[I18n.locale] || {}
          autoWidth:  false
          processing: true
          serverSide: true
          scrollX:    true
          order: [[responsesView.data('column-count') - 1, "desc"]]
          ajax: responsesView.data('api-detail'),
          createdRow: (r,d,i) =>
            conversationUrl = $(".fullwidth-question-log-container").data("conversation-api")
            conversationUrl = parse(conversationUrl, true)
            conversationUrl.query.filter = d[0]
            $(r).find("td").wrapInner("<a href='#{conversationUrl}' target='_blank'></a>")
        })

  isVisible = (element) ->
    return false unless offset = $(element).offset()
    return false unless windowHeight = window.innerHeight
    return true if 0 < offset.top < windowHeight
    return false

  renderVisibleQuestionGraphs = ->
    $('.fullwidth-tab-graph-view.never-shown').each ->
      renderQuestionGraph(@) if isVisible(@)

  renderMultipleChoiceSelectGraph = (element) ->

    graphColours = [
      '#2BC2AC',
      '#2962FF',
      '#3C379F',
      '#E33AB9',
      '#7322FF',
      '#E6AC1C',
      '#E6AC1C',
      '#A3C9FE',
      '#EA8CD0',
      '#002B9F'
    ]

    counts = element.data( 'counts' )
    data = []

    # pull the categories
    categories = counts["order"]

    # only process the top 10 categories
    categories = categories.slice(0,10)

    # get the sample sizes
    for val in categories
      if val
        sampleSize = counts["counts"][val.toString()]
        data.push( sampleSize )

    otherIndex = categories.indexOf("other")
    if otherIndex != -1
      categories[otherIndex] = I18n.t('other')

    # translations
    for i in [0..categories.length-1]
      if counts["display_values"][categories[i]] != undefined
        categories[i] = counts["display_values"][categories[i]]

    summaryHeight = $(element).parent().find('table').outerHeight()
    ret = Highcharts.chart element[0],
      chart:
        type: 'bar'
        style: fontFamily: 'Open Sans'
        spacing: [0,0,0,0]
        height: summaryHeight
      title: text: null
      xAxis:
        type: 'category'
        categories: categories
        title: text: null
        tickWidth: 1
        gridLineWidth: 1
        gridLineColor: '#D2D2D4'
        lineWidth: 0
        tickLength: 60
        labels:
          y: 5
          style:
            fontSize: '16px'
            color: '#3E3E40'
      yAxis:
        min: 0
        title:
          text: null
          align: 'high'
        gridLineWidth: 0
        allowDecimals: false
      plotOptions:
        bar: dataLabels: enabled: false
        series: minPointLength: 3
      credits: enabled: false
      series: [ {
        name: null
        showInLegend: false
        colors: graphColours
        colorByPoint: true
        data: data
      } ]
      tooltip:
        valueSuffix: " #{I18n.t('fullwidth_samples')}"
        enabled: true
        pointFormat: '<b>{point.y}</b><br/>'

    ret

  renderYesNoGraph = (element) ->
    ret = "not set"
    counts = element.data( 'counts' )
    seriesData = [
      {
        y: counts["yes"]
        color: '#2BC2AC'
      }
      {
        y: counts["no"]
        color: '#2962FF'
      }
    ]
    if counts["other"]
      seriesData.push(
        {
          y: counts["other"]
          color: '#D2D2D4'
        }
      )

    summaryHeight = $(element).parent().find('table').outerHeight()
    ret = Highcharts.chart element[0],
      chart:
        type: 'bar'
        style: fontFamily: 'Open Sans'
        spacing: [0,0,0,0]
        height: summaryHeight
      title: text: null
      xAxis:
        type: 'category'
        categories: [
          I18n.t('yes')
          I18n.t('no')
          I18n.t('other')
        ]
        title: text: null
        tickWidth: 1
        gridLineWidth: 1
        gridLineColor: '#D2D2D4'
        lineWidth: 0
        tickLength: 60
        labels:
          y: 5
          style:
            fontSize: '16px'
            color: '#3E3E40'
        # labels: align: 'left'
      yAxis:
        min: 0
        title:
          text: null
          align: 'high'
        gridLineWidth: 0
        allowDecimals: false
      plotOptions:
        bar: dataLabels: enabled: false
        series: minPointLength: 3
      credits: enabled: false
      series: [ {
        name: null
        showInLegend: false
        # pointWidth: 30
        data: seriesData
      } ]
      tooltip:
        valueSuffix: " #{I18n.t('fullwidth_samples')}"
        enabled: true
        pointFormat: '<b>{point.y}</b><br/>'

    ret

  renderSatGauge = (element) ->
    ret = "not set"

    counts = element.data('counts')
    satisfactionScore = Math.round(counts.satisfaction)
    graphColor = '#2962FF'
    backgroundColor = '#D2D2D4'

    # gaugeOptions =
        # style: fontFamily: 'Open Sans'
        # spacing: [0,0,0,0]
        # height: '67%'
      # title: text: null

    ret = Highcharts.chart(element[0],
      chart:
        style: fontFamily: 'Open Sans'
        type: 'solidgauge'
        height: '75%'
        spacing: [0,0,0,0]
      yAxis:
        min: 0
        max: 100
        title: text: null
        minColor: graphColor
        maxColor: graphColor
        lineWidth: 0
        tickWidth: 0
        minorTickInterval: null
        labels: enabled: false
      credits: enabled: false
      title:
        text: I18n.t('overall_satisfaction')
        y: 150
        floating: true
        style:
          color: '#3E3E40'
          fontSize: '16px'
      plotOptions: solidgauge:
        dataLabels:
          y: 16
          verticalAlign: 'bottom'
          borderWidth: 0
          useHTML: true
      exporting: enabled: false
      tooltip: enabled: false
      pane:
        startAngle: -90
        endAngle: 90
        background:
          shape: 'arc'
          backgroundColor: backgroundColor
          innerRadius: '75%'
          outerRadius: '100%'
      series: [ {
        data: [ {
          y: satisfactionScore
          innerRadius: '75%'
          outerRadius: '100%'
        } ]
        dataLabels: format: '<div style="text-align:center">' + '<span style="font-size:31.25px">{y}</span>' + '</div>'
      } ]
    )

    ret

  renderSatGraph = (element) ->
    ret = "not set"

    counts = element.data( "counts" )
    scaleMin = counts["minimum"]
    scaleMax = counts["maximum"]
    data = []

    for val in [scaleMin..scaleMax].slice(0,20)
      sampleSize = counts[val.toString()]
      sampleSize = 0 if sampleSize == null
      data.push( [ val, sampleSize ] )

    summaryHeight = $(element).parent().find('.fullwidth-question-osat-chart').innerHeight()
    ret = Highcharts.chart element[0],
      chart:
        type: 'column'
        style: fontFamily: 'Open Sans'
        height: summaryHeight - 30
      title: text: null
      xAxis:
        type: 'category'
        gridLineWidth: 1
        gridLineColor: '#D2D2D4'
        lineWidth: 0
        labels:
          style:
            fontSize: '16px'
            color: '#3E3E40'
      yAxis:
        min: 0
        title: text: null
        gridLineWidth: 0
        lineWidth: 1
        lineColor: '#D2D2D4'
        labels:
          style:
            fontSize: '16px'
            color: '#3E3E40'
      legend: enabled: false
      tooltip:
        valueSuffix: " #{I18n.t('fullwidth_samples')}"
        enabled: true
        pointFormat: '<b>{point.y}</b><br/>'
      plotOptions:
        bar: dataLabels: enabled: false
        series: minPointLength: 3
      credits: enabled: false
      series: [ {
        name: null
        color: '#5E89FF'
        data: data
      } ]

    ret

  renderNPSGauge = (element) ->
    ret = "not set"

    counts = element.closest('.fullwidth-question-nps-graph-container').data('counts')
    netPromoterScore = Math.round(counts["nps"])

    if netPromoterScore == 0
      graphColor = '#D2D2D4'
      backgroundColor = '#D2D2D4'
    else if netPromoterScore > 0
      graphColor = '#2962FF'
      backgroundColor = '#D2D2D4'
    else
      graphColor = '#D2D2D4'
      backgroundColor = '#CC2E3B'

    ret = Highcharts.chart(element[0],
      chart:
        style: fontFamily: 'Open Sans'
        type: 'solidgauge'
        height: '75%'
        spacing: [0,0,0,0]
      yAxis:
        min: -100
        max: 100
        title: text: null
        minColor: graphColor
        maxColor: graphColor
        lineWidth: 0
        tickWidth: 0
        minorTickInterval: null
        labels: enabled: false
      credits: enabled: false
      title:
        text: I18n.t('net_promoter_score')
        y: 150
        floating: true
        style:
          color: '#3E3E40'
          fontSize: '16px'
      plotOptions: solidgauge:
        dataLabels:
          y: 16
          verticalAlign: 'bottom'
          borderWidth: 0
          useHTML: true
      exporting: enabled: false
      tooltip: enabled: false
      pane:
        startAngle: -90
        endAngle: 90
        background:
          shape: 'arc'
          backgroundColor: backgroundColor
          innerRadius: '75%'
          outerRadius: '100%'
      series: [ {
        data: [ {
          y: netPromoterScore
          innerRadius: '75%'
          outerRadius: '100%'
        } ]
        dataLabels: format: '<div style="text-align:center">' + '<span style="font-size:31.25px">{y}</span>' + '</div>'
      } ]
    )

    ret

  renderNPSGraph = (element) ->
    ret = "not set"

    counts = element.closest('.fullwidth-question-nps-graph-container').data('counts')
    cNPS = counts["cPromoters"] + counts["cPassives"] + counts["cDetractors"]
    scaleMax = 10
    scaleMin = 1
    colours = [
      '#CC2E3B', '#CC2E3B', '#CC2E3B', '#CC2E3B', '#CC2E3B', '#CC2E3B',
      '#5E89FF', '#5E89FF',
      '#2BC2AC', '#2BC2AC'
    ]
    if counts.hasOwnProperty("0")
      scaleMin = 0
      colours.unshift '#CC2E3B'

    data = []

    for val in [scaleMin..scaleMax]
      sampleSize = counts[val.toString()]
      sampleSize = 0 if sampleSize == null
      data.push( [ val, sampleSize ] )

    summaryHeight = $(element).parent().find('.fullwidth-question-nps-chart').height()
    ret = Highcharts.chart element[0],
      chart:
        type: 'column'
        style: fontFamily: 'Open Sans'
        spacing: [10,0,0,0],
        height: summaryHeight
      title: text: null
      xAxis:
        type: 'category'
        gridLineWidth: 1
        gridLineColor: '#D2D2D4'
        lineWidth: 0
        labels:
          style:
            fontSize: '16px'
            color: '#3E3E40'
      yAxis:
        min: 0
        title: text: null
        gridLineWidth: 0
        lineWidth: 1
        lineColor: '#D2D2D4'
        labels:
          style:
            fontSize: '16px'
            color: '#3E3E40'
      legend: enabled: false
      tooltip:
        valueSuffix: " #{I18n.t('fullwidth_samples')}"
        enabled: true
        pointFormat: '<b>{point.y}</b><br/>'
      plotOptions:
        bar: dataLabels: enabled: false
        series: minPointLength: 3
      credits: enabled: false
      series: [ {
        name: null
        data: data
        colors: colours
        colorByPoint: true
      } ]

    ret

  renderStateProvGraph = (element) ->
    ret = "not set"

    # set the height of the graph div
    panelHeading = element.parents( ".panel" ).find( ".panel-heading" )
    headingHeight = panelHeading.css( "height" ).replace( "px", "" )
    #                                              legend   footer
    element.css( "height", (340 - headingHeight - 50      ) + "px" )

    us_only = element.data( 'us-only' )
    ca_only = element.data( 'ca-only' )
    labels = element.data( 'labels' ).split( '|' )
    amounts = element.data( 'amounts' ).split( '|' )

    map = require('@highcharts/map-collection/custom/usa-and-canada.geo.json')
    map = require('@highcharts/map-collection/countries/ca/ca-all.geo.json') if ca_only
    map = require('@highcharts/map-collection/countries/us/custom/us-all-territories.geo.json') if us_only

    maxCount = 0
    data = []
    for i in [0..labels.length-1]
      amount = parseInt( amounts[i] )
      data.push [ labels[i], amount ]
      maxCount = amount if maxCount < amount

    # Create the chart
    ret = Highcharts.mapChart element[0],
      chart:
        map: map
        style: fontFamily: 'Open Sans'
        backgroundColor: 'rgba(0,0,0,0)'
      title: text: null
      mapNavigation:
        enabled: true
        buttonOptions: verticalAlign: 'bottom'
      colorAxis:
        min: 0
        max: maxCount
      series: [ {
        data: data
        name: 'Sample Size'
        states: hover: color: '#BADA55'
        dataLabels:
          enabled: true
          format: '{point.name}'
      } ]
      legend: enabled: false
      credits: enabled: false

    ret

  # events

  $('#wrapper').scroll ->
    clearTimeout(renderVisibleQuestionGraphs)
    setTimeout(renderVisibleQuestionGraphs, 250)

  $('.fullwidth .js-action.js-overview-tab-graph-button').click ->
    overviewQuestion = $(@).closest('.js-overview-question')
    overviewQuestion.find('.fullwidth-tab-responses-view').removeClass('selected')
    overviewQuestion.find('.fullwidth-tab-graph-view').addClass('selected')
    overviewQuestion.find('.js-overview-panel-graph').removeClass('hide')
    overviewQuestion.find('.js-overview-panel-log').addClass('hide')
    renderQuestionGraph(overviewQuestion)

  $('.fullwidth .js-action.js-overview-tab-responses-button').click ->
    overviewQuestion = $(@).parents('.js-overview-question')
    overviewQuestion.find('.fullwidth-tab-graph-view').removeClass('selected')
    overviewQuestion.find('.fullwidth-tab-responses-view').addClass('selected')
    overviewQuestion.find('.js-overview-panel-graph').addClass('hide')
    overviewQuestion.find('.js-overview-panel-log').removeClass('hide')
    renderQuestionResponses(overviewQuestion)

  # main

  renderVisibleQuestionGraphs()
