import React from "react";
import hasLoader from "../../hoc/has-loader";

import Tweets from "../../../views/twitter/tweets/tweets";

class TwitterTweetsContainer extends React.Component {
  render() {
    const { componentData } = this.props;

    return <Tweets tweets={componentData.tweets} />;
  }
}

export default hasLoader(TwitterTweetsContainer);
