import React, { useEffect, useState } from "react";
import I18n from "i18n-js";
import { Icon, Typography } from "stingray-components";

import { DateRangeType } from "./ExecutiveDashboard";
import { getMetricParams } from "./metricUtils";
import { Metric } from "./Metric";
import { MetricDrivers } from "./MetricDrivers";

import styles from "./SurveyCategoryPanel.module.scss";

export type Survey = {
  id: number;
  name: string;
};

export type SurveyCategory = {
  id: number;
  name: string;
  icon: string;
  has_nps_question: boolean;
  has_csat_question: boolean;
  surveys: Survey[];
}

interface SurveyCategoryPanelProps {
  dateRange: DateRangeType
  oldFiltersParams?: URLSearchParams;
  surveyCategory: SurveyCategory;
}

export const SurveyCategoryPanel: React.FC<SurveyCategoryPanelProps> = ({ dateRange, oldFiltersParams, surveyCategory }) => {
  const [parentElem, setParentElem] = useState<HTMLElement | null>(null);
  const [metricParams, setMetricParams] = useState<URLSearchParams>();

  useEffect(() => {
    setMetricParams(getMetricParams(dateRange, surveyCategory.surveys));
  }, [dateRange, surveyCategory]);

  return (
    <div className={styles.surveyCategoryPanel} ref={setParentElem}>
      <div className={styles.titleSection}>
        { surveyCategory.icon && <Icon icon={surveyCategory.icon} size="3.75rem" /> }
        <Typography variant="h3" css={{ fontWeight: 500 }}>{ surveyCategory.name }</Typography>
      </div>
      { !surveyCategory.has_nps_question &&
        <Typography className={styles.noRelatedQuestion} variant="h6">{I18n.t("no_nps_question")}</Typography>
      }
      { surveyCategory.has_nps_question && <>
        <Metric
          url={metricParams && `/executive_dashboard/nps_data?${metricParams?.toString()}`}
          dateRange={dateRange}
          parentElem={parentElem}
          surveys={surveyCategory.surveys}
          asPercentage={false}
          viewMoreUrl={oldFiltersParams && `surveys/%{survey_id}/nps_report?${oldFiltersParams?.toString()}`} />
        <MetricDrivers url={metricParams && `/executive_dashboard/nps_drivers_data?${metricParams.toString()}`} />
      </> }
      { !surveyCategory.has_csat_question &&
        <Typography className={styles.noRelatedQuestion} variant="h6">{I18n.t("no_csat_question")}</Typography>
      }
      { surveyCategory.has_csat_question && <>
        <Metric
          url={metricParams && `/executive_dashboard/csat_data?${metricParams.toString()}`}
          dateRange={dateRange}
          parentElem={parentElem}
          surveys={surveyCategory.surveys}
          asPercentage={true}
          viewMoreUrl={oldFiltersParams && `surveys/%{survey_id}/sat_report?${oldFiltersParams.toString()}`} />
        <MetricDrivers url={metricParams && `/executive_dashboard/csat_drivers_data?${metricParams.toString()}`} />
      </> }
    </div>
  );
}
