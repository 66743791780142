jQuery ->
  renderChartCompletionsAndAlertsTimeline = ($element) ->
    url = $element.data 'url'
    graphSubtitle = if document.ontouchstart?
      'Click and drag in the plot area to zoom in'
    else
      'Pinch the chart to zoom in'

    $.getJSON url, (data) ->
      # console.log data
      # establish Highcharts data structure
      completions = []
      completionsHistogramKeys = {}
      completionsHistogram = []
      alerts = []
      alertsHistogramKeys = {}
      alertsHistogram = []
      completionCounter = 0
      nextMonday = undefined
      for entry in data['completions']
        completionCounter += 1
        # 2017-03-08 14:00:17
        m = moment entry['answered_at']
        completions.push
          x: m.toDate().getTime()
          y: completionCounter
          # name: entry['phone_number']
        # dayOfWeek = dateTime.getDay()
        monday = m.utcOffset(0).isoWeekday('Monday') unless m.isoWeekday() == 1
        monday.hour(0).minutes(0).seconds(0).milliseconds(0)
        nextMonday = monday
        # console.log nextMonday
        mondayMS = monday.toDate().getTime()
        completionsHistogramKeys[mondayMS] = 0 unless completionsHistogramKeys[mondayMS]?
        completionsHistogramKeys[mondayMS] += 1
      # console.log completions
      for mondayMidnight, value of completionsHistogramKeys
        completionsHistogram.push [
          parseFloat mondayMidnight
          value
        ]
      if nextMonday?
        nextMonday.add(7, 'days')
        completionsHistogram.push [nextMonday.toDate().getTime(), 0]
      # repeat process for alerts
      alertCounter = 0
      nextMonday = undefined
      for reply in data['alerts']
        alertCounter += 1
        # 2017-03-08 14:00:17
        m = moment reply['answered_at']
        alerts.push
          x: m.toDate().getTime()
          y: alertCounter
          # name: "#{reply['phone_number']} answered #{reply['answer']} to Q#{reply['question']}."
        monday = m.utcOffset(0).isoWeekday('Monday') unless m.isoWeekday() == 1
        monday.hour(0).minutes(0).seconds(0).milliseconds(0)
        nextMonday = monday
        mondayMS = monday.toDate().getTime()
        alertsHistogramKeys[mondayMS] = 0 unless alertsHistogramKeys[mondayMS]?
        alertsHistogramKeys[mondayMS] += 1
      # console.log alerts
      for mondayMidnight, value of alertsHistogramKeys
        alertsHistogram.push [
          parseFloat mondayMidnight
          value
        ]
      if nextMonday?
        nextMonday.add(7, 'days')
        alertsHistogram.push [nextMonday.toDate().getTime(), 0]

      Highcharts.chart $element[0],
        credits: enabled: false
        chart:
          zoomType: 'x'
        title: text: ''
        subtitle: text: graphSubtitle
        xAxis: [
          {
            gridLineWidth: 1
            title:
              enabled: true
              text: 'Date / Time (UTC)'
            type: 'datetime'
          }
        ]
        yAxis: title: text: ''
        legend: enabled: true
        plotOptions:
          scatter:
            lineWidth: 1
            marker:
              radius: 3
            states: hover:
              lineWidth: 1
            tooltip:
              headerFormat: '<b>{series.name}</b><br>'
              pointFormat: '{point.y}'
        series: [
          {
            color: '#81C927'
            data: completions
            fillColor:
              linearGradient:
                x1: 0
                y1: 0
                x2: 0
                y2: 1
              stops: [
                [0, '#81C927'],
                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              ]
            name: 'Completions'
            type: 'scatter'
          },
          {
            color: 'rgba(128, 201, 38, 0.4)'
            data: completionsHistogram
            name: 'Weekly Completions'
            groupPadding: 0
            plotOptions:
              tooltip:
                # headerFormat: '<b>{series.name}</b><br>'
                pointFormat: '{point.y}'
            pointPadding: 0
            pointPlacement: 'between'
            type: 'column'
            visible: false
            # yAxis: 1
          },
          {
            color: '#F9CA00'
            data: alerts
            fillColor:
              linearGradient:
                x1: 0
                y1: 0
                x2: 0
                y2: 1
              stops: [
                [0, '#F9CA00'],
                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              ]
            name: 'Alerts'
            type: 'scatter'
          },
          {
            color: 'rgba(250, 204, 0, 0.4)'
            data: alertsHistogram
            name: 'Weekly Alerts'
            groupPadding: 0
            plotOptions:
              tooltip:
                # headerFormat: '<b>{series.name}</b><br>'
                pointFormat: '{point.y}'
            pointPadding: 0
            pointPlacement: 'between'
            type: 'column'
            visible: false
            # yAxis: 1
          },
        ]

  $('.js-completions-alerts-timeline-wrapper').on 'click', '.js-completions-alerts-timeline-toggle', ->
    $completionsTimeline = $('.js-completions-alerts-timeline')

    if $completionsTimeline.hasClass 'hide'
      $completionsTimeline.removeClass 'hide'
      renderChartCompletionsAndAlertsTimeline($completionsTimeline) if $completionsTimeline.html() == ''
      newLabel = 'Collapse'
    else
      $completionsTimeline.addClass 'hide'
      newLabel = 'Expand'
    $('.js-completions-alerts-timeline-toggle').html newLabel
    false

  window.renderChartQuestionTimeline = ($timeline) ->
    url = $timeline.data 'url'
    answerType = $timeline.data 'answer-type'
    graphSubtitle = if document.ontouchstart?
      'Click and drag in the plot area to zoom in'
    else
      'Pinch the chart to zoom in'

    $.getJSON url, (data) ->
      # establish Highcharts data structure
      # console.log data

      switch answerType
        when 'Yes/No'
          entries =
            yes: []
            no: []
            other: []

          for entry in data
            interpreted = entry['interpreted']
            interpreted = 'other' unless interpreted == 'yes' || interpreted == 'no'
            splitDate = entry['answered_at'].split /[^0-9]/
            repliedAt = new Date(splitDate[0], splitDate[1]-1, splitDate[2], splitDate[3], splitDate[4], splitDate[5]).getTime()
            counter = entries[interpreted].length + 1
            entries[interpreted].push [
              repliedAt
              counter
            ]
          # console.log 'YES/NO ENTRIES', entries

          Highcharts.chart $timeline[0],
            credits: enabled: false
            chart:
              zoomType: 'x'
            title: text: ''
            subtitle: text: graphSubtitle
            xAxis:
              title:
                enabled: true
                text: 'Date / Time (UTC)'
              type: 'datetime'
            yAxis: title: text: 'Completions'
            legend: enabled: false
            plotOptions: scatter:
              # fillColor:
                # linearGradient:
                  # x1: 0
                  # y1: 0
                  # x2: 0
                  # y2: 1
                # stops: [
                  # [0, '#D6BEFA'],
                  # [1, Highcharts.Color('#D6BEFA').setOpacity(0).get('rgba')]
                # ]
              lineWidth: 1
              marker:
                radius: 3
              states: hover:
                lineWidth: 1
              tooltip:
                enabled: false
                headerFormat: '<b>{series.name}</b><br>'
                pointFormat: '{point.y}'
            series: [
              {
                color: '#81C927'
                data: entries['yes']
                name: 'Yes'
                type: 'scatter'
              }
              {
                color: '#FA5C37'
                data: entries['no']
                name: 'No'
                type: 'scatter'
              }
              {
                color: '#C0C0C0'
                data: entries['other']
                name: 'Other'
                type: 'scatter'
              }
            ]
        when 'Scale', 'Multiple Choice', 'NPS', 'State/Prov'
          entries = []
          if answerType == 'Multiple Choice' || answerType == 'State/Prov'
            multiple_choice_counter = 0
            seriesName = 'Counter'
          else
            seriesName = 'Scale'
          for entry in data
            splitDate = entry['answered_at'].split /[^0-9]/
            repliedAt = new Date(splitDate[0], splitDate[1]-1, splitDate[2], splitDate[3], splitDate[4], splitDate[5]).getTime()

            if answerType == 'Scale' || answerType == 'NPS'
              entries.push [
                repliedAt
                parseInt entry['interpreted']
              ]
            else
              multiple_choice_counter += 1
              entries.push [
                repliedAt
                multiple_choice_counter
              ]

          # console.log 'SCALE ENTRIES', entries

          Highcharts.chart $timeline[0],
            credits: enabled: false
            chart:
              zoomType: 'x'
            title: text: ''
            subtitle: text: graphSubtitle
            xAxis:
              title:
                enabled: true
                text: 'Date / Time (UTC)'
              type: 'datetime'
            yAxis: title: text: 'Completions'
            legend: enabled: false
            plotOptions: scatter:
              # fillColor:
                # linearGradient:
                  # x1: 0
                  # y1: 0
                  # x2: 0
                  # y2: 1
                # stops: [
                  # [0, '#D6BEFA'],
                  # [1, Highcharts.Color('#D6BEFA').setOpacity(0).get('rgba')]
                # ]
              lineWidth: 0
              marker:
                radius: 3
              states: hover:
                lineWidth: 1
              tooltip:
                enabled: false
                headerFormat: '<b>{series.name}</b><br>'
                pointFormat: '{point.y}'
            series: [
              {
                color: '#AB7DF7'
                data: entries
                name: seriesName
                type: 'scatter'
              }
            ]
        when 'Free Text', 'Phone Number', 'Email'
          entries =
            positive: []
            negative: []
            neutral: []

          for entry in data
            sentiment = entry['sentiment']
            key = if sentiment > 0
              'positive'
            else if sentiment < 0
              'negative'
            else
              'neutral'
            repliedAt = new Date(entry['answered_at']).getTime()
            entries[key].push [
              repliedAt
              sentiment
            ]
          # console.log 'FREE TEXT ENTRIES', entries

          Highcharts.chart $timeline[0],
            credits: enabled: false
            chart:
              zoomType: 'x'
            title: text: ''
            subtitle: text: graphSubtitle
            xAxis:
              title:
                enabled: true
                text: 'Date / Time (UTC)'
              type: 'datetime'
            yAxis: title: text: 'Sentiment Score'
            legend: enabled: false
            plotOptions: scatter:
              # fillColor:
                # linearGradient:
                  # x1: 0
                  # y1: 0
                  # x2: 0
                  # y2: 1
                # stops: [
                  # [0, '#D6BEFA'],
                  # [1, Highcharts.Color('#D6BEFA').setOpacity(0).get('rgba')]
                # ]
              # lineWidth: 1
              marker:
                radius: 3
              # states: hover:
                # lineWidth: 1
              tooltip:
                enabled: false
                headerFormat: '<b>{series.name}</b><br>'
                pointFormat: '{point.y}'
            series: [
              {
                color: '#81C927'
                data: entries['positive']
                name: 'Positive'
                type: 'scatter'
              }
              {
                color: '#FA5C37'
                data: entries['negative']
                name: 'Negative'
                type: 'scatter'
              }
              {
                color: '#C0C0C0'
                data: entries['neutral']
                name: 'Neutral'
                type: 'scatter'
              }
            ]
