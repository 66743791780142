import React from "react";

import spinner from "../../images/spinner.png";
import styles from "./Loading.module.scss";

const Loading = () => {
  return (
    <div className={styles.loading}>
      <img className={styles.spinner} src={spinner} />
    </div>
  );
};

export default Loading;
