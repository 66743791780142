import React from "react";

import { PrettyLoader } from "../../views/shared";

import hasLoader from "../hoc/has-loader";

import LiveResponsesContent from "../../views/tile-live-responses";

import { LiveResponsesContainerType } from "../../types";

class LiveResponsesContainer extends React.Component {
  render() {
    const { status } = this.props;

    return (
      <PrettyLoader status={status} size="medium">
        {this.contentToRender()}
      </PrettyLoader>
    );
  }

  contentToRender() {
    const { componentData } = this.props;
    const { messages, question } = componentData;

    return <LiveResponsesContent messages={messages} question={question} />;
  }
}

LiveResponsesContainer.propTypes = LiveResponsesContainerType;

export default hasLoader(LiveResponsesContainer);
