import React from "react";

import classNames from "classnames";
import styles from "./IconButton.module.scss";

const IconButton = ({ children, className, ...props }) => {
  return (
    <button className={classNames(className, styles.iconButton)} {...props}>
      { children }
    </button>
  );
}

export default IconButton;
