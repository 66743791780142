import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const ErrorMessage = ({ message, caption = "Error" }) => {
  return (
    <div className={cx("text")}>
      <div className={styles.caption}>{caption}</div>
      <div className={styles.message}>{message}</div>
    </div>
  );
};

ErrorMessage.propTypes = {
  caption: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
