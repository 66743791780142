import React from "react";
import I18n from "i18n-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import TooltipHelper from "../utils/tooltip-helper";
import Attachment from "./Attachment";

import styles from "./OutboundMessage.module.scss";

const OutboundMessage = ({ attachments, limitations, message, senderInfo, sendingFailed, sendingErrorDetails }) => {
  return (
    <div className={styles.outboundMessage}>
      <div className={styles.container}>
        { attachments.map((attachment) => (
          <Attachment key={attachment.url} attachment={attachment} limitations={limitations} />
        )) }
        { message && (
          <div className={styles.bubble}>{ message }</div>
        ) }
        <div className={styles.messageInfo}>
          { senderInfo && <div className={styles.senderInfo}>{ senderInfo.fullName }</div> }
          { sendingFailed && (
            <TooltipHelper tooltipMessage={sendingErrorDetails || I18n.t("live_conversations.failed_to_send_message")}>
              <FontAwesomeIcon color="#F53D3D" icon={faExclamationCircle} />
            </TooltipHelper>
          ) }
        </div>
      </div>
    </div>
  );
}

export default OutboundMessage;
