import classNames from "classnames/bind";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import I18n from "i18n-js";
import React, { useEffect, useState } from "react";
import ReactSelect, { components } from "react-select";

import { fetchData } from "../../utils/request-helper";
import { LoadingStatus } from "../shared";

import styles from "./styles.module.scss";

const { DropdownIndicator } = components;
const cx = classNames.bind(styles);

const CustomDropdownIndicator = (props) => { 
  if (props.selectProps.options.length <= 1) {
     return null; 
  } 
  return <DropdownIndicator {...props} />; 
};

const CustomSingleValue = ({ children }) => {
  return (
    <div>
      <FontAwesomeIcon icon={faComments} style={{ marginRight: '15px' }} />
      {children}
    </div>
  )
};

const FeaturedQuestion = ({featuredQuestions, filtersQueryString, survey}) => {
  const [featuredQuestion, setFeaturedQuestion] = useState(featuredQuestions[0]);
  const [featuredQuestionData, setFeaturedQuestionData] = useState();
  const [loading, setLoading] = useState(true);

  const getFeaturedQuestionData = async (featuredQuestion) => {
    const data = await fetchData(
      `/dashboard_lite_featured_question_data?${filtersQueryString}&question_id=${featuredQuestion.value}`
    );

    setFeaturedQuestionData(data);
    setLoading(false);
  }

  const onSelectionChanged = (newFeaturedQuestion) => {
    if (newFeaturedQuestion !== featuredQuestion) {
      setLoading(true);
      setFeaturedQuestion(newFeaturedQuestion);
    }
  }

  useEffect(() => {
    if (featuredQuestion) {
      getFeaturedQuestionData(featuredQuestion)
    }
  }, [featuredQuestion]);

  return (
    <div className={cx("featured-question")}>
      <div className={cx("featured-question-title")}>{I18n.t("dashboard_lite.featured_question_title")}</div>
      <ReactSelect
        components={{ SingleValue: CustomSingleValue, DropdownIndicator: CustomDropdownIndicator }}
        styles={{
          control: (base, state) => ({
            ...base,
            marginBottom: "10px",
            paddingLeft: "15px",
            height: "73px",
            background: "rgba(0, 0, 0, 0.05)",
            border: "0",
            borderColor: "none",
            boxShadow: state.isFocused ? "0 0 0 2px #5175FA" : null,
          }),
        }}
        isDisabled={featuredQuestions?.length <= 1}
        value={featuredQuestion}
        onChange={onSelectionChanged}
        options={featuredQuestions} />
      <div className={cx("featured-question-container")}>
        {loading && (
          <LoadingStatus centered={true} relative={true} />
        )}
        {!loading && (
          <>
            <div className={cx("row-header" )}>
              {featuredQuestionData?.npsData && <div className={cx("nps-column")}>{I18n.t("dashboard_lite.nps")}</div>}
              <div className={cx("responses-column")}>{I18n.t("dashboard_lite.responses")}</div>
            </div>
            <div className={cx("feature-question-scrolling-container" )}>
              {featuredQuestionData?.replies?.map((reply) =>
                <a key={reply.replyDictionaryId} href={`/surveys/${survey?.id}/logs?reply_dictionary_id=${reply.replyDictionaryId}`} target="_blank">
                  <div className={cx("row-body")}>
                    {featuredQuestionData?.npsData && <div className={cx("nps-column")}>{reply.nps}</div>}
                    <div className={cx("responses-column")}>{reply.answer}</div>
                  </div>
                </a>
              )}
              {featuredQuestionData?.replies?.length === 0 && (
                <div className={cx("nothing-found")}>{I18n.t("dashboard_lite.nothing_found")}</div>
              )}
            </div>
          </>
        )}
      </div>
      <a href={`/surveys/${survey?.id}/logs?${filtersQueryString}&question_id=${featuredQuestion.value}`} target="_blank">
        <div className={cx("view-all-responses-button")}>{I18n.t("dashboard_lite.view_all_responses")}</div>
      </a>
    </div>
  );
};

export default FeaturedQuestion;
