$ ->
  filter_export_type_content = (value) ->
    $( "#export_recurrence_type" ).prop( "disabled", false )

    $('#reply-optional-data-section').hide()
    $('#poc-filtering-section').hide()
    $('#question-filtering-section').hide()
    $('#metric-filtering-section').hide()
    $('#sentiment-selection-section').hide()
    $('#alert-configuration-section').hide()
    $('#alert-filtering-section').hide()
    $('#solicitation-campaign-section').hide()

    switch value
      when "Replies"
        $('#poc-filtering-section').show()
        $('#question-filtering-section').show()
      when "Replies (Horizontal)"
        $('#reply-optional-data-section').show()
        $('#poc-filtering-section').show()
        $('#question-filtering-section').show()
      when "Conversations"
        $('#poc-filtering-section').show()
      when "Topics"
        $('#poc-filtering-section').show()
      when "Topics (By Month)"
        $('#sentiment-selection-section').show()
      when "POC Report"
        # POC Report does not support recurrence
        $("#export_recurrence_type").val("Once").trigger("chosen:updated")
        filter_export_recurrence_content("Once")
        $('#export_recurrence_type').prop("disabled", true)
        $('#metric-filtering-section').show()
      when "Alerts"
        $('#alert-configuration-section').show()
        $('#alert-filtering-section').show()
      when "Solicitations"
        $('#solicitation-campaign-section').show()

  filter_export_recurrence_content = (value) ->
    $('#recurrence-date-range').toggle(value == "Once")
    $('#recurrence-daily').toggle(value == "Daily")
    $('#recurrence-weekly').toggle(value == "Weekly")
    $('#recurrence-monthly').toggle(value == "Monthly")

  # When cloning a data export, we will never trigger the export_type or export_recurrence_type's change function, so we analyse the default value on init
  export_type = $('#export_type').val()
  if(export_type)
    filter_export_type_content export_type

  export_recurrence_type = $('#export_recurrence_type').val()
  if(export_recurrence_type)
    filter_export_recurrence_content export_recurrence_type

  $('[id^=select-for-]').each ->
    $(this).chosen({
      search_contains: true,
      hide_results_on_select: false,
      placeholder_text_multiple: $(this).data("placeholder") || I18n.t("select_placeholder"),
      width: "100%" # fix for https://github.com/harvesthq/chosen/issues/92 🎂 11 years old issue 🎂
    })

  $('#export_type').on 'change', ->
    filter_export_type_content this.value

  $('#export_recurrence_type').on 'change', ->
    filter_export_recurrence_content this.value

  #init code
  flatpickr_opts = {
    locale: I18n.locale,
    altInput: true,
    altFormat: "F j, Y",
    maxDate: "today"
  }
  $(".data-exports-new #export_start_date").flatpickr(flatpickr_opts)
  $(".data-exports-new #export_end_date").flatpickr(flatpickr_opts)
  $(".data-exports-clone #export_start_date").flatpickr(flatpickr_opts)
  $(".data-exports-clone #export_end_date").flatpickr(flatpickr_opts)
