import I18n from "i18n-js";

jQuery(() => {
  if (!window.location.pathname.includes("/solicitations_report")) {
    return;
  }

  const formatToPercentage = (floatValue) => {
    return `${Math.round(floatValue * 100)}%`;
  };
  const showLocationName = (row) => {
    if (row["poc_name"]) {
      const locationPaused =
        row["poc_status"] == 2 ? I18n.t("solicitations_report.location_paused_tooltip") : "";

      return `${row["poc_name"]} ${locationPaused}`;
    } else {
      return I18n.t("solicitations_report.no_locations");
    }
  };

  $.ajax({
    type: "GET",
    url: $("#solicitations-report-table-body").data("fetch-url"),
    success: (table) => {
      let newRows = "";
      for (let row of table) {
        newRows += `
          <tr>
            <td class="summary-section">${showLocationName(row)}</td>
            <td class="summary-section">${row["reached"] || 0}</td>
            <td class="summary-section">${row["opted_out"] || 0}</td>
            <td class="summary-section">${row["quarantined"] || 0}</td>
            <td class="summary-section">${row["invalid_location"] || 0}</td>
            <td class="summary-section">${row["invalid_contact_info"] || 0}</td>
            <td class="summary-section">${row["error"] || 0}</td>
            <td class="summary-section">${row["total"] || 0}</td>
            <td class="solicitations-section">${row["sms_sent"] || 0}</td>
            <td class="solicitations-section">${formatToPercentage(
              row["sms_clickthrough"] || 0
            )}</td>
            <td class="solicitations-section">${row["emails_sent"] || 0}</td>
            <td class="solicitations-section">${formatToPercentage(
              row["emails_clickthrough"] || 0
            )}</td>
            <td class="reminders-section">${row["reminder_sms_sent"] || 0}</td>
            <td class="reminders-section">${formatToPercentage(
              row["reminder_sms_clickthrough"] || 0
            )}</td>
            <td class="reminders-section">${row["reminder_emails_sent"] || 0}</td>
            <td class="reminders-section">${formatToPercentage(
              row["reminder_emails_clickthrough"] || 0
            )}</td>
          </tr>
        `;
      }

      $("#solicitations-report-table-body").html(newRows);

      let common_button_options = {
        exportOptions: {
          format: {
            header: (data, columnIdx) => {
              let tableHeaderRowTh = $("#table-header-row th");
              if (tableHeaderRowTh.length > 0) {
                let exportTitle = tableHeaderRowTh[columnIdx].getAttribute("export-title");
                if (exportTitle) {
                  return exportTitle;
                } else {
                  return data;
                }
              } else {
                return data;
              }
            },
          },
        },
        fieldSeparator: ",",
        filename: $(".solicitations-report-table").data("title")?.toLowerCase()?.replace(/ /g, "_"),
        title: $(".solicitations-report-table").data("title"),
      };

      $("table.solicitations-report-table").DataTable({
        dom:
          "<'row'<'col-sm-6 dt-dom-buttons'B><'col-sm-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        buttons: [
          $.extend(
            {
              extend: "copy",
              text: '<i class="fas fa-copy"></i> Copy',
              titleAttr: "Copy",
              style: "font-size: 1.3rem",
            },
            common_button_options
          ),
          $.extend(
            {
              extend: "excel",
              text: '<i class="fas fa-file-excel"></i> Excel',
              titleAttr: "Excel",
            },
            common_button_options
          ),
          $.extend(
            {
              extend: "csv",
              text: '<i class="fas fa-file-csv"></i> CSV',
              titleAttr: "CSV",
            },
            common_button_options
          ),
          $.extend(
            {
              extend: "print",
              text: '<i class="fas fa-print"></i> Print',
              titleAttr: "Print",
            },
            common_button_options
          ),
        ],
        paging: false,
        searching: true,
        info: true,
        ordering: true,
        order: [],
      });
    },
    error: (error) => {
      console.error("cannot get solicitations_table_data:", error);
    },
  });
});
