jQuery ->
  # initiate dataTables based on their `entries` and `empty-table` HTML5 data attributes
  $('.js-data-table').each ->
    isMobile = $(window).width() < 768

    languageSettings = window.jQueryDataTableLanguagePacks[I18n.locale] || {}

    if $(@).data('emptyTable')
      languageSettings.emptyTable = $(@).data('empty-table')

    options =
      language: languageSettings
      pagingType: if isMobile then "full" else "full_numbers"
      searchDelay: 600
      processing: true
      serverSide: true
      deferLoading: 0
      ajax: $(@).data('source')
      columnDefs: [ {
        orderable: false,
        targets: 'no-sort'
      }]
      lengthMenu: [10, 25, 50, 100]
      dom: "<'row'<'col-sm-6 action-left'<f>><'col-sm-6'l>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-5'i><'col-sm-7'p>>"

    if $(@).data('isadmin')
      options.lengthMenu = [ [10, 25, 50, 100, -1], [10, 25, 50, 100, "All"] ]
      options.dom = "<'row'<'col-sm-6 action-left'<f><'dt-dom-buttons'B>><'col-sm-6'l>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-5'i><'col-sm-7'p>>"
      options.buttons = [
        extend: 'collection',
        text: 'Export',
        buttons: [
          {
            extend: 'copy',
            text: "<i class='fas fa-copy'></i> #{I18n.t("copy")}",
            titleAttr: I18n.t("copy"),
            title: $(@).data('title')
            fieldSeparator: ','
          },
          {
            extend: 'excel',
            text: '<i class="fas fa-file-excel"></i> Excel',
            titleAttr: 'Excel'
            title: $(@).data('title')
            filename: 'logs'
          },
          {
            extend: 'csv',
            text: '<i class="fas fa-file-csv"></i> CSV',
            titleAttr: 'CSV'
            fieldSeparator: ','
            filename: 'logs'
          },
          {
            extend: 'pdf',
            text: '<i class="fas fa-file-pdf"></i> PDF',
            titleAttr: 'PDF'
            title: $(@).data('title')
            filename: 'logs'
          },
          {
            extend: 'print',
            text: "<i class='fas fa-print'></i> #{I18n.t("print")}",
            titleAttr: I18n.t("print"),
            title: $(@).data('title')
          }
        ]
      ]

    $table = $(@).DataTable options

    bFiltered = $(@).data 'filtered'
    if !bFiltered
      $table.order( [0, 'desc'] )

    search = $(@).data 'search'
    if search?
      $table.search search

    $table.draw()
