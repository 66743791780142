import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import { fetchData } from "../utils/request-helper";

interface HighchartsCustomProps {
  chartOptions: Highcharts.Options;
  fetchUrl: string;
}

const HighchartsCustom: React.FC<HighchartsCustomProps> = ({chartOptions, fetchUrl}) => {
  const [currentChartOptions, setCurrentChartOptions] = useState<Highcharts.Options>(chartOptions);
  const [highchartInterface, setHighchartInterface] = useState<Highcharts.Chart>();

  const updateChartOptions = async (fetchUrl: string) => {
    const chartOptions = await fetchData(fetchUrl);

    setCurrentChartOptions(chartOptions);
  }

  useEffect(() => {
    if (fetchUrl) {
      updateChartOptions(fetchUrl);
    }
  }, [fetchUrl]);

  useEffect(() => {
    if (currentChartOptions && currentChartOptions !== chartOptions && highchartInterface) {
      highchartInterface.hideLoading();
    }
  }, [chartOptions, currentChartOptions, highchartInterface])

  if (fetchUrl && chartOptions && !chartOptions.chart?.events) {
    chartOptions.chart = {
      events: {
        load() {
          this.showLoading();

          setHighchartInterface(this);
        }
      }
    }
  }

  return (
    <HighchartsReact highcharts={Highcharts} options={currentChartOptions} />    
  );
}

export default HighchartsCustom;