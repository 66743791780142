import React from "react";
import I18n from "i18n-js";

import TopTenContent from "../../../views/sia/tile-top-ten";

import { PrettyLoader } from "../../../views/shared";

import hasLoader from "../../hoc/has-loader";

import { TopTenStrengthsContainerType } from "../../../types";

class TopTenStrengthsContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;

    const { title, data, detailsPath } = componentData;

    const columns = [
      { header: I18n.t("topic"), id: "topic", colStyle: "text-no-wrap" },
      { header: I18n.t("nps_impact"), id: "impact", colSpan: 2 },
      {
        header: I18n.t("satisfaction"),
        id: "satisfaction",
        colStyle: "text-right",
      },
    ];

    return (
      <PrettyLoader status={status} size="flex">
        <TopTenContent
          header={title}
          rows={this.getRowData(data)}
          columns={columns}
          globalImpactColor="#1DE9B6"
          detailsPath={detailsPath}
        />
      </PrettyLoader>
    );
  }

  getRowData(data) {
    return (
      data &&
      data.map(({ topic, impact, satisfaction }) => {
        return {
          topic,
          impact: impact && impact.toFixed(1),
          satisfaction: satisfaction && `${satisfaction.toFixed(1)}%`,
        };
      })
    );
  }
}

TopTenStrengthsContainer.propTypes = TopTenStrengthsContainerType;

export default hasLoader(TopTenStrengthsContainer);
