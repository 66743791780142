jQuery ->
  { trackEvent } = require('./utils/analytics')

  $(".mobile-hamburger").on 'click', ->
    $(".navbar-2020").toggleClass("mobile")

  $("#dashboard-lite-switch").on 'change', (event) ->
    dashboard_lite_checked = event.target.checked
    document.cookie = "_chatter_dashboard_lite_checked=#{dashboard_lite_checked}; path=/; max-age=#{10*365*24*60*60};" # 10 years
    trackEvent("Dashboard Mode Toggled", "Dashboard Mode Toggled", { mode: dashboard_lite_checked && "lite" || "pro" })

  $(".navbar-2020").on('show.bs.collapse','.collapse', ->
    $(".navbar-2020").find('.collapse.in').collapse('hide'))

  $(".navbar-2020").on('shown.bs.collapse','.collapse', ->
    elementOffset = 10
    $middleContainer = $(@).parent()
    $navbarContent = $middleContainer.parent()
    $navbarContent[0].scrollTo({ top: elementOffset + $(@)[0].getBoundingClientRect().top - $middleContainer[0].getBoundingClientRect().top, behavior: 'smooth'})
  )