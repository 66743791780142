import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import ErrorMessage from "./error-message/error-message";
import ErrorImage from "./error-image/error-image";

import { ErrorStatusType } from "../../../types";

const ErrorStatus = ({
  message = "Looks like something went wrong.",
  centered = false,
  multiRow = false,
  caption,
}) => {
  return (
    <div className={cx("container", { centered: centered })}>
      {multiRow ? (
        <React.Fragment>
          <div className={cx("row", "multi")}>
            <div className={cx("image-wrapper")}>
              <ErrorImage verticalAlign="bottom" />
            </div>
          </div>
          <div className={cx("row", "multi")}>
            <div className={cx("message-wrapper")}>
              <ErrorMessage message={message} caption={caption} />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className={cx("row")}>
          <div className={cx("image-wrapper")}>
            <ErrorImage />
          </div>
          <div className={cx("message-wrapper")}>
            <ErrorMessage message={message} caption={caption} />
          </div>
        </div>
      )}
    </div>
  );
};

ErrorStatus.propTypes = ErrorStatusType;

export default ErrorStatus;
