import React from "react";

import styles from "./styles.module.scss";

import { ChartLegendType } from "../../../types";

const ChartLegend = ({ value, color = "black" }) => {
  return (
    <span className={styles["chart-legend"]}>
      <span style={{ color: color }} className={styles.dot}>
        ●
      </span>
      <span className={styles.text}>{value}</span>
    </span>
  );
};

ChartLegend.propTypes = ChartLegendType;

export default ChartLegend;
