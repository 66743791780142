import React from "react";
import I18n from "i18n-js";

import styles from "./styles.module.scss";

import { NPSDetailContentType } from "../../../../types";

import { isArrayEmpty, charInsteadOfEmpty } from "../../../../utils/general-helper";

import { TrendIcon } from "../../../shared";

const NPSDetailContent = ({
  title = I18n.t("your_nps"),
  value = 0,
  previousValue = 0,
  trendIcon,
  ranks = [],
}) => {
  return (
    <React.Fragment>
      <div className={styles["name"]}>{title}</div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["value-wrapper"]}>
          <div className={styles["value"]}>{charInsteadOfEmpty(value)}</div>
          <div className={styles["icon"]}>
            <TrendIcon icon={trendIcon} />
          </div>
        </div>
        <div className={styles["detail"]}>
          {previousValue && `${I18n.t("previous_periods_score")}: ${previousValue}`}
        </div>
        <div>{renderRanks(ranks)}</div>
      </div>
    </React.Fragment>
  );
};

const defaultValues = [
  {
    id: "district",
    title: I18n.t("rank_in_district"),
  },
  {
    id: "region",
    title: I18n.t("rank_in_region"),
  },
  {
    id: "overall",
    title: I18n.t("rank_overall"),
  },
];

const renderRanks = (ranks) => {
  if (isArrayEmpty(ranks)) {
    ranks = defaultValues;
  }

  return ranks.map(({ title, rank }) => {
    return (
      <div key={title} className={styles["detail-row"]}>
        <div className={styles["title"]}>{title}</div>
        <div className={styles["rank"]}>{charInsteadOfEmpty(rank)}</div>
      </div>
    );
  });
};

NPSDetailContent.propTypes = NPSDetailContentType;

export default NPSDetailContent;
