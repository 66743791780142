import React from "react";
import I18n from "i18n-js";

import styles from "./styles.module.scss";

import { HeaderText, PrettyButton, ChartLegend } from "../../shared";
import TopicsChart from "../topics-chart/topics-chart";
import TopicsWrapper from "../topics-wrapper/topics-wrapper";

import { TopicsContentType } from "../../../types";

import { isArrayEmpty } from "../../../utils/general-helper";

const TopicsContent = ({ headerText = I18n.t("topics_raised"), detailsPath, chartOptions }) => {
  const { positiveData, negativeData, neutralData, mixedData } = chartOptions;

  return (
    <TopicsWrapper>
      <HeaderText value={headerText}>
        <div className={styles["legend-wrapper"]}>
          {!isArrayEmpty(positiveData) && (
            <ChartLegend key="Positive" value={I18n.t("positive")} color="#1DE9B6" />
          )}
          {!isArrayEmpty(negativeData) && (
            <ChartLegend key="Negative" value={I18n.t("negative")} color="#FF5983" />
          )}
          {!isArrayEmpty(neutralData) && (
            <ChartLegend key="Neutral" value={I18n.t("neutral")} color="#F0F0F7" />
          )}
          {!isArrayEmpty(mixedData) && (
            <ChartLegend key="Mixed" value={I18n.t("mixed")} color="#206FA5" />
          )}
        </div>
      </HeaderText>
      <TopicsChart {...chartOptions} />
      <div className={styles.viewmore}>
        <PrettyButton path={detailsPath} openInNewTab={true}>
          {I18n.t("see_more")}
        </PrettyButton>
      </div>
    </TopicsWrapper>
  );
};

TopicsContent.propTypes = TopicsContentType;

export default TopicsContent;
