import React from "react";
import I18n from "i18n-js";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import MultiTableTable from "../multitable-table/multitable-table";

import { isArrayEmpty } from "../../../utils/general-helper";

import { HeaderText, PrettyButton } from "../../shared";

import { MultiTableContentType } from "../../../types";

const MultiTableContent = ({
  headerText,
  median,
  detailsPath,
  scoreLabel = "NPS",
  tableLeft = {},
  tableRight = {},
  additionalText = "No Data",
}) => {
  const noData = isArrayEmpty(tableLeft.data) && isArrayEmpty(tableRight.data);
  const hasTwoTables = isTableValid(tableLeft) && isTableValid(tableRight);

  return (
    <div className={styles.multitable}>
      <HeaderText value={headerText}>
        {median != null && !noData && (
          <div className={styles.median}>
            {I18n.t("median")}: {median}
          </div>
        )}
      </HeaderText>
      {!noData &&
        (hasTwoTables
          ? getTwoTablesView(tableLeft, tableRight, scoreLabel)
          : getSingleTableView(isTableValid(tableLeft) ? tableLeft : tableRight, scoreLabel))}
      {noData && <div className={styles.nodata}>{additionalText}</div>}
      {detailsPath && !noData && (
        <div className={styles["viewmore-container"]}>
          <div className={styles.viewmore}>
            <PrettyButton path={detailsPath} openInNewTab={true}>
              {I18n.t("view_more")}
            </PrettyButton>
          </div>
        </div>
      )}
    </div>
  );
};

const isTableValid = (table) => !isArrayEmpty(table.data) || table.title;

const getTwoTablesView = (tableLeft, tableRight, scoreLabel) => {
  return (
    <div className={styles.tables}>
      <div className={cx("table", "left")}>
        <div className={styles.caption}>{tableLeft.title}</div>
        <MultiTableTable rows={tableLeft.data} scoreLabel={scoreLabel} />
      </div>
      <div className={cx("table", "right")}>
        <div className={styles.caption}>{tableRight.title}</div>
        <MultiTableTable rows={tableRight.data} scoreLabel={scoreLabel} />
      </div>
    </div>
  );
};

//show left table in full width
const getSingleTableView = (table, scoreLabel) => {
  return (
    <div className={styles.tables}>
      <div className={cx("table", "full-width")}>
        {table.title && <div className={styles.caption}>{table.title}</div>}
        <MultiTableTable rows={table.data} scoreLabel={scoreLabel} />
      </div>
    </div>
  );
};

MultiTableContent.propTypes = MultiTableContentType;

export default MultiTableContent;
