import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import NoInitiationData from "components/views/shared/no-initiation-data/no-initiation-data";
const cx = classNames.bind(styles);

import defaultOptions from "./default-options.json";

import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";

import { extendChartConfig } from "../../../utils/chart-helper";

const InitiationChart = ({ seriesHasEnoughDataPoints, initiationSeries, extraOptions = {} }) => {
  defaultOptions["series"][0]["data"] = initiationSeries;
  let min = Math.min(...initiationSeries);
  let max = Math.max(...initiationSeries);
  let displayGraph = seriesHasEnoughDataPoints;
  defaultOptions["yAxis"]["min"] = min;
  defaultOptions["yAxis"]["max"] = max;
  let extendedOptions = extendChartConfig(defaultOptions, extraOptions);

  return (
    <div className={cx("graph")}>
      {displayGraph ? (
        <HighchartsReact highcharts={Highcharts} options={extendedOptions} />
      ) : (
        <NoInitiationData />
      )}
    </div>
  );
};

InitiationChart.propTypes = {
  extraOptions: PropTypes.object,
  percentage: PropTypes.number,
};

export default InitiationChart;
