$ ->

  $("#poc-table").DataTable()

  performRequest = (url, successCallback, errorCallback ) ->
    $('.poc-preview-button').attr('disabled', true)
    $('.poc-import-button').attr('disabled', true)

    data = new FormData(document.querySelector('#poc_import_csv_form'))

    $.ajax
      type: "POST"
      url: url
      data: data
      success: (response) ->
        $('.poc-import-csv-errors-block').empty()
        $('.poc-import-csv-result-block')[0].innerHTML = response
        $('.poc-import-csv-errors-block').addClass "hidden"
        $('.poc-import-csv-result-block').removeClass "hidden"
        successCallback()
      error: (xhr, status, error) ->
        $('.poc-import-csv-errors-block')[0].innerHTML = xhr.responseText
        $('.poc-import-csv-errors-block').removeClass "hidden"
        $('.poc-import-csv-result-block').addClass "hidden"
        errorCallback()
      complete: ->
        $('.poc-import-button').removeAttr('disabled')
      processData: false
      contentType: false
  
  $('#csv_upload').on 'click', (event) ->
    $('#csv_upload').val("")
    $('.poc-import-button').addClass "hidden"
    $('.poc-import-csv-errors-block').addClass "hidden"
    $('.poc-import-csv-result-block').addClass "hidden"

  $('#csv_upload').on 'change', (event) ->
    if event.target.files.length
      $('.poc-preview-button').removeAttr('disabled')
    else
      $('.poc-preview-button').attr('disabled', true)

  $('.poc-preview-button').on 'click', (event) ->
    if (!$(this).attr('disabled'))
      successCallback = () ->
        $('.poc-import-button').removeClass "hidden"

      errorCallback = () ->
        $('#csv_upload').val("")
        $('.poc-import-button').addClass "hidden"

      performRequest($(this).data('url'), successCallback, errorCallback) 

  $('.poc-import-button:not(.disabled)').on 'click', ->
    if (!$(this).attr('disabled'))
      successCallback = () ->
        $('.poc-import-button').addClass "hidden"

      errorCallback = () ->
        $('.poc-import-button').addClass "hidden"

      performRequest($(this).data('url'), successCallback, errorCallback)
