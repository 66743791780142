import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";

import I18n from "i18n-js";
import classNames from "classnames";
import styles from "./StartConversationButton.module.scss";

const StartConversationButton = ({ onClick, show }) => {
  return (
    <div className={styles.startConversationButtonContainer}>
      <button className={classNames(styles.startConversationButton, { [styles.show]: show })} type="button" onClick={onClick}>
        <FontAwesomeIcon icon={faComments} size="lg" />
        { I18n.t("live_conversations.start_conversation") }
      </button>
    </div>
  );
}

export default StartConversationButton;
