import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const SectionHeader = (props) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>{props.title}</div>
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionHeader;
