import React from "react";

import Attachment from "./Attachment";
import UserIcon from "./UserIcon";

import styles from "./InboundMessage.module.scss";

const InboundMessage = ({ attachments, limitations, message, conversation }) => {
  return (
    <div className={styles.inboundMessage}>
      <div className={styles.userIcon}>
        <UserIcon conversation={conversation} />
      </div>
      <div className={styles.container}>
        { attachments.map((attachment) => (
          <Attachment key={attachment.url} attachment={attachment} limitations={limitations}></Attachment>
        ))}
        { message && (
          <div className={styles.bubble}>{ message }</div>
        )}
      </div>
    </div>
  );
}

export default InboundMessage;
