import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import defaultOptions from "./default-options.json";

import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";

import {
  extendChartConfig,
  modelSeriesArray,
  getDisplayPercentage,
} from "../../../utils/chart-helper";

const SatChart = ({ value, extraOptions = {} }) => {
  let options = prepareData(value);
  let extendedOptions = extendChartConfig(options, extraOptions);

  return (
    <div className={cx("graph")}>
      <HighchartsReact highcharts={Highcharts} options={extendedOptions} />
    </div>
  );
};

SatChart.propTypes = {
  extraOptions: PropTypes.object,
  value: PropTypes.number,
};

const prepareData = (value) => {
  let seriesColor, percentage;
  const endAngle = value < 0 ? -360 : 360;

  if (value) {
    seriesColor = value < 0 ? "#FF5983" : "#2962FF";
    percentage = Math.abs(value);
  } else {
    //no data
    seriesColor = "#e8e8e8";
    percentage = 0;
  }

  let series = modelSeriesArray([{ color: seriesColor, y: getDisplayPercentage(percentage) }]);

  let options = {
    pane: {
      endAngle: endAngle,
    },
    series: series,
  };
  return extendChartConfig(defaultOptions, options);
};

export default SatChart;
