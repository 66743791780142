import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

import PropTypes from "prop-types";

const cx = classNames.bind(styles);

class TopicSegmentationTable extends React.Component {
  render() {
    const { topicInfo } = this.props;
    const NB_TOPICS_TO_DISPLAY = 3;
    return (
      <div className={cx("topics-table-container")}>
        <div>
          <table className={cx("topics-table", "header-table")}>
            <thead>
              <tr>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              <tr className={cx("topics-table__sectionRow", "headerRow")}>
                <td>
                  <div className={cx("topics-table__sectionRow__section")}>
                    <div className={cx("topics-table__sectionRow__section__image")}>
                      <img
                        src={require("../../../../assets/images/Strength.png")}
                        height="50"
                        alt="Weakness"
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr className={cx("topics-table__sectionRow", "headerRow")}>
                <td>
                  <div className={cx("topics-table__sectionRow__section")}>
                    <div className={cx("topics-table__sectionRow__section__image")}>
                      <img
                        src={require("../../../../assets/images/Weakness.png")}
                        height="50"
                        alt="Weakness"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={cx("content-table-container")}>
          <table className={cx("topics-table", "content-table")}>
            <thead>
              <tr>
                {Object.keys(topicInfo).map((category) => {
                  return <th key={category}>{category}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[...Array(NB_TOPICS_TO_DISPLAY)].map((_, i) => (
                <tr
                  className={i === 0 ? cx("topics-table__sectionRow") : ""}
                  key={"positive-row-" + i}
                >
                  {Object.keys(topicInfo).map((category) => {
                    let topicName = topicInfo[category].positive[i].topic.split(":");
                    topicName.shift();
                    const topLevelTopic = topicName[topicName.length - 1];
                    let tagName = "";
                    if (topLevelTopic.indexOf("(") !== -1) {
                      tagName = topLevelTopic.slice(topLevelTopic.indexOf("("));
                      topicName[topicName.length - 1] = topicName[topicName.length - 1].replace(
                        tagName,
                        ""
                      );
                    }
                    topicName = topicName.join(" - ");
                    const key = `topic-positive-${i}-${category}`;
                    return (
                      <td title={topicInfo[category].positive[i].topic} key={key}>
                        {topicName}
                        <br />
                        {tagName ? tagName : <br />}
                      </td>
                    );
                  })}
                </tr>
              ))}
              {[...Array(NB_TOPICS_TO_DISPLAY)].map((_, i) => (
                <tr
                  className={i === 0 ? cx("topics-table__sectionRow") : ""}
                  key={"negative-row-" + i}
                >
                  {Object.keys(topicInfo).map((category) => {
                    let topicName = topicInfo[category].negative[i].topic.split(":");
                    topicName.shift();
                    const topLevelTopic = topicName[topicName.length - 1];
                    let tagName = "";
                    if (topLevelTopic.indexOf("(") !== -1) {
                      tagName = topLevelTopic.slice(topLevelTopic.indexOf("("));
                      topicName[topicName.length - 1] = topicName[topicName.length - 1].replace(
                        tagName,
                        ""
                      );
                    }
                    topicName = topicName.join(" - ");
                    const key = `topic-negative-${i}-${category}`;
                    return (
                      <td title={topicInfo[category].negative[i].topic} key={key}>
                        {topicName}
                        <br />
                        {tagName ? tagName : <br />}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

TopicSegmentationTable.propTypes = {
  topicInfo: PropTypes.any,
};

export default TopicSegmentationTable;
