jQuery ->
  toggleWebTagReactComponents = (newType) ->
    if !newType
      $(".web-tag-configuration-form .trigger-filters-section").addClass("hide")
      $(".web-tag-configuration-form .trigger-sources-section").addClass("hide")
      $(".web-tag-configuration-form .trigger-conditions-section").addClass("hide")
    if newType == "Always-On"
      $(".web-tag-configuration-form .trigger-filters-section").removeClass("hide")
      $(".web-tag-configuration-form .trigger-sources-section").addClass("hide")
      $(".web-tag-configuration-form .trigger-conditions-section").addClass("hide")
    if newType == "Digital Intercept"
      $(".web-tag-configuration-form .trigger-filters-section").removeClass("hide")
      $(".web-tag-configuration-form .trigger-sources-section").removeClass("hide")
      $(".web-tag-configuration-form .trigger-conditions-section").removeClass("hide")

  $(".web-tag-configuration-form #web_tag_configuration_web_tag_type").on "change", (e) ->
    newType = e.target.value
    toggleWebTagReactComponents(newType)

  $form = $(".web-tag-configuration-form")
  if $form.length > 0
    type = $(".web-tag-configuration-form #web_tag_configuration_web_tag_type").val()
    toggleWebTagReactComponents(type)

    $form.on "submit", (e) ->
      formValues = $(@).find("form").serializeArray()
      sourceValues = formValues.filter((formValue) -> formValue.name.startsWith("trigger_source"))
      webTagType = formValues.find((formValue) -> formValue.name == "web_tag_configuration[web_tag_type]").value
      if sourceValues.length == 0 && webTagType == "Digital Intercept"
        e.preventDefault()
        e.stopPropagation()
        confirm(I18n.t("web_tag_configuration.select_one_trigger_source"))

  $('.web-tag-configurations-list .web-tag-configuration-switch-container .switch').on "change", (e) ->
    newValue = e.target.checked
    $(@).find("input").prop('disabled', true)
    id = $(@).data "web-tag-id"
    url = $(@).data "url"
    $.ajax
      type: "POST"
      url: url
      data:
        id: id
        enabled: newValue
      success: () ->
        $('.web-tag-configurations-list .web-tag-configuration-switch-container .switch').find("input").prop("disabled", false)
      error: (error) ->
        $('.web-tag-configurations-list .web-tag-configuration-switch-container .switch').find("input").prop("disabled", false)

  $('.website-configuration.get-javascript-container #copy-js-to-clipboard').on "click", ->
    code = $('.website-configuration.get-javascript-container code')[0].textContent
    navigator.clipboard.writeText(code)
    $(@).text(I18n.t("web_tag_configuration.javascript_copied"))
    $(@).prop("disabled", true)