import React from "react";
import hasLoader from "../../hoc/has-loader";

import LiveTwitterFeed from "../../../views/twitter/live-feed/live-feed";
import { PrettyLoader } from "../../../views/shared";

class TwitterLiveFeedContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;

    return (
      <PrettyLoader status={status} size="medium">
        <LiveTwitterFeed tweets={componentData.tweets} />
      </PrettyLoader>
    );
  }
}

export default hasLoader(TwitterLiveFeedContainer);
