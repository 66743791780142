import React from "react";
// import Tweet from "react-tweet";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

import Slick from "src/vendor/react-slick";

const cx = classNames.bind(styles);

import { HeaderText } from "../../shared";
import { isArrayEmpty } from "../../../utils/general-helper";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import defaultOptions from "./default-options.json";

const LiveTwitterFeed = ({ tweets = [] }) => {
  return (
    <div>
      <div className={cx("header-wrapper")}>
        <HeaderText value="Latest tweets" />
      </div>
      <div className={cx("tweets-container")}>
        <div className={cx("live-tweets")}>
          {!isArrayEmpty(tweets) && (
            <Slick {...defaultOptions}>
              {/* {tweets.map((tweet, index) => {
                return (
                  // <Tweet
                  //   key={index}
                  //   data={tweet}
                  //   linkProps={{ target: "_blank", rel: "noreferrer" }}
                  // />
                );
              })} */}
            </Slick>
          )}
        </div>
      </div>
    </div>
  );
};

export default LiveTwitterFeed;
