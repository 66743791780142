import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import readXlsxFile, { Row } from "read-excel-file";
import { Button, Loading, Themes } from "stingray-components";

import withReactQuery from "../../src/hocs/withReactQuery";
import CheckboxList from "../shared/CheckboxList";
import FilePicker from "../shared/FilePicker";
import LoadingPage from "../shared/LoadingPage";
import SelectInput, { SelectOption } from "../shared/Select";
import { fetchData } from "../utils/request-helper";
import { toSnakeCase } from "../utils/text-utilities";
import ErrorMessage from "./ErrorMessage";
import PointOfContactFields from "./PointOfContactFields";
import { PreviewResponse, SurveyIngestionFormFields } from "./SurveyIngestionTypes";

import styles from "./SurveyIngestion.module.scss";
import PointOfContactMatchingFields from "./PointOfContactMatchingFields";

type SurveyIngestionProps = {
  api: {
    csrfToken: string;
  };
};

const SurveyIngestion: React.FC<SurveyIngestionProps> = ({ api }) => {
  const methods = useForm<SurveyIngestionFormFields>({
    mode: "onChange",
  });
  const {
    watch,
    register,
    formState: { isValid, isDirty },
  } = methods;

  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [excelColumns, setExcelColumns] = useState<SelectOption[]>();
  const [nbExcelRows, setNbExcelRows] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const pocMetadataFields = ["address", "city", "state", "country", "postal_code", "latitude", "longitude", "google_place_id"] as const;

  const ingestionTypes = [
    {
      label: "Point of Contacts",
      value: "point_of_contacts",
    },
    {
      label: "Survey Conversations",
      value: "survey_conversations",
    },
  ];

  const file = watch("file");
  const ingestionType = watch("ingestionType")?.value;
  const companyId = watch("company")?.value;
  const surveyId = watch("survey")?.value;

  const { data: companies } = useQuery("companies", async () => await fetchData("/admin/survey_ingestion/companies"), {
    refetchOnWindowFocus: false,
  });
  const { data: surveys } = useQuery(
    ["surveys", companyId],
    async () => await fetchData(`/admin/survey_ingestion/company/${companyId}/surveys`),
    {
      enabled: !!(ingestionType === "survey_conversations" && companyId),
    }
  );
  const { data: survey } = useQuery(
    ["survey", surveyId],
    async () => await fetchData(`/admin/survey_ingestion/survey/${surveyId}`),
    {
      enabled: !!surveyId,
    }
  );
  const hasUnsupportedQuestions = survey?.questions?.some((question: any) => !question.isSupported);

  const previewPointOfContactsMutation = useMutation(
    async (data: SurveyIngestionFormFields) => {
      const pocMetadataFieldsColumns = pocMetadataFields.reduce<Record<string, string>>((columns, key) => {
        const value = data[`${key}PocMetadataColumn`]?.value;
        if (value) {
          columns[key] = value;
        }
        return columns;
      }, {});
      data.additionalPocMetadataColumns?.forEach((column) => {
        if (column) {
          const key = toSnakeCase(column);

          pocMetadataFieldsColumns[key] = column;
        }
      })

      const formData = new FormData();
      formData.append("file", data.file[0]);
      formData.append("nbExcelRows", nbExcelRows.toString());
      formData.append("companyId", data.company.value);
      formData.append("pointOfContactNameColumn", data.pointOfContactNameColumn.value);
      formData.append("pointOfContactTriggerColumn", data.pointOfContactTriggerColumn.value);
      formData.append("pointOfContactCodeColumn", data.pointOfContactCodeColumn.value);
      formData.append("pocMetadataFieldsColumns", JSON.stringify(pocMetadataFieldsColumns));

      return fetchData("/admin/survey_ingestion/preview_point_of_contacts", {
        method: "POST",
        headers: {
          "X-CSRF-Token": api.csrfToken,
        },
        body: formData,
        throwOnError: true,
      });
    },
    {
      onSuccess: (response: PreviewResponse) => {
        window.location.href = `/admin/survey_ingestion/process_point_of_contacts/${response.processId}`;
      },
      onError: (_error) => {
        setErrorMessage("Something went wrong. Please try again later.");
      },
    }
  );

  const previewConversationsMutation = useMutation(
    async (data: SurveyIngestionFormFields) => {
      const questionColumns: any = [];
      (survey?.questions || []).forEach((question: any) => {
        questionColumns.push({
          id: question.id,
          column: (data as any)[`question${question.id}Column`]?.value
        });
      });

      const replyDictionaryFieldsColumns: { [key: string]: string } = {};
      data.additionalReplyDictionaryColumns?.forEach((column: string) => {
        if (column) {
          const key: string = toSnakeCase(column);
          replyDictionaryFieldsColumns[key] = column;
        }
      });


      const formData = new FormData();
      formData.append("file", data.file[0]);
      formData.append("nbExcelRows", nbExcelRows.toString());
      formData.append("companyId", data.company.value);
      formData.append("surveyId", data.survey.value);
      formData.append("isGoogleReview", data.isGoogleReview ? "true" : "false");
      formData.append("dateColumn", data.dateColumn?.value ?? "");
      formData.append("questionColumns", JSON.stringify(questionColumns));
      formData.append("replyDictionaryFieldsColumns", JSON.stringify(replyDictionaryFieldsColumns));

      const tryToMatchPOCs = data.pointOfContactKey.value !== "none";
      formData.append("tryToMatchPOCs", tryToMatchPOCs.toString());

      if (tryToMatchPOCs) {
        formData.append("pointOfContactKey", data.pointOfContactKey.value);
        formData.append("pointOfContactKeyColumn", data.pointOfContactKeyColumn.value);
      }

      return fetchData("/admin/survey_ingestion/preview_conversations", {
        method: "POST",
        headers: {
          "X-CSRF-Token": api.csrfToken,
        },
        body: formData,
        throwOnError: true,
      });
    },
    {
      onSuccess: (response: PreviewResponse) => {
        window.location.href = `/admin/survey_ingestion/process_conversations/${response.processId}`;
      },
      onError: (_error) => {
        setErrorMessage("Something went wrong. Please try again later.");
      },
    }
  );

  const handleSubmit = async (data: SurveyIngestionFormFields) => {
    setErrorMessage("");

    if (data.ingestionType.value === "point_of_contacts") {
      previewPointOfContactsMutation.mutate(data);
    } else if (data.ingestionType.value === "survey_conversations") {
      previewConversationsMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (file && file.length > 0) {
      const selectedFile = file[0];

      setIsLoadingExcel(true);
      readXlsxFile(selectedFile)
        .then((rows: Row[]) => {
          const excelColumns: SelectOption[] = rows[0].map((column) => ({
            label: column.toString(),
            value: column.toString(),
          }));
          setExcelColumns(excelColumns);
          setNbExcelRows(rows.length);
        })
        .catch((error) => {
          console.error("Error reading file:", error);
        })
        .finally(() => {
          setIsLoadingExcel(false);
        });
    }
  }, [file]);

  useEffect(() => {
    if (hasUnsupportedQuestions) {
      const unsupportedQuestionLabels = survey?.questions
        ?.filter((question: any) => !question.isSupported)
        .map((question: any) => question.label)
        .join("\n");

      setErrorMessage(
        `Some of the questions in the survey are not supported.\n\nHere the list: \n\n${unsupportedQuestionLabels}\n\nPlease disable them and try again.`
      );
    } else {
      setErrorMessage("");
    }
  }, [hasUnsupportedQuestions]);

  return (
    <div className={classNames(Themes.CHATTER.theme, styles.surveyIngestion)}>
      <FormProvider {...methods}>
        <form>
          <FilePicker name="file" accept=".xlsx">
            <div className={styles.question}>What excel file do you want to ingest?</div>
          </FilePicker>

          {isLoadingExcel && <LoadingPage />}

          {excelColumns && (
            <SelectInput options={ingestionTypes} name="ingestionType">
              <div className={styles.question}>What do you want to ingest?</div>
            </SelectInput>
          )}

          {ingestionType && companies && (
            <SelectInput options={companies} name="company">
              <div className={styles.question}>Which company?</div>
            </SelectInput>
          )}
          {excelColumns && ingestionType === "point_of_contacts" && (
            <>
              <div className={styles.question}>Please select the excel column for each field</div>
              <div className={styles.group}>
                <PointOfContactFields excelColumns={excelColumns} />
              </div>

              <div className={styles.question}>Poc Metadata fields (optional)</div>
              <div className={styles.group}>
                {pocMetadataFields.map((field) => (
                  <SelectInput key={field} options={excelColumns} name={`${field}PocMetadataColumn`} isRequired={false}>
                    <div className={styles.question}>{field}</div>
                  </SelectInput>
                ))}
                <CheckboxList name="additionalPocMetadataColumns" checkboxOptions={excelColumns}>
                  <div className={styles.question}>additional poc metadata fields</div>
                </CheckboxList>
              </div>
              <Button
                css={{ marginTop: "20px" }}
                size="large"
                width="125px"
                disabled={!isValid || !isDirty || previewPointOfContactsMutation.isLoading || previewPointOfContactsMutation.isSuccess}
                onClick={methods.handleSubmit(handleSubmit)}
              >
                {previewPointOfContactsMutation.isLoading || previewPointOfContactsMutation.isSuccess ? <Loading size="20px" /> : "Preview"}
              </Button>
            </>
          )}
          {ingestionType === "survey_conversations" && (
            <>
              {surveys && (
                <>
                  <SelectInput options={surveys} name="survey">
                    <div className={styles.question}>Which survey? *</div>
                  </SelectInput>
                  <div>* make sure that your survey has a selected 'ML processor', and set the 'CEC processor' to 'None' to optimize performance.</div>
                  <label className={styles.checkboxContainer}>
                    <input type="checkbox" {...register("isGoogleReview")} /> Is Google Review?
                  </label>
                </>
              )}
              {excelColumns && survey && !hasUnsupportedQuestions && (
                <>

                  <PointOfContactMatchingFields excelColumns={excelColumns} />
                  <div className={styles.question}>Please select the excel column for each field</div>
                  <div className={styles.group}>
                    <SelectInput options={excelColumns} name="dateColumn">
                      <div className={styles.question}>date</div>
                    </SelectInput>
                    {survey.questions.map((question: any) => (
                      <SelectInput key={question.id} options={excelColumns} name={`question${question.id}Column`}>
                        <div className={styles.question}>{question.label}</div>
                      </SelectInput>
                    ))}
                    <CheckboxList name="additionalReplyDictionaryColumns" checkboxOptions={excelColumns}>
                      <div className={styles.question}>additional reply dictionary fields (optional)</div>
                    </CheckboxList>
                  </div>

                  <Button
                    css={{ marginTop: "20px" }}
                    size="large"
                    width="125px"
                    disabled={!isValid || !isDirty || previewConversationsMutation.isLoading || previewConversationsMutation.isSuccess}
                    onClick={methods.handleSubmit(handleSubmit)}
                  >
                    {previewConversationsMutation.isLoading || previewConversationsMutation.isSuccess ? <Loading size="20px" /> : "Preview"}
                  </Button>
                </>
              )}
            </>
          )}
          <ErrorMessage errorMessages={errorMessage} />
        </form>
      </FormProvider>
    </div>
  );
};
export default withReactQuery(SurveyIngestion);
