import React, { useEffect, useState } from "react";
import { Card, TabRoot, TabList, TabTrigger, TabContent, Themes, Select, SelectOption, Typography, SelectItem } from "stingray-components";
import { fetchData } from "../utils/request-helper";

import { screenSize } from "../utils/constants";
import ScreenBreakpoints from "../utils/screen_breakpoints/ScreenBreakpoints";
import { formatDate } from "../utils/general-helper";
import { SurveyCategoryPanel, SurveyCategory } from "./SurveyCategoryPanel";
import { MetricTile } from "./MetricTile";
import { getMetricParams } from "./metricUtils";
import { MetricData } from "./Metric";

import classnames from "classnames";
import I18n from "i18n-js";
import styles from "./ExecutiveDashboard.module.scss";

const DATE_RANGES = {
  LAST_DAY: "last_day",
  LAST_WEEK: "last_week",
  LAST_MONTH: "last_month",
  LAST_QUARTER: "last_quarter"
};

interface Filters {
  start_date: string;
  end_date: string;
  historial_start_date: string;
  historial_end_date: string;
  locations: Location[];
  time_zone: string;
}

interface Location {
  id: string;
  name: string;
}

export type DateRangeType = typeof DATE_RANGES[keyof typeof DATE_RANGES];

const ExecutiveDashboard: React.FC = () => {
  const [dateRange, setDateRange] = useState<DateRangeType>(DATE_RANGES.LAST_WEEK);
  const [metricParams, setMetricParams] = useState<URLSearchParams>();
  const [oldFiltersParams, setOldFiltersParams] = useState<URLSearchParams>();
  const [surveyCategories, setSurveyCategories] = useState<SurveyCategory[]>([]);
  const [filters, setFilters] = useState<Filters>();
  const [globalNps, setGlobalNps] = useState<MetricData>();
  const [globalCsat, setGlobalCsat] = useState<MetricData>();
  const [globalInitiations, setGlobalIniations] = useState<MetricData>();
  const [userScreenSize, setUserScreenSize] = useState(screenSize.SMALL);

  const locale = I18n.locale;
  const timeZone = filters?.time_zone

  useEffect(() => {
    (async () => {
      const data = await fetchData("/executive_dashboard/survey_categories");

      setSurveyCategories(data);
    })();
  }, [])

  useEffect(() => {
    (async () => {
      clearAllData();

      const data = await fetchData(`/executive_dashboard/filters?date_range=${dateRange}`);

      setFilters(data);
    })();
  }, [dateRange])

  useEffect(() => {
    if (surveyCategories.length) {
      const surveys = surveyCategories.map((surveyCategory: SurveyCategory) => surveyCategory.surveys).flat();

      setMetricParams(getMetricParams(dateRange, surveys));
    }
  }, [dateRange, surveyCategories]);

  useEffect(() => {
    if (metricParams) {
      updateGlobalNps();
      updateGlobalCsat();
      updateGlobalInitiations();
    }
  }, [metricParams]);

  useEffect(() => {
    if (filters) {
      const params = new URLSearchParams();

      params.append("fltr_ed", filters.end_date.split("T")[0]);
      params.append("fltr_interval", "week")
      params.append("fltr_sd", filters.start_date.split("T")[0]);

      setOldFiltersParams(params);
    }
  }, [filters]);

  const onDateRangeChange = (newDateRange: SelectItem) => setDateRange(newDateRange.value);

  const updateGlobalNps = async () => {
    const data = await fetchData(`/executive_dashboard/nps_data?${metricParams?.toString()}`);

    setGlobalNps(data);
  };

  const updateGlobalCsat = async () => {
    const data = await fetchData(`/executive_dashboard/csat_data?${metricParams?.toString()}`);

    setGlobalCsat(data);
  };

  const updateGlobalInitiations = async () => {
    const data = await fetchData(`/executive_dashboard/initiations_data?${metricParams?.toString()}`);

    setGlobalIniations(data);
  };

  const clearAllData = () => {
    setFilters(undefined);
    setGlobalNps(undefined);
    setGlobalCsat(undefined);
    setGlobalIniations(undefined);
  };

  return (
    <>
      <ScreenBreakpoints userScreenSize={userScreenSize} setUserScreenSizeCallback={setUserScreenSize} />
      <div className={classnames(Themes.CHATTER.theme, styles.executiveDashboard)}>
        <div className={styles.header}>
          <Typography
              variant="subtitle2"
              dangerouslySetInnerHTML={{
                __html: filters ? I18n.t(
                  "filters_description",
                  {
                    start_date: formatDate({
                      date: new Date(filters.start_date),
                      locale,
                      timeZone,
                    }),
                    end_date: formatDate({
                      date: new Date(filters.end_date),
                      locale,
                      timeZone,
                    }),
                    historical_start_date: formatDate({
                      date: new Date(filters.historial_start_date),
                      locale,
                      timeZone,
                    }),
                    historical_end_date: formatDate({
                      date: new Date(filters.historial_end_date),
                      locale,
                      timeZone,
                    }),
                    location_count: filters.locations.length,
                  }
                ) : "&nbsp;"
              }} />
          <div className={styles.dateRangeSelectWrapper}>
            <Select placeholder={{ label: I18n.t(dateRange), value: dateRange }} onChange={onDateRangeChange} outlined>
              { Object.values(DATE_RANGES).map((dateRange: DateRangeType) => (
                <SelectOption key={dateRange} item={{ label: I18n.t(dateRange), value: dateRange }} value={dateRange} />
              )) }
            </Select>
          </div>
        </div>
        <div className={styles.globalMetricsWrapper}>
          <div className={styles.globalMetrics}>
            <Typography variant="h5">{ I18n.t("global_metrics") }</Typography>
            <Card>
              <div className={styles.globalMetricsCard}>
                <MetricTile dateRange={dateRange} metricData={globalNps} asPercentage={false} />
                <MetricTile dateRange={dateRange} metricData={globalCsat} asPercentage={true} />
                <MetricTile dateRange={dateRange} metricData={globalInitiations} asPercentage={false} />
              </div>
            </Card>
          </div>
        </div>
        { surveyCategories.length !== 0 && userScreenSize !== screenSize.LARGE && (
          // FIXME
          // @ts-ignore
          <TabRoot defaultValue={surveyCategories[0].name}>
            <TabList>
              { surveyCategories.map(surveyCategory => (
                <TabTrigger key={surveyCategory.id} value={surveyCategory.name} css={{textWrap: "nowrap"}}>
                  { surveyCategory.name }
                </TabTrigger>
              )) }
            </TabList>
            { surveyCategories.map(surveyCategory => (
              <TabContent key={surveyCategory.id} value={surveyCategory.name}>
                <SurveyCategoryPanel dateRange={dateRange} oldFiltersParams={oldFiltersParams} surveyCategory={surveyCategory} />
              </TabContent>
            )) }
          </TabRoot>
        ) }
        { userScreenSize === screenSize.LARGE && (
          <div className={styles.surveyCategoriesContainer}>
            <div className={styles.surveyCategoryPanels}>
              { surveyCategories.map(surveyCategory => <SurveyCategoryPanel key={surveyCategory.id} dateRange={dateRange} oldFiltersParams={oldFiltersParams} surveyCategory={surveyCategory} />) }
            </div>
          </div>
        ) }
      </div>
    </>
  );
}

export default ExecutiveDashboard;
