import React from "react";
import PropTypes from "prop-types";

import { ChartEngagement } from "../../views/engagement";

import Highcharts from "highcharts";

import defaultOptions from "./default-options.json";

import {
  extendChartConfig,
  getDisplayPercentage,
  modelSeriesArray,
} from "../../utils/chart-helper";

class ChartEngagementWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.chartComponent = React.createRef();
  }

  chartContainerInit() {
    const container = this.chartComponent.current.container.current;

    container.style.height = "100%";
    container.style.width = "100%";
    // container.className = "js-engagement-graph";
    this.chartComponent.current.chart.reflow();
  }

  componentDidMount() {
    this.chartContainerInit();
  }

  prepareData(percentage) {
    let series = modelSeriesArray([{ y: getDisplayPercentage(percentage) }]);
    const extended = {
      series: series,
    };
    return extendChartConfig(defaultOptions, extended);
  }

  render() {
    const { percentage, preferResponded = false } = this.props;

    const options = this.prepareData(percentage);
    const heading = preferResponded ? "Engagement" : "Completions";

    return (
      <ChartEngagement
        options={options}
        text={heading}
        percentage={percentage}
        highcharts={Highcharts}
        chartComponent={this.chartComponent}
      />
    );
  }
}

ChartEngagementWrapper.propTypes = {
  percentage: PropTypes.number.isRequired,
  preferResponded: PropTypes.bool,
};

export default ChartEngagementWrapper;
