import React, { useEffect, useRef, useState } from "react";

import I18n from "i18n-js";
import styles from "./Template.module.scss";
import classNames from "classnames";

const Template = ({ title, description, content, isSelected, onClick }) => {
  const contentRef = useRef();
  const [showExpandButton, setShowExpandButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const { current } = contentRef;

    if (current.clientHeight < current.scrollHeight) setShowExpandButton(true);
  }, [contentRef]);

  const handleEOnxpandButtonClick = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  }

  return (
    <div
      className={classNames(styles.template, { [styles.selectedTemplate]: isSelected })}
      onClick={onClick}>
      <div className={styles.title}>{ title }</div>
      <div className={styles.description}>{ description }</div>
      <div
        className={classNames(styles.content, {
          [styles.collapsed]: showExpandButton && !isExpanded,
          [styles.expanded]: showExpandButton && isExpanded,
        })}
        ref={contentRef}>
        { content }
        { showExpandButton && !isExpanded && (
          <div className={classNames(styles.contentGradient, { [styles.isSelected]: isSelected })} />
        ) }
      </div>
      { showExpandButton && (
        <button className={styles.expandButton} type="button" onClick={handleEOnxpandButtonClick}>
          { isExpanded ? I18n.t("live_conversations.see_less") : I18n.t("live_conversations.see_more") }
        </button>
      ) }
    </div>
  );
}

export default Template;
