import React from "react";
import PropTypes from "prop-types";

import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";

import "./tooltip-helper.scss";

//Display tooltip when tooltipMessage is not empty.
//This functionality is not part of OverlayTrigger you cannot specify null in overlay
//Behaviour of OverlayTrigger can be changed by passing props to overlayTriggerProps. Example: <TooltipHelper placement="bottom" ... />
const TooltipHelper = ({ tooltipMessage, children, ...overlayTriggerProps }) => {
  if (tooltipMessage) {
    const tooltip = <Tooltip id={generateRandomID("tooltip", 6)}>{tooltipMessage}</Tooltip>;
    return (
      <OverlayTrigger placement="top" overlay={tooltip} {...overlayTriggerProps}>
        {children}
      </OverlayTrigger>
    );
  } else {
    return children;
  }
};

//Generates Random ID starting with prefix and following by N number of random characters .
const generateRandomID = (prefix, n) =>
  `${prefix}-${Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, n)}`;

TooltipHelper.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipMessage: PropTypes.string,
};

export default TooltipHelper;
