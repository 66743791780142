import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);
import TrendIcon from "../../shared/trend-icon/trend-icon";
import TooltipHelper from "../../../utils/tooltip-helper";
import NPSWrapper from "../nps-wrapper/nps-wrapper";
import NPSChart from "../nps-chart/nps-chart";
import { NPSContentType } from "../../../types";
import { GotoLink } from "../../shared";
import I18n from "i18n-js";

const NPSContent = ({ value, text = "NPS", icon, tooltipMessage, detailsPath }) => {
  const contentClass = cx("content", { negative: value < 0 });

  let hasData = value != null;

  return (
    <NPSWrapper>
      <NPSChart value={value} />
      <div className={cx("wrapper")}>
        <div className={cx("percentage")}>
          <div className={contentClass}>{hasData ? value : I18n.t("no_data")}</div>
          {hasData && (
            <TooltipHelper tooltipMessage={tooltipMessage}>
              <div className={cx("icon")}>
                <TrendIcon icon={icon} />
              </div>
            </TooltipHelper>
          )}
        </div>
        <div className={cx("text")}>{text}</div>
      </div>
      {detailsPath && <GotoLink url={detailsPath} openInNewTab={true} />}
    </NPSWrapper>
  );
};

NPSContent.propTypes = NPSContentType;

export default NPSContent;
