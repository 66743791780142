import Highcharts from "highcharts"

jQuery ->

  ChatterConstants = {
    TopOfFoldBackgroundColour: "#FCFCFC"
  }

  Chart.pluginService.register beforeDraw: (chart) ->
    if chart.config.type == 'doughnut'
      width = chart.chart.width
      height = chart.chart.height
      ctx = chart.chart.ctx
      ctx.restore()
      fontSize = (height / 114).toFixed(2)
      ctx.font = fontSize + 'em "Helvetica Neue"'
      ctx.textBaseline = 'middle'
      numerator = chart.data.datasets[0].data[0]
      denominator = numerator + chart.data.datasets[0].data[1]
      percentage = Math.round(numerator * 100 / denominator)
      text = "#{percentage}%"
      textX = Math.round((width - (ctx.measureText(text).width)) / 2)
      textY = height / 2
      ctx.fillText text, textX, textY
      ctx.save()
    return

  renderChartTrending = ($element) ->
    ret = "not set"
    tdata = $element.data 'trending'

    bar_keys = []
    for key,value of tdata.bars
      bar_keys.push key

    bar_keys = bar_keys.sort()

    bHasNPS = ( tdata.nps != null )

    xCategories = []
    yResponses = []
    yAvgResponses = []
    yOverallNPS = []
    yNPS = []
    maxResponses = 0
    avgNPS = Math.round(tdata.average_nps_responses*Math.pow(10,2))/Math.pow(10,2)
    avgStarted = Math.round(tdata.average_started*Math.pow(10,2))/Math.pow(10,2)
    minNPS = 100
    maxNPS = -100

    for i in [0..bar_keys.length-1]
      bar_key = bar_keys[i]
      bar = tdata.bars[ bar_key ]

      cResponses = bar.c_answeredNPS
      if bHasNPS
        tempNPS = ( 100.0 * (bar.c_promoters - bar.c_detractors) / bar.c_npstotal )
        tempNPS = Math.round(tempNPS*Math.pow(10,2))/Math.pow(10,2)
        yNPS.push tempNPS
        yOverallNPS.push Math.round(tdata.nps*Math.pow(10,2))/Math.pow(10,2)
        yAvgResponses.push avgNPS
        minNPS = tempNPS if minNPS > tempNPS
        maxNPS = tempNPS if maxNPS < tempNPS
      else
        cResponses = bar.c_started
        yAvgResponses.push avgStarted

      yResponses.push cResponses
      if cResponses > maxResponses
        maxResponses = cResponses

      bar_date = bar_key.substr( 0, 19 )
      switch tdata.interval
        when 'day'
          xCategories.push moment( bar_date ).format( "ddd MMM DD" )
        when 'week'
          xCategories.push moment( bar_date ).format( "MMM Do" )
        when 'month'
          xCategories.push moment( bar_date ).format( "MMM" )

    # figure out the column colours
    yNPSColours = []
    for nps in yNPS
      if nps == minNPS
        yNPSColours.push '#FC7256'
      else if nps == maxNPS
        yNPSColours.push '#22A875'
      else
        yNPSColours.push '#CECECE'

    yResponsesMax = (Math.floor(maxResponses/25)+1)*25

    yAxis = []

    if bHasNPS
      yAxis.push {
        id: "NPS"
        min: -100
        max: 100
        tickPositions: [ -100, -50, 0, 50, 100 ]
        labels:
          style: color: '#596679'
        title:
          text: 'NPS'
          style: color: '#596679'
      }

    yAxis.push {
      id: "Responses"
      min: -1 * yResponsesMax
      max: yResponsesMax
      showFirstLabel: false
      tickPositions: [ -1*yResponsesMax, 0, yResponsesMax ]
      labels:
        style: color: '#1590BB'
      title:
        text: 'Responses'
        style: color: '#1590BB'
      opposite: bHasNPS
    }

    series = []

    if bHasNPS
      series.push {
        name: 'NPS'
        type: 'column'
        yAxis: "NPS"
        data: yNPS
        color: '#CECECE'
        borderRadius: '2px'
        colors: yNPSColours
        colorByPoint: true
        minPointLength: 3
      }

    series.push {
      name: 'Responses'
      type: 'spline'
      yAxis: "Responses"
      data: yResponses
      color: '#1590BB'
      lineWidth: '1px'
      marker:
        radius: 2
        hover:
          radius: 3
      states:
        hover:
          lineWidth: '1px'
    }

    if yAvgResponses[0] > 1.0
      series.push {
        name: 'Avg Responses'
        type: 'line'
        color: '#824CC8'
        yAxis: "Responses"
        dashStyle: 'LongDash'
        data: yAvgResponses
        tooltip: enabled: false
        marker: enabled: false
        lineWidth: '1px'
        marker:
          enabled: false
          radius: 2
          hover:
            radius: 3
        states:
          hover:
            lineWidth: '1px'
      }

    if bHasNPS
      series.push {
        name: 'Overall NPS'
        type: 'line'
        color: '#37404D'
        yAxis: "NPS"
        dashStyle: 'LongDash'
        data: yOverallNPS
        tooltip: enabled: false
        lineWidth: '1px'
        marker:
          enabled: false
          radius: 2
          hover:
            radius: 3
        states:
          hover:
            lineWidth: '1px'
      }

    Highcharts.chart $element[0],
      chart:
        alignTicks: true
        zoomType: 'xy'
        style:
          fontWeight: 300
        spacingLeft: 0
        spacingRight: 0
        backgroundColor: ChatterConstants.TopOfFoldBackgroundColour
      title: text: null
      xAxis: [ {
        categories: xCategories
        crosshair: true
        tickWidth: 0
        tickColor: '#4d4d4d'
      } ]
      yAxis: yAxis
      tooltip: shared: true
      legend: enabled: false
      credits: enabled: false
      series: series

  # trending
  $trending = $('.dash-1-7 .js-trending')
  renderChartTrending $trending if $trending.length

  renderChartCompletionRateDonut = ($element) ->
    completed = $element.data 'completed'
    total = $element.data 'total'
    partial = total - completed
    completionRateChart = new Chart $element,
      type: 'doughnut'
      data:
        labels: [
          'Completed'
          'Partial'
        ]
        datasets: [
          backgroundColor: [
            '#AB7DF7',
            '#D6BEFA'
          ]
          data: [
            completed
            partial
          ]
        ]
      options:
        cutoutPercentage: 80
        legend:
          display: false
        responsive: true

  # completion rate
  $completionRate = $('.js-overview-completion-rate')
  renderChartCompletionRateDonut $completionRate if $completionRate.length

  renderChartAlertRateDonut = ($element) ->
    alerts = $element.data 'alerts'
    total = $element.data 'total'
    normal = total - alerts
    completionRateChart = new Chart $element,
      type: 'doughnut'
      data:
        labels: [
          'Alerts'
          'Non-Alerts'
        ]
        datasets: [
          backgroundColor: [
            '#AB7DF7',
            '#D6BEFA'
          ]
          data: [
            alerts
            normal
          ]
        ]
      options:
        cutoutPercentage: 80
        legend:
          display: false
        responsive: true

  # alert rate
  $alertRate = $('.js-overview-alert-rate')
  renderChartAlertRateDonut($alertRate) if $alertRate.length

