import React from "react";
import SatContent from "../../views/tile-sat";
import { PrettyLoader } from "../../views/shared";
import { getTrendIconFromDelta } from "../../utils/general-helper";
import hasLoader from "../hoc/has-loader";
import { SatContainerType } from "../../types";

class SatContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;
    const { satScore, satScoreHistorical, satDetailsPath } = componentData;

    let trendingIcon;
    if (satScore != null && satScoreHistorical != null) {
      trendingIcon = getTrendIconFromDelta(satScore - satScoreHistorical);
    }

    return (
      <PrettyLoader status={status}>
        <SatContent
          value={satScore}
          text="satisfaction"
          icon={trendingIcon}
          detailsPath={satDetailsPath}
        />
      </PrettyLoader>
    );
  }
}

SatContainer.propTypes = SatContainerType;

export default hasLoader(SatContainer);
