import React from "react";

import NPSContent from "../../views/tile-nps";

import { PrettyLoader } from "../../views/shared";

import { getTrendIconFromDelta } from "../../utils/general-helper";

import hasLoader from "../hoc/has-loader";

import { NPSContainerType } from "../../types";

class NPSContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;
    const { npsScore, npsScoreHistorical, npsDetailsPath } = componentData;

    let trendingIcon;
    if (npsScore != null && npsScoreHistorical != null) {
      trendingIcon = getTrendIconFromDelta(npsScore - npsScoreHistorical);
    }

    return (
      <PrettyLoader status={status}>
        <NPSContent value={npsScore} text="NPS" icon={trendingIcon} detailsPath={npsDetailsPath} />
      </PrettyLoader>
    );
  }
}

NPSContainer.propTypes = NPSContainerType;

export default hasLoader(NPSContainer);
