import I18n from "i18n-js";
import React, { MouseEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { Button, Card, Loading, Typography, styled } from "stingray-components";
import { CopyToClipboardButton } from "../shared/CopyToClipboardButton";

import { User, UserManagementFormFields } from "./userManagementTypes";

import classNames from "classnames";
import styles from "./UserManagementForm.module.scss";
import { MutationFetcherProps, useConfig } from "../../src/hooks/useConfig";

const APITokenButton = styled(Button, {
    "& > div": {
        width: 100,
        transition: "background  0.4s ease-out"
    },
    variants: {
        color: {
            regular: {},
            danger: {
                "& > div": {
                    background: "#F00000",

                    "&:hover": {
                        background: "#FF0000 !important"
                    },
                },
            }
        },
    },
})

type APIFetcherProps = MutationFetcherProps & {
    targetUserId: number;
}

const createAPIToken = async ({ csrfToken, targetUserId }: APIFetcherProps) => {
    const response = await fetch(`/api/user_management/${targetUserId}/api_token`, {
        method: "POST",
        headers: {
            "X-CSRF-Token": csrfToken
        }
    });

    if (!response.ok) throw new Error("Could not create the API token.");

    return await response.json();
}

const deleteAPIToken = async ({ csrfToken, targetUserId }: APIFetcherProps) => {
    const response = await fetch(`/api/user_management/${targetUserId}/api_token`, {
        method: "DELETE",
        headers: {
            "X-CSRF-Token": csrfToken
        }
    });

    if (!response.ok) throw new Error("Could not delete the API token.");
}

type APITokenCardProps = {
    targetUser: User;
};

const APITokenCard = ({ targetUser }: APITokenCardProps) => {
    const config = useConfig()

    const { setValue, control } = useFormContext<UserManagementFormFields>();

    const createTokenMutation = useMutation({
        mutationFn: createAPIToken,
        onSuccess: (data) => setValue("apiToken", data.apiToken)
    })

    const deleteTokenMutation = useMutation({
        mutationFn: deleteAPIToken,
        onSuccess: () => setValue("apiToken", null)
    })

    const apiToken = useWatch({
        control,
        name: "apiToken",
    })

    return (
        <Card className={styles.cardContainer}>
            <div className={styles.cardSubsection}>
                <div className={styles.buttonHeaderContainer}>
                    <Typography variant="h6">{I18n.t("user_management.api_access_token")}</Typography>
                    <APITokenButton
                        size="medium"
                        color={apiToken ? "danger" : "regular"}
                        disabled={createTokenMutation.isLoading || deleteTokenMutation.isLoading}
                        onClick={(e: MouseEvent) => {
                            e.preventDefault()

                            if (apiToken) {
                                deleteTokenMutation.mutate({targetUserId: targetUser.id, csrfToken: config.api.csrfToken})
                            } else {
                                createTokenMutation.mutate({targetUserId: targetUser.id, csrfToken: config.api.csrfToken})
                            }
                        }}
                    >
                        <Typography variant="h8">
                            {(createTokenMutation.isLoading || deleteTokenMutation.isLoading) ?
                                <Loading size="25px" /> :
                                (apiToken ? I18n.t("user_management.delete") : I18n.t("user_management.generate"))
                            }
                        </Typography>
                    </APITokenButton>
                </div>
                <div className={classNames(styles.cardSubsectionSettings, styles.apiTokenContainer)}>
                    <Typography variant="h9">{apiToken ?? I18n.t("user_management.no_api_token")}</Typography>
                    {apiToken && <CopyToClipboardButton textToCopy={apiToken} size="xl" />}
                </div>
            </div>
        </Card>
    );
};

export default APITokenCard;
