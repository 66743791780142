jQuery ->

  parse = require('url-parse')
  Highcharts = require('highcharts')
  { translateTopicName } = require('./utils/translation_helper')

  # short circuit if not on right page
  return unless window.location.pathname.includes("/monthly_report")

  DEFAULT_HIGHCHARTS_OPTIONS =
    chart:
      style: {
        "font-family": "'Open Sans', sans-serif;"
      }
    title: text: null
    xAxis:
      type: "datetime"
      tickInterval: 24 * 3600 * 1000
      dateTimeLabelFormats:
        day: I18n.t("date.formats.no_year")
        week: I18n.t("date.formats.no_year")
    plotOptions:
      column:
        borderWidth: 0
        groupPadding: 0.1
        pointPadding: 0
        shadow: false
    yAxis:
      softMin: 0
    legend:
      enabled: true
      verticalAlign: "top"
    credits: enabled: false
    tooltip: shared: true

  renderTrendingNPS = () ->
    startDates = $('.trends-container').data('start-dates-js')
    npsGraph = $("#nps-graph")
    npsData = npsGraph.data('nps-data')
    initiationsData = npsGraph.data('initiations-data')

    chartOptions =
      xAxis:
        min: startDates.first
        max: startDates.last
      yAxis: [
        {
          title:
            text: I18n.t("nps")
          min: -100
          max: 100
          allowDecimals: false
          startOnTick: false
        }
        {
          title:
            text: I18n.t("initiations")
          labels:
            format: '{value}'
          opposite: true
          alignTicks: true
          gridLineWidth: 0
          allowDecimals: false
        }
      ]
      series: [
        {
          name: I18n.t("initiations")
          type: 'column'
          yAxis: 1
          data: initiationsData
          color: '#2962FF'
        }
        {
          name: I18n.t("nps")
          type: 'line'
          data: npsData
          color: '#00a'
        }
      ]

    chartOptions = $.extend(true, {}, DEFAULT_HIGHCHARTS_OPTIONS, chartOptions)
    Highcharts.chart npsGraph[0], chartOptions

  renderTrendingCompletions = () ->
    startDates = $('.trends-container').data('start-dates-js')
    completionsGraph = $('#completions-graph')
    completionsData = completionsGraph.data('completions-data')
    initiationsData = completionsGraph.data('initiations-data')

    chartOptions =
      chart: type: 'column'
      xAxis:
        min: startDates.first
        max: startDates.last
      plotOptions:
        column:
          stacking: 'normal'
      series: [
        {
          name: I18n.t("initiations")
          data: initiationsData
          color: '#2962FF'
        }
        {
          name: I18n.t("completions")
          data: completionsData
          color: '#00A'
        }
      ]

    chartOptions = $.extend(true, {}, DEFAULT_HIGHCHARTS_OPTIONS, chartOptions)
    Highcharts.chart completionsGraph[0], chartOptions

  renderTrendingAlerts = () ->
    startDates = $('.trends-container').data('start-dates-js')
    alertsGraph = $("#alerts-graph")
    alertsOpenedData = alertsGraph.data('alerts-opened-data')
    alertsClosedData = alertsGraph.data('alerts-closed-data')

    chartOptions =
      chart:
        type: 'column'
      xAxis:
        min: startDates[0]
        max: startDates[-1]
      series: [
        {
          name: I18n.t("opened_alerts")
          data: alertsOpenedData
          color: 'rgb(255, 89, 131)'
        }
        {
          name: I18n.t("closed_alerts")
          data: alertsClosedData
          color: 'rgb(29, 233, 182)'
        }
      ]

    chartOptions = $.extend(true, {}, DEFAULT_HIGHCHARTS_OPTIONS, chartOptions)
    Highcharts.chart alertsGraph[0], chartOptions

  renderStackedTopTopicGraph = ($element) ->
    topicData = $element.data('topic-data')
    month = $element.data('month')
    $topicGraph = $("#topic-graph")

    startDates = $(".trends-container").data("start-dates")
    startDatesJS = $(".trends-container").data("start-dates-js")

    negativeData = []
    positiveData = []
    neutralData = []

    # collect data points into array with JS format timestamps for highcharts
    for startDate, index in startDates
      if data = topicData[startDate]
        negativeData.push [startDatesJS[index], data.c_negative_mentions]
        positiveData.push [startDatesJS[index], data.c_positive_mentions]
        neutralData.push [startDatesJS[index], data.c_neutral_mentions]

    chartOptions =
      chart:
        type: 'area'
      yAxis:
        title: text: I18n.t("nb_of_mentions")
      tooltip:
        shared: true
      plotOptions:
        area:
          stacking: 'normal'
          marker: enabled: false
      series: [
        {
          name: I18n.t("negative")
          data: negativeData
          color: 'rgb(255, 89, 131)'
        }
        {
          name: I18n.t("positive")
          data: positiveData
          color: 'rgb(29, 233, 182)'
        }
        {
          name: I18n.t("neutral")
          data: neutralData
          color: '#f0f0f7'
        }
      ]
    chartOptions = $.extend(true, {}, DEFAULT_HIGHCHARTS_OPTIONS, chartOptions)

    Highcharts.chart $topicGraph[0], chartOptions


  renderMonthlyTopics = () ->
    $survey_monthly_report = $('.survey-monthly-report')
    return unless $survey_monthly_report.length

    $.ajax
      type: "GET"
      url: $survey_monthly_report.data('topicurl')
      success: (response) ->
        processMonthlyReportTopicData(response)
      error: (xhr, status, error) ->
        alert xhr.responseText

  processMonthlyReportTopicData = (topicData) ->
    return unless topicData

    # set the at a glance box
    $at_a_glance_box = $('.survey-monthly-report .at-a-glance-box.topic-negative-mentions')
    $at_a_glance_box.removeClass('lazy-load')

    $top_topic_container = $('#top-topic-container')
    $top_topic_container.removeClass('lazy-load')

    if topicData.top_3_negative
      $at_a_glance_box.find('.body').text(translateTopicName(topicData.top_3_negative[0][0]))
      $at_a_glance_box.find('.body').attr('title', translateTopicName(topicData.top_3_negative[0][0]))
    if topicData.historical_top_3_negative
      $at_a_glance_box.find('.last-period-data').text(translateTopicName(topicData.historical_top_3_negative[0][0]))
      $at_a_glance_box.find('.last-period-data').attr('title', translateTopicName(topicData.historical_top_3_negative[0][0]))

    # set the "What You Customers Are Saying" section
    $top_topics = $top_topic_container.find('#top-topics .top-topic')

    for i in [0..2]
      top_topic = $top_topics[i]
      if i < topicData.top_3_total.length
        topic = switch topicData.selected_topic_type
          when "most_mentioned"
            topicData.top_3_total[i]
          when "most_negative"
            topicData.top_3_negative[i]
          when "most_positive"
            topicData.top_3_positive[i]
        topic_name = translateTopicName(topic[0])
        total_mentions = topic[1].c_total_mentions
        total_positive = topic[1].c_positive_mentions
        total_negative = topic[1].c_negative_mentions
        total_neutral = topic[1].c_neutral_mentions

        $top_topic = $($top_topics[i])
        $top_topic.data('topic-data', topic[1])
        $top_topic.find('.topic-name').text(topic_name)
        $top_topic.find('.topic-name').attr('title', topic_name)
        $top_topic.find('.topic-total-mentions').text(total_mentions+" #{I18n.t("total_mentions")}")
        $top_topic.find('.topic-positive-mentions').text(total_positive)
        $top_topic.find('.topic-negative-mentions').text(total_negative)
        $top_topic.find('.topic-neutral-mentions').text(total_neutral)
      else
        top_topic.style.display = 'none'

    $($top_topics[0]).trigger('click')

  $('.survey-monthly-report .org-selector-container .org-selector .org-name').on 'click', (e) ->
    $(this).parents('.org-selector').find('.org-name').removeClass('selected')
    $(this).addClass('selected')

  $('.survey-monthly-report .trends-container .trend-selector-container .trend-selector').on 'click', (e) ->
    $(this).parents('.trend-selector-container').find('.trend-selector').removeClass('selected')
    $(this).addClass('selected')

  $('.survey-monthly-report .customers-saying-container .topic-selector-container .topic-selector').on 'click', (e) ->
    $(this).parents('.topic-selector-container').find('.topic-selector').removeClass('selected')
    $(this).addClass('selected')

  $('#top-topics .top-topic').on 'click', (e) ->
    $('#top-topics .top-topic').removeClass('selected')
    $(this).addClass('selected')
    renderStackedTopTopicGraph $(this)

  $('.survey-monthly-report #month-select').on 'change', (e) ->
    d = ($(@).val() == $("#fltr_sd").val())
    $("#go-button").prop("disabled", d)

  $(".survey-monthly-report #go-button").on 'click', (e) ->
    url = parse(window.location.href, true)
    query_string = url.query
    query_string["fltr_ed"] = $("#month-select").val()
    delete query_string.fltr_sd

    url.set("query", query_string)
    window.location.href = url

  $("#nps-selector").click ->
    $("#nps-selector").addClass("selected")
    $("#completions-selector").removeClass("selected")
    $("#alerts-selector").removeClass("selected")
    $("#nps-graph").removeClass("hide")
    $("#completions-graph").addClass("hide")
    $("#alerts-graph").addClass("hide")

  $("#completions-selector").click ->
    $("#nps-selector").removeClass("selected")
    $("#completions-selector").addClass("selected")
    $("#alerts-selector").removeClass("selected")
    $("#nps-graph").addClass("hide")
    $("#completions-graph").removeClass("hide")
    $("#alerts-graph").addClass("hide")

  $("#alerts-selector").click ->
    $("#nps-selector").removeClass("selected")
    $("#completions-selector").removeClass("selected")
    $("#alerts-selector").addClass("selected")
    $("#nps-graph").addClass("hide")
    $("#completions-graph").addClass("hide")
    $("#alerts-graph").removeClass("hide")

  # main execution

  renderTrendingNPS()
  renderTrendingCompletions()
  renderTrendingAlerts()
  renderMonthlyTopics()
