jQuery ->
  $surveysList = $('.js-company-surveys')
  $('.js-company-surveys-search').on 'keyup', (e) ->
    nameString = $(@).val()
    if nameString?
      nameString = nameString.toLowerCase() if nameString?
    else
      nameString = ''
    $surveysList.isotope
      filter: ->
        $(@).data('name').startsWith nameString
    return