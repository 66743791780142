jQuery(() => {
  $("#message-center-new-form input[type=radio][name=send_type]").on("change", (e) => {
    switch (e.target.value) {
      case "now":
        $(".message-center-send-date, .message-center-select-time-zone-container").addClass(
          "visibility-hidden"
        );
        break;
      case "later":
        $(".message-center-select-time-zone").chosen();
        $("#message-center-send-date").flatpickr({
          altInput: true,
          altFormat: I18n.t("date.flatpickr_formats.date_with_time", {
            defaultValue: "F j, Y h:i K",
          }),
          defaultDate: new Date(),
          enableTime: true,
          locale: I18n.locale,
          minDate: "today",
          time_24hr: I18n.t("date.flatpickr_formats.time_is_24hour") === "true",
        });
        $(".message-center-send-date, .message-center-select-time-zone-container").removeClass(
          "visibility-hidden"
        );
        break;
    }
  });
});
