import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import AlertSymbol from "images/alert-symbol.svg";

const cx = classNames.bind(styles);

const AlertsIcon = ({ hasNewAlerts }) => {
  const imageClassName = cx("image", { new: hasNewAlerts });

  return (
    <div className={styles.wrapper}>
      <div className={styles.fixer}>
        <AlertSymbol className={imageClassName} />
      </div>
    </div>
  );
};

AlertsIcon.propTypes = {
  hasNewAlerts: PropTypes.bool,
};

export default AlertsIcon;
