import React from "react";
import PropTypes from "prop-types";

//Return fake data after timeout
//When data prop is passed to WrappedComponent it will override data object inside of options
// options -
//  data - object what will be returned to component as componentData
//  error - your can fake error after timeout
//  timeout - how long should component be in loading state
let hasFakeLoader = (WrappedComponent, options = { data: {}, error: false, timeout: 0 }) => {
  class HasLoader extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        componentData: {},
        status: "loading",
      };
    }

    fakeFetch() {
      const { data, timeout, error } = options;

      let updatedData = {
        ...data,
        ...this.props.data,
      };

      return setTimeout(() => {
        if (error) {
          this.setState({
            componentData: {},
            status: "error",
          });
        } else {
          this.setState({
            componentData: updatedData,
            status: "done",
          });
        }
      }, timeout);
    }

    componentDidMount() {
      const { data, timeout, error } = options;
      this.fakeFetch(timeout, data, error);
    }

    componentWillUnmount() {
      clearInterval(this.refresher);
    }

    render() {
      return <WrappedComponent {...this.state} />;
    }
  }

  HasLoader.propTypes = {
    data: PropTypes.object,
  };

  const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || "Component";
  };

  HasLoader.displayName = `hasFakeLoader(${getDisplayName(WrappedComponent)})`;

  return HasLoader;
};

export default hasFakeLoader;
