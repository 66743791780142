import React, { useEffect, useRef, useState } from "react";
import ActionCable from "actioncable";
import debounce from "lodash.debounce";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { screenSize, uniqueId } from "../utils/constants";
import { fetchData } from "../utils/request-helper";
import ScreenBreakpoints from "../utils/screen_breakpoints/ScreenBreakpoints";

import Header from "./Header";
import IconButton from "./IconButton";
import Conversation from "./Conversation";
import ConversationList from "./ConversationList";
import FilterConversations from "./FilterConversations";
import LiveConversationsMenu from "./LiveConversationsMenu";
import StartConversation from "./StartConversation";
import StartConversationButton from "./StartConversationButton";
import ConversationInfo from "./ConversationInfo";

import { Themes } from "stingray-components";
import I18n from "i18n-js";
import classNames from "classnames";
import styles from "./LiveConversations.module.scss";

const LiveConversations = ({ api, limitations, locations, user, templates }) => {
  const PAGE_SIZE = 20;

  const actionCableConsumer = useRef(ActionCable.createConsumer("/cable"));
  const [isLoading, setIsLoading] = useState(false);
  const [conversationIds, setConversationIds] = useState([]);
  const [conversationInfoIsOpen, setConversationInfoIsOpen] = useState(false);
  const [furthestConversationIndex, setFurthestConversationIndex] = useState(-1);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState();
  const [subscribedLocationIds, setSubscribedLocationIds] = useState([]);
  const [subscribedToCompany, setSubscribedToCompany] = useState(false);
  const [conversationReceived, setConversationReceived] = useState();
  const [userScreenSize, setUserScreenSize] = useState(screenSize.SMALL);

  const [filters, setFilters] = useState();
  const [filtersAreExpanded, setFiltersAreExpanded] = useState(false);
  const [showClosedConversations, setShowClosedConversations] = useState(false);

  const [showMenu, setShowMenu] = useState(false);
  const [showConversation, setShowConversation] = useState(false);
  const [showStartConversation, setShowStartConversation] = useState(false);

  const conversationsRef = useRef(conversations);
  const conversationIdsRef = useRef(conversationIds);
  const furthestConversationIndexRef = useRef(furthestConversationIndex);

  const hasMoreConversations = furthestConversationIndex < conversationIds.length - 1;

  const conversationShown = (showConversation || userScreenSize !== screenSize.SMALL) && !showStartConversation && (userScreenSize !== screenSize.MEDIUM || !conversationInfoIsOpen);
  const conversationInfoShown = conversationInfoIsOpen && !showStartConversation;

  useEffect(() => {
    conversationsRef.current = conversations;
  }, [conversations]);

  useEffect(() => {
    conversationIdsRef.current = conversationIds;
    if (conversationIds.length !== 0 && conversations.length === 0) debouncedGetNextPage();
  }, [conversationIds]);

  useEffect(() => {
    furthestConversationIndexRef.current = furthestConversationIndex;
  }, [furthestConversationIndex]);

  useEffect(() => {
    if (userScreenSize === screenSize.SMALL) {
      setShowConversation(false);
    }
    if (userScreenSize !== screenSize.LARGE) {
      setConversationInfoIsOpen(false);
    }
  }, [userScreenSize]);

  useEffect(() => {
    if (conversationReceived && conversationMatchesFilters(conversationReceived.conversation)) {
      updateConversation({ conversation: conversationReceived.conversation, bumpToTop: conversationReceived.bumpToTop });
      if (conversationReceived.conversation.id === selectedConversation?.id) {
        setSelectedConversation(conversationReceived.conversation);
      }
    }
  }, [conversationReceived]);

  useEffect(() => {
    const searchParams = new URLSearchParams({
      conversation_name: filters?.conversationName || "",
      point_of_contact_id: filters?.location?.value || "",
      phone_number: filters?.phoneNumber || "",
      message: filters?.message || "",
      show_closed_conversations: showClosedConversations,
    });

    debouncedGetConversationIds(searchParams);
  }, [filters, showClosedConversations]);

  useEffect(() => {
    if (!locations?.length || !user) return;

    const locationIdsToSubscribe = [];
    let needToSubscribeToCompany = false;

    if (filters?.location?.value) {
      locationIdsToSubscribe.push(filters.location.value);
    } else if (user?.has_all_accesses) {
      needToSubscribeToCompany = true;
    } else {
      locationIdsToSubscribe.push(...locations.map((location) => location.value));
    }

    const locationIdsToAdd = locationIdsToSubscribe.filter((locationId) => !subscribedLocationIds.includes(locationId));
    const locationIdsToRemove = subscribedLocationIds.filter((locationId) => !locationIdsToSubscribe.includes(locationId));

    locationIdsToRemove.forEach((locationId) => {
      unsubscribe(getSubscriptionIdentifier({locationId}));
    });
    if (subscribedToCompany && !needToSubscribeToCompany) {
      unsubscribe(getSubscriptionIdentifier({companyId: user.company_id}))
    }

    locationIdsToAdd.forEach((locationId) => {
      subscribe(getSubscriptionIdentifier({locationId}));
    });
    if (!subscribedToCompany && needToSubscribeToCompany) {
      subscribe(getSubscriptionIdentifier({companyId: user.company_id}))
    }

    setSubscribedLocationIds(locationIdsToSubscribe);
    setSubscribedToCompany(needToSubscribeToCompany);
  }, [filters, locations, user, selectedConversation]);

  const getSubscriptionIdentifier = ({locationId, companyId}) => {
    if (!locationId && !companyId) return null;

    return { channel: "LiveConversationsChannel", point_of_contact_id: locationId, company_id: companyId, uniqueId: uniqueId };
  }

  const subscribe = (identifier) => {
    if (identifier) {
      actionCableConsumer.current.subscriptions.create(identifier, {
        received: (conversation) => setConversationReceived({ conversation, bumpToTop: true }),
      });
    }
  }

  const unsubscribe = (identifier) => {
    if (identifier) {
      const subscriptions = actionCableConsumer.current.subscriptions.subscriptions.find(s => s.identifier === JSON.stringify(identifier));

      subscriptions?.unsubscribe();
    }
  };

  const conversationMatchesFilters = (conversation) => {
    if (filters?.location?.value && conversation.location.id !== filters.location.value) {
      return false;
    }

    if (filters?.phoneNumber && !conversation.phone.replace(/\D/g, "").includes(filters.phoneNumber.replace(/\D/g, ""))) {
      return false
    }

    if (filters?.conversationName && !conversation.dictionary["_conversation_name"]?.toLowerCase()?.includes(filters.conversationName.toLowerCase())) {
      return false
    }

    if (filters?.message) {
      const filterMessage = filters.message?.toLowerCase();
      if (!conversation.replies.some((reply) => reply.sentText?.toLowerCase()?.includes(filterMessage) || reply.answer?.toLowerCase()?.includes(filterMessage))) {
        return false;
      }
    }

    return true;
  }

  const handleScroll = ({ target }) => {
    if (target.id !== "live-conversations" && target.id !== "scrolling-left-container") return;

    const bottomScrollPosition = target.clientHeight + target.scrollTop;
    const bottomThreshold = target.scrollHeight - (target.clientHeight * 0.5);
    if (hasMoreConversations && bottomScrollPosition >= bottomThreshold) {
      debouncedGetNextPage();
    }
  }

  const getNextPage = async () => {
    const currentConversations = conversationsRef.current;
    const currentConversationIds = conversationIdsRef.current;
    const currentFurthestConversationIndex = furthestConversationIndexRef.current;

    const fromIndex = currentFurthestConversationIndex + 1;
    const toIndex = Math.min(fromIndex + PAGE_SIZE, currentConversationIds.length);
    const conversationIdsToLoad = currentConversationIds.slice(fromIndex, toIndex);

    const newConversations = (await fetchData(`${api.conversationsUrl}?ids=${conversationIdsToLoad.join(",")}`)) || [];

    setConversations(currentConversations.concat(newConversations));
    setFurthestConversationIndex(toIndex);
    setIsLoading(false);
  }

  const debouncedGetNextPage = useRef(debounce(() => getNextPage(), 150, { leading: true })).current;

  const getConversationIds = (searchParams = {}) => {
    setIsLoading(true);

    setTimeout(async () => {
      const ids = (await fetchData(`${api.conversationIdsUrl}?${searchParams}`)) || [];

      if (ids.length === 0) setIsLoading(false);

      setConversations([]);
      setFurthestConversationIndex(-1);
      setConversationIds(ids);
    });
  }

  const debouncedGetConversationIds = useRef(debounce((searchParams) => getConversationIds(searchParams), 350, { leading: false, trailing: true })).current;

  const setSelectedConversationCallback = (conversation) => {
    setSelectedConversation(conversation);
    setShowConversation(!!conversation);
    setShowStartConversation(false);
    if (userScreenSize === screenSize.MEDIUM) {
      setConversationInfoIsOpen(false);
    }
  }

  const updateConversation = ({ conversation, bumpToTop }) => {
    if (conversation.isConversationClosed !== showClosedConversations) {
      setConversations(conversations.filter((c) => c.id !== conversation.id));
    } else if (bumpToTop) {
      setConversations([conversation].concat(conversations.filter((c) => c.id !== conversation.id)));
    } else {
      setConversations([ ...conversations.map((oldConversation) => oldConversation.id === conversation.id ? conversation : oldConversation) ]);
    }
  };

  return (
    <div className={Themes.CHATTER.theme}>
      <ScreenBreakpoints userScreenSize={userScreenSize} setUserScreenSizeCallback={setUserScreenSize} />
      <div id="live-conversations" className={styles.liveConversations} onScroll={handleScroll}>
        <LiveConversationsMenu
            show={showMenu}
            setShow={setShowMenu}
            userScreenSize={userScreenSize} />
        <div className={styles.leftContainer} >
          <Header>
            <IconButton onClick={() => setShowMenu(!showMenu)}>
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
            <span>{ I18n.t("live_conversations.conversations") }</span>
          </Header>
          <div id="scrolling-left-container" className={styles.scrollingLeftContainer}>
            <StartConversationButton
              onClick={() => setShowStartConversation(true)}
              show={userScreenSize >= screenSize.MEDIUM || !filtersAreExpanded} />
            <FilterConversations
              filters={filters}
              filtersAreExpanded={filtersAreExpanded}
              locations={locations}
              setFiltersCallback={setFilters}
              setFiltersAreExpandedCallback={setFiltersAreExpanded}
              showClosedConversations={showClosedConversations}
              setShowClosedConversations={setShowClosedConversations}
              userScreenSize={userScreenSize} />
            <ConversationList
              conversations={conversations}
              isLoading={isLoading}
              hasMoreConversations={hasMoreConversations}
              selectedConversation={selectedConversation}
              setSelectedConversationCallback={setSelectedConversationCallback} />
          </div>
        </div>
        <div className={styles.rightContainer}>
          <StartConversation
            api={api}
            limitations={limitations}
            locations={locations}
            show={showStartConversation}
            setShow={setShowStartConversation}
            setSelectedConversationCallback={setSelectedConversationCallback}
            setConversationReceivedCallback={setConversationReceived}
            templates={templates}
            userScreenSize={userScreenSize} />
          <Conversation
            key={selectedConversation?.id}
            api={api}
            limitations={limitations}
            templates={templates}
            user={user}
            userScreenSize={userScreenSize}
            isOpen={conversationShown}
            setIsOpen={setShowConversation}
            conversationInfoIsOpen={conversationInfoIsOpen}
            setConversationInfoIsOpen={setConversationInfoIsOpen}
            selectedConversation={selectedConversation}
            setSelectedConversationCallback={setSelectedConversationCallback}
            setConversationReceivedCallback={setConversationReceived} />
          <div className={classNames({ [styles.conversationInfoContainer]: conversationInfoShown})}>
            <ConversationInfo
              key={selectedConversation?.id}
              api={api}
              user={user}
              isOpen={conversationInfoShown}
              setIsOpen={setConversationInfoIsOpen}
              selectedConversation={selectedConversation}
              setConversationReceivedCallback={setConversationReceived}
              userScreenSize={userScreenSize} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveConversations;
