import React from "react";

import ResponseRateContent from "../../views/tile-response-rate";

import { PrettyLoader } from "../../views/shared";

import { getTrendIconFromDelta } from "../../utils/general-helper";

import hasLoader from "../hoc/has-loader";

class ResponseRateContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;
    const { responseRate, responseRateHistorical, detailsPath } = componentData;

    let trendingIcon;
    if (responseRate != null && responseRateHistorical != null) {
      trendingIcon = getTrendIconFromDelta(responseRate - responseRateHistorical);
    }

    return (
      <PrettyLoader status={status}>
        <ResponseRateContent value={responseRate} icon={trendingIcon} detailsPath={detailsPath} />
      </PrettyLoader>
    );
  }
}

export default hasLoader(ResponseRateContainer);
