import classNames from "classnames/bind";
import I18n from "i18n-js";
import React, { useState } from 'react';
import styles from "../styles.module.scss";
import config from '../config.json';
import activityTimeoutImage from '../../../../images/activity_timeout.png';
import inactivityTimeoutImage from '../../../../images/inactivity_timeout.png';
import navbarEnterImage from '../../../../images/navbar_enter.png';
import windowExitImage from '../../../../images/window_exit.png';

const cx = classNames.bind(styles);

const processInputSources = (sources) => {
  const processedSources = [];
  if (!sources) {
    return processedSources;
  }
  sources.forEach(source => {
    switch (source.type) {
      case "button":
        processedSources.push({
          type: source.type,
          value: source.value.map(button => ({ selectorType: button.selector_type, selectorValue: button.selector_value }))
        });
        break;
      case "behavioural":
        processedSources.push({
          type: source.type,
          value: {
            navbarEnter: {
              enabled: source.value.navbar_enter.enabled,
              delay: source.value.navbar_enter.delay
            },
            windowExit: {
              enabled: source.value.window_exit.enabled,
              delay: source.value.window_exit.delay
            }
          }
        });
        break;
      case "timeout":
        processedSources.push({
          type: source.type,
          value: {
            activityTimeout: {
              enabled: source.value.activity_timeout.enabled,
              delay: source.value.activity_timeout.delay
            },
            inactivityTimeout: {
              enabled: source.value.inactivity_timeout.enabled,
              delay: source.value.inactivity_timeout.delay
            }
          }
        });
        break;
    }
  });
  return processedSources;
}

const processAvailableSourcesInput = (sources) => {
  return config.types.sources.filter(type => !sources.find(source => source.type === type));
}

const TriggerSourcesConfiguration = ({ sources }) => {
  const inputCurrentSources = processInputSources(sources);
  const inputAvailableSources = processAvailableSourcesInput(inputCurrentSources);
  const [currentSources, setCurrentSources] = useState(inputCurrentSources);
  const [availableSources, setAvailableSources] = useState(inputAvailableSources);
  const [newSourceOverlayEnabled, setNewSourceOverlayEnabled] = useState(false);

  const handleAddSource = (e) => {
    e.preventDefault();
    setNewSourceOverlayEnabled(true);
  }

  const handleAddSourceOption = (e, newSourceType) => {
    e.preventDefault();
    setNewSourceOverlayEnabled(false);
    setAvailableSources(availableSources.filter(source => source !== newSourceType));
    const newSource = {
      type: newSourceType,
      value: null
    };
    switch (newSourceType) {
      case "button":
        newSource.value = [
          {
            selectorType: "id",
            selectorValue: ""
          }
        ];
        break;
      case "behavioural":
        newSource.value = {
          navbarEnter: {
            enabled: true,
            delay: 500
          },
          windowExit: {
            enabled: true,
            delay: 2000
          }
        }
        break;
      case "timeout": {
        newSource.value = {
          activityTimeout: {
            enabled: true,
            delay: 60
          },
          inactivityTimeout: {
            enabled: true,
            delay: 15
          }
        }
        break;
      }
    }
    setCurrentSources([...currentSources, newSource]);
  }

  const handleRemoveTriggerType = (e, type) => {
    e.preventDefault();
    const newCurrentSources = [...currentSources].filter((source) => source.type !== type);

    setAvailableSources([...availableSources, type]);
    setCurrentSources(newCurrentSources);
  }

  const handleChangeButtonSelectorType = (index, newType) => {
    const buttonSource = currentSources.find((source) => source.type === "button");
    const button = buttonSource.value[index];
    button.selectorType = newType;
    setCurrentSources([...currentSources]);
  }

  const handleChangeButtonSelectorValue = (index, newValue) => {
    const buttonSource = currentSources.find((source) => source.type === "button");
    const button = buttonSource.value[index];
    button.selectorValue = newValue;
    setCurrentSources([...currentSources]);
  }

  const handleAddSourceButton = (e) => {
    e.preventDefault();
    const buttonSource = currentSources.find((source) => source.type === "button");
    buttonSource.value.push({
      selectorType: "id",
      selectorValue: ""
    });
    setCurrentSources([...currentSources]);
  }

  const handleRemoveButtonSource = (e, index) => {
    e.preventDefault();
    const buttonSource = currentSources.find((source) => source.type === "button");
    buttonSource.value.splice(index, 1);
    setCurrentSources([...currentSources]);
  }

  const toggleBehaviouralSourceType = (type) => {
    const behaviouralSource = currentSources.find((source) => source.type === "behavioural");
    behaviouralSource.value[type].enabled = !behaviouralSource.value[type].enabled;
    setCurrentSources([...currentSources]);
  }

  const handleBehaviouralTypeValueChange = (e, type) => {
    const behaviouralSource = currentSources.find((source) => source.type === "behavioural");
    behaviouralSource.value[type].delay = e.target.value;
    setCurrentSources([...currentSources]);
  }

  const toggleTimeoutSourceType = (type) => {
    const timeoutSource = currentSources.find((source) => source.type === "timeout");
    timeoutSource.value[type].enabled = !timeoutSource.value[type].enabled;
    setCurrentSources([...currentSources]);
  }

  const handleTimeoutTypeValueChange = (e, type) => {
    const timeoutSource = currentSources.find((source) => source.type === "timeout");
    timeoutSource.value[type].delay = e.target.value;
    setCurrentSources([...currentSources]);
  }

  return (
    <div className={cx("trigger-sources")}>
      <div className={cx("connector")} />
      <h2>{I18n.t("web_tag_configuration.trigger_sources")}</h2>
      <div className={cx("trigger-configuration-container")}>
        {currentSources.length === 0 && (
          <div id="no_trigger_sources" dangerouslySetInnerHTML={{ __html: I18n.t("web_tag_configuration.no_source_selected") }}></div>
        )}
        {currentSources.map((source) => {
          switch (source.type) {
            case "button":
              return (
                <div id={"source-type-configuration-" + source.type} className={cx(["trigger-type", "source-button"])} key={"source-type-configuration-" + source.type}>
                  <h4 className={cx("trigger-type-header")}>{I18n.t("web_tag_configuration.source_trigger_type_button")}</h4>
                  <button onClick={(e) => handleRemoveTriggerType(e, "button")} className={cx("trigger-type-remove")}>&#10006;</button>
                  {source.value.map((button, index) => {
                    return (
                      <div key={"source-button-list-element-" + index} className={cx("source-button-tile")}>
                        {index > 0 && (
                          <button onClick={(e) => handleRemoveButtonSource(e, index)} className={cx("source-button-tile-delete")}>
                            <i className="fas fa-trash"></i>
                          </button>
                        )}

                        <div className={cx(["source-button-inputs", "col-xs-8"])}>
                          <label className={cx("control-label select")} htmlFor={`trigger_source[button][${index}][selector_type]`}>{I18n.t("web_tag_configuration.selector_type")}</label>
                          <select onChange={(e) => handleChangeButtonSelectorType(index, e.target.value)} value={button.selectorType} className="form-control select" required id={`trigger_source[button][${index}][selector_type]`} name={`trigger_source[button][${index}][selector_type]`}>
                            {config.dom_element_query_types.map((queryType) =>
                              <option key={"dom_element_option_" + queryType} value={queryType}>{I18n.t("web_tag_configuration.identifier_type." + queryType)}</option>
                            )}
                          </select>
                          <br></br>
                          <label className={cx("control-label string")} htmlFor={`trigger_source[button][${index}][selector_value]`}>{I18n.t("web_tag_configuration.selector_value")}</label>
                          <input onChange={(e) => handleChangeButtonSelectorValue(index, e.target.value)} value={button.selectorValue} required className="form-control string" id={`trigger_source[button][${index}][selector_value]`} name={`trigger_source[button][${index}][selector_value]`} />
                        </div>
                        <div className={cx(["source-button-preview", "col-xs-4"])}>
                          <div className={cx("preview-button")}>
                            <span>
                              {(() => {
                                switch (button.selectorType) {
                                  case "id":
                                    if (button.selectorValue.charAt(0) !== "#") {
                                      return "#" + button.selectorValue.replace(" ", "");
                                    } else {
                                      return button.selectorValue.replace(" ", "");
                                    }
                                  case "classname":
                                    if (button.selectorValue.charAt(0) !== ".") {
                                      return "." + button.selectorValue.replace(" ", "");
                                    } else {
                                      return button.selectorValue.replace(" ", "");
                                    }
                                  default:
                                    return button.selectorValue.replace(" ", "");
                                }
                              })()}
                            </span>
                            <i className="fas fa-hand-pointer"></i>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <div className={cx("source-button-add-button-container")}>
                    <button onClick={(e) => handleAddSourceButton(e)} className={cx("source-button-add-button")}>{I18n.t("web_tag_configuration.add_new_button")}<span>+</span></button>
                  </div>
                </div>
              )
            case "behavioural":
              return (
                <div id={"source-type-configuration-" + source.type} className={cx(["trigger-type", "source-behavioural"])} key={"source-type-configuration-" + source.type}>
                  <h4 className={cx("trigger-type-header")}>{I18n.t("web_tag_configuration.source_trigger_type_behavioural")}</h4>
                  <button onClick={(e) => handleRemoveTriggerType(e, "behavioural")} className={cx("trigger-type-remove")}>&#10006;</button>
                  <div className={cx("configuration-container")}>
                    <div className={cx(["configuration-tile", "navbar-enter", `${!source.value.navbarEnter.enabled ? "disabled" : ""}`])}>
                      <div className={cx("type-header")}>
                        <h5>{I18n.t("web_tag_configuration.navbar_enter")}</h5>
                        <div className={cx("type-switch-container")}>
                          <label className={cx("switch")}>
                            <input id="trigger_source[behavioural][navbar_enter][enabled]" name="trigger_source[behavioural][navbar_enter][enabled]" type="checkbox" checked={source.value.navbarEnter.enabled} onChange={() => toggleBehaviouralSourceType("navbarEnter")} />
                            <span className={cx("slider")} />
                          </label>
                        </div>
                      </div>
                      <img src={navbarEnterImage} />
                      <p className={cx("delay-description")} dangerouslySetInnerHTML={{ __html: I18n.t("web_tag_configuration.navbar_enter_description") }}></p>
                      <div className={cx("delay-input-container")}>
                        <label className="control-label select" htmlFor={`trigger_source[behavioural][navbar_enter][delay]`}>{I18n.t("web_tag_configuration.delay")}</label>
                        <div className={cx("delay-input")}>
                          <input disabled={!source.value.navbarEnter.enabled} value={source.value.navbarEnter.delay} onChange={(e) => handleBehaviouralTypeValueChange(e, "navbarEnter")} required className="form-control string" id={`trigger_source[behavioural][navbar_enter][delay]`} name={`trigger_source[behavioural][navbar_enter][delay]`} type="number" />
                          {!source.value.navbarEnter.enabled && (
                            <input type="hidden" value={source.value.navbarEnter.delay} id={`trigger_source[behavioural][navbar_enter][delay]`} name={`trigger_source[behavioural][navbar_enter][delay]`} />
                          )}
                          <span>ms</span>
                        </div>
                      </div>
                    </div>
                    <div className={cx(["configuration-tile", "window-exit", `${!source.value.windowExit.enabled ? "disabled" : ""}`])}>
                      <div className={cx("type-header")}>
                        <h5>{I18n.t("web_tag_configuration.window_exit")}</h5>
                        <div className={cx("type-switch-container")}>
                          <label className={cx("switch")}>
                            <input id="trigger_source[behavioural][window_exit][enabled]" name="trigger_source[behavioural][window_exit][enabled]" type="checkbox" checked={source.value.windowExit.enabled} onChange={() => toggleBehaviouralSourceType("windowExit")} />
                            <span className={cx("slider")} />
                          </label>
                        </div>
                      </div>
                      <img src={windowExitImage} />
                      <p className={cx("delay-description")} dangerouslySetInnerHTML={{ __html: I18n.t("web_tag_configuration.window_exit_description") }}></p>
                      <div className={cx("delay-input-container")}>
                        <label className="control-label select" htmlFor={`trigger_source[behavioural][window_exit][delay]`}>{I18n.t("web_tag_configuration.delay")}</label>
                        <div className={cx("delay-input")}>
                          <input disabled={!source.value.windowExit.enabled} onChange={(e) => handleBehaviouralTypeValueChange(e, "windowExit")} value={source.value.windowExit.delay} required className="form-control string" id={`trigger_source[behavioural][window_exit][delay]`} name={`trigger_source[behavioural][window_exit][delay]`} type="number" />
                          {!source.value.windowExit.enabled && (
                            <input type="hidden" value={source.value.windowExit.delay} id={`trigger_source[behavioural][window_exit][delay]`} name={`trigger_source[behavioural][window_exit][delay]`} />
                          )}
                          <span>ms</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            case "timeout":
              return (
                <div id={"source-type-configuration-" + source.type} className={cx(["trigger-type", "source-timeout"])} key={"source-type-configuration-" + source.type}>
                  <h4 className={cx("trigger-type-header")}>{I18n.t("web_tag_configuration.source_trigger_type_timeout")}</h4>
                  <button onClick={(e) => handleRemoveTriggerType(e, "timeout")} className={cx("trigger-type-remove")}>&#10006;</button>
                  <div className={cx("configuration-container")}>
                    <div className={cx(["configuration-tile", "activity-timeout", `${!source.value.activityTimeout.enabled ? "disabled" : ""}`])}>
                      <div className={cx("type-header")}>
                        <h5>{I18n.t("web_tag_configuration.time_on_page")}</h5>
                        <div className={cx("type-switch-container")}>
                          <label className={cx("switch")}>
                            <input id="trigger_source[timeout][activity_timeout][enabled]" name="trigger_source[timeout][activity_timeout][enabled]" type="checkbox" checked={source.value.activityTimeout.enabled} onChange={() => toggleTimeoutSourceType("activityTimeout")} />
                            <span className={cx("slider")} />
                          </label>
                        </div>
                      </div>
                      <img src={activityTimeoutImage} />
                      <p className={cx("delay-description")} dangerouslySetInnerHTML={{ __html: I18n.t("web_tag_configuration.activity_timeout_description") }}></p>
                      <div className={cx("delay-input-container")}>
                        <label className="control-label select" htmlFor={`trigger_source[timeout][activity_timeout][delay]`}>{I18n.t("web_tag_configuration.delay")}</label>
                        <div className={cx("delay-input")}>
                          <input disabled={!source.value.activityTimeout.enabled} value={source.value.activityTimeout.delay} onChange={(e) => handleTimeoutTypeValueChange(e, "activityTimeout")} required className="form-control string" id={`trigger_source[timeout][activity_timeout][delay]`} name={`trigger_source[timeout][activity_timeout][delay]`} type="number" />
                          {!source.value.activityTimeout.enabled && (
                            <input type="hidden" value={source.value.activityTimeout.delay} id={`trigger_source[timeout][activity_timeout][delay]`} name={`trigger_source[timeout][activity_timeout][delay]`} />
                          )}
                          <span>s</span>
                        </div>
                      </div>
                    </div>
                    <div className={cx(["configuration-tile", "inactivity-timeout", `${!source.value.inactivityTimeout.enabled ? "disabled" : ""}`])}>
                      <div className={cx("type-header")}>
                      <h5>{I18n.t("web_tag_configuration.inactivity_timeout")}</h5>
                        <div className={cx("type-switch-container")}>
                          <label className={cx("switch")}>
                            <input id="trigger_source[timeout][inactivity_timeout][enabled]" name="trigger_source[timeout][inactivity_timeout][enabled]" type="checkbox" checked={source.value.inactivityTimeout.enabled} onChange={() => toggleTimeoutSourceType("inactivityTimeout")} />
                            <span className={cx("slider")} />
                          </label>
                        </div>
                      </div>
                      <img src={inactivityTimeoutImage} />
                      <p className={cx("delay-description")} dangerouslySetInnerHTML={{ __html: I18n.t("web_tag_configuration.inactivity_timeout_description") }}></p>
                      <div className={cx("delay-input-container")}>
                        <label className="control-label select" htmlFor={`trigger_source[timeout][inactivity_timeout][delay]`}>{I18n.t("web_tag_configuration.delay")}</label>
                        <div className={cx("delay-input")}>
                          <input disabled={!source.value.inactivityTimeout.enabled} onChange={(e) => handleTimeoutTypeValueChange(e, "inactivityTimeout")} value={source.value.inactivityTimeout.delay} required className="form-control string" id={`trigger_source[timeout][inactivity_timeout][delay]`} name={`trigger_source[timeout][inactivity_timeout][delay]`} type="number" />
                          {!source.value.inactivityTimeout.enabled && (
                            <input type="hidden" value={source.value.inactivityTimeout.delay} id={`trigger_source[timeout][inactivity_timeout][delay]`} name={`trigger_source[timeout][inactivity_timeout][delay]`} />
                          )}
                          <span>s</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          }
        })}
        {availableSources.length > 0 && (
          <div className={cx("add-trigger-type")}>
            <button onClick={handleAddSource} className={cx("add-trigger-type-button")}>+</button>
            <div className={cx(["add-trigger-type-options", `${newSourceOverlayEnabled ? "enabled" : ""}`])}>
              {availableSources.map(availableSource => (
                <button onClick={(e) => handleAddSourceOption(e, availableSource)} key={"source-option-" + availableSource} className={cx("add-trigger-type-option")}>
                  {I18n.t("web_tag_configuration.source_trigger_type_" + availableSource)}
                </button>
              ))}
            </div>
            <div onClick={() => setNewSourceOverlayEnabled(false)} className={cx(["add-trigger-type-overlay", `${newSourceOverlayEnabled ? "enabled" : ""}`])} />
          </div>
        )}

      </div>
    </div>
  )
}

export default TriggerSourcesConfiguration;
