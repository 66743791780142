Highcharts = require("highcharts")

jQuery ->
  ChatterConstants = {
    TopOfFoldBackgroundColour: "#FCFCFC"
  }

  # ISOTOPE ON SURVEYS LIST
  $surveys = $('.js-surveys-list').isotope
    itemSelector: '.survey'
    getSortData:
      name:      '[data-name]'
      responses: '[data-responses] parseInt'
      status:    '[data-status] parseInt'
      surveyed:  '[data-surveyed]'
    layoutMode: 'fitRows'
    resize: true
    sortAscending:
      name:      true
      responses: false
      status:    true
      surveyed:  false
    stagger: 0
    transitionDuration: 300

  # ISOTOPE SORTING FROM BUTTON DROPDOWN
  $('.js-surveys-sort-by').on 'click', 'a', (e) ->
    e.preventDefault()

    $surveys.isotope
      sortBy:  $(@).data('sort-by')
    $('.js-surveys-sort-by-current').html $(@).html()
    return

  # POPOVER ON EXTRA CONTACT LISTS
  $('.js-survey-lists-more').on 'click', ->
    $(@).closest('.survey-lists').find('.survey-list').removeClass('survey-list-hidden')
    $(@).remove()
    $surveys.isotope()
    return

  # $(document).ready ->
  $('.js-test-slick').slick {
    dots: true
    infinite: true
    speed: 500
    slidesToShow: 1
    slidesToScroll: 1
    variableWidth: true
    autoplay: true
    autoplaySpeed: 9000
    arrows: false
  }

  fallbackCopyTextToClipboard = (body, text) ->
    textArea = document.createElement('textarea')
    textArea.value = text
    body.appendChild textArea
    textArea.trigger( 'focus' )
    textArea.select()
    try
      successful = document.execCommand('copy')
      msg = if successful then 'successful' else 'unsuccessful'
      console.log 'Fallback: Copying text command was ' + msg
    catch err
      console.error 'Fallback: Oops, unable to copy', err
    body.removeChild textArea
    return

  copyTextToClipboard = (body, text) ->
    if !navigator.clipboard
      fallbackCopyTextToClipboard body, text
      return
    navigator.clipboard.writeText(text).then (->
      console.log 'Async: Copying to clipboard was successful!'
      return
    ), (err) ->
      console.error 'Async: Could not copy text: ', err
      return
    return

  # Copy topic trending data SQL to clipboard
  $('#topicTrendingDataCopyButton').on 'click', ->
    modal = $('#topicTrendingDataModal')
    body = $('#topicTrendingDataModal .modal-body')[0]
    # text = body.textContent
    text = body.innerText
    copyTextToClipboard body, text
    modal.modal 'hide'
    return

  # Copy export SQL to clipboard
  $('#exportSQLCopyButton').on 'click', ->
    modal = $('#exportSQLModal')
    body = $('#exportSQLModal .modal-body')[0]
    text = body.textContent
    copyTextToClipboard body, text
    modal.modal 'hide'
    return

  # Copy export Joined Topics SQL to clipboard
  $('#exportJoinedTopicsSQLCopyButton').on 'click', ->
    modal = $('#exportJoinedTopicsSQLModal')
    body = $('#exportJoinedTopicsSQLModal .modal-body')[0]
    text = body.textContent
    copyTextToClipboard body, text
    modal.modal 'hide'
    return

  ###*
  * Add a URL parameter (or changing it if it already exists)
  * @param {search} string  this is typically document.location.search
  * @param {key}    string  the key to set
  * @param {val}    string  value
  ###

  addUrlParam = (search, key, val) ->
    newParam = key + '=' + val
    params = '?' + newParam
    # If the "search" string exists, then build params from it
    if search
      # Try to replace an existance instance
      params = search.replace(new RegExp('([?&])' + key + '[^&]*'), '$1' + newParam)
      # If nothing was replaced, then add the new param to the end
      if params == search
        params += '&' + newParam
    params

  $('.livefeed-text-container').on 'click', ->
    url = $(@).parents( ".parent" ).data( "conversationurl" )
    them = $(@).parents( ".livefeed-message" ).data( "them" )
    if them && url
      #window.location.href = url+"?filter="+encodeURIComponent(them)
      aUrl = document.createElement('a')
      aUrl.href = url
      newSearch = addUrlParam( aUrl.search, 'filter', encodeURIComponent(them) )
      aUrl.search = newSearch
      window.location.href = aUrl.href
    return

  # process the live feed
  insertLiveFeedResponses = (container, entries) ->
    # get the template
    template = container.prev( "#template" )

    newReplyIDs = []
    $(entries).each (idx, elt) ->
      newReplyIDs.push elt.id

    # delete the entries in the widget
    parent = container.find( '.parent' )
    parent.children( '.livefeed-message' ).each (idx, elt) ->
      rID = parseInt $(elt).attr( 'data-replyid' )
      rIdx = newReplyIDs.indexOf( rID )
      if rIdx == -1
        $(elt).remove()
      else
        newReplyIDs.splice( rIdx, 1 )

    # insert all the new entries
    $(entries).each (idx, elt) ->
      if newReplyIDs.indexOf( elt.id ) != -1
        timeFrom = moment(  elt.answered_at ).fromNow()
        poc_location = ""
        if elt.poc_name != null && elt.poc_name.trim().length != 0
          poc_location = " - " + elt.poc_name
        newEntry = template.clone( true )
        newEntry.removeClass 'hidden'
        newEntry.removeAttr 'id'
        newEntry.find( ".livefeed-text" ).text( elt.answer )
        newEntry.find( ".livefeed-timestamp" ).text( timeFrom+poc_location )
        newEntry.attr( "data-replyid", elt.id )
        newEntry.attr( "data-them", elt.them )
        parent.append newEntry
      return

    return

  cancel_live_feed = false
  url = null

  customEndsWith = (str, search) ->
    if (str == null or search == null or str == undefined or search == undefined)
      return false
    return str.substring(str.length - (search.length), str.length) == search

  setFeedReloadTimer = ( url ) ->
    if !url
      return

    if !customEndsWith(window.location.href,  url.replace( "/get_realtime_replies", "" ) )
      cancel_live_feed = true

    # call it again!
    if !cancel_live_feed
      setTimeout (->
        getLiveFeed( url )
        return
      ), 5000

  getLiveFeed = ( url ) ->

    livefeedVisible =  $('.js-livefeed-container:visible').visible(true,true)
    documentHasFocus = document.hasFocus()
    if url == null
      url = $('h1.survey-title').data( 'livefeedurl' )
    if documentHasFocus && livefeedVisible
      liveFeedContainer = $('.js-livefeed-message-container')
      question_id = null
      if liveFeedContainer.length
        question_id = liveFeedContainer.data 'questionid'

      data =
        question_id: question_id

      $.ajax
        type: "GET"
        url: url
        data: data
        success: (response) ->
          if livefeedVisible
            insertLiveFeedResponses liveFeedContainer, response.messages

          setFeedReloadTimer( url )

        error: (xhr, status, error) ->
          #alert xhr.responseText
          setFeedReloadTimer( url )

    else
      # if the window was not visible lets checkin again in 5 seconds
      setFeedReloadTimer( url )

    return

  $liveFeedContainer = $('.js-livefeed-message-container')
  if $liveFeedContainer.length
    $liveFeedContainer.easyTicker({
    })

  $(window).on 'beforeunload', ->
    cancel_live_feed = true
    return


  renderChartEngagement = ($element) ->
    ret = "not set"
    preferResponded = $element.data 'preferResponded'
    completed = $element.data 'completed'
    responded = $element.data 'responded'
    total = $element.data 'total'
    partial = total - completed
    percentage = 0

    if preferResponded
      count = responded
      titleText = "Engagement"
    else
      count = completed
      titleText = "Completions"

    if total > 0
      percentage = Math.round( 100.0 * count / total )

    innerRadius = '76%'
    radius = '100%'

    try
      title = '<div class="engagement-title"><div class="engagement-label">'+titleText+'</div><div class="engagement-separator"></div><div class="engagement-percentage">'+percentage+'%</div></div>'
      titleY = 90
      series = [ {
        name: "Completion Rate"
        enableMouseTracking: false
        data: [ {
          color: '#B167BC'
          radius: radius
          innerRadius: innerRadius
          y: Math.round( 100.0 * count / total )
        } ]
        tooltip: enabled: false
      } ]

      ret = Highcharts.chart $element[0], {
        chart:
          type: 'solidgauge'
          marginTop: 0
          marginBottom: 0
          spacingBottom: 0
          spacingTop: 0
          spacingLeft: 0
          spacingRight: 0
          backgroundColor: ChatterConstants.TopOfFoldBackgroundColour
        title:
          useHTML: true
          text: title
          align: "center"
          floating: true
          y: titleY
        pane:
          startAngle: 0
          endAngle: 360
          background: [ {
            outerRadius: radius
            innerRadius: innerRadius
            backgroundColor: '#D09BD8'
            borderWidth: 0
          } ]
        yAxis:
          min: 0
          max: 100
          lineWidth: 0
          tickPositions: []
        plotOptions: solidgauge:
          dataLabels:
            enabled: false
          linecap: 'round'
          stickyTracking: false
          rounded: true
        credits: enabled: false
        series: series
      }, ->
    catch e
      alert( "renderChartEngagement exception: " + e )
    finally
      # alert( "renderChartEngagement ret =" + ret )

    ret

  # engagement
  $liveFeedContainer = $('.dash-1-7 .js-livefeed-container')
  if $liveFeedContainer.length
    getLiveFeed( null )

  # topics
  handleTopicsSentiment = (element, event, sentiment) ->
    p = $(element.chart.container).parents( ".topics-graph-container" )
    url = p.data( "topics-detail-url" )

    aUrl = document.createElement('a')
    aUrl.href = url
    paramSeparator = "?"
    if aUrl.search
      paramSeparator = "&"

    url = url + paramSeparator + "topic_name=" + encodeURIComponent( event.point.category ) + "&sentiment=" + encodeURIComponent( sentiment )
    window.location.href = url
    return

  renderTopicsSentiment = (element) ->
    topicsSentiment = $(element).data()['topicsSentiment']
    topic_names = []
    positive = []
    negative = []
    neutral = []
    totals = []
    positive_series = []
    negative_series = []
    neutral_series = []
    total_series = []

    for i of topicsSentiment
      array = topicsSentiment[i]

      topic_names.push array[0]
      positive.push array[3]
      negative.push array[1]
      neutral.push array[2]
      totals.push array[4]

      minWidthPadding = 0
      p = array[3] + minWidthPadding
      positive_series.push(p)
      n = p + array[1] + minWidthPadding
      negative_series.push(n)
      ne = n + array[2] + minWidthPadding
      neutral_series.push(ne)
      total_series.push array[4]


    Highcharts.chart element,
      chart:
        type: 'bar'
        backgroundColor: '#f7f7f7'
        height: '285px'
        paddingLeft: '10px'
      tooltip:
        enabled: false
      title: 'none'
      xAxis:
        categories: topic_names
        min: 0
        labels:
          align: 'left'
          reserveSpace: false
          y: 5
          x: 10
          style:
            color: '#454D5A'
            fontSize: '12px'
            whiteSpace: 'nowrap'
        gridLineColor: ''
        tickWidth: 0
        tickAmount: 0
        lineWidth: 0
      yAxis:
        min: 0
        title: 'none'
        labels: 'none'
        gridLineColor: ''
        tickWidth: 0
        tickAmount: 0
      legend: reversed: true
      plotOptions:
        series:
          borderWidth: 0
          pointWidth: 27
        bar: grouping: false
      credits: enabled: false
      series: [
        {
          name: I18n.t("neutral")
          data: neutral_series
          color: '#E6E6E6'
          borderRadius: '16px'
          events:
            click: (event) ->
              handleTopicsSentiment(this, event, 'neutral')
        }
        {
          name: I18n.t("negative")
          data: negative_series
          color: '#FC7256'
          borderRadius: '16px'
          events:
            click: (event) ->
              handleTopicsSentiment(this, event, 'negative')
        }
        {
          name: I18n.t("positive")
          data: positive_series
          color: '#22A875'
          borderRadius: '16px'
          events:
            click: (event) ->
              handleTopicsSentiment(this, event, 'positive')
        }
      ]

  for topicGraph in $('.topics-graph-container')
    renderTopicsSentiment( topicGraph )


  # topic summary resize handler
  resizeTopicSummaryBoxes = () ->
    topic_min_width = 32
    topic_container  = $('.topics-index .topic-summary-box-container')
    sentiment_values = topic_container.data( 'sentiment-values' )
    if sentiment_values == undefined
      return
    topic_counts = sentiment_values.split( '|' )
    topic_count_positive = parseInt( topic_counts[0] )
    topic_count_negative = parseInt( topic_counts[1] )
    topic_count_neutral = parseInt( topic_counts[2] )
    topic_count_mixed = parseInt( topic_counts[3] )
    topic_count_total = topic_count_positive + topic_count_negative + topic_count_neutral + topic_count_mixed

    topic_container_width = topic_container.width() - topic_min_width*4
    topic_container_width = 0 if topic_container_width < 0
    topic_width_positive = ( topic_count_positive * topic_container_width / topic_count_total ) + topic_min_width
    topic_width_negative = ( topic_count_negative * topic_container_width / topic_count_total ) + topic_min_width
    topic_width_neutral  = ( topic_count_neutral  * topic_container_width / topic_count_total ) + topic_min_width
    topic_width_mixed    = ( topic_count_mixed    * topic_container_width / topic_count_total ) + topic_min_width

    topic_positive = topic_container.find( '.positive' )
    topic_positive.css
      "width": topic_width_positive
      "padding-left": 16
    topic_negative = topic_container.find( '.negative' )
    topic_negative.css
      "width": topic_width_positive + topic_width_negative
      "padding-left": topic_width_positive + 16
    topic_neutral = topic_container.find( '.neutral' )
    topic_neutral.css
      "width": topic_width_positive + topic_width_negative + topic_width_neutral
      "padding-left": topic_width_positive + topic_width_negative + 16
    topic_mixed = topic_container.find( '.mixed' )
    topic_mixed.css
      "width": topic_width_positive + topic_width_negative + topic_width_neutral + topic_width_mixed
      "padding-left": topic_width_positive + topic_width_negative + topic_width_neutral + 16

    return

  activateTooltip = () ->
    # This has to be done on a delay as it takes time for the images to be converted into SVGs
    setTimeout (->
      $('.js-data-table-topic-sentiment .js-tooltip').tooltip()
    ), 500

  renderTopicsDataTable = (data_table) ->
    languageSettings = window.jQueryDataTableLanguagePacks[I18n.locale] || {}
    languageSettings.lengthMenu = ""

    if $(@).data('emptyTable')
      languageSettings.emptyTable = $(@).data('empty-table')

    options =
      language: languageSettings
      pagingType: "full_numbers"
      searching: false
      responsive: true
      processing: true
      serverSide: true
      order: [[ 4, 'desc' ]]
      columnDefs: [{ className: "question-column", "targets": [ 0 ] }, { className: "break-word", "targets": [ 2 ] }]
      ajax: data_table.data('source')

    data_table.DataTable options

    return

  # topics datatable
  $topic_data_table = $('.js-data-table-topic-sentiment')
  if $topic_data_table.length
    resizeTopicSummaryBoxes()
    renderTopicsDataTable($topic_data_table)

    # set a window resize handler
    $(window).on 'resize', ->
      resizeTopicSummaryBoxes()

  $('.js-data-table-topic-sentiment').on 'draw.dt', ->
    hasReplyToCheck = !!$('.js-data-table-topic-sentiment tbody .reply-categorization-status.fa-flag.can-check').length
    $headerIcon = $('.reply-all-categorization-status.fa-flag')
    if $headerIcon.length
      if hasReplyToCheck
        $headerIcon.attr( 'title', 'Click here to mark the topics for all reply on the page as requiring QC' )
        $headerIcon.addClass( 'can-check' )
        $headerIcon.removeClass( 'requires-qc' )
      else
        $headerIcon.attr( 'title', 'The topics for all reply on this page have been marked for QC' )
        $headerIcon.addClass( 'requires-qc' )
        $headerIcon.removeClass( 'can-check' )
    activateTooltip()


  $('#topics-sentiment-dropdown').on 'change', ->
    url = $(this).find(":selected").data('newtarget')
    window.location.href = url

  $('#topics-sentiment-filter-dropdown').on 'change', ->
    url = $(this).find(":selected").data('newtarget')
    window.location.href = url

  $('.topic-level-selector-container .topic-level-selector').on 'click', ->
    url = $(this).data('newurl')
    window.location.href = url

  $('.topic-level-selector-container .topic-level-selector').on 'mouseenter', ->
    hint = $(this).data('hint')
    $hint_box = $(this).parent().next()
    $hint_box.text( hint )
    return

  $('.topic-level-selector-container .topic-level-selector').on 'mouseleave', ->
    $hint_box = $(this).parent().next()
    original_hint = $hint_box.data('originalhint')
    $hint_box.text( original_hint )
    return

  $('.data-table-topic-sentiment').on 'click', '.reply-categorization-status', ->
    $icon = $(this)
    return if $icon.hasClass('requires-qc')
    return if !window.confirm('You are about to mark this reply as having topics that require re-evaluation (QC) by the Chatter team. You will not be able to undo this action. Are you sure?')

    url = $icon.data( 'markurl' )
    return if !url

    $.ajax
      type: "POST"
      url: url

      beforeSend: (xhr) ->
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))

      success: (response) ->
        $icon.attr( 'title', 'The topics for this reply have been marked for QC' )
        $icon.addClass( 'requires-qc' )
        $icon.removeClass( 'can-check' )
        activateTooltip()

      error: (xhr, status, error) ->
        #alert xhr.responseText
        alert("Your request to mark the topics assdociated with this reply as requiring re-evaluation failed. Please contact Chatter Support.")

  $('.data-table-topic-sentiment').on 'click', '.reply-all-categorization-status', ->
    replyIdsToCheck = []
    $('.js-data-table-topic-sentiment tbody .reply-categorization-status.fa-flag.can-check').each (index, element) ->
      $icon = $(this)
      replyIdsToCheck.push($icon.data('id'))
    return unless replyIdsToCheck.length

    $headerIcon = $('.reply-all-categorization-status.fa-flag')
    url = $headerIcon.data( 'markurl' )
    return unless url

    return if !window.confirm('You are about to mark all replies on the page as having topics that require re-evaluation (QC) by the Chatter team. You will not be able to undo this action. Are you sure?')

    url = url.replace("REPLY_IDS_TO_OVERRIDE", encodeURIComponent(replyIdsToCheck))

    $.ajax
      type: "POST"
      url: url

      beforeSend: (xhr) ->
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))

      success: (response) ->
        $headerIcon.attr( 'title', 'The topics for all reply on this page have been marked for QC' )
        $headerIcon.addClass( 'requires-qc' )
        $headerIcon.removeClass( 'can-check' )

        $('.js-data-table-topic-sentiment tbody .reply-categorization-status.fa-flag.can-check').each (index, element) ->
          $icon = $(this)
          $icon.attr( 'title', 'The topics for this reply have been marked for QC' )
          $icon.addClass( 'requires-qc' )
          $icon.removeClass( 'can-check' )
        activateTooltip()

      error: (xhr, status, error) ->
        alert("Your request to mark the topics associated with all reply on the page as requiring re-evaluation failed. Please contact Chatter Support.")

  $(".wrapper").on 'scroll', ->
    wrapper_height =  parseInt($(".wrapper")[0].scrollHeight)
    half_wrapper_height = parseInt(wrapper_height) / 2
    scrollbar_height = $(".wrapper").scrollTop()
    if half_wrapper_height <= scrollbar_height
      $('#chevron-elevator').addClass('fa-chevron-up')
      $('#chevron-elevator').removeClass('fa-chevron-down')
      $('.elevator').attr('title', 'Go to the top of the page')
    else if half_wrapper_height >= scrollbar_height
      $('#chevron-elevator').removeClass('fa-chevron-up')
      $('#chevron-elevator').addClass('fa-chevron-down')
      $('.elevator').attr('title', 'Go to the bottom of the page')


  callElevator = ->
    wrapper_height =  parseInt($(".wrapper")[0].scrollHeight)
    half_wrapper_height = parseInt(wrapper_height) / 2
    scrollbar_height = $(".wrapper").scrollTop()
    if half_wrapper_height <= scrollbar_height
      $('#chevron-elevator').removeClass('fa-chevron-up')
      $('#chevron-elevator').addClass('fa-chevron-down')
      $('.elevator').attr('title', 'Go to the bottom of the page')
      $(".wrapper").scrollTop(0)
    else if half_wrapper_height >= scrollbar_height
      $('#chevron-elevator').addClass('fa-chevron-up')
      $('#chevron-elevator').removeClass('fa-chevron-down')
      $(".wrapper").scrollTop(wrapper_height)
      $('.elevator').attr('title', 'Go to the top of the page')

  $('div.elevator.anchor').on 'click', ->
    callElevator()

  # code for signage view
  # type selector
  $(".js-signage-type-selector #type").on 'change', ->
    url = new URL(window.location.href)
    url.searchParams.set('type', $(@)[0].value)
    window.location.href = url

  # poc selector
  $(".js-signage-poc-selector #point_of_contact_id").on 'change', ->
    url = new URL(window.location.href)
    url.searchParams.set('poc_id', $(@)[0].value)
    window.location.href = url

  # phone number selector
  $(".js-signage-phone-number-selector #phone_number_id").on 'change', ->
    url = new URL(window.location.href)
    url.searchParams.set('phone_number_id', $(@)[0].value)
    window.location.href = url
  
  # survey trigger selector
  $(".js-signage-survey-trigger-selector #survey_trigger").on 'change', ->
    url = new URL(window.location.href)
    url.searchParams.set('survey_trigger', $(@)[0].value)
    window.location.href = url

   # use only location trigger checkbox
  $(".js-signage-use-only-location-trigger #use_only_survey_trigger").on 'change', ->
    url = new URL(window.location.href)
    url.searchParams.set('use_only_survey_trigger', $(@)[0].checked)
    window.location.href = url
