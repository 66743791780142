import I18n from "i18n-js";

import { SelectOption } from "../shared/Select";

export const contactMethodOptions = [
  { label: I18n.t("user_management.contact_methods.none"), value: "none" },
  { label: I18n.t("user_management.contact_methods.sms"), value: "sms" },
  { label: I18n.t("user_management.contact_methods.email"), value: "email" },
  { label: I18n.t("user_management.contact_methods.all"), value: "all" }
]

export enum POCRestrictionType {
  SINGLE = "SinglePointOfContactRestrictionDefinition",
  GROUP = "PointOfContactGroupRestrictionDefinition",
  CLAUSE = "PointOfContactClauseRestrictionDefinition"
}

export const samePOCRestriction = (a: PointOfContact, b: PointOfContact) => {
  switch (a.type) {
    case POCRestrictionType.SINGLE:
      if (a.pocId === b.pocId) return true;
      break;
    case POCRestrictionType.GROUP:
      if (a.pocGroupId === b.pocGroupId) return true;
      break;
    case POCRestrictionType.CLAUSE:
      if (a.clauseValue === b.clauseValue) return true;
      break;
  }

  return false;
}

export const countPOCRestrictions = (pocRestrictions: PointOfContact[]) => {
  return pocRestrictions.reduce((accumulator, current) => accumulator + (current.pocCount || 1), 0)
}

export type PointOfContact = {
  label: string;
  type: POCRestrictionType;
  pocId?: number;
  pocGroupId?: number;
  clauseField?: string;
  clauseValue?: string;
  pocCount?: number;
}

export type SurveyAlertConfiguration = {
  contactMethod: SelectOption;
  pocRestrictions: PointOfContact[];
}

export type UserSurveyConfig = {
  surveyId: number;
  surveyName: string;
  readAccess: boolean;
  hasReadAccessByDefault: boolean;
  surveyAlertConfiguration: SurveyAlertConfiguration;
};

export type LiveConversationsConfiguration = {
  contactMethod: SelectOption | undefined;
}

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  language: string;
  roleTypes: string[];
  emailDigestFrequency: string;
  grantAccessToAllPoc: boolean;
  messageCenterEnabled: boolean;
  liveConversationsEnabled: boolean;
  liveConversationsConfiguration: LiveConversationsConfiguration;
  pocRestrictions: PointOfContact[];
  liveConversationsPocRestrictions: PointOfContact[];
  surveyConfigs: UserSurveyConfig[];
  hidePII: boolean;
  syncToJourneys: boolean;
  apiToken: string;
}

export type UserManagementFormFields = {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  language: SelectOption;
  roleTypes: SelectOption[];
  emailDigestFrequency: SelectOption;
  messageCenterEnabled: boolean;
  liveConversationsEnabled: boolean;
  liveConversationsConfiguration: LiveConversationsConfiguration | null;
  pocAccess: string;
  pocRestrictions: PointOfContact[];
  liveConversationsPocRestrictions: PointOfContact[];
  surveyConfigs: UserSurveyConfig[];
  smsLiveConversation: boolean;
  emailLiveConversation: boolean;
  grantAccessToAllPoc: boolean;
  hidePII: boolean;
  syncToJourneys: boolean;
  apiToken: string | null;
  currentUserAccessibleFeatures: string[];
  targetUserAccessibleFeatures: string[];
};

export type Company = {
  id: string;
  name: string;
}
