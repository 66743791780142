import I18n from "i18n-js";
import React, { PropsWithChildren } from "react";
import Select, { Props as ReactSelectProps, StylesConfig } from "react-select";
import { Typography } from "stingray-components";

import { Controller, useFormContext } from "react-hook-form";

import styles from './Select.module.scss';

export type SelectOption = {
  label: string;
  value: string;
}

const reactSelectStyles: StylesConfig = {
  container: (baseStyles) => ({
    ...baseStyles,

    borderRadius: "0.625rem",
  }),
  control: (baseStyles) => ({
    ...baseStyles,

    padding: "0.625rem",

    cursor: "pointer",
    border: "none",
    boxShadow: 'none',
    backgroundColor: "var(--colors-inputBackground)",
    transition: "background-color 0.3s ease-out 0s",
    fontSize: "var(--fontSizes-h10)",

    "&:hover": {
      backgroundColor: "var(--colors-inputBackgroundHover)",
    },
  }),
  option: (baseStyles) => ({
    ...baseStyles,

    fontSize: "var(--fontSizes-h10)",
  })
}

type SelectInputProps = PropsWithChildren<{
  name: string;
  isRequired?: boolean;
} & ReactSelectProps>

const SelectInput = ({ children, name, isRequired = true, ...selectProps }: SelectInputProps) => {
  const { control } = useFormContext();

  return (
      <Controller
        control={control}
        name={name}
        rules={{ required: isRequired, validate: (value) => !isRequired || (selectProps.isMulti ? value.length > 0 : !!value) }}
        render={({ field }) => (
          <div className={styles.selectContainer}>
            <Typography variant="h7">{children}</Typography>
            <Select
              {...field}
              {...selectProps}
              styles={reactSelectStyles}
              components={{ IndicatorSeparator: () => null }}
              menuPosition="fixed"
            />
          </div>
        )}
      />
  );
};

export default SelectInput;
