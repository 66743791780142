import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const SatWrapper = ({ children }) => <div className={styles.wrapper}>{children}</div>;

SatWrapper.propTypes = {
  children: PropTypes.node,
};

export default SatWrapper;
