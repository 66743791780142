import classNames from "classnames/bind";
import I18n from "i18n-js";
import React from "react";

import { formatTopicName, topicLink } from "../../utils/general-helper";
import TooltipHelper from "../../utils/tooltip-helper";
import { LoadingStatus } from "../shared";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const Topic = ({ count, direction, filters, isTopic, loading, name, position, state, survey, value }) => {
  const TopicContent = ({count, isTopic, name, value}) => {
    return <>
      {isTopic &&
        <TooltipHelper tooltipMessage={formatTopicName(name)}>
          <div className={cx("topic-name")}>{formatTopicName(name)}</div>
        </TooltipHelper>
      }
      {!isTopic &&
        <div className={cx("topic-name")}>{name}</div>
      }
      <div className={cx("topic-count")}>{count}</div>
      <div
        className={cx("topic-value")}
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    </>
  }

  return (
    <div className={cx("topic", direction, state)}>
      <div
        className={cx("topic-position")}
        dangerouslySetInnerHTML={{
          __html: position,
        }}
      />
      <div className={cx("topic-container")}>
        {loading && (
          <div className={cx("loading")}>
            <LoadingStatus centered={false} />
          </div>
        )}
        {value && (
          <>
            {isTopic && (
              <a href={topicLink({ filters, survey, topicName: name })} target="_blank">
                <TopicContent count={count} isTopic={isTopic} name={name} value={value} />
              </a>
            )}
            {!isTopic && <TopicContent count={count} isTopic={isTopic} name={name} value={value} />}
          </>
        )}
        {!loading && !value && (
          <div className={cx("topic-not-available")}>{I18n.t("not_available")}</div>
        )}
      </div>
    </div>
  );
};

export default Topic;
