import React from "react";
import moment from "moment";

import UserIcon from "./UserIcon";

import I18n from "i18n-js";
import styles from "./ConversationListItem.module.scss";
import classNames from "classnames";

const ConversationListItem = ({ conversation, selectedConversation, setSelectedConversationCallback }) => {
  const unread = conversation?.isUnread;

  const getReplyWasSentByUs = (reply) => !reply?.answeredAt;

  const getTimeStamp = (reply) => {
    if (reply?.answeredAt) return moment.unix(reply?.answeredAt).calendar();
    else if (reply?.createdAt) return moment.unix(reply?.createdAt).calendar();

    return "";
  }

  const getSender = (reply) => {
    if (getReplyWasSentByUs(reply)) {
      if (reply?.senderInfo?.initials) return `${reply.senderInfo.initials}: `;
      return I18n.t("live_conversations.you");
    }

    return "";
  }

  const getMessage = (reply) => {
    if (!getReplyWasSentByUs(reply)) {
      return reply?.answer;
    } else if (reply?.sentText) {
      return reply?.sentText;
    } else if (reply?.replyAttachments?.length > 0) {
      return I18n.t("live_conversations.sent_attachments");
    }

    return "";
  }

  const lastReply = conversation?.replies?.at(-1);
  const timestamp = getTimeStamp(lastReply);
  const sender = getSender(lastReply);
  const message = getMessage(lastReply);

  return (
    <div
      className={classNames(styles.conversationListItem, {
        [styles.unread]: unread,
        [styles.selected]: selectedConversation?.id === conversation?.id
      })}
      onClick={() => setSelectedConversationCallback(conversation)}>
      <UserIcon conversation={conversation} />
      <div className={styles.contentWrapper}>
        <div className={styles.titleRow}>
          <span className={classNames(styles.title, { [styles.unreadTitle]: unread })}>{ conversation?.name }</span>
          <span className={styles.timestamp}>{ timestamp }</span>
        </div>
        <div className={classNames({ [styles.unreadMessage]: unread })}>
          { sender }{ message }
        </div>
      </div>
    </div>
  );
}

export default ConversationListItem;
