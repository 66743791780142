import React, { PropsWithChildren } from 'react';
import { SelectOption } from './Select';
import { Typography } from 'stingray-components';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './CheckboxList.module.scss';

type CheckboxListProps = PropsWithChildren<{
  name: string;
  checkboxOptions: SelectOption[],
}>;

const CheckboxList: React.FC<CheckboxListProps> = ({ name, checkboxOptions, children }) => {
  const { control } = useFormContext();

  return (
    <div className={styles.checkboxListContainer}><Typography variant="h7">{children}</Typography><Controller
        control={control}
        name={name}
        defaultValue={[]}
        render={({ field: { onChange, onBlur, value = [], name, ref } }) => (
          <div className={styles.scrollingContainer}>
            {checkboxOptions.map((checkboxOption: SelectOption) => {
              const isChecked = value.includes(checkboxOption.value);
              return (
                <div key={checkboxOption.value} className={styles.checkboxContainer}><label className={styles.label}><input
                      type="checkbox"
                      name={name}
                      value={checkboxOption.value}
                      checked={isChecked}
                      onChange={(e) => {
                        const newValue = e.target.checked
                          ? [...value, checkboxOption.value]
                          : value.filter((val: string) => val !== checkboxOption.value);
                        onChange(newValue);
                      }}
                      onBlur={onBlur}
                      ref={ref}
                    />
                    {checkboxOption.label}
                  </label></div>
              );
            })}
          </div>
        )}
      /></div>
  );
};

export default CheckboxList;