import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Loading, Themes } from "stingray-components";

import withReactQuery from "../../src/hocs/withReactQuery";
import LoadingPage from "../shared/LoadingPage";
import { fetchData } from "../utils/request-helper";
import ErrorMessage from "./ErrorMessage";
import PointOfContactsStatus from "./ProcessPointOfContactsStatus";
import { PreviewResponse, PointOfContactsStatusResponse } from "./SurveyIngestionTypes";

import styles from "./ProcessPointOfContacts.module.scss";

type ProcessPointOfContactsProps = {
  api: {
    csrfToken: string;
  };
  processId: string;
};

const ProcessPointOfContacts: React.FC<ProcessPointOfContactsProps> = ({ api, processId }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [statusError, setStatusError] = useState("");

  const { data: status, isError: isStatusError } = useQuery<PointOfContactsStatusResponse>(
    ["status", processId],
    async () => {
      return fetchData(`/admin/survey_ingestion/point_of_contacts_status/${processId}`, {
        throwOnError: true,
      });
    },
    {
      enabled: !!processId,
      refetchInterval: (data) => {
        return data?.ingestStatus?.state !== "completed" ? 5000 : false;
      },
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (_response: PointOfContactsStatusResponse) => {
        setStatusError("");
      },
      onError: (_error) => {
        setStatusError("Something went wrong while fetching status. Please try again later.");
      },
    }
  );

  const ingestPointOfContactsMutation = useMutation({
      mutationFn: async () => {
        return fetchData(`/admin/survey_ingestion/ingest_point_of_contacts/${processId}`, {
          method: "POST",
          headers: {
            "X-CSRF-Token": api.csrfToken,
          },
          throwOnError: true,
        });
      },
      onSuccess: (_response: PreviewResponse) => {
        setErrorMessage("");
      },
      onError: (_error) => {
        setErrorMessage("Something went wrong while trying to queue ingest. Please try again later.");
      },
    }
  );

  const handleSubmit = async () => ingestPointOfContactsMutation.mutate();

  useEffect(() => {
    const errors = status?.ingestStatus?.errors || status?.previewStatus?.errors;

    if (errors && errors.length > 0) {
      const newErrorMessage = errors
        ?.map((error) => {
          return (
            `Excel Row: ${error.excelRow}\n` +
            `Point of Contact: ${JSON.stringify(error.pointOfContact)}\n` +
            `Errors: ${error.errorMessages.join(", ")}`
          );
        })
        .join("\n\n");
      setErrorMessage(newErrorMessage);
    } else {
      setErrorMessage("");
    }
  }, [status]);

  return (
    <div className={classNames(Themes.CHATTER.theme, styles.processPointOfContacts)}>
      <Button
        css={{ marginTop: "20px", marginBottom: "20px" }}
        size="large"
        width="125px"
        onClick={() => window.history.back()}
      >
        Back
      </Button>
      {!status && !isStatusError && <LoadingPage />}
      {status?.previewStatus && (
        <PointOfContactsStatus status={status?.previewStatus} isPreview={true} />
      )}
      {!!(
        status?.previewStatus &&
        status.previewStatus.state === "completed" &&
        status.previewStatus.errors &&
        status.previewStatus.errors.length === 0 &&
        status.previewStatus.nbPocValid &&
        status.previewStatus.nbPocValid > 0 &&
        status?.ingestStatus
      ) && (
        <>
          <Button
            css={{ marginTop: "50px" }}
            size="large"
            width="125px"
            disabled={
              ingestPointOfContactsMutation.isLoading ||
              ingestPointOfContactsMutation.isSuccess ||
              status?.ingestStatus?.state !== "none"
            }
            onClick={handleSubmit}
          >
            {ingestPointOfContactsMutation.isLoading ? <Loading size="20px" /> : "Ingest"}
          </Button>
          <PointOfContactsStatus status={status?.ingestStatus} isPreview={false} />
        </>
      )}
      <ErrorMessage errorMessages={errorMessage || statusError} />
    </div>
  );
};
export default withReactQuery(ProcessPointOfContacts as any);
