import { DateRangeType } from "./ExecutiveDashboard";
import { Survey } from "./SurveyCategoryPanel";

import I18n from "i18n-js";

export const formatTrend = (currentMetricValue: number | null, previousMetricValue: number | null, asPercentage: boolean, dateRange: DateRangeType): string => {
  if (currentMetricValue === null || previousMetricValue === null) {
    return "&nbsp;";
  }
  const variation = Math.round(Math.abs(currentMetricValue - previousMetricValue));
  const hasIncreased = currentMetricValue > previousMetricValue;

  if (variation === 0) {
    return I18n.t("metric_trend_no_changes", { 
      dateRange: I18n.t(dateRange) 
    });
  } else {
    return I18n.t("metric_trend", {
      direction: hasIncreased ? I18n.t("increased") : I18n.t("decreased"),
      variation: variation.toString() + (asPercentage ? "%" : ""),
      dateRange: I18n.t(dateRange),
    });
  }  
  
}

// formatMetric(60.1333, false) => "60.1"
// formatMetric(60.011, false) => "60"
// formatMetric(60.011, true) => "60%"
// formatMetric(null) => "N/A"
export const formatMetric = (value: number | null, asPercentage: boolean): string => {
  if (value == null) {
    return I18n.t("not_available");    
  } else {
    return (Math.round(value * 10) / 10).toString() + (asPercentage ? "%" : "");
  }
}

export const getMetricParams = (dateRange: DateRangeType, surveys: Survey[]): URLSearchParams => {
  const params = new URLSearchParams();
    
  params.append('date_range', dateRange);
  surveys.forEach(survey => params.append('survey_ids[]', survey.id.toString()));

  return params;
}
