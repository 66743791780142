import React from "react";
import styles from "./styles.module.scss";
import { TrendIconType, trendingIconType } from "components/types";

const TrendIcon = ({ icon = "none", size }) => {
  let image = null;

  switch (icon) {
    case trendingIconType.increase:
      image = (
        <svg className={styles.increase} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <g transform="translate(-579 -1317)">
            <circle cx="8" cy="8" r="8" transform="translate(579 1317)" />
            <path fill="#fff" d="M591 1325.476l-4-6.476-4 6.476h3.2V1331h1.6v-5.524z" />
          </g>
        </svg>
      );
      break;
    case trendingIconType.decrease:
      image = (
        <svg className={styles.decrease} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <g transform="translate(-1042 -1432)">
            <circle cx="8" cy="8" r="8" transform="translate(1042 1432)" />
            <path fill="#fff" d="M1054 1439.524l-4 6.476-4-6.476h3.2V1434h1.6v5.524z" />
          </g>
        </svg>
      );
      break;
    case trendingIconType.nochange:
      image = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <g transform="translate(-621 -1317)">
            <circle cx="8" cy="8" r="8" transform="translate(621 1317)" />
            <path fill="#fff" d="M624 1324h10v2h-10z" />
          </g>
        </svg>
      );
      break;
    case trendingIconType.none:
      image = null;
      break;
    default:
      image = null;
      break;
  }

  return image ? (
    <div className={styles.wrapper} style={{ width: size, height: size }}>
      {image}
    </div>
  ) : null;
};

TrendIcon.propTypes = TrendIconType;
export default TrendIcon;
