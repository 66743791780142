import React from "react";
import I18n from "i18n-js";

import { PrettyLoader } from "../../views/shared";

import hasLoader from "../hoc/has-loader";

import TopTableContent from "../../views/tile-top-table";

class TopDistrictsContainer extends React.Component {
  render() {
    const { status } = this.props;

    return (
      <PrettyLoader status={status} size="flex">
        {this.contentToRender()}
      </PrettyLoader>
    );
  }

  contentToRender() {
    const { componentData } = this.props;
    const { headerText, rows, detailsPath } = componentData;

    let columns = [
      { header: I18n.t("rank"), id: "rank", colStyle: "text-center" },
      { header: I18n.t("location"), id: "location", colSpan: 2 },
      { header: I18n.t("nps"), id: "nps" },
      {
        header: I18n.t("sample_size"),
        id: "sample_size",
        colStyle: "text-right",
      },
    ];

    return (
      <TopTableContent
        headerText={headerText}
        rows={rows}
        columns={columns}
        detailsPath={detailsPath}
      />
    );
  }
}

export default hasLoader(TopDistrictsContainer);
