import React from "react";

import styles from "./styles.module.scss";

import { HeaderText, ChartLegend } from "../../shared";

import NPSTrendingChart from "../nps-trending-chart/nps-trending-chart";

import { NPSTrendingContentType } from "../../../types";

import { isArrayEmpty } from "../../../utils/general-helper";

const NPSTrendingContent = ({ headerText, chartData }) => {
  const { categories, series, xAxisTitle, yAxisTitle } = chartData;

  return (
    <div>
      <HeaderText value={headerText}>
        <div className={styles["legend-wrapper"]}>
          {series &&
            series.map((s) => {
              if (!isArrayEmpty(s.data))
                return <ChartLegend key={s.name} value={s.name} color={s.color} />;
            })}
        </div>
      </HeaderText>
      <NPSTrendingChart
        categories={categories}
        series={series}
        xAxisTitle={xAxisTitle}
        yAxisTitle={yAxisTitle}
      />
    </div>
  );
};

NPSTrendingContent.propTypes = NPSTrendingContentType;

export default NPSTrendingContent;
