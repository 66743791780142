import React from "react";
import I18n from "i18n-js";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { LiveResponsesType } from "../../../types";
import Slick from "src/vendor/react-slick";
import { HeaderText, ConversationBubble } from "../../shared";
import { isArrayEmpty } from "../../../utils/general-helper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import defaultOptions from "../default-options.json";

const cx = classNames.bind(styles);

const LiveResponsesContent = ({ question, messages = [] }) => {
  return (
    <div>
      <div className={cx("header-wrapper")}>
        <HeaderText value={I18n.t("live_responses")} />
        <div className={cx("question")}>
          <div className={cx("question-text")}>{question}</div>
        </div>
      </div>
      <div className={cx("messages-container")}>
        <div className={cx("live-messages")}>
          {!isArrayEmpty(messages) && (
            <Slick {...defaultOptions}>
              {messages.map((message) => {
                const { id, info, text } = message;
                return (
                  <a className={cx("message")} key={id} href={message.conversationPath}>
                    <ConversationBubble text={text} info={info} />
                  </a>
                );
              })}
            </Slick>
          )}
        </div>
      </div>
    </div>
  );
};

LiveResponsesContent.propTypes = LiveResponsesType;

export default LiveResponsesContent;
