import React from "react";
import SelectInput, { SelectOption } from "../shared/Select";

import styles from "./SurveyIngestion.module.scss";

const PointOfContactFields: React.FC<{ excelColumns: SelectOption[] }> = ({ excelColumns }) => {
  return (
    <>
      <SelectInput options={excelColumns} name="pointOfContactNameColumn">
        <div className={styles.question}>name</div>
      </SelectInput>
      <SelectInput options={excelColumns} name="pointOfContactTriggerColumn">
        <div className={styles.question}>trigger</div>
      </SelectInput>
      <SelectInput options={excelColumns} name="pointOfContactCodeColumn">
        <div className={styles.question}>code</div>
      </SelectInput>
    </>
  );
};

export default PointOfContactFields;
