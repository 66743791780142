import React from "react";

import PrettySquareBox from "../pretty-square-box/pretty-square-box";
import LoadingStatus from "../loading-status/loading-status";
import ErrorStatus from "../error-status/error-status";
import HeaderText from "../header-text/header-text";

import { PrettyLoaderType } from "../../../types";

const PrettyLoader = ({ status, size = "small", style, children, title, titleClass, link }) => {
  return (
    <PrettySquareBox height={size} style={style} link={link}>
      {title && (
        <HeaderText value="">
          <div className={titleClass}>{title}</div>
        </HeaderText>
      )}
      {status === "error" && (
        <ErrorStatus multiRow={size === "medium"} centered={size === "medium"} />
      )}
      {status === "loading" && <LoadingStatus />}
      {status === "done" && children}
    </PrettySquareBox>
  );
};

PrettyLoader.propTypes = PrettyLoaderType;

export default PrettyLoader;
