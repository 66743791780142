import React from "react";

import { PrettyLoader } from "../../views/shared";

import TopicsContent from "../../views/tile-topics";

import hasLoader from "../hoc/has-loader";

import { TopicsChartContainerType } from "../../types";

class TopicsChartContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;
    const { topics, detailsPath } = componentData;

    const options = this.prepareData(topics);

    return (
      <PrettyLoader status={status} size="medium">
        <TopicsContent chartOptions={options} detailsPath={detailsPath} />
      </PrettyLoader>
    );
  }

  prepareData(value, extraOptions = null) {
    if (value) {
      let categories = [];
      let neutralData = [];
      let positiveData = [];
      let negativeData = [];
      let mixedData = [];

      for (let i in value) {
        const { name, neutralCount, positiveCount, negativeCount, mixedCount } = value[i];
        let totalCount = neutralCount + positiveCount + negativeCount + mixedCount;

        categories.push(name);
        neutralData.push(this.adjustCount(neutralCount, totalCount));
        positiveData.push(this.adjustCount(positiveCount, totalCount));
        negativeData.push(this.adjustCount(negativeCount, totalCount));
        mixedData.push(this.adjustCount(mixedCount, totalCount));
      }

      let result = {
        categories: categories,
        extraOptions: extraOptions,
        negativeData: negativeData,
        neutralData: neutralData,
        positiveData: positiveData,
        mixedData: mixedData,
      };

      return result;
    }

    return null;
  }

  //if countValue is smaller than threshold (percentage) we set value to that percentage from total
  //Example:
  // countValue is 1
  // totalValue is 100
  // this will set countValue to 3 (since threshold is 3%)
  adjustCount(countValue, totalValue, threshold = 0.03) {
    if (totalValue > 0) {
      const percentage = countValue / totalValue;
      if (percentage >= threshold || countValue === 0) {
        return countValue;
      } else {
        return totalValue * threshold;
      }
    } else {
      return countValue;
    }
  }
}

TopicsChartContainer.propTypes = TopicsChartContainerType;

export default hasLoader(TopicsChartContainer);
