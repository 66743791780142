import React, { MouseEvent, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Card, Loading, Themes, Typography, styled } from "stingray-components";

import { MutationFetcherProps } from "../../src/hooks/useConfig";
import withReactQuery from "../../src/hocs/withReactQuery";
import SelectInput, { SelectOption } from "../shared/Select";
import I18n from "i18n-js";
import styles from "./DeleteLiveConversations.module.scss";
import DatePickerInput from "../shared/DatePicker";

type SendEmailFormFields = {
    companyUser: SelectOption;
    emailDigest: SelectOption;
    startDate: string;
    endDate: string
}

type SendEmailDigestProps = {
    api: {
        csrfToken: string;
    };
    company: {
        id: number;
        name: string;
    };
    usersEmail: string[];
}

type SendEmailFetcherProps = MutationFetcherProps & {
    data: SendEmailFormFields;
    companyId: number;
}

const ErrorTypography = styled(Typography, { color: "$red400" });
const SuccessTypography = styled(Typography, { color: "$green400" });

const sendEmailDigestToCompanyUser = async ({ data, csrfToken, companyId }: SendEmailFetcherProps) => {
    const response = await fetch(`/api/companies/${companyId}/send_email_digest_to_company_user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
            startDate: data.startDate,
            endDate: data.endDate,
            emailDigest: data.emailDigest.value,
            userEmail: data.companyUser.value
        }),
    });

    if (!response.ok) throw new Error("Failed to send email digest.");
    return await response.json();
}

const SendEmailDigest = (props: SendEmailDigestProps) => {
    const [successText, setSuccessText] = useState<string>();
    const methods = useForm<SendEmailFormFields>();
    const { watch, setError, clearErrors, formState: { errors } } = methods;
    const startDate = watch("startDate");
    const endDate = watch("endDate");

    useEffect(() => {
        if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
            setError("startDate", {
                type: "custom",
                message: "Start date must be before end date."
            });
            setSuccessText("");
        } else {
            clearErrors("startDate");
        }
    }, [startDate, endDate, setError, clearErrors]);

    const { api, company, usersEmail } = props;

    const companyUserOptions = usersEmail.map((email) => ({ value: email, label: email }));
    const emailDigestOptions = ["Company Digest", "Google Review Digest"].map(digest => ({ value: digest, label: digest }));

    const { handleSubmit } = methods;
    const { mutate, isLoading, error: submitError } = useMutation({
        mutationFn: sendEmailDigestToCompanyUser,
        onError: console.error, onSuccess: () => onSuccess()
    });

    const onSubmit = (data: SendEmailFormFields) => {
        if (new Date(data.startDate) > new Date(data.endDate)) {
            setError("startDate", {
                type: "custom",
                message: "Start date must be before end date."
            });
            return;
        }

        clearErrors("startDate");
        setSuccessText("");

        if (!Object.keys(errors).length) {
            mutate({ data, csrfToken: api.csrfToken, companyId: company.id });
        }

    }

    const onSuccess = () => {
        setSuccessText(`Email sent successfully`);
    };

    const returnToPreviousPage = (e: MouseEvent) => location.assign(document.referrer);
    return (
        <div className={Themes.CHATTER.theme}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.sectionContainer}>
                        <Typography variant="h4" css={{ fontWeight: 500 }}>
                            Send email for {company.name}
                        </Typography>
                        <Card className={styles.card}>
                            <SelectInput name="companyUser" options={companyUserOptions} isRequired>
                                Company user
                            </SelectInput>
                            <DatePickerInput name="startDate" isRequired >
                                Start date
                            </DatePickerInput>
                            <DatePickerInput name="endDate" isRequired>
                                End date
                            </DatePickerInput>
                            <SelectInput name="emailDigest" options={emailDigestOptions} isRequired>
                                Email digest type
                            </SelectInput>
                        </Card>

                        <div className={styles.statusTextContainer}>
                            <SuccessTypography>{successText}</SuccessTypography>
                        </div>

                        <div className={styles.statusTextContainer}>
                            {errors.startDate && <ErrorTypography>{errors.startDate.message}</ErrorTypography>}
                            <ErrorTypography>{submitError?.message}</ErrorTypography>
                        </div>

                        <div className={styles.submitSectionContainer}>
                            <Button
                                size="large"
                                width="150px"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    returnToPreviousPage(e);
                                }}>
                                Cancel
                            </Button>
                            <Button
                                disabled={isLoading}
                                size="large"
                                width="150px"
                                onClick={handleSubmit(onSubmit)}>
                                {isLoading ? <Loading size="25px" /> : "Send Email"}
                            </Button>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
}

export default withReactQuery(SendEmailDigest as any);

