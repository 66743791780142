import React, { useState } from "react";

import Dimmer from "./Dimmer";

import classNames from "classnames";
import styles from "./Modal.module.scss";

const Modal = ({
  isOpen,
  setIsOpen,
  title,
  body,
  bodyClassName,
  onClose,
  actionButtonText,
  onActionButtonClicked,
  cancelButtonText,
  onCancelButtonClicked,
}) => {
  const [isPhasingOut, setIsPhasingOut] = useState(false);

  if (!isOpen && !isPhasingOut) return <></>;

  const handleOnClose = () => {
    onClose && onClose();
    setIsOpen(false);
    setIsPhasingOut(true);
  };

  return (
    <Dimmer
      className={styles.dimmer}
      isPhasingOut={isPhasingOut}
      setIsPhasingOut={setIsPhasingOut}
      onClick={handleOnClose}>
      <div
        className={classNames(styles.modal, { [styles.modalPhasingOut]: isPhasingOut })}
        onClick={(e) => e.stopPropagation()}>
        {title && <div className={styles.title}>{ title }</div>}
        <div className={classNames(bodyClassName, styles.body)}>
          { body }
          <div className={styles.buttonSection}>
            { actionButtonText && (
              <button
                className={styles.actionButton}
                type="button"
                onClick={() => {
                  onActionButtonClicked && onActionButtonClicked();
                  handleOnClose();
                }}>
                { actionButtonText }
              </button>
            ) }
            { cancelButtonText && (
              <button
                type="button"
                onClick={() => {
                  onCancelButtonClicked && onCancelButtonClicked();
                  handleOnClose();
                }}>
                { cancelButtonText }
              </button>
            ) }
          </div>
        </div>
      </div>
    </Dimmer>
  );
}

export default Modal;
