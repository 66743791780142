import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const InitiationWrapper = ({ children }) => <div className={styles.wrapper}>{children}</div>;

InitiationWrapper.propTypes = {
  children: PropTypes.node,
};

export default InitiationWrapper;
