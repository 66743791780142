import I18n from "i18n-js";
import React from "react";

import Tile from "./tile";

const NpsTile = ({ filters, npsData }) => {
  const overall = npsData && npsData.npsScore;
  const overallHistorical = npsData && npsData.npsScoreHistorical;
  const total = npsData && npsData.promoters + npsData.passives + npsData.detractors;
  const counts = npsData && [
    {
      category: I18n.t("dashboard_lite.promoters"),
      number: npsData.promoters,
      percentage: `${total ? Math.round((npsData.promoters / total) * 100) : 0}%`,
      state: "positive",
    },
    {
      category: I18n.t("dashboard_lite.passives"),
      number: npsData.passives,
      percentage: `${total ? Math.round((npsData.passives / total) * 100) : 0}%`,
      state: "neutral",
    },
    {
      category: I18n.t("dashboard_lite.detractors"),
      number: npsData.detractors,
      percentage: `${total ? Math.round((npsData.detractors / total) * 100) : 0}%`,
      state: "negative",
    },
  ];

  return (
    <Tile
      counts={counts}
      filters={filters}
      loading={!npsData}
      overall={overall}
      overallHistorical={overallHistorical}
      title={I18n.t("dashboard_lite.nps_tile_title")}
      tooltip={I18n.t("dashboard_lite.nps_tile_tooltip")}
    />
  );
};

export default NpsTile;
