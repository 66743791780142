import React from "react";
import { PointOfContactsStatus } from "./SurveyIngestionTypes";

import styles from "./ProcessPointOfContactsStatus.module.scss";

type ProcessPointOfContactsStatusProps = {
  status: PointOfContactsStatus;
  isPreview: boolean;
};
const ProcessPointOfContactsStatus: React.FC<ProcessPointOfContactsStatusProps> = ({
  status,
  isPreview,
}: ProcessPointOfContactsStatusProps) => {
  const { state, nbProcessedRows, nbTotalRows, nbPocExists, nbPocInFile, nbPocInvalid, nbPocValid } = status;

  return (
    <div className={styles.processPointOfContactsStatus}>
      <div className={styles.title}>{isPreview ? "Preview Results" : "Ingest Results"}</div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>State:</div>
        <div className={styles.resultValue}>{state}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Processed rows:</div>
        <div className={styles.resultValue}>{nbProcessedRows}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Total rows:</div>
        <div className={styles.resultValue}>{nbTotalRows}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Progression:</div>
        <div className={styles.resultValue}>{nbProcessedRows && nbTotalRows ? Math.round(nbProcessedRows / nbTotalRows * 100) : 0}%</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Points of Contact {isPreview ? "to be created" : "created"}:</div>
        <div className={styles.resultValue}>{nbPocValid}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Invalid Points of Contact:</div>
        <div className={styles.resultValue}>{nbPocInvalid}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Existing Points of Contact:</div>
        <div className={styles.resultValue}>{nbPocExists}</div>
      </div>
      <div className={styles.resultRow}>
        <div className={styles.resultLabel}>Total Points of Contact in File:</div>
        <div className={styles.resultValue}>{nbPocInFile}</div>
      </div>
    </div>
  );
};
export default ProcessPointOfContactsStatus;
