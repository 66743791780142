$ ->
  { loadJSONComponentAndSchema } = require('../jsoneditor.coffee')
  $('.company-editor').find('.company-name-editor').find('.json-editor').each (i) ->
    $companyName = $(this)
    # Prevent race condition between JS files: check if the json editor was already loaded, or wait for load event
    if $companyName[0] && $companyName[0].jsoneditor
      loadJSONComponentAndSchema($companyName, ".js-company-name-field:eq(#{i})", "smart_strings")
      $companyName[0].jsoneditor.set({ default: "" }) if $.isEmptyObject($companyName[0].jsoneditor.get())
    else
      $companyName.on 'json-editor-load', ->
        loadJSONComponentAndSchema($companyName, ".js-company-name-field:eq(#{i})", "smart_strings")
        $companyName[0].jsoneditor.set({ default: "" }) if $.isEmptyObject($companyName[0].jsoneditor.get())

  $('.company-editor').find('.company-poc-alias-singular-editor').find('.json-editor').each (i) ->
    $companyPocAliasSingular = $(this)
    # Prevent race condition between JS files: check if the json editor was already loaded, or wait for load event
    if $companyPocAliasSingular[0] && $companyPocAliasSingular[0].jsoneditor
      loadJSONComponentAndSchema($companyPocAliasSingular, ".js-company-poc-alias-singular-field:eq(#{i})", "smart_strings")
      $companyPocAliasSingular[0].jsoneditor.set({ default: "" }) if $.isEmptyObject($companyPocAliasSingular[0].jsoneditor.get())
    else
      $companyPocAliasSingular.on 'json-editor-load', ->
        loadJSONComponentAndSchema($companyPocAliasSingular, ".js-company-poc-alias-singular-field:eq(#{i})", "smart_strings")
        $companyPocAliasSingular[0].jsoneditor.set({ default: "" }) if $.isEmptyObject($companyPocAliasSingular[0].jsoneditor.get())

  $('.company-editor').find('.company-poc-alias-plural-editor').find('.json-editor').each (i) ->
    $companyPocAliasPlural = $(this)
    # Prevent race condition between JS files: check if the json editor was already loaded, or wait for load event
    if $companyPocAliasPlural[0] && $companyPocAliasPlural[0].jsoneditor
      loadJSONComponentAndSchema($companyPocAliasPlural, ".js-company-poc-alias-plural-field:eq(#{i})", "smart_strings")
      $companyPocAliasPlural[0].jsoneditor.set({ default: "" }) if $.isEmptyObject($companyPocAliasPlural[0].jsoneditor.get())
    else
      $companyPocAliasPlural.on 'json-editor-load', ->
        loadJSONComponentAndSchema($companyPocAliasPlural, ".js-company-poc-alias-plural-field:eq(#{i})", "smart_strings")
        $companyPocAliasPlural[0].jsoneditor.set({ default: "" }) if $.isEmptyObject($companyPocAliasPlural[0].jsoneditor.get())

  $('.company-editor').on 'keyup', ->
    if $(this).find('.input-error').length > 0
      $('#save-company').prop('disabled', true)
    else
      $('#save-company').prop('disabled', false)