import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

import { HeaderTextType } from "../../../types";

const HeaderText = ({ value, children, className }) => {
  return (
    <div className={cx("header", className)}>
      <div className={styles.text}>{value}</div>
      {children}
    </div>
  );
};

HeaderText.propTypes = HeaderTextType;

export default HeaderText;
