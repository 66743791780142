jQuery ->
  updatePointOfContactRestrictionText = (textElement, dataElement) ->
    pointOfContactNames = dataElement.attr("data-selected-names")

    if pointOfContactNames == ""
      textElement.html("Using points of contact from data access")
    else
      sortedPointOfContactNames = pointOfContactNames.split(",").sort()
      pocRestrictionText = sortedPointOfContactNames.slice(0,2).join(", ")
      overriden_poc_count = sortedPointOfContactNames.length
      if overriden_poc_count > 2
        pocRestrictionText += " and #{overriden_poc_count - 2} others"

      textElement.html(pocRestrictionText)

  updatePointOfContactRestrictions = (classId) ->
    pointOfContactSelection = $(".js-modal-poc-access-editor-" + classId + " .point-of-contact-selections").attr("data-selected-values")
    $("." + classId + "-restriction-values input").val(pointOfContactSelection)

    textElement = $("." + classId + "-restriction-text")
    dataElement = $(".js-modal-poc-access-editor-" + classId + " .point-of-contact-selections")

    updatePointOfContactRestrictionText(textElement, dataElement)

  $(".grant-access-to-all-pocs input").on "change", (e) ->
    $(".data-access-selector").toggleClass("hide");

  $("form.simple_form").filter(".edit_user,.new_user").on "submit", ->
    pointOfContactSelection = $(".poc-accesses .point-of-contact-selections").attr("data-selected-values")
    if (!$(".grant-access-to-all-pocs input").is(":checked"))
      $(".data-access-restriction-values input").val(pointOfContactSelection)

  $(".live_conversations-access input").on "change", (e) ->
    $(".live_conversations-sms-notifications").toggleClass("hide");
    $(".live_conversations-email-notifications").toggleClass("hide");
    $(".live-conversations-separator").toggleClass("hide");
    $(".live-conversation-poc-restriction").toggleClass("hide");

  $(".js-modal-poc-access-editor-live-conversation .js-modal-save").on "click", (e)->
    updatePointOfContactRestrictions("live-conversation")

  $("[class*='js-modal-poc-access-editor-survey-'] .js-modal-save").on "click", (e)->
    surveyClassId = $(this).attr("value")
    updatePointOfContactRestrictions(surveyClassId)

  #    The window.onLoad is necessary since the html corresponding element are generated after when the script is
  #    loaded in the page and so the event handlers never get set otherwise
  $ ->
    $(".survey-configuration-read input").on "change", (e) ->
      inputId = $(this).attr("id")
      surveyId = inputId.split("_")[6]

      $(".survey-" + surveyId + "-alert-toggle").toggleClass("hide")
      $(".survey-" + surveyId + "-alert-toggle input").prop("checked", false)
      if !$(".survey-" + surveyId + "-alert-configuration").attr("class").split(" ").includes("hide")
        $(".survey-" + surveyId + "-alert-configuration").toggleClass("hide")

    $(".survey-configuration-toggle input").on "change", (e) ->
      inputId = $(this).attr("id")
      surveyId = inputId.split("_")[6]
      $(".survey-" + surveyId + "-alert-configuration").toggleClass("hide");

    $(".roles-selector").find("label").each (e) ->
      if $(this).text() == "Customer Admin"
        $(this).find("input").on "change", (e) ->
          is_admin = $(this).prop("checked")

          $(".survey-configuration-read").toggleClass("hide")
          $(".survey-configuration-read-admin").toggleClass("hide")
          if is_admin
            $(".survey-configuration-toggle>div").removeClass("hide")
          else
            $(".survey-config").each (e) ->
              is_checked = $(this).find(".survey-configuration-read label input").is(":checked")
              if !is_checked
                $(this).find(".survey-configuration-toggle>div").addClass("hide")
                $(this).find("[class*='-alert-configuration']").addClass("hide")


