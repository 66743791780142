import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

import { isMobileOrTablet } from "../utils/general-helper";
import TooltipHelper from "../utils/tooltip-helper";

import IconButton from "./IconButton";
import Modal from "./Modal";
import TemplatesModal from "./TemplatesModal";

import I18n from "i18n-js";
import styles from "./InputMessage.module.scss";
import classNames from "classnames";
import cameraIcon from "../../images/camera-icon.svg";
import imageIcon from "../../images/image-icon.svg";
import removeIcon from "../../images/remove-icon.svg";
import sendIcon from "../../images/send-icon.svg";
import sendIconDisabled from "../../images/send-icon-disabled.svg";
import templatesIcon from "../../images/templates-icon.svg";

const InputMessage = ({ disabled = false, hasLimit = false, limitations, placeholder, templates, onSendMessageCallback }) => {
  const TEXT_AREA_PADDING = Number(styles.textAreaPadding.replace("px", ""));
  const TEXT_AREA_MAX_HEIGHT = 200;
  const INPUT_MESSAGE_PADDING = Number(styles.inputMessagePadding.replace("px", ""));

  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);
  const [message, setMessage] = React.useState("");
  const [isSending, setIsSending] = React.useState(false);
  const [attachments, setAttachments] = React.useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalBody, setErrorModalBody] = useState("");

  const endOfAttachments = React.useRef();
  const textAreaRef = React.useRef();
  const inputMessageRef = React.useRef();

  const messageToSend = message.trim().replace(/\n/g, "\r\n");
  const acceptedTwilioMimeTypes = limitations?.acceptedImageMimeTypes?.join(", ");
  const attachmentsSize = attachments ? Math.ceil(attachments.reduce((total, attachment) => total + attachment.file.size, 0) / 1024) : 0;
  const overLimit = attachments.length > 0 ? attachmentsSize > limitations?.mmsKbLimit : messageToSend.length > limitations?.smsCharLimit;
  const inputDisabled = disabled || isSending;
  const sendDisabled = inputDisabled || (!messageToSend && attachments.length === 0);

  useEffect(() => {
    const textArea = textAreaRef?.current;
    const inputMessage = inputMessageRef?.current;

    if (textArea && inputMessage) {
      textArea.style.height = "0px";
      const textAreaHeight = Math.min(textArea.scrollHeight + TEXT_AREA_PADDING, TEXT_AREA_MAX_HEIGHT);
      const inputMessageHeight = textAreaHeight + INPUT_MESSAGE_PADDING * 2;
      textArea.style.height = textAreaHeight + "px";
      inputMessage.style.minHeight = inputMessageHeight + "px"
    }
  }, [attachments, inputMessageRef, message]);

  useEffect(() => {
    scrollToEndOfAttachment();
  }, [attachments]);

  const onKeyDown = (e) => {
    if (!isMobileOrTablet() && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSendClicked();
    }
  };

  const showErrorModal = (errorModalBody) => {
    setErrorModalBody(errorModalBody);
    setIsErrorModalOpen(true);
  }

  const onSendClicked = async () => {
    if (sendDisabled) return;

    if (overLimit) {
      if (attachmentsSize > 0) {
        showErrorModal(I18n.t("live_conversations.mms_over_limit", { limit: limitations?.mmsKbLimit }))
      } else {
        showErrorModal(I18n.t("live_conversations.sms_over_limit", { limit: limitations?.smsCharLimit }));
      }
      return;
    }

    if (attachments.length > limitations?.mmsNbAttachmentsLimit) {
      showErrorModal(I18n.t("live_conversations.mms_over_nb_attachments_limit", { limit: limitations?.mmsNbAttachmentsLimit }));
      return;
    }

    const unsupportedFile = attachments.find(attachment => !acceptedTwilioMimeTypes.includes(attachment?.file?.type));
    if (unsupportedFile) {
      showErrorModal(I18n.t("live_conversations.mms_unsupported_file_type", { file_extension: unsupportedFile.file.name.split('.').pop(),file_name: unsupportedFile.file.name }));
      return;
    }

    setIsSending(true);
    const handled = await onSendMessageCallback(messageToSend, attachments);
    if (handled) {
      setMessage("");
      setAttachments([]);
    }
    setIsSending(false);
  };

  const handleTemplatesModalClose = (template) => {
    template && setMessage(template.content);
  }

  const sanitizeFilename = (filename) => {
    // Replace non-ASCII characters with a suitable replacement, such as "_"
    return filename.replace(/[^\x00-\x7F]/g, "_");
  };

  const onAttachmentsChange = (event) => {
    const newAttachments = Array.from(event?.target?.files || []).map(file => ({ file: new File([file], sanitizeFilename(file.name), { type: file.type }) }));

    newAttachments.forEach(async (newAttachment) => {
      if (limitations?.acceptedImageMimeTypes?.includes(newAttachment?.file?.type)) {
        const compressedFile = await imageCompression(newAttachment.file, { maxSizeMB: 0.19, maxIteration: 20 });
        newAttachment.file = new File([compressedFile], compressedFile.name, { type: compressedFile.type });

        const reader = new FileReader();
        reader.onload = (e) => {
          newAttachment.image = e?.target?.result;
          setAttachments([...attachments, ...newAttachments]);
        }
        reader.readAsDataURL(newAttachment.file);
      }
    });

    setAttachments([...attachments, ...newAttachments]);
  }

  const onRemoveAttachment = (attachment) => {
    setAttachments(attachments.filter((a) => a !== attachment));
  }

  const scrollToEndOfAttachment = () => {
    endOfAttachments?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className={styles.inputMessage} ref={inputMessageRef}>
        <textarea
          className={classNames(styles.textAreaInput, {[styles.withAttachmentPreview]: attachments.length > 0 })}
          ref={textAreaRef}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onKeyDown}
          value={message}
          disabled={inputDisabled}
          placeholder={placeholder || I18n.t("live_conversations.message_placeholder")}
        />
        { attachments.length > 0 && (
          <div className={styles.attachementsPreviewContainer}>
            <div className={styles.attachementsPreviewScrollingContent}>
              {attachments.map((attachment, index) => (
                <div key={index} className={styles.attachmentPreviewContainer}>
                  <TooltipHelper tooltipMessage={attachment.file.name}>
                    <div className={styles.attachmentPreview}>
                      { attachment.image && <img src={attachment.image} /> }
                      { !attachment.image && (
                        <>
                          <FontAwesomeIcon icon={faFile} />
                          <span>{ attachment.file.name }</span>
                        </>
                      ) }
                    </div>
                  </TooltipHelper>
                  <IconButton className={styles.attachmentRemoveButton} onClick={() => onRemoveAttachment(attachment)}>
                    <img src={removeIcon} />
                  </IconButton>
                </div>
              ))}
              <div ref={endOfAttachments} />
            </div>
          </div>
        ) }
        <div className={styles.actionButtons}>
          { templates?.length > 0 && (
            <IconButton disabled={inputDisabled} onClick={() => setIsTemplatesModalOpen(true)}>
              <img src={templatesIcon} />
            </IconButton>
          )}
          { acceptedTwilioMimeTypes?.length > 0 && (
            <>
              <IconButton className={styles.attachmentButton} disabled={inputDisabled}>
                <label htmlFor="fileAttachment">
                  <img src={imageIcon} />
                </label>
                <input
                  id="fileAttachment"
                  name="fileAttachment"
                  type="file"
                  accept={acceptedTwilioMimeTypes}
                  disabled={inputDisabled}
                  multiple={true}
                  onChange={(event) => onAttachmentsChange(event)} />
              </IconButton>
              <IconButton className={styles.attachmentButton} disabled={inputDisabled}>
                <label htmlFor="cameraAttachment">
                  <img src={cameraIcon} />
                </label>
                <input
                  id="cameraAttachment"
                  name="cameraAttachment"
                  type="file"
                  accept={acceptedTwilioMimeTypes}
                  disabled={inputDisabled}
                  capture="environment"
                  multiple={true}
                  onChange={(event) => onAttachmentsChange(event)} />
              </IconButton>
            </>
          )}
          <IconButton disabled={sendDisabled} onClick={onSendClicked}>
            <img src={sendDisabled ? sendIconDisabled : sendIcon} />
          </IconButton>
        </div>
        {!disabled && hasLimit && (
          <div className={styles.limit}>
            { attachmentsSize ?
              <span className={classNames({[styles.overLimit]: overLimit})}>{I18n.t("live_conversations.mms_limit", { value: attachmentsSize, limit: limitations?.mmsKbLimit })}</span> :
              <span className={classNames({[styles.overLimit]: overLimit})}>{I18n.t("live_conversations.sms_limit", { value: messageToSend.length, limit: limitations?. smsCharLimit })}</span> }
          </div>
        )}
      </div>
      <Modal
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        body={errorModalBody}
        actionButtonText={I18n.t("live_conversations.ok")} />
      { templates && (
        <TemplatesModal
          isOpen={isTemplatesModalOpen}
          setIsOpen={setIsTemplatesModalOpen}
          templates={templates}
          onClose={handleTemplatesModalClose} />
      ) }
    </>
  );
}

export default InputMessage;
