$ ->

  $("#topic-function-table").DataTable()

  performRequest = (url, successCallback, errorCallback ) ->
    $('.topic-function-preview-button').attr('disabled', true)
    $('.topic-function-import-button').attr('disabled', true)

    data = new FormData(document.querySelector('#topic_function_import_csv_form'))

    $.ajax
      type: "POST"
      url: url
      data: data
      success: (response) ->
        $('.topic-function-import-csv-errors-block').empty()
        $('.topic-function-import-csv-result-block')[0].innerHTML = response
        $('.topic-function-import-csv-errors-block').addClass "hidden"
        $('.topic-function-import-csv-result-block').removeClass "hidden"
        successCallback()
      error: (xhr, status, error) ->
        $('.topic-function-import-csv-errors-block')[0].innerHTML = xhr.responseText
        $('.topic-function-import-csv-errors-block').removeClass "hidden"
        $('.topic-function-import-csv-result-block').addClass "hidden"
        errorCallback()
      complete: ->
        $('.topic-function-import-button').removeAttr('disabled')
      processData: false
      contentType: false

  $('#csv_upload').on 'click', (event) ->
    $('#csv_upload').val("")
    $('.topic-function-import-button').addClass "hidden"
    $('.topic-function-import-csv-errors-block').addClass "hidden"
    $('.topic-function-import-csv-result-block').addClass "hidden"

  $('#csv_upload').on 'change', (event) ->
    if event.target.files.length
      $('.topic-function-preview-button').removeAttr('disabled')
    else
      $('.topic-function-preview-button').attr('disabled', true)

  $('.topic-function-preview-button').on 'click', (event) ->
    if (!$(this).attr('disabled'))
      successCallback = () ->
        $('.topic-function-import-button').removeClass "hidden"

      errorCallback = () ->
        $('#csv_upload').val("")
        $('.topic-function-import-button').addClass "hidden"

      performRequest($(this).data('url'), successCallback, errorCallback)

  $('.topic-function-import-button:not(.disabled)').on 'click', ->
    if (!$(this).attr('disabled'))
      successCallback = () ->
        $('.topic-function-import-button').addClass "hidden"

      errorCallback = () ->
        $('.topic-function-import-button').addClass "hidden"

      performRequest($(this).data('url'), successCallback, errorCallback)
