import React from "react";
import PropTypes from "prop-types";
import Slick from "src/vendor/react-slick";
import TopAndBottomPerformanceContainer from "../tile-top-and-bottom";
import FastestClimbersContainer from "../tile-fastest-climbers";
import TopicsContainer from "../tile-topics";

class CarouselContainer extends React.Component {
  render() {
    //https://react-slick.neostack.com/docs/api
    let settings = {
      arrows: false,
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 1500,
    };

    const { topicsApi, pocRankingsApi } = this.props;

    return (
      <Slick {...settings}>
        <TopicsContainer api={topicsApi} />
        <TopAndBottomPerformanceContainer api={pocRankingsApi} />
        <FastestClimbersContainer api={pocRankingsApi} />
      </Slick>
    );
  }
}

CarouselContainer.propTypes = {
  pocRankingsApi: PropTypes.string,
  topicsApi: PropTypes.string,
};

export default CarouselContainer;
