/* eslint-disable camelcase */
import React from "react";
import i18n from "i18n-js";
import { PrettyLoader } from "../../views/shared";
import hasLoader from "../hoc/has-loader";
import NPSTrendingGraph from "../../views/tile-nps-trending-graph";

// mixpanel event vars
const graphRef = React.createRef();
let inViewTriggered = false;

const handleScroll = () => {
  if (
    graphRef.current.getBoundingClientRect().bottom < window.innerHeight ||
    graphRef.current.getBoundingClientRect().top < 0
  ) {
    if (!inViewTriggered) {
      inViewTriggered = true;
      // mixpanel is defined in the global scale and only on prod, eslint does not like this, but we are catching it with the if
      try {
        // eslint-disable-next-line no-undef
        mixpanel.track("NPS Trending viewed");
      } catch (e) {
        if (e instanceof ReferenceError) {
          // ignore it just means were not in prod
        } else {
          console.error(e);
        }
      }
    }
  }
};

const NpsTrendingGraphContainer = ({ status, componentData }) => {
  React.useEffect(() => {
    if (graphRef.current) {
      window.addEventListener("scroll", handleScroll, true);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [graphRef.current]);

  if (componentData && componentData.error) {
    console.warn(componentData.error || "could not load the nps trending data");
  }

  return (
    <div className="report-trending-graph-container" ref={graphRef}>
      {componentData && componentData.error ? null : (
        <PrettyLoader
          status={status}
          size="medium"
          style={{ margin: "10px 0px" }}
          title={i18n.t("response_trending")}
          titleClass="report-title">
            <NPSTrendingGraph 
              segmentInfo={componentData.segmentation_info}
              npsInfo={componentData.nps_info}
              answerType={componentData.answer_type}
              timeIntervals={componentData.date_intervals}
            />
        </PrettyLoader>
      )}
    </div>
  );
}

export default hasLoader(NpsTrendingGraphContainer);