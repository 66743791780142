import React, { useEffect } from "react";
import classNames from "classnames";

import { screenSize } from "../utils/constants";
import Dimmer from "./Dimmer";

import styles from "./SlidingPage.module.scss";

const SlidingPage = ({ children, isClosed, isSlidingFromLeft, isSlidingOut, setIsSlidingOut, userScreenSize }) => {
  useEffect(() => {
    if (userScreenSize === screenSize.SMALL) {
      document.getElementById("live-conversations").style.overflowY = isClosed ? "auto" : "hidden";
    }
  }, [isClosed, userScreenSize]);

  if (userScreenSize !== screenSize.SMALL) return <>{ !isClosed && <div className={styles.slidingPage}>{children}</div> }</>;

  if (isClosed && !isSlidingOut) return <></>;

  return (
    <Dimmer isPhasingOut={isSlidingOut} setIsPhasingOut={setIsSlidingOut}>
      <div
        className={classNames(styles.slidingPage, {
          [styles.slidingPageLeft]: isSlidingFromLeft,
          [styles.slidingPageLeftSlideOut]: isSlidingFromLeft && isSlidingOut,
          [styles.slidingPageRight]: !isSlidingFromLeft,
          [styles.slidingPageRightSlideOut]: !isSlidingFromLeft && isSlidingOut,
        })}
        onClick={(e) => e.stopPropagation()}>
        { children }
      </div>
    </Dimmer>
  );
}

export default SlidingPage;
