import PropTypes from "prop-types";

const fetchStatusType = {
  done: "done",
  error: "error",
  loading: "loading",
};

const trendingIconType = {
  decrease: "decrease",
  increase: "increase",
  nochange: "nochange",
  none: "none",
};

const tileSizeType = {
  medium: "medium",
  small: "small",
  flex: "flex",
};

const questionType = {
  NPS: "NPS",
  Satisfaction: "Satisfaction",
};

const topicIconType = {
  weakness: "weakness",
  strength: "strength",
  none: "none",
};

//PropTypes

const multiTableRowType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  trend: PropTypes.oneOf(Object.keys(trendingIconType)),
});

const multiTableRowsType = PropTypes.arrayOf(multiTableRowType);

const multiTableTableType = PropTypes.shape({
  data: multiTableRowsType,
  questionType: PropTypes.string,
  title: PropTypes.string,
});

const AlertContainerType = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

const CompletionRateContainerType = {
  componentData: PropTypes.shape({
    completed: PropTypes.number,
    completedHistorical: PropTypes.number,
    initiated: PropTypes.number,
    initiatedHistorical: PropTypes.number,
    partial: PropTypes.number,
    preferResponded: PropTypes.bool,
    responded: PropTypes.number,
    respondedHistorical: PropTypes.number,
  }).isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

const InitiationContainerType = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

//TODO: add componentData type shape to all containers
const FastestClimbersContainerType = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

const NPSContainerType = {
  componentData: PropTypes.shape({
    detailsPath: PropTypes.string,
    npsScore: PropTypes.number,
    npsScoreHistorical: PropTypes.number,
  }).isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

const SatContainerType = {
  componentData: PropTypes.shape({
    detailsPath: PropTypes.string,
    satScore: PropTypes.number,
    satScoreHistorical: PropTypes.number,
  }).isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

const TopAndBottomPerformanceContainerType = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

const TopicsChartContainerType = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

const NPSTrendingContainerType = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

const TopTenStrengthsContainerType = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

const TopTenWeaknessesContainerType = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

//Content Types
const AlertsContentType = {
  alertDetailsPath: PropTypes.string,
  newAlertText: PropTypes.string,
  newAlertsCount: PropTypes.number,
  totalAlertText: PropTypes.string,
  totalAlertsCount: PropTypes.number,
  totalIcon: PropTypes.PropTypes.oneOf(Object.keys(trendingIconType)),
  totalTooltipMessage: PropTypes.string,
};

const CompletionContentType = {
  completed: PropTypes.number,
  icon: PropTypes.PropTypes.oneOf(Object.keys(trendingIconType)),
  initiated: PropTypes.number,
  percentage: PropTypes.number,
  preferResponded: PropTypes.bool,
  tooltipMessage: PropTypes.string,
};

const InitiationContentType = {
  initiated: PropTypes.number,
  initiatedHistorical: PropTypes.number,
  tooltipMessage: PropTypes.string,
  icon: PropTypes.PropTypes.oneOf(Object.keys(trendingIconType)),
};

const MultiTableContentType = {
  additionalText: PropTypes.string,
  detailsPath: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  median: PropTypes.number,
  scoreLabel: PropTypes.oneOf(Object.keys(questionType)),
  tableLeft: multiTableTableType,
  tableRight: multiTableTableType,
};

const MultitableTableType = {
  rows: multiTableRowsType,
  scoreLabel: PropTypes.oneOf(Object.keys(questionType)),
};

const NPSContentType = {
  detailsPath: PropTypes.string,
  icon: PropTypes.PropTypes.oneOf(Object.keys(trendingIconType)),
  text: PropTypes.string,
  tooltipMessage: PropTypes.string,
  value: PropTypes.number,
};

const TopicsContentType = {
  chartOptions: PropTypes.shape({
    categories: PropTypes.array,
    extraOptions: PropTypes.object,
    neutralData: PropTypes.array,
    positiveData: PropTypes.array,
    mixedData: PropTypes.array,
  }),
  detailsPath: PropTypes.string,
  headerText: PropTypes.string,
};

const TrendingChartSeriesType = {
  name: PropTypes.string,
  data: PropTypes.array,
  color: PropTypes.string,
};

const NPSTrendingChartType = {
  xAxisTitle: PropTypes.string,
  yAxisTitle: PropTypes.string,
  categories: PropTypes.array,
  series: PropTypes.arrayOf(PropTypes.shape(TrendingChartSeriesType)),
};

const NPSTrendingContentType = {
  chartData: PropTypes.shape(NPSTrendingChartType),
  headerText: PropTypes.string,
};

const DetailLinkType = {
  openInNewTab: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

const ErrorStatusType = {
  caption: PropTypes.string,
  centered: PropTypes.bool,
  message: PropTypes.string,
  multiRow: PropTypes.bool,
};

const HeaderTextType = {
  children: PropTypes.node,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
};

const LoadingStatusType = {
  centered: PropTypes.bool,
};

const PrettyButtonType = {
  children: PropTypes.node,
  className: PropTypes.string,
  openInNewTab: PropTypes.bool,
  path: PropTypes.string,
};

const PrettyLoaderType = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.keys(tileSizeType)),
  status: PropTypes.oneOf(Object.keys(fetchStatusType)).isRequired,
  link: PropTypes.string,
};

const PrettySquareBoxType = {
  children: PropTypes.node,
  height: PropTypes.oneOf(Object.keys(tileSizeType)),
  link: PropTypes.string,
  style: PropTypes.any,
};

const TrendIconType = {
  icon: PropTypes.oneOf(Object.keys(trendingIconType)),
  size: PropTypes.number,
};

const ChartLegendType = {
  color: PropTypes.string,
  value: PropTypes.string.isRequired,
};

const RankType = {
  title: PropTypes.string,
  rank: PropTypes.string,
};

const NPSDetailContentType = {
  title: PropTypes.string,
  value: PropTypes.number,
  previousValue: PropTypes.number,
  trendIcon: PropTypes.oneOf(Object.keys(trendingIconType)),
  ranks: PropTypes.arrayOf(PropTypes.shape(RankType)),
};

const KPIContentType = {
  question: PropTypes.string,
  value: PropTypes.string,
  trendIcon: PropTypes.oneOf(Object.keys(trendingIconType)),
  answer: PropTypes.string,
  previousPeriod: PropTypes.object,
  ranks: PropTypes.arrayOf(PropTypes.shape(RankType)),
};

const LiveResponsesContainerType = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

const LiveResponsesMessagesType = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  info: PropTypes.array,
};

const LiveResponsesType = {
  question: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.shape(LiveResponsesMessagesType)),
};

const ConversationBubbleType = {
  text: PropTypes.string,
  info: PropTypes.array,
};

const TopTopicContentType = {
  category: PropTypes.string,
  topic: PropTypes.string,
};

const TopTopicIconType = {
  icon: PropTypes.oneOf(Object.keys(topicIconType)),
  size: PropTypes.number,
};

const GotoLinkType = {
  openInNewTab: PropTypes.bool,
  url: PropTypes.string,
};

const MetricTileContainerType = {
  componentData: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.keys(fetchStatusType)),
};

const MetricTileType = {
  questionType: PropTypes.string.isRequired,
  currentMetric: PropTypes.number,
  delta: PropTypes.number,
  title: PropTypes.string.isRequired,
  dateRange: PropTypes.string,
};

const ContentTileType = {
  questionType: PropTypes.string.isRequired,
  data: PropTypes.any,
  title: PropTypes.string.isRequired,
};

export {
  AlertContainerType,
  AlertsContentType,
  ChartLegendType,
  CompletionContentType,
  CompletionRateContainerType,
  ConversationBubbleType,
  DetailLinkType,
  ErrorStatusType,
  FastestClimbersContainerType,
  fetchStatusType,
  GotoLinkType,
  HeaderTextType,
  InitiationContainerType,
  InitiationContentType,
  KPIContentType,
  LiveResponsesContainerType,
  LiveResponsesType,
  LoadingStatusType,
  MultiTableContentType,
  multiTableRowsType,
  MultitableTableType,
  NPSContainerType,
  NPSContentType,
  NPSDetailContentType,
  NPSTrendingChartType,
  NPSTrendingContainerType,
  NPSTrendingContentType,
  PrettyButtonType,
  PrettyLoaderType,
  PrettySquareBoxType,
  questionType,
  SatContainerType,
  tileSizeType,
  TopAndBottomPerformanceContainerType,
  topicIconType,
  TopicsChartContainerType,
  TopicsContentType,
  TopTenStrengthsContainerType,
  TopTenWeaknessesContainerType,
  TopTopicContentType,
  TopTopicIconType,
  TrendIconType,
  trendingIconType,
  MetricTileContainerType,
  MetricTileType,
  ContentTileType,
};
