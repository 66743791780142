import React, { useContext, createContext, PropsWithChildren } from "react";

export type MutationFetcherProps = {
    csrfToken: string;
}

export type Config = {
  api: {
    csrfToken: string;
  }
}

type ConfigProviderProps = PropsWithChildren<{
    config: Config;
}>

export const ConfigContext = createContext<Config | undefined>(undefined);

export const ConfigProvider = ({config, children }: ConfigProviderProps) => {
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }

  return context;
};

