import React from "react";
import PropTypes from "prop-types";

import SummaryEngagement from "./summary-engagement";
import AlertSection from "./alert-section";

import SectionHeader from "../../views/shared/section-header/section-header";

class Engagement extends React.Component {
  render() {
    const { alertPath, engagementPath } = this.props;
    return (
      <React.Fragment>
        <SectionHeader title="Engagement" />
        <div className="js-engagement">
          <SummaryEngagement api={engagementPath} />
          <AlertSection api={alertPath} />
        </div>
      </React.Fragment>
    );
  }
}

Engagement.propTypes = {
  alertPath: PropTypes.string,
  engagementPath: PropTypes.string,
};

export default Engagement;
