import React from 'react'
import classNames from "classnames";
import { Loading, Themes } from "stingray-components";

import styles from './LoadingPage.module.scss'

const LoadingPage = () => {
  return <div className={classNames(Themes.CHATTER.theme, styles.loadingPageContainer)} >
    <Loading size="50px" />
  </div>
}

export default LoadingPage;
