html2canvas = require('html2canvas')

ScreenToPng = (selector, fileName) ->
  node = $(selector)
  # node.css('overflow-x', 'visible')
  # html2canvas does not support box-shadow
  node.parent().addClass("no-box-shadow")
  node.find(".filters-container-wide").addClass("hide")
  # useWidth = node.prop('scrollWidth')
  # useHeight = node.prop('scrollHeight')
  # html2canvas(node[0], { width: useWidth, height: useHeight } ).then (canvas) ->
  html2canvas(node[0], { }).then (canvas) ->
    canvas.toBlob((blob) ->
      window.saveAs(blob, "#{fileName}.png")
      # node.css('overflow-x', 'auto')
      node.parent().removeClass("no-box-shadow")
      node.find(".filters-container-wide").removeClass("hide")
    )

module.exports = {ScreenToPng}