import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faClose } from "@fortawesome/free-solid-svg-icons";

import { screenSize } from "../utils/constants";
import { fetchData } from "../utils/request-helper";

import SlidingPage from "./SlidingPage";
import Header from "./Header";
import IconButton from "./IconButton";
import Modal from "./Modal";
import InputMessage from "./InputMessage";

import I18n from "i18n-js";
import styles from "./StartConversation.module.scss";

const StartConversation = ({
  api,
  limitations,
  locations,
  templates,
  show,
  setShow,
  setConversationReceivedCallback,
  setSelectedConversationCallback,
  userScreenSize,
}) => {
  const [isSlidingOut, setIsSlidingOut] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalBody, setErrorModalBody] = useState("");

  const [conversationName, setConversationName] = useState("");
  const [location, setLocation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [existingConversation, setExistingConversation] = useState(null);

  const hasMultipleLocations = locations.length > 1;
  const currentLocation = hasMultipleLocations ? location : locations[0];

  const closeCallback = () => setShow(false);

  const onChevronClicked = () => {
    closeCallback();
    setIsSlidingOut(true);
  }

  const showErrorModal = (errorModalBody) => {
    setErrorModalBody(errorModalBody);
    setIsErrorModalOpen(true);
  }

  const isValidPhoneNumber = (phoneNumber, countryCode) => {
    // Remove any space, dot, dash, parentheses, and plus
    phoneNumber = phoneNumber.replace(/[\s.()\-+]+/g, "");

    let regex;

    switch(countryCode.toUpperCase()) {
        case "CA":
        case "US":
            // Phone number can be of format: 12345678901 or 2345678901
            regex = /^1?\d{10}$/;
            break;
        case "FR":
            // French phone numbers can be of format: 33123456789 or 0123456789
            regex = /^(0|33)[1-9]\d{8}$/;
            break;
        default:
            console.error("country code is not supported");
            return false;
    }

    return regex.test(phoneNumber);
  }

  const handleSendMessage = async (message, attachments) => {
    const { csrfToken, getExistingConversationUrl } = api;
    const countryCode = currentLocation?.country_code || "US";

    if (!currentLocation || !isValidPhoneNumber(phoneNumber, countryCode)) {
      showErrorModal(I18n.t("live_conversations.enter_location_and_valid_phone_number"));
      return;
    }

    const existingConversation = await fetchData(`${getExistingConversationUrl}?them=${phoneNumber}&poc_id=${currentLocation.value}`, {
      method: "GET",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });

    setMessage(message);
    setAttachments(attachments);

    if (!existingConversation?.id) return startConversation(message, attachments);

    setExistingConversation(existingConversation);
    setIsModalOpen(true);
    return false;
  }

  const redirectToExistingConversation = async (message, attachments) => {
    if (!existingConversation) return;

    const body = new FormData();
    body.append("conversation_id", existingConversation.id);
    body.append("message", message);
    attachments?.forEach((attachment) => body.append("mms_attachments[]", attachment.file));

    const updatedConversation = await fetchData(existingConversation.apiEndpoints.sendMessage, {
      method: "POST",
      headers: {
        "X-CSRF-Token": api.csrfToken,
      },
      body,
    });

    setIsModalOpen(false);

    if (!updatedConversation) {
      showErrorModal(I18n.t("live_conversations.general_error"));
      return;
    }

    setConversationName("");
    setLocation("");
    setPhoneNumber("");
    setMessage("");
    setAttachments([]);
    setExistingConversation(null);

    setSelectedConversationCallback(updatedConversation);
    setConversationReceivedCallback({ conversation: updatedConversation, bumpToTop: true });

    setTimeout(() => closeCallback(), 400); // $animationDuration scss variable
  }

  const startConversation = async (message, attachments) => {
    const { csrfToken, startConversationUrl } = api;

    const body = new FormData();
    body.append("conversation_name", conversationName);
    body.append("location", currentLocation.value);
    body.append("them", phoneNumber);
    body.append("message", message);
    attachments?.forEach((attachment) => body.append("mms_attachments[]", attachment.file));

    const newConversation = await fetchData(startConversationUrl, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body,
    });

    if (!newConversation) {
      showErrorModal(I18n.t("live_conversations.general_error"));
      return;
    }

    setIsModalOpen(false);
    setConversationName("");
    setLocation("");
    setPhoneNumber("");
    setMessage("");
    setAttachments([]);

    setConversationReceivedCallback({ conversation: newConversation, bumpToTop: true });
    setSelectedConversationCallback(newConversation);

    setTimeout(() => closeCallback(), 400); // $animationDuration scss variable

    return true;
  }

  useEffect(() => {
    if (!show) {
      setConversationName("");
      setLocation("");
      setPhoneNumber("");
    }
  }, [show]);

  return (
    <>
      <SlidingPage
        isClosed={!show}
        isSlidingOut={isSlidingOut}
        setIsSlidingOut={setIsSlidingOut}
        closeCallback={closeCallback}
        userScreenSize={userScreenSize}>
        <Header>
          {userScreenSize === screenSize.SMALL && (
            <IconButton onClick={() => onChevronClicked()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </IconButton>
          )}
          <span>{ I18n.t("live_conversations.start_conversation") }</span>
          {userScreenSize !== screenSize.SMALL && (
            <IconButton onClick={() => onChevronClicked()}>
              <FontAwesomeIcon className={styles.closeIcon} icon={faClose} />
            </IconButton>
          )}
        </Header>
        <div className={styles.body}>
          <div className={styles.form}>
            <span className={styles.label}>{ I18n.t("live_conversations.conversation_name") }</span>
            <input
              className={styles.input}
              value={conversationName}
              onChange={(e) => setConversationName(e.target.value)} />

            <span className={styles.label}>{ I18n.t("live_conversations.location_you_represent") }</span>
            <span className={styles.requiredLabel}>*</span>
            { hasMultipleLocations && (
              <ReactSelect
                isClearable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    marginBottom: "10px",
                    height: "50px",
                    background: "rgba(0, 0, 0, 0.05)",
                    border: "0",
                    borderColor: "none",
                    borderRadius: "10px",
                    boxShadow: state.isFocused ? "0 0 0 2px #5175FA" : null,
                  }),
                }}
                value={location}
                onChange={(location) => setLocation(location)}
                options={locations} />
            )}
            { !hasMultipleLocations && <input className={styles.input} type="text" value={locations[0]?.label} disabled /> }

            <span className={styles.label}>{ I18n.t("live_conversations.customer_phone_number") }</span>
            <span className={styles.requiredLabel}>*</span>
            <input
              className={styles.input}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)} />
          </div>
        </div>
        <InputMessage hasLimit={true} limitations={limitations} templates={templates} onSendMessageCallback={handleSendMessage} />
      </SlidingPage>
      <Modal
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        body={errorModalBody}
        actionButtonText={I18n.t("live_conversations.ok")} />
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        body={I18n.t("live_conversations.existing_conversation_found")}
        actionButtonText={I18n.t("live_conversations.continue_with_existing_conversation")}
        onActionButtonClicked={() => redirectToExistingConversation(message, attachments)}
        cancelButtonText={I18n.t("live_conversations.start_new_conversation")}
        onCancelButtonClicked={() => startConversation(message, attachments)} />
    </>
  );
}

export default StartConversation;
