jQuery ->
  $ ->

    updateHiddenFields = ->
      $('#poc-group-hidden-fields-container').empty()
      $('#poc-group-selected-poc-list option').each ->
        $('<input>').attr('type', 'hidden').attr('name', 'point_of_contact_group[point_of_contact_ids][]').val($(this).val()).appendTo('#poc-group-hidden-fields-container')

    $('#poc-group-add-poc-button').on 'click', (event) ->
      event.preventDefault()
      $('#poc-group-available-poc-list option:selected').each ->
        $('#poc-group-selected-poc-list').append $(this).clone()
        $(this).remove()
      updateHiddenFields()

    $('#poc-group-remove-poc-button').on 'click', (event) ->
      event.preventDefault()
      $('#poc-group-selected-poc-list option:selected').each ->
        $('#poc-group-available-poc-list').append $(this).clone()
        $(this).remove()
      updateHiddenFields()

    updateHiddenFields()


