import React from "react";

import styles from "./styles.module.scss";

import { TopTopicContentType } from "../../../../types";

import { TopTopicIcon } from "../../../shared";

const TopTopicContent = ({ category, topic, icon }) => {
  return (
    <div className={styles["wrapper"]}>
      {icon && (
        <div className={styles["logo-wrapper"]}>
          <TopTopicIcon icon={icon} className={styles["icon"]} />
        </div>
      )}
      <div className={styles["content-wrapper"]}>
        <div className={styles["category"]}>{category}</div>
        <div className={styles["topic"]}>{topic}</div>
      </div>
    </div>
  );
};

TopTopicContent.propTypes = TopTopicContentType;

export default TopTopicContent;
