import I18n from "i18n-js";
import React, { MouseEvent } from "react";
import { useMutation } from "react-query";
import { Button, Card, Loading, Typography, styled } from "stingray-components";
import { User } from "./userManagementTypes";

import styles from "./UserManagementForm.module.scss";
import { MutationFetcherProps, useConfig } from "../../src/hooks/useConfig";

const ResetPasswordButton = styled(Button, {
    "& > div": {
        width: 100,
        transition: "background  0.4s ease-out"
    },
    variants: {
        color: {
            regular: {},
            success: {
                "& > div": {
                    background: "#00F000",

                    "&:hover": {
                        background: "#00FF00 !important"
                    },
                },
            }
        },
    },
})

type ResetPasswordFetcherProps = MutationFetcherProps & {
    targetUserId: number;
}

const sendResetPassword = async ({ csrfToken, targetUserId }: ResetPasswordFetcherProps) => {
    const response = await fetch(`/api/user_management/${targetUserId}/reset_password`, {
        method: "POST",
        headers: {
            "X-CSRF-Token": csrfToken
        }
    });

    if (!response.ok) throw new Error("Could not send the password reset.");

    return true;
}

type ResetPasswordCardProps = {
    targetUser: User;
};

const ResetPasswordCard = ({ targetUser }: ResetPasswordCardProps) => {
    const config = useConfig()

    const { mutate, isSuccess, isLoading } = useMutation({
        mutationFn: sendResetPassword,
    })

    return (
        <Card className={styles.cardContainer}>
            <div className={styles.cardSubsection}>
                <div className={styles.buttonHeaderContainer}>
                    <Typography variant="h6">{I18n.t("reset_password")}</Typography>
                    <ResetPasswordButton
                        size="medium"
                        color={isSuccess ? "success" : "regular"}
                        disabled={isLoading || isSuccess}
                        onClick={(e: MouseEvent) => {
                            e.preventDefault()
                            mutate({ csrfToken: config.api.csrfToken, targetUserId: targetUser.id })
                        }}
                    >
                        <Typography variant="h8">
                            {(isLoading) ?
                                <Loading size="25px" /> :
                                (isSuccess ? I18n.t("user_management.sent") : I18n.t("user_management.send"))
                            }
                        </Typography>
                    </ResetPasswordButton>
                </div>
            </div>
            {isSuccess &&
                <div className={styles.cardSubsectionSettings}>
                    <Typography variant="h9">{I18n.t("user_management.reset_email_sent")}</Typography>
                </div>
            }
        </Card>
    );
};

export default ResetPasswordCard;
