import React from "react";

import { PrettyLoader } from "../../../views/shared";
import NPSComparison from "../../../views/report-page/comparison";

import { isArrayEmpty } from "../../../utils/general-helper";

import hasLoader from "../../hoc/has-loader";

const NPSComparisonChartContainer = ({ status, componentData }) => {
  const { categories, series, yAxisTitle, headerText } = componentData;

  let previousData;
  let currentData;

  if (!isArrayEmpty(series) && series.length > 1) {
    previousData = { ...series[0] };
    currentData = { ...series[1] };
  }

  let options = {
    categories: categories,
    series: [
      {
        ...previousData,
        color: "#E4E4EB",
      },
      {
        ...currentData,
        color: "#2962FF",
      },
    ],
    yAxisTitle: yAxisTitle,
  };

  return (
    <PrettyLoader status={status} size="medium">
      <NPSComparison headerText={headerText} chartData={options} />
    </PrettyLoader>
  );
};

export default hasLoader(NPSComparisonChartContainer);
