import React from "react";

import AlertsContent from "../../views/tile-alert-new";

import { PrettyLoader } from "../../views/shared";

import hasLoader from "../hoc/has-loader";

import { AlertContainerType } from "../../types";

//TODO this shares Type of old Container, so we need to introduce new type once we know the API
class AlertContainer extends React.Component {
  render() {
    const { status, componentData } = this.props;
    const { totalAlertsCount, unresolvedAlertsCount, alertDetailsPath } = componentData;

    return (
      <PrettyLoader status={status}>
        <AlertsContent
          unresolvedAlertsCount={unresolvedAlertsCount}
          totalAlertsCount={totalAlertsCount}
          alertDetailsPath={alertDetailsPath}
        />
      </PrettyLoader>
    );
  }
}

AlertContainer.propTypes = AlertContainerType;

export default hasLoader(AlertContainer);
