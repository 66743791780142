import React from "react";
import PropTypes from "prop-types";

import { addQueryParams } from "../../utils/general-helper";

import axios from "axios";

/**
 * HOC
 * Fetches data from api endpoint passed as a "api" prop
 * Checks for componentData and passes it down to WrappedComponent
 * Sets loading/error state
 * Checks for refresh and triggers it
 */
let hasLoader = (WrappedComponent) => {
  class HasLoader extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        componentData: {},
        fromCache: false,
        status: "loading",
      };
    }

    componentDidMount() {
      this.fetchData()
        .then(() => {
          const { componentData } = this.state;
          const { refresh } = componentData;
          refresh && refresh.enabled && this.refresh(refresh.rate * 1000);
        })
        .catch((exception) => {
          this.setState({
            componentData: {},
            errorMessage: exception.message,
            status: "error",
          });
        });
    }

    componentWillUnmount() {
      clearInterval(this.refresher);
    }

    refresh(rate) {
      //cannot pass refresh:true here since it would be treated as different request
      this.refresher = setInterval(() => {
        //fetch data only when document hasFocus
        //when refresh is called we will remove cache for this request and then cache refreshed request
        if (window.document.hasFocus()) {
          this.fetchData({}, true);
        }
      }, rate);
    }

    //cached response is used only if ignoreCache and disableCache both set to false
    fetchData(queryParams, ignoreCache = true) {
      let { api, cache = {} } = this.props;
      let { disableCache } = cache;

      let apiEndpoint = addQueryParams(api, queryParams);

      //clearCacheEntry=true removes current cached version and then calls API
      let cacheOptions = {
        cache,
        clearCacheEntry: disableCache || ignoreCache,
      };

      return axios.get(apiEndpoint, cacheOptions).then((res) => {
        const { componentData } = res.data;

        if (!componentData) {
          throw new Error("componentData attribute is missing from API!");
        }

        this.setState({
          componentData: componentData,
          status: "done",
          fromCache: res.request.fromCache ? true : false,
        });
      });
    }

    render() {
      const { settings } = this.props;
      return <WrappedComponent {...this.state} {...settings} />;
    }
  }

  const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || "Component";
  };

  HasLoader.displayName = `hasLoader(${getDisplayName(WrappedComponent)})`;

  //we can directly set disableCache as prop to hasLoader and prevent component from caching responses.
  HasLoader.propTypes = {
    api: PropTypes.string.isRequired,
    settings: PropTypes.object,
    cache: PropTypes.shape({
      disableCache: PropTypes.bool,
      maxAge: PropTypes.string,
    }),
  };

  return HasLoader;
};

export default hasLoader;
