import React, { useContext, useState, createContext, PropsWithChildren } from "react";

export type ModalProviderProps = PropsWithChildren<{
  onSubmit: (data: any) => void;
  initialData: any;
}>

export type ModalContextType = {
  open: boolean;
  setOpen: (value: boolean) => void;
  toggleOpen: () => void;
} & ModalProviderProps

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider = ({ onSubmit, initialData, children }: ModalProviderProps) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  const value = {
    open,
    setOpen,
    toggleOpen,
    onSubmit,
    initialData
  };

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
};
