$ ->
  { loadJSONComponentAndSchema } = require('../jsoneditor.coffee')
  $('.survey-category-editor').find('.survey-category-name-editor').find('.json-editor').each (i) ->
    $surveyCategoryName = $(this)   
    $surveyCategoryName.on 'json-editor-load', ->
      loadJSONComponentAndSchema($surveyCategoryName, ".js-survey-category-name-field:eq(#{i})", "smart_strings")
      $surveyCategoryName[0].jsoneditor.set({ default: "" }) if $.isEmptyObject($surveyCategoryName[0].jsoneditor.get())

  $('.survey-category-editor').on 'keyup', ->
    if $(this).find('.input-error').length > 0
      $('#save-survey-category').prop('disabled', true)
    else
      $('#save-survey-category').prop('disabled', false)