var html2canvas = require("html2canvas");

jQuery(function () {
  jQuery("#copy-image-button").on("click", function (e) {
    var contentToCopy = document.getElementById("content-to-copy");
    contentToCopy.classList.add("deactivate-unsupported-style");
    html2canvas(contentToCopy, { useCORS: true }).then(function (canvas) {
      contentToCopy.classList.remove("deactivate-unsupported-style");
      canvas.toBlob(function (blob) {
        var item = new ClipboardItem({ "image/png": blob });
        navigator.clipboard
          .write([item])
          .then(function () {
            e.target.innerHTML = "Copied!";
          })
          .catch(function (error) {
            console.error("Error copying to clipboard: ", error);
          });
      });
    });
  });
  jQuery("#copy-text-button").on("click", function (e) {
    var range = document.createRange();
    range.selectNode(document.getElementById("content-to-copy"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    e.target.innerHTML = "Copied!";
  });
});
