import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

//special class can be added to any column, but needs to be included in stylesheets of this component
const SimpleTable = ({ columns = [], rows = [] }) => {
  let colSpanPercent = calculateColSpanPercent(columns);

  return (
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr>
          {columns.map(({ header, colSpan = 1, colStyle }, index) => {
            return (
              <th
                key={index}
                className={appendClassName(colStyle)}
                style={{ width: `${colSpanPercent * colSpan}%` }}
              >
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {rows.map((row, index) => {
          return (
            <tr key={index}>
              {columns.map(({ id, colStyle }, index) => {
                return (
                  <td key={index} className={appendClassName(colStyle)}>
                    {row[id] && row[id]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const appendClassName = (colStyle) => {
  if (colStyle) {
    let stylesIsArray = Array.isArray(colStyle);
    return stylesIsArray ? cx(...colStyle) : cx(colStyle);
  }
};

//calculates how many percent is one col based on how many columns with span are in array
let calculateColSpanPercent = (columns = []) => {
  let total = 0;

  columns.forEach((col) => {
    let { colSpan } = col;
    if (!colSpan) {
      colSpan = 1;
    }
    total += colSpan;
  });

  return 100 / total;
};

export default SimpleTable;
